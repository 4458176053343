import { gql } from '@apollo/client';

export const GET_AVAILABLE_SLOTS = gql`
  query GetAvailableSlots(
    $business: Int!
    $service: Int!
    $appointmentDate: Date!
    $subBusinesses: [Int]
    $adults: Int!
    $appointmentWindow: AppointmentWindow!
  ) {
    getAvailability(
      business: $business
      service: $service
      subBusinesses: $subBusinesses
      appointmentDate: $appointmentDate
      appointmentWindow: $appointmentWindow
      adults: $adults
    ) {
      first {
        business {
          id
          name
        }
        subBusiness {
          id
          name
        }
        startTime
        startDateOnly
        startTimeOnly
        endTime
        endDateOnly
        endTimeOnly
        timeZone
      }
      second {
        business {
          id
          name
        }
        subBusiness {
          id
          name
        }
        startTime
        startDateOnly
        startTimeOnly
        endTime
        endDateOnly
        endTimeOnly
        timeZone
      }
      third {
        business {
          id
          name
        }
        subBusiness {
          id
          name
        }
        startTime
        startDateOnly
        startTimeOnly
        endTime
        endDateOnly
        endTimeOnly
        timeZone
      }
      fourth {
        business {
          id
          name
        }
        subBusiness {
          id
          name
        }
        startTime
        startDateOnly
        startTimeOnly
        endTime
        endDateOnly
        endTimeOnly
        timeZone
      }
      fifth {
        business {
          id
          name
        }
        subBusiness {
          id
          name
        }
        startTime
        startDateOnly
        startTimeOnly
        endTime
        endDateOnly
        endTimeOnly
        timeZone
      }
      others {
        business {
          id
          name
        }
        subBusiness {
          id
          name
        }
        startTime
        startDateOnly
        startTimeOnly
        endTime
        endDateOnly
        endTimeOnly
        timeZone
      }
    }
  }
`;



export const CREATE_USER_APPOINTMENT = gql`
  mutation CreateUserAppointment($appointmentInput: AppointmentInput!) {
    createUserAppointment(appointmentInput: $appointmentInput) {
      isCreated
      errorMessage
      user {
        id
        name
      }
      appointment {
        id
        adults
        children
        allocatedTime
        timeZone
        business {
          id
          name
          description
          address1
          city
          state
          appointmentConfirmationOffset
          isAppointmentConfirmationRequired
          categoryTags
          safetyTags
          phone
          webAddress
          menus {
            id
            path
          }
          menu {
            id
            path
          }
          pictures {
            id
            path
          }
          picture {
            id
            path
          }
          promotions {
            id
            path
            text
          }
          isFavorite
          serviceRatings {
            ratingType
            value
            reviewsCount
            modifiedTime
          }
        }
        service {
          name
          id
        }
        subBusiness {
          id
          name
          description
          address1
          appointmentConfirmationOffset
          categoryTags
          safetyTags
          phone
          isFavorite
        }
        comments {
          id
          comment {
            id
            commentText
            createdTime
            modifiedTime
            user {
              id
              name
              firstName
              lastName
              email
            }
          }
          messageDirection
        }
        venueEntrance {
          id
          name
        }
        startTime
        startTimeOnly
        endTime
        status
      }
    }
  }
`;