import styled from "styled-components";

export const NextArrow = styled.div`
position: absolute;
top: 35%;
z-index: 2;
font-size: 46px;
cursor: pointer;
right: -15px;
`;

export const PreviousArrow = styled.div`
position: absolute;
top: 35%;
z-index: 2;
font-size: 46px;
cursor: pointer;
left: -15px
`;


export const Wrapper = styled.div`
  height: 170px;
  margin: 0px 18px;
  position: relative;
  border-radius: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  gap: 22px;
  @media (max-width: 768px) {
    flex-direction: column;
    height: 280px;
    padding: 0;
  }
`;

export const ImgWrapper = styled.div`
  width: 60%;
  display: flex;
  align-items: center;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const TextWrapper = styled.div`
  width: 40%;
  border-radius: 10px;
  text-align: center;
  padding: 10px 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const CouponImage = styled.img`
  width: 100%;
  height: inherit;
  object-fit: contain;
`;

export const CouponTitle = styled.h6`
  font-weight: 600;
  font-size: 22px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;
export const CouponSummary = styled.p`
  font-style: italic;
  text-overflow: ellipsis;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
`;

export const CouponExpires = styled.p`
font-size: 14px;
font-weight: 600;
`;