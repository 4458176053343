import React from 'react'
import { MessageBoxScrollList, StyledMessageLoader } from '../../ManageUserMessages.styled'
import MessageItem from '../MessageItem/MessageItem'
const MessageBody = ({ allchats }) => {
    return (
        <>
            <MessageBoxScrollList id='scroll-list'>
                {allchats.map((chat) => <MessageItem chat={chat} />)}

            </MessageBoxScrollList>
            {/* <div style={{ background: "red", height: "200px" }}></div> */}
        </>

    )
}

export default MessageBody