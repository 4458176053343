import React, { useState, useEffect } from 'react';
import { isEmpty } from 'lodash';
import { Columns, Column, Button } from '@safelyq/bulma-ui-library';
import { TRAFFIC_SETUP_MESSAGES } from '../../common/constants';
import { FiInfo } from "react-icons/fi";
import { Tooltip } from 'antd';
import { toast } from 'react-toastify';

export const TrafficSetup = ({ tab, data: business, onSubmit, isLoading }) => {
  const [values, setValues] = useState({});

  const [isDirty, setDirty] = useState(false);

  useEffect(() => {
    if (!isEmpty(business)) {
      const {
        totalCapacity,
        allowedCapacityAsPercentage,
        allowedCapacityInUnits,
        startingOffset,
        appointmentGap,
        endingOffset,
        maxAllowedGuests,
        isChildrenAllowed,
        oldAppointmentsThreshold,
        allowUserSchedule,
        allowBusinessSchedule,
      } = business;

      setValues({
        totalCapacity,
        allowedCapacityAsPercentage,
        allowedCapacityInUnits,
        startingOffset,
        appointmentGap,
        endingOffset,
        maxAllowedGuests,
        isChildrenAllowed,
        oldAppointmentsThreshold,
        allowUserSchedule,
        allowBusinessSchedule,
      });
    }
  }, [business]);

  useEffect(() => {
    let isDirty = false;
    if (!isEmpty(business)) {
      const {
        totalCapacity,
        allowedCapacityAsPercentage,
        allowedCapacityInUnits,
        startingOffset,
        appointmentGap,
        endingOffset,
        maxAllowedGuests,
        isChildrenAllowed,
        oldAppointmentsThreshold,
        allowUserSchedule,
        allowBusinessSchedule,
      } = business;

      if (totalCapacity != values.totalCapacity) isDirty = true;
      if (allowedCapacityAsPercentage != values.allowedCapacityAsPercentage)
        isDirty = true;
      if (allowedCapacityInUnits != values.allowedCapacityInUnits)
        isDirty = true;
      if (startingOffset != values.startingOffset) isDirty = true;
      if (appointmentGap != values.appointmentGap) isDirty = true;
      if (endingOffset != values.endingOffset) isDirty = true;
      if (oldAppointmentsThreshold != values.oldAppointmentsThreshold)
        isDirty = true;
      if (maxAllowedGuests != values.maxAllowedGuests) isDirty = true;
      if (isChildrenAllowed != values.isChildrenAllowed) isDirty = true;
      if (allowUserSchedule != values.allowUserSchedule) isDirty = true;
      if (allowBusinessSchedule != values.allowBusinessSchedule) isDirty = true;
      setDirty(isDirty);
    }
  }, [values]);

  const handleValuesChange = ({ target: { name, value } }) => {
    let allowedCapacityInUnits = 0;

    if (name === 'allowedCapacityAsPercentage' && values.totalCapacity) {
      allowedCapacityInUnits = String((value / 100) * values.totalCapacity);

      setValues({ ...values, [name]: value, allowedCapacityInUnits, maxAllowedGuests: allowedCapacityInUnits });

      return;
    }

    if (name === 'totalCapacity' && values.allowedCapacityAsPercentage) {
      allowedCapacityInUnits = String(
        (values.allowedCapacityAsPercentage / 100) * value
      );

      setValues({ ...values, [name]: value, allowedCapacityInUnits });

      return;
    }

    if (name === 'maxAllowedGuests') {
      let eValue = value
      const GuestInNumber = parseInt(value)
      const capicityInNumber = parseInt(values.allowedCapacityInUnits)
      if (GuestInNumber > capicityInNumber) {
        eValue = values.allowedCapacityInUnits
        toast.warning("Maximum guests in a group should be less or equal to Final Capacity")
      }
      else {
        eValue = value
      }
      setValues({ ...values, [name]: eValue, });

      return;
    }
    setValues({ ...values, [name]: value });
  };

  const handleChildrenAllowed = ({ target: { name, checked } }) =>
    setValues({ ...values, [name]: checked });

  const handleUserScheduledAllowed = ({ target: { name, checked } }) =>
    setValues({ ...values, [name]: checked });

  const handleBusinessScheduledAllowed = ({ target: { name, checked } }) =>
    setValues({ ...values, [name]: checked });

  const handleSave = () => {
    if (tab == 'LOCATION') {
      onSubmit({ businessTrafficSetup: { ...values } }, 7, {
        success: TRAFFIC_SETUP_MESSAGES.Success,
        error: TRAFFIC_SETUP_MESSAGES.Error,
      });
    } else {
      onSubmit({ businessTrafficSetup: { ...values } }, 7, business.id, {
        success: TRAFFIC_SETUP_MESSAGES.Success,
        error: TRAFFIC_SETUP_MESSAGES.Error,
      });
    }
  };
  const validate = () => {
    if (
      !isEmpty(String(values.totalCapacity)) &&
      !isEmpty(String(values.allowedCapacityAsPercentage)) &&
      !isEmpty(String(values.allowedCapacityInUnits)) &&
      !isEmpty(String(values.startingOffset)) &&
      !isEmpty(String(values.appointmentGap)) &&
      !isEmpty(String(values.endingOffset)) &&
      !isEmpty(String(values.maxAllowedGuests))
    )
      return true;
    return false;
  };

  const isSubmitEnable = validate();

  return (
    <Columns multiline>
      <Column size='4'>
        <div className='control'>
          <lable className='label is-flex is-justify-content-space-between is-align-content-center is-align-items-center'>
            <div>Total Capacity</div>
            <Tooltip title="Maximum number of customers in the facility (per fire code occupancy)"> <FiInfo /> </Tooltip>
          </lable>
          <input
            name='totalCapacity'
            type='number'
            value={values.totalCapacity}
            min='0'
            className={`input`}
            onChange={handleValuesChange}
          />
        </div>
      </Column>
      <Column size='4'>
        <div className='control'>
          <lable className='label'>% of Capacity</lable>
          <input
            name='allowedCapacityAsPercentage'
            type='number'
            value={values.allowedCapacityAsPercentage}
            min='1'
            max='100'
            className={`input`}
            onChange={handleValuesChange}
          />
        </div>
      </Column>
      <Column size='4'>
        <div className='control'>
          <lable className='label'>Final Capacity</lable>
          <input
            name='allowedCapacityInUnits'
            type='number'
            value={values.allowedCapacityInUnits}
            min='0'
            disabled
            className={`input`}
          />
        </div>
      </Column>
      <Column size='4'>
        <div className='control'>
          <lable className='label is-flex is-justify-content-space-between is-align-content-center is-align-items-center'>

            <div>  Start Offset <small>(in minutes)</small></div>
            <Tooltip title="The time (in minutes) at the start of the day's appointments"> <FiInfo /> </Tooltip>
          </lable>
          <input
            name='startingOffset'
            type='number'
            value={values.startingOffset}
            min='0'
            className={`input`}
            onChange={handleValuesChange}
          />
        </div>
      </Column>
      <Column size='4'>
        <div className='control'>
          <lable className='label is-flex is-justify-content-space-between is-align-content-center is-align-items-center'>

            <div> Gap between appointment <small>(in minutes)</small></div>
            <Tooltip title="The time (in minutes) at the end of the day's appointments"> <FiInfo /> </Tooltip>
          </lable>
          <input
            name='appointmentGap'
            type='number'
            value={values.appointmentGap}
            min='0'
            className={`input`}
            onChange={handleValuesChange}
          />
        </div>
      </Column>
      <Column size='4'>
        <div className='control'>
          <lable className='label is-flex is-justify-content-space-between is-align-content-center is-align-items-center'>

            <div> End Offset <small>(in minutes)</small></div>
            <Tooltip title="The time (in minutes) between each appointment"> <FiInfo /> </Tooltip>
          </lable>
          <input
            name='endingOffset'
            type='number'
            value={values.endingOffset}
            min='0'
            className={`input`}
            onChange={handleValuesChange}
          />
        </div>
      </Column>
      <Column size='4'>
        <div className='control'>
          <lable className='label is-flex is-justify-content-space-between is-align-content-center is-align-items-center'>

            <div> Old appointment threshold <small>(in minutes)</small></div>
            <Tooltip title="???"> <FiInfo /> </Tooltip>
          </lable>
          <input
            name='oldAppointmentsThreshold'
            type='number'
            value={values.oldAppointmentsThreshold}
            min='0'
            className={`input`}
            onChange={handleValuesChange}
          />
        </div>
      </Column>
      <Column size='4'>
        <div className='control'>
          <lable className='label is-flex is-justify-content-space-between is-align-content-center is-align-items-center'>
            <div>Maximum guests in a group</div>
            <Tooltip title="Number of additional guests allowed per appointment"> <FiInfo /> </Tooltip>
          </lable>
          <input
            name='maxAllowedGuests'
            type='number'
            value={values.maxAllowedGuests}
            min='0'
            max={values.allowedCapacityInUnits}
            className={`input`}
            onChange={handleValuesChange}
          />
        </div>
      </Column>
      <Column size='4'>
        <div className='control'>
          <label className='label is-hidden-mobile'>&nbsp;</label>
          <label className='checkbox'>
            <input type='checkbox' name='allowUserSchedule' checked={values?.allowUserSchedule} onChange={handleUserScheduledAllowed} />

            <strong className='ml-2'>  User Appointment Scheduled Allowed ?  </strong>
            <Tooltip title="Should users be able to schedule appointments"> <FiInfo /> </Tooltip>
          </label>
        </div>
      </Column>
      <Column size='4'>
        <div className='control'>
          <label className='label is-hidden-mobile'>&nbsp;</label>
          <label className='checkbox'>
            <input
              type='checkbox'
              name='allowBusinessSchedule'
              checked={values?.allowBusinessSchedule}
              onChange={handleBusinessScheduledAllowed}
            />
            <strong className='ml-2'>  Business Appointment Scheduled Allowed ?   </strong>

            <Tooltip title="Should business admins be able to schedule appointments"> <FiInfo /> </Tooltip>
          </label>
        </div>
      </Column>
      <Column size='4'>
        <div className='control'>
          <label className='label is-hidden-mobile'>&nbsp;</label>
          <label className='checkbox'>
            <input
              type='checkbox'
              name='isChildrenAllowed'
              checked={values.isChildrenAllowed}
              onChange={handleChildrenAllowed}
            />
            <strong className='ml-2'>Children Allowed ?</strong>

            <Tooltip title="Are children allowed at this business"> <FiInfo /> </Tooltip>
          </label>
        </div>
      </Column>
      <Column size='12'>
        <Button
          type='submit'
          color='info'
          disabled={!isSubmitEnable || isLoading}
          loading={isLoading}
          onClick={handleSave}
        >
          Save
        </Button>
      </Column>
    </Columns>
  );
};
