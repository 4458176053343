
import React, { Fragment, useState } from 'react';
import { Icon, Button, } from '@safelyq/bulma-ui-library';
import { statusEnum } from '../../constants';
import moment from 'moment';
import { useMutation } from '@apollo/client';
import { UPDATE_APPOINTMENT_STATUS } from '../../requests';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { changeApptStatus } from 'Redux/AuthSlice/AuthSlice';
import FullPageLoader from 'sharedComponents/FullPageLoader/FullPageLoader';

import { Button as AntButton, Modal } from 'antd';


const AppointmentDropdown = ({ appt, apptId, handleOpenPopup, apptStatus, isAllowToCancel, isAllowToEdit, isConfirmationRequired, confirmationOffset, appointmentTime, questions, preCheckinOffset }) => {
    const isBooked = apptStatus === statusEnum.BOOKED;

    const isCancelled = apptStatus === statusEnum.CANCELED;

    const isPassed = apptStatus === statusEnum.PASSED;

    const isConfirmed = apptStatus === statusEnum.CONFIRMED;

    const isCompleted = apptStatus === statusEnum.COMPLETED;

    const isCheckedIn = apptStatus === statusEnum.CHECKEDIN;

    const isBusinessCheckedIn = apptStatus === statusEnum.CHECKED_IN_BY_BUSINESS;

    const isServing = apptStatus === statusEnum.SERVING_NOW;
    const isCancelable = !isCancelled && !isCompleted && !isPassed && isAllowToCancel && !isServing && !isCheckedIn && !isBusinessCheckedIn;
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [onStatusChange, { data, loading, error }] = useMutation(UPDATE_APPOINTMENT_STATUS)
    const dispatch = useDispatch()






    const handleOk = async () => {
        try {
            const response = await onStatusChange({ variables: { id: apptId, status: statusEnum.CANCELED } })
            if (response.data.updateAppointmentStatus.isSaved === false) {
                const errorMessage = { code: 403, message: response.data.updateAppointmentStatus.errorMessage };
                throw errorMessage;
            }
            else {

                dispatch(changeApptStatus({ apptID: apptId, apptStatus: statusEnum.CANCELED }))
                setIsModalOpen(false);
                toast.success(`You appointment has been cancelled`);
            }

        } catch (error) {
            console.log("ERRROR", error);
            toast.error(error.message);
        }
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const isCheckedable = () => {
        const currentTime = moment(new Date()).utc();
        const checkInThreshold = moment(appointmentTime).diff(moment(currentTime), 'minutes');
        return (checkInThreshold <= preCheckinOffset && (isConfirmationRequired ? isConfirmed : isBooked));
    };

    const isConfirmable = () => {
        const currentTime = moment(new Date()).utc();
        const checkInThreshold = moment(appointmentTime).diff(moment(currentTime), 'minutes');
        return (checkInThreshold <= confirmationOffset && isBooked && isConfirmationRequired);
    };

    const statusActions = { isAllowCancel: isCancelable, isAllowCheckin: isCheckedable(), isAllowConfirm: isConfirmable(), };

    const handlechangeAppt = async (status) => {
        try {
            const response = await onStatusChange({ variables: { id: apptId, status } })
            if (response.data.updateAppointmentStatus.isSaved === false) {
                const errorMessage = { code: 403, message: response.data.updateAppointmentStatus.errorMessage };
                throw errorMessage;
            }
            else {

                dispatch(changeApptStatus({ apptID: apptId, apptStatus: status }))
                toast.success(`You appointment has been cancelled`);
            }

        } catch (error) {
            console.log("ERRROR", error);
            toast.error(error.message);
        }
    };

    const handleCancelAppt = () => {
        setIsModalOpen(true)
    }

    // const handleCheckedin = () => onStatusChange({ variables: { id, status: statusEnum.UPDATE_CHECKEDIN } });

    // const handleConfirm = () => {
    //     if (questions?.length > 0) push(`/${id}/confirm-checkin`);
    //     else onStatusChange({ variables: { id, status: statusEnum.CONFIRMED } });
    // };

    return (
        <Fragment>
            {loading && <FullPageLoader />}
            <Modal title="Cancel Appointment" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}
                footer={[
                    <AntButton key="submit" type="primary" loading={loading} onClick={handleOk}>  Yes    </AntButton>,
                    <AntButton key="back" onClick={handleCancel}>   Cancel  </AntButton>,


                ]}

            >
                <p>Are you sure you want to cancel appointment</p>

            </Modal>
            <div className='dropdown is-hoverable is-right ml-3'>
                <div className='dropdown-trigger'>
                    <Button size='small' className='button-icon' aria-haspopup='true' aria-controls='actions-dropdown'  >
                        <Icon name='ellipsis-v' color='info' scale='lg' />
                    </Button>
                </div>
                <div className='dropdown-menu' id='actions-dropdown' role='menu'>
                    <div className='dropdown-content'>
                        {isConfirmable() && (<div className='dropdown-item' onClick={() => handlechangeAppt(statusEnum.CONFIRMED)}  > <Icon name='check' color='info' />  Confirm Appointment  </div>)}
                        {isCheckedable() && (<div className='dropdown-item' onClick={() => handlechangeAppt(statusEnum.UPDATE_CHECKEDIN)} >   <Icon name='check-square-o' color='info' />   Checked-In  </div>)}
                        <div className='dropdown-item' onClick={() => handleOpenPopup(appt)}>   <Icon name='pencil' color='success' />   Edit/View  </div>
                        {isCancelable && (<div className='dropdown-item' onClick={() => handleCancelAppt(statusEnum.CANCELED)} >   <Icon name='close' color='danger' />   Cancel Appointment  </div>)}
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default AppointmentDropdown