import React, { useState, useEffect } from 'react';
import { Columns } from 'react-bulma-components';
import Constants from 'constants/index';

/* eslint jsx-a11y/anchor-is-valid: 0 */

const SubLevelView = ({ subLevelSlot, selectedSlot, onChange }) => {
  const [pageSize, setPageSize] = useState(
    Constants.DEFAULT_TIME_SLOTS_PAGE_SIZE
  );
  const loadMoreRecords = () => {
    setPageSize(pageSize + Constants.DEFAULT_TIME_SLOTS_PAGE_SIZE);
  };
  const resetPageSize = () => {
    setPageSize(Constants.DEFAULT_TIME_SLOTS_PAGE_SIZE);
  };

  useEffect(() => {
    setPageSize(Constants.DEFAULT_TIME_SLOTS_PAGE_SIZE);
  }, [subLevelSlot]);

  return (
    <>
      <Columns.Column
        key={`subLevelName_${subLevelSlot.subBusiness.id}`}
        size={12}
        className={`px-1 is-size-5 py-0 my-2 ${subLevelSlot.subBusiness.name ? '' : 'is-hidden'}`}
      >
        {subLevelSlot.subBusiness.name}
      </Columns.Column>
      {subLevelSlot.appointmentSlots
        .slice(0, pageSize)
        .map((slot, appointmentSlotIndex) => (
          <Columns.Column
            key={`level_slot_${appointmentSlotIndex}_${subLevelSlot.id}`}
            size={3}
            className="px-1 is-size-5 py-0 my-2  is-multiline"
          >
            <div
              key={`level_slot_tab_${subLevelSlot.id}`}
              className={`timeTab-slot false ${
                selectedSlot &&
                slot.startTimeOnly === selectedSlot.startTimeOnly &&
                slot.subBusiness.id === selectedSlot.subBusiness.id
                  ? 'selected'
                  : ''
              }`}
              onClick={() => {
                onChange(slot);
              }}
            >
              {slot.startTimeOnly}
            </div>
          </Columns.Column>
        ))}

      {subLevelSlot.appointmentSlots.length >
        Constants.DEFAULT_TIME_SLOTS_PAGE_SIZE && (
        <>
          <Columns.Column
            key={`showMore_GroupBySubLevelView_${subLevelSlot.subBusiness.id}`}
            size={12}
            className={`px-1 is-size-5 py-0 my-2 ${
              pageSize >= subLevelSlot.appointmentSlots.length && 'is-hidden'
            }`}
          >
            <div className={`buttons mt-4 is-centered`}>
              <a
                className="link is-size-6 is-info"
                href="/#"
                onClick={(e) => {
                  e.preventDefault();
                  loadMoreRecords();
                }}
              >
                More
                <span className="icon is-size-6">
                  <i className="fa fa-angle-down"></i>
                </span>
              </a>
            </div>
          </Columns.Column>

          <Columns.Column
            key={`showLess_GroupBySubLevelView_${subLevelSlot.subBusiness.id}`}
            size={12}
            className={`px-1 is-size-5 py-0 my-2 ${
              pageSize < subLevelSlot.appointmentSlots.length && 'is-hidden'
            }`}
          >
            <div className={`buttons mt-4 is-centered`}>
              <a
                className="link is-size-6 is-info"
                href="/#"
                onClick={(e) => {
                  e.preventDefault();
                  resetPageSize();
                }}
              >
                Less
                <span className="icon is-size-6">
                  <i className="fa fa-angle-up"></i>
                </span>
              </a>
            </div>
          </Columns.Column>
        </>
      )}
    </>
  );
};

export default SubLevelView;
