import React, { useState, useEffect, Fragment } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import useData from './hook/useData';
import { useQuery } from '@apollo/client';
import Queries from '../Common/Queries';
import './checkin.css';
import CurrentAppointmentInfo from './CurrentAppointmentInfo';
import moment from 'moment';
import ConfirmationMessage from './ConfirmationMessage';
import Constants from 'constants/index';
import Button from './Button';
import Instruction from './Instruction';
import BusinessQuestions from './BusinessQuestions';
import { useSelector } from 'react-redux';

const ConfirmCheckIn = () => {
  const { allAppointments } = useSelector((state) => state.auth);
  const { id: appointmentId } = useParams();
  const [answers, setAnswers] = useState([]);
  const [confirmedLocal, setConfirmedLocal] = useState(false);
  const [appointmentData, setAppointmentData] = useState(null);
  const [timeGap, setTimeGap] = useState(0);
  const { loading, error, refetch, data } = useQuery(Queries.GET_APPOINTMENT, {
    variables: { appointmentId: appointmentId },
  });

  const { onUpdateAppointmentStatus } = useData(
    { service: {}, subBusiness: {}, business: { details: {} } },
    refetch
  );

  const onAnswerChange = (id, name, value) => {
    const tempAnswers = [...answers];

    let answer = tempAnswers.find((answer) => answer.questionId == id);

    if (answer) {
      answer.questionId = id;
      answer[name] = value;

      setAnswers([...answers]);
    } else {
      setAnswers([...answers, { questionId: id, [name]: value }]);
    }
  };

  useEffect(() => {
    const getCurrentAppointment = allAppointments.find((appt) => appt.id === parseInt(appointmentId))
    if (getCurrentAppointment) {
      setAppointmentData(getCurrentAppointment)
    }
  }, [appointmentId, allAppointments])

  useEffect(() => {
    if (loading) {
      //message.loading('Fetching Appointment Details...');
    }

    if (error) {
      toast.error(error.message || 'Error while fetching info...');
      return null;
    }

    if (data) {
      // setAppointmentData(data.getUserAppointmentById || {});
    }
  }, [loading, error, data, appointmentId]);

  useEffect(() => {
    if (appointmentData) {
      const { timeZone, startTime } = appointmentData;

      const nowInBizTZ = moment(new Date()).tz(timeZone);

      const apptTime = moment(startTime).tz(timeZone);

      let minutes = apptTime.diff(nowInBizTZ, 'minutes');

      setTimeGap(minutes);
    }
  }, [appointmentData]);

  useEffect(() => {
    if (timeGap) {
      const interval = setInterval(() => {
        const {
          timeZone,
          startTime,
          business: { appointmentConfirmationOffset },
        } = appointmentData;

        var nowInBizTZ = moment(new Date()).tz(timeZone);

        var apptTime = moment(startTime).tz(timeZone);

        let minutes = apptTime.diff(nowInBizTZ, 'minutes');

        if (minutes <= appointmentConfirmationOffset) {
          setTimeGap(minutes);
        }
      }, 60000);
      return () => clearInterval(interval);
    }
  }, [timeGap, appointmentData]);

  const handleStatusChange = (status, userResponses) => {
    onUpdateAppointmentStatus({
      variables: {
        appointmentId: appointmentId,
        newStatus: status,
        userResponses: userResponses ?? [],
      },
    });
  };

  const handleConfirmed = () => {
    handleConfirmedLocal();

    handleStatusChange(Constants.CONFIRMED_STATUS_NAME, answers);
  };

  const handleConfirmedLocal = () => {
    setConfirmedLocal((prev) => !prev);
  };

  return (
    <div className='mb-6'>
      {appointmentData && (
        <Fragment>
          <section className='px-1 pt-0 pb-1'>
            <CurrentAppointmentInfo appointmentData={appointmentData} />
          </section>

          <section className='section pt-4 px-5 pb-2'>
            <div className='container px-1 has-text-centered '>
              {confirmedLocal &&
                appointmentData?.business?.questions?.length > 0 ? (
                <QuestionPanel
                  data={appointmentData}
                  onConfirm={handleConfirmed}
                  answers={answers}
                  onAnswerChange={onAnswerChange}
                />
              ) : (
                <div className='is-flex is-flex-direction-column is-align-items-center'>
                  <ConfirmationMessage
                    data={appointmentData}
                    interval={timeGap}
                    confirmedLocal={confirmedLocal}
                    onConfirm={handleConfirmed}
                    onLocalConfirm={handleConfirmedLocal}
                    onCheckIn={() =>
                      handleStatusChange(Constants.CHECKED_IN_INPUT_NAME)
                    }
                  />
                </div>
              )}
            </div>
          </section>
        </Fragment>
      )}
    </div>
  );
};

const QuestionPanel = ({ ...props }) => {
  const { onAnswerChange, data, onConfirm, answers } = props;
  const { preCheckinInstructions, questions } = useData(data, () => { });

  return (
    <Fragment>
      <section className='px-1 pt-2 pb-1'>
        <Instruction preCheckinInstructions={preCheckinInstructions} />
      </section>

      <section className='section container px-0 py-4'>
        <BusinessQuestions
          questions={questions}
          onAnswerChange={onAnswerChange}
        />

        <Button
          clicked={onConfirm}
          disable={questions.length > 0 && answers.length < questions.length}
          label='Confirm'
        />
      </section>
    </Fragment>
  );
};

export default ConfirmCheckIn;
