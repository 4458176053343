import { useContext, useEffect } from 'react';
import PageTitleContext from 'contexts/PageTitleContext';

const usePageTitle = (pageTitle) => {
  const { setPageTitle } = useContext(PageTitleContext);

  useEffect(() => {
    setPageTitle(pageTitle);
  }, [pageTitle, setPageTitle]);

  return { pageTitle, setPageTitle };
};

export default usePageTitle;
