import { useState, useEffect } from 'react';
import _ from 'lodash';
import moment from 'moment-timezone';

const dayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

export const useWeekDays = ({ slots, timeZone }) => {
  const [days, setDays] = useState();

  const [status, setStatus] = useState('');

  const [openAt, setOpen] = useState('');

  const [closeAt, setClose] = useState('');

  let businessCurrentTime = moment().tz(timeZone);

  const businessCurrentDay = businessCurrentTime.day();

  const isCurrentDayExist = days?.find(
    ({ id }) => String(id) === String(`dayOfWeek_${businessCurrentDay}`)
  );

  useEffect(() => {
    const daySlots = _.chain(slots)
      .groupBy('dayOfWeek')
      .map((daySlots, key) => ({
        id: `dayOfWeek_${key}`,
        dayOfWeek: parseInt(key),
        openingTime: moment.min(
          daySlots.map((daySlot) => moment(daySlot.openingTime))
        ),
        closingTime: moment.max(
          daySlots.map((daySlot) => moment(daySlot.closingTime))
        ),
      }))
      .value();
    const dumpArray = [];
    daySlots.map(({ openingTime, closingTime, dayOfWeek, id }) => {
      openingTime = moment(openingTime).format('hh:mm A');
      closingTime = moment(closingTime).format('hh:mm A');
      let dayName = dayNames[dayOfWeek];
      dumpArray.push({ openingTime, closingTime, dayName, id });
    });
    setDays(dumpArray);
  }, []);

  useEffect(() => {
    if (isCurrentDayExist) {
      let openingTime = isCurrentDayExist.openingTime;
      let closingTime = isCurrentDayExist.closingTime;
      businessCurrentTime = moment(businessCurrentTime).format('hh:mm A');

      let isCurrentTimeBetween = moment(
        businessCurrentTime,
        'hh:mm A'
      ).isBetween(
        moment(openingTime, 'hh:mm A'),
        moment(closingTime, 'hh:mm A')
      );

      if (isCurrentTimeBetween) {
        setStatus('OPEN');
        setClose(closingTime);
      } else {
        setStatus('CLOSED');
        setOpen(openingTime);
      }
    }
  }, [days]);

  return { days, isCurrentDayExist, status, openAt, closeAt };
};
