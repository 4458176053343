import React, { useState, useEffect, Fragment } from 'react';
import { Link } from 'react-router-dom';
import * as _ from 'lodash';
import {
  Block,
  Text,
  Subtitle,
  Icon,
  Buttons,
  Button,
  Content,
  Paragraph,
} from '@safelyq/bulma-ui-library';
import { useBookingDispatch, useBookingState } from '../../../../context';

export const GroupByTimeView = ({
  serviceSubLevels = [],
  availableSlots = [],
}) => {
  const dispatch = useBookingDispatch();
  const { selectedSlot } = useBookingState();

  const [slots, setSlots] = useState([]);

  const [pageSize, setPageSize] = useState(5);

  const handleLoadMore = () => setPageSize(pageSize + 5);

  const handleReset = () => setPageSize(5);

  const handleSlotChange = (slot) =>
    dispatch({ type: 'SET_SLOT', payload: slot });

  useEffect(() => {
    var result = _.chain(availableSlots)
      .groupBy('startTimeOnly')
      .map((slots, startTime) => {
        const subLevels = slots.map((slot, index) => {
          var currentSubLevel = serviceSubLevels.find((ssl) => {
            return ssl.id === slot.subBusiness.id;
          });

          return {
            ...slot,
            name: currentSubLevel?.name,
          };
        });

        return {
          startTime: startTime,
          subLevels: subLevels,
        };
      })
      .value();
    setSlots(result);
  }, [availableSlots, pageSize]);

  if (_.isEmpty(slots)) return <NotFoundSection />;

  return (
    <Fragment>
      {slots.slice(0, pageSize).map(({ startTime, subLevels }, index) => (
        <Block key={`subLevel-${index}`}>
          <Subtitle size='5'>
            <Icon name='clock-o' collection='fa' size='small' />
            {' ' + startTime}
          </Subtitle>
          <Buttons size='small'>
            {subLevels.map((subLevel, index) => (
              <Button
                light={!_.isEqual(subLevel, selectedSlot)}
                color='info'
                key={`slot-${index}`}
                onClick={() => handleSlotChange(subLevel)}
              >
                <Text>{typeof subLevel.name !== 'undefined' ? subLevel.name : 'Slot'}</Text>
                {_.isEqual(subLevel, selectedSlot) && (
                  <Icon name='check' collection='fa'></Icon>
                )}
              </Button>
            ))}
          </Buttons>
          <hr />
        </Block>
      ))}
      {pageSize < slots.length && (
        <div className='icon-text is-justify-content-center'>
          <Link to='#' onClick={handleLoadMore}>
            Load More <Icon name='angle-down' collection='fa'></Icon>
          </Link>
        </div>
      )}
      {pageSize >= slots.length && (
        <div className='icon-text is-justify-content-center'>
          <Link to='#' onClick={handleReset}>
            Show Less <Icon name='angle-up' collection='fa'></Icon>
          </Link>
        </div>
      )}
    </Fragment>
  );
};

const NotFoundSection = ({ role }) => {
  return (
    <Content className='py-5'>
      <Paragraph alignment='centered' weight='bold' color='grey-light'>
        No slots available
      </Paragraph>
    </Content>
  );
};
