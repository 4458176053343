import React from 'react';
import BusinessSlots from '../BusinessSlots';

const BusinessInfo = ({ businessData }) => {
    return (
        <div className="container">
            <div
                style={{
                    boxShadow:
                        '0 0.5em 1em -0.125em rgba(10,10,10,.1), 0 0 0 1px rgba(10,10,10,.02)',
                }}
            >
                <div className="mb-0 px-3 py-2">
                    <div className="columns is-mobile">
                        <div className="column is-8 is-size-5 has-text-weight-bold">
                            {businessData.name}
                        </div>
                        <div className="column is-4 has-text-right">
                            <span className="icon is-size-5 mr-2 mt-2">
                                <i className="fa fa-heart blue-color" />
                            </span>
                            {/*<span className="icon is-size-5 ">
                            <i className="fa fa-share-alt" />
                        </span>*/}
                        </div>
                    </div>
                </div>
                <div className="card-content py-1 px-1">
                    <div className="container">
                        <div className="columns mb-0">
                            {/*<div className="column is-12 ml-2 pb-0">
                            <span className="icon is-size-5 is-size-6-mobile">
                                <i className="fa fa-star yellow-color" />
                            </span>
                            <span className="icon is-size-5 is-size-6-mobile">
                                <i className="fa fa-star yellow-color" />
                            </span>
                            <span className="icon is-size-5 is-size-6-mobile">
                                <i className="fa fa-star yellow-color" />
                            </span>
                            <span className="icon is-size-5 is-size-6-mobile">
                                <i className="fa fa-star yellow-color" />
                            </span>
                            <span className="icon is-size-5 is-size-6-mobile">
                                <i className="fa fa-star-half-empty yellow-color" />
                            </span>
                            <span className="rating-text">
                                <span>4.5/5(345)</span>
                            </span>
                        </div>*/}
                        </div>

                        <div className="columns ml-0 mt-0">
                            <div className="column is-12 pt-0">
                                <div className="subtitle">{businessData.description}</div>
                            </div>
                        </div>

                        <div className="columns is-mobile pl-2 is-vcentered mb-0">
                            <div className="column is-6">
                                <div className="subtitle mb-1 is-inline-block">
                                    <span className="icon is-size-5 is-size-6-mobile">
                                        <i className="fa fa-map-marker blue-color" />
                                    </span>
                                    <span className="is-size-6">{businessData.address1}</span>
                                </div>
                            </div>

                            <div className="column is-6 has-text-right">
                                <div className="mr-3">
                                    {/*<div>4 ml</div>*/}
                                    <span className="icon is-medium direction">
                                        <span className="fa-stack">
                                            <i
                                                className="fa fa-square fa-stack-2x has-text-info"
                                                data-fa-transform="rotate-270"
                                                style={{ transform: 'rotate(45deg)' }}
                                            ></i>
                                            <i
                                                className="fa fa-level-down  fa-stack-1x fa-inverse has-text-white is-size-6"
                                                data-fa-transform="rotate-270"
                                                style={{ transform: 'rotate(270deg)' }}
                                            ></i>
                                        </span>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="columns mb-0 ml-0 mt-0">
                            <div className="column  is-12 pt-0">
                                <BusinessSlots timeZone={businessData.timeZone} slots={businessData.slots || []} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BusinessInfo;
