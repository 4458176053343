import React from 'react';
import HomePageDesktop from './HomePageDesktop';
import './home.css';
const HomePage = () => {
  return (
    <>
      <HomePageDesktop />
      {/* //width < breakpoint ? <HomePageMobile/> : <HomePageDesktop/> */}
    </>

  );
};

export default HomePage;

