import React from 'react';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { Columns, Column, Block } from '@safelyq/bulma-ui-library';
import { SuperAdminNavbar, AdminDrawer } from '@safelyq/base-feature';
import { useAdminCount } from 'hooks/useAdminCount';

export const SuperAdminLayout = ({ ...props }) => {
    const {
        children,
        isAllowSideBar,
        isAllowFullScreen = false,
        isAllowNotifications,
        businessId,
        ...rest
    } = props;

    const { data: unreadCount, businessName, businessPermissions, refetch } = useAdminCount();

    if (businessId) refetch({ id: businessId })

    const handle = useFullScreenHandle();

    const handleFullScreen = () => {
        handle.enter();
    };

    // Add an event listener
    document.addEventListener("refetch", function (e) {
        if (businessId) refetch({ id: businessId });
    });

    const { avatarName, logout } = rest;

    return (
        <Columns multiline gap='0' className='mb-0'>
            <Column size='12'>
                <SuperAdminNavbar
                    onFullScreen={handleFullScreen}
                    isAllowFullScreen={isAllowFullScreen}
                    isAllowSideBar={isAllowSideBar}
                    isAllowNotifications={isAllowNotifications}
                    businessId={businessId}
                    onLogout={logout}
                    avatarName={avatarName}
                    unreadMessages={unreadCount}
                    businessName={businessName}
                    onRefetch={refetch}

                />
            </Column>
            {isAllowSideBar && (
                <Column size={2} className='is-hidden-mobile'>
                    <AdminDrawer
                        isAssociate={businessPermissions.isAssociate}
                        unreadMessages={unreadCount}
                        onRefetch={refetch}
                        toggle={() => { }}
                    />
                </Column>
            )}
            <Column size={isAllowSideBar ? 10 : 12}>
                <FullScreen handle={handle}>
                    <section className='hero p-0 is-white is-fullheight-with-navbar has-text-black'>
                        <Block>{children}</Block>
                    </section>
                </FullScreen>
            </Column>
        </Columns>
    );
};
