import React, { useState, useRef, useEffect } from 'react';
import moment from 'moment';
import 'moment-timezone';
import Moment from 'react-moment';
import _ from 'lodash';

function useOutsideAlerter(ref, onCloseBusinessSlot) {
    useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                onCloseBusinessSlot(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref]);
}

const CurrentBusienssStatus = ({ openStatus, currentSlot }) => {
    return (
        <div id='business-slot-header' className='dropdown-trigger'>
            <button
                className='pl-0  is-size-6'
                style={{ border: 'none', background: 'none' }}
                aria-haspopup='true'
                aria-controls='dropdown-menu6'
            >
                <div style={{ whiteSpace: 'nowrap' }}>
                    <span
                        className={`is-6 is-size-6 has-text-weight-bold ${openStatus === 'Open' ? 'has-text-success' : 'has-text-danger'
                            } has-text-success pr-1`}
                    >
                        {openStatus}
                    </span>
                    {/*currentSlot === 'Open' ? (
                            <span className='is-6 is-size-6 has-text-weight-bold has-text-success pr-1'>
                                Open
                            </span>
                        ) : (
                                <span className='is-6 is-size-6 has-text-weight-bold has-text-danger pr-1'>
                                    Closed
                                </span>
                            )*/}
                    <span>{`${openStatus === 'Open' ? 'Closes at ' : 'Opens at '}`}</span>
                    {openStatus && (
                        <span
                            className='is-6 is-size-6 has-text-weight-bold'
                            style={{ whiteSpace: 'nowrap' }}
                        >
                            {currentSlot}
                        </span>
                    )}
                    <span className='icon is-small'>
                        <i className='fa fa-angle-down' aria-hidden='true'></i>
                    </span>
                </div>
            </button>
        </div>
    );
};

const BusinessSlots = ({ slots, timeZone }) => {
    const wrapperRef = useRef(null);
    const [businessSlots, setBusinessSlots] = useState(false);
    useOutsideAlerter(wrapperRef, setBusinessSlots);

    if (slots && slots.length === 0) {
        // Render empty control
        return <></>;
    }

    const daySlots = _.chain(slots)
        .groupBy('dayOfWeek')
        .map((daySlots, key) => ({
            id: `dayOfWeek_${key}`,
            dayOfWeek: parseInt(key),
            openingTime: moment.min(daySlots.map(daySlot => moment(daySlot.openingTime))),
            closingTime: moment.max(daySlots.map(daySlot => moment(daySlot.closingTime)))
        }))
        .value();

    var now = moment();
    //convert local current time to business time zone time
    const today = moment(now).tz(timeZone);
    let todaySlot = _.find(daySlots, function (s) {
        return today?.weekday() === s?.dayOfWeek;
    });

    const checkBusinessStatus = (slots) => {
        let currentStatus = 'Open';
        let isOpen = false;
        var now = moment();

        const currentTime = moment(now).tz(timeZone);
        const slotsToCheck = _.filter(slots, function (s) {
            return currentTime.weekday() === s.dayOfWeek;
        });

        for (var i = 0; i < slotsToCheck.length; i++) {
            var startTime = moment(
                moment(slots[i].openingTime).format('HH:mm'),
                'HH:mm'
            );
            var endTime = moment(
                moment(slots[i].closingTime).format('HH:mm'),
                'HH:mm'
            );
            var curTime = moment(currentTime.format('HH:mm'), 'HH:mm');

            if (curTime.diff(startTime) >= 0 && curTime.diff(endTime) <= 0) {
                isOpen = true;
                break;
            }
        }

        if (isOpen === false) {
            currentStatus = 'Closed';
        }

        return currentStatus;
    };

    let openStatus = 'Closed';

    if (todaySlot) {
        openStatus = checkBusinessStatus(todaySlot ? [todaySlot] : []);
    }

    if (openStatus === 'Closed') {
        let nextDay = today?.weekday() === 6 ? 0 : today?.weekday() + 1;
        todaySlot = _.find(slots, function (s) {
            return nextDay === s.dayOfWeek;
        });
    }

    let currentSlot = todaySlot
        ? openStatus === 'Closed'
            ? moment(todaySlot.openingTime).format('h:mm A')
            : moment(todaySlot.closingTime).format('h:mm A')
        : 'N/A';

    const getDayOfWeek = (day) => {
        let dayOfWeek;

        switch (day) {
            case 1:
                dayOfWeek = `Mon`;
                break;

            case 2:
                dayOfWeek = `Tue `;
                break;

            case 3:
                dayOfWeek = `Wed`;
                break;

            case 4:
                dayOfWeek = `Thu `;
                break;

            case 5:
                dayOfWeek = `Fri   `;
                break;

            case 6:
                dayOfWeek = `Sat  `;
                break;

            case 0:
            default:
                dayOfWeek = `Sun `;
                break;
        }

        return dayOfWeek;
    };

    return (
        <div
            id='dropdown-button-div'
            ref={wrapperRef}
            className={`dropdown ${businessSlots && 'is-active'}`}
            onClick={() => {
                setBusinessSlots(!businessSlots);
            }}
        >
            <CurrentBusienssStatus
                openStatus={openStatus}
                currentSlot={currentSlot}
            />

            <div
                id='dropdown-menu-business-slot'
                className='dropdown-menu'
                role='menu'
                style={{ whiteSpace: 'nowrap' }}
            >
                <div id='dropdown-content' className='dropdown-content'>
                    {daySlots.map((daySlot) => (
                        <div key={`businessDaySlot_${daySlot.id}`} className='dropdown-item'>
                            <span
                                className='has-text-weight-bold pr-2'
                                style={{ display: 'inline-block', width: '40px' }}
                            >
                                {getDayOfWeek(daySlot.dayOfWeek)}
                                {': '}
                            </span>
                            <Moment format='hh:mm A'>{daySlot.openingTime}</Moment>
                            {' - '}
                            <Moment format='hh:mm A'>{daySlot.closingTime}</Moment>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default BusinessSlots;
