import React from 'react'
import { Button, Title, Field, Control, Buttons, } from '@safelyq/bulma-ui-library';
import { isEqual } from 'lodash';
import { useSelector } from 'react-redux';
import moment from 'moment';
import AddAppointComment from './AddAppointComment';
const AppointmentComments = ({ business, isAllowComment, apptId }) => {
    const { userinfo, authinfo, allAppointments } = useSelector((state) => state.auth);
    const comments = allAppointments.find((appt) => appt.id === apptId).comments

    return (
        <div>
            <Title size='6'>Comments</Title>
            {comments.map((comment) => {
                return (
                    <div className='mb-4'>
                        <article className='media has-text-black' key={comment.id}>
                            <figure className='media-left'>
                                <Button size='small' color={isEqual(authinfo.profile.sub, comment.comment.user.id) ? 'info' : 'primary'} className='avatar-icon rounded' >
                                    <strong>
                                        {comment.messageDirection === 'BusinessToUser' ? (
                                            <strong>  {business.name ? String(business.name).charAt(0).toUpperCase() : ''}  </strong>
                                        ) : (
                                            <strong>  {comment.comment.user.firstName && comment.comment.user.lastName ? String(comment.comment.user.firstName).charAt(0).toUpperCase() + String(comment.comment.user.lastName).charAt(0).toUpperCase() : String(comment.comment.user.email).substring(0, 2).toUpperCase()}</strong>
                                        )}
                                    </strong>
                                </Button>
                            </figure>
                            <div className='media-content'>
                                <div className='content'>
                                    <p>
                                        <strong>
                                            {comment.messageDirection === 'BusinessToUser' ? (
                                                <strong>{business.name}</strong>
                                            ) : (
                                                <strong> {comment.comment.user.firstName && comment.comment.user.lastName ? comment.comment.user.firstName + ' ' + comment.comment.user.lastName : comment.comment.user.email}</strong>
                                            )}
                                        </strong>
                                        <small className='has-text-grey-light'>  {moment(comment.comment.modifiedTime || comment.comment.createdTime).format('DD-MM-YYYY')} </small>
                                        <br />
                                        {comment.comment.commentText}
                                    </p>
                                </div>
                            </div>
                        </article>
                    </div>
                )
            })}
            <AddAppointComment isAllowComment={isAllowComment} apptId={apptId} />
        </div>
    )
}

export default AppointmentComments