import React, { useState } from 'react'
import { SendInputSection, MessageField, AttachmentButton, SendButton } from '../../ManageUserMessages.styled'
import { Icon, Button, Text } from '@safelyq/bulma-ui-library';
import EmojiPicker from 'emoji-picker-react';
import { useMutation } from '@apollo/client';
import { SEND_MESSAGE_TO_BUSINESS } from '../../requests';
import { useFirebase } from 'hooks/useFirebase';
import { toast } from 'react-toastify';
import { ClipLoader } from 'react-spinners';
import DropDownBox from './DropDownBox';
import { useFile } from '../../hooks';
import { isEmpty } from 'lodash';


const MessageInput = ({ activeChat, getNewMessage, allowedMessage, allowedResponse, allowedAttatchment }) => {

    const [isOpenEmojiModal, setOpenEmojiModal] = useState(false);
    const [messageText, setMessageText] = useState('');
    const [isOpenDropZone, setOpenDropZone] = useState(false);
    const [loader, setLoader] = useState(false)

    const [onSendMessageToBusiness,] = useMutation(SEND_MESSAGE_TO_BUSINESS);
    const { onAddMessage } = useFirebase()
    const { onUpload, onRemove, ...fileParams } = useFile();

    const handleEmojiModalOpen = () => { setOpenEmojiModal(!isOpenEmojiModal); }

    const handleEmojiChange = (_, emojiObject) => {
        setMessageText((prev) => `${prev} ${emojiObject.emoji}`);
    }

    const handleKeyEnter = (e) => {
        if (e.key === 'Enter') handleMessageSend();
    };

    const handleMessageTextChange = (e) => {
        setMessageText(e.target.value)
    }

    const handleOpenDropZone = () => { setOpenDropZone(!isOpenDropZone); }


    const handleMessageSend = async () => {
        let attachment = fileParams.path ? { fileName: fileParams.name, base64String: fileParams.path, contentType: fileParams.type, } : null;
        let variables = { businessId: activeChat.business.id, messageText, attachment, };
        if (!attachment && !String(fileParams.type).includes('image') && !String(fileParams.type).includes('word') && !String(fileParams.type).includes('pdf') && !String(fileParams.type).includes('spreadsheet') && !String(fileParams.type).includes('presentation') && !String(fileParams.type).includes('plain')) {
            delete variables.attachment;
        }
        if (isEmpty(messageText) && !variables.attachment) return;
        if (messageText || String(fileParams.type).includes('image') || String(fileParams.type).includes('video') || String(fileParams.type).includes('audio') || String(fileParams.type).includes('pdf') || String(fileParams.type).includes('word') || String(fileParams.type).includes('spreadsheet') || String(fileParams.type).includes('presentation') || String(fileParams.type).includes('plain') || String(fileParams.type).includes('application')) {
            try {
                setLoader(true)
                const response = await onSendMessageToBusiness({ variables })
                // onAddMessage(response.data.sendMessageToBusiness.userMessage.business.id, response.data.sendMessageToBusiness.messageId)

                getNewMessage(response.data.sendMessageToBusiness.userMessage)
                toast.success("Message Send Successfully")
                setMessageText("")
                setOpenEmojiModal(false)
                onRemove();
                setOpenDropZone(false)
                setLoader(false)
            } catch (error) {
                console.log("Error", error);
            }
        }
        else {
            alert('File type not supported');
            setMessageText('');
            onRemove();
            setOpenDropZone(false);
        }
    }

    return (
        <>
            {isOpenDropZone && <DropDownBox onUpload={onUpload}  {...fileParams} />}
            <SendInputSection>
                {allowedMessage && allowedResponse ? (
                    <>
                        <div className={`dropdown is-up ${isOpenEmojiModal ? 'is-active' : ''}`}   >
                            <div className='dropdown-trigger'>
                                <Button className='button-icon is-small mr-3' aria-haspopup='true' aria-controls='emoji-dropdown' onClick={handleEmojiModalOpen} >
                                    <Icon name='smile-o' style={{ fontSize: 18 }}></Icon>
                                </Button>
                            </div>
                            <div className='dropdown-menu p-0' id='emoji-dropdown' role='menu' >
                                <div className='dropdown-content p-0'>
                                    <div className='dropdown-item p-0'>
                                        <Button className='button-icon is-small is-right' onClick={handleEmojiModalOpen}  >
                                            <Icon name='times' style={{ fontSize: 18 }}></Icon>
                                        </Button>
                                        <EmojiPicker onEmojiClick={handleEmojiChange} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <MessageField value={messageText} onKeyDown={handleKeyEnter} onChange={handleMessageTextChange} placeholder='Type a message here' />
                        {allowedAttatchment && (
                            <AttachmentButton className='ml-3' onClick={handleOpenDropZone}>
                                {isOpenDropZone ? (<Icon name='times' onClick={onRemove} />) : (<Icon name='paperclip' />)}
                            </AttachmentButton>
                        )}
                        {loader ? <ClipLoader size='20' /> : <SendButton name='paper-plane' className='ml-3' onClick={handleMessageSend} />}
                    </>
                ) : (
                    <Text size='6' color='danger'>
                        Buisness doesn't allow to send response back
                    </Text>
                )}

            </SendInputSection>
        </>

    )
}

export default MessageInput