import React, { useState, useEffect } from 'react';
import { Columns } from 'react-bulma-components';
import * as _ from 'lodash';
import SubLevelView from './SubLevelView';

const GroupBySubLevelView = ({
  serviceSubLevels = [],
  availableSlots = [],
  selectedSlot,
  userMessage,
  onChange,
}) => {
  const [groupBySubLevelSlots, setGroupBySubLevelSlots] = useState([]);

  useEffect(() => {
    var pagesSize = {};
    var result = _.chain(availableSlots)
      .groupBy('subBusiness.id')
      .map((slots, businessId) => {
        pagesSize = {
          ...pagesSize,
          [`"${businessId}"`]: 4,
        };
        return {
          subBusiness: serviceSubLevels.find((s) => {
            return s.id === +businessId;
          }),
          appointmentSlots: slots,
        };
      })
      .value();
    setGroupBySubLevelSlots(result);
  }, [availableSlots, serviceSubLevels]);

  return (
    <Columns className='is-mobile'>
      {groupBySubLevelSlots.map((subLevel, index) => {
        return (
          <SubLevelView
            subLevelSlot={subLevel}
            selectedSlot={selectedSlot}
            onChange={onChange}
            key={index}
          />
        );
      })}

      {availableSlots.length === 0 && (
        <Columns.Column size={12} className='px-1 is-size-5 py-0 my-2'>
          <div
            className={`timeTab-slot has-text-weight-bold is-size-6 has-text-grey-lighter`}
          >
            {userMessage}
          </div>
        </Columns.Column>
      )}
    </Columns>
  );
};

export default GroupBySubLevelView;
