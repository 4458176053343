
import React from 'react'
import { useQuery } from '@apollo/client';
import Queries from '../../../Common/Queries';
import { Skeleton } from 'antd';
import BusinessListSlider from './BusinessListSlider';

const SuggestedBusinessSection = () => {
    const { loading, data, } = useQuery(Queries.GET_SUGGESTED_BUSINESSES, { variables: { input: {}, }, });
    if (loading) {
        return (<Skeleton.Input style={{ height: '200px', }} active={true} size={"large"} block={true} />)
    }
    return (
        <div className='row'>
            <div className='col-lg-12'>
                <p className='suggested_heading'> Suggested for you </p>
                {data.getSuggestedBusinesses.map((suggest) => {
                    return (
                        <div className='row mb-5'>
                            <div className='col-lg-12'>
                                <h1 className="categoryname"  >{suggest.category.description}</h1>
                                <BusinessListSlider allbusinesses={suggest.businesses} />
                            </div>
                        </div>
                    )
                })}
            </div>

        </div>
    )
}

export default SuggestedBusinessSection