import React from 'react';

const Calendar = ({ selectedDate, onDayChange }) => {
  return (

    <h1>Calendar</h1>
  );
};

export default Calendar;
