import React, { useContext } from 'react';
import firestore from '@firebase'
import { COLLECTION } from 'constants/index'
import { useData } from 'components/Common/LoginMenu/hooks';

const appointmentsRef = firestore.collection(COLLECTION.APPOINTMENTS)
const businessesRef = firestore.collection(COLLECTION.BUSINESSES)
const commentRef = firestore.collection(COLLECTION.COMMENTS)
const chatRef = firestore.collection(COLLECTION.CHAT)
const ratingsRef = firestore.collection(COLLECTION.RATINGS)

export const useFirebase = () => {
    const { authUser: { id } } = useData();
    const isUser = id ? id : '123456'

    const onCreateAppointment = async (appt) => {
        if (appt) {
            const obj = {
                businessId: String(appt.business.id),
                customerId: String(isUser),
                appointment: appt
            }

            await appointmentsRef.doc(String(appt.id)).set(obj);
        }

    }
    const onUpdateAppointmentFirebase = async (appt) => {
        const obj = {
            businessId: String(appt.business.id),
            customerId: String(isUser),
            appointment: appt
        }

        await appointmentsRef.doc(String(appt.id)).update(obj)
    }
    const onUpdateStatus = async (appt) => {
        const obj = {
            businessId: String(appt.business.id),
            customerId: String(isUser),
            appointment: appt
        }
        await appointmentsRef.doc(String(appt.id)).update(obj)
    }
    const onAddCommentFirebase = async (apptId, businessId, comment) => {
        const obj = {
            businessId: String(businessId),
            customerId: String(isUser),
            comment: comment.id
        }
        await commentRef.doc(String(apptId)).set(obj);
    }

    const onAddMessage = async (businessId, messageId) => {
        const obj = {
            businessId: String(businessId),
            customerId: String(isUser),
            messageId: messageId
        }
        // await chatRef.doc(String(businessId)).set(obj);
        await chatRef.add(obj);
    }

    const onAddRatings = async (businessId, ratings) => {
        const obj = {
            businessId: String(businessId),
            customerId: String(isUser),
            ratings: ratings
        }
        await ratingsRef.add(obj);
    }

    return {
        onCreateAppointment,
        onUpdateAppointmentFirebase,
        onUpdateStatus,
        onAddCommentFirebase,
        onAddMessage,
        onAddRatings
    };
};
