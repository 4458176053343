import React, { useEffect, useState } from 'react';
import { isEmpty, chain } from 'lodash';
import { Button, Columns, Column, Table } from '@safelyq/bulma-ui-library';
import { TableView, TileView } from './components';
import { useHolidaysOperation } from './hooks';
import { dummySlots } from './constants';
import { HOURS_OF_OPERATION_MESSAGES } from '../../common/constants';
import moment from 'moment';
import { toast } from 'react-toastify';
import handleNumericCheck from './../../../../../utils/NumericCheck';
import { ModifiedDays, ParsedDays } from './HoursOperation.helper';

export const HoursOperation = ({ data, tab, onSubmit, serviceId }) => {
  const [weekDays, setWeekDays] = useState([])
  const [hasError, setHasError] = useState(false)
  const handleInputChange = (e, index) => {
    let { name, value } = e.target;
    const updatedInputs = [...weekDays];
    if (name === "isOpen" || name === "hasBreak") {
      if (name === "isOpen") {
        if (e.target.checked === false) {
          updatedInputs[index]['openingTime'] = "00:00"
          updatedInputs[index]['closingTime'] = "00:00"
        }
      }
      else {
        if (e.target.checked) {
          const getBreakTime = weekDays[index].breakStartTime
          if (getBreakTime) {
            updatedInputs[index][`${name}Error`] = ""
            const day_openingTime = moment().format('YYYY-MM-DD') + ' ' + weekDays[index].openingTime;
            const day_closingTime = moment().format('YYYY-MM-DD') + ' ' + weekDays[index].closingTime;
            const break_Start_Time = moment().format('YYYY-MM-DD') + ' ' + weekDays[index].breakStartTime;
            const isBreakBetween = moment(break_Start_Time).isBetween(day_openingTime, day_closingTime);
            if (isBreakBetween) {
              updatedInputs[index][`breakStartTimeError`] = ""
            } else {
              updatedInputs[index][`breakStartTimeError`] = "Incorrect Break Time"
              toast.error(`Break start time should be between ${moment(weekDays[index].openingTime, "hh mm").format('LT')} and ${moment(weekDays[index].closingTime, "hh mm").format('LT')} `)

            }
          }
          else {
            updatedInputs[index][`${name}Error`] = "Enter Break time and break duration"
          }
        }
        else {
          updatedInputs[index][`${name}Error`] = ""
          updatedInputs[index][`breakStartTimeError`] = ""
          updatedInputs[index][`breakDurationError`] = ""
          updatedInputs[index]['breakDuration'] = '';
        }
      }
      updatedInputs[index][name] = e.target.checked;
    }
    else if (name === "breakStartTime") {
      const day_openingTime = moment().format('YYYY-MM-DD') + ' ' + weekDays[index].openingTime;
      const day_closingTime = moment().format('YYYY-MM-DD') + ' ' + weekDays[index].closingTime;
      const break_Start_Time = moment().format('YYYY-MM-DD') + ' ' + value;
      const isBreakBetween = moment(break_Start_Time).isBetween(day_openingTime, day_closingTime);
      if (isBreakBetween) {
        updatedInputs[index][`${name}Error`] = ""
      } else {
        updatedInputs[index][`${name}Error`] = "Incorrect Break Time"
        toast.error(`Break start time should be between ${moment(weekDays[index].openingTime, "hh mm").format('LT')} and ${moment(weekDays[index].closingTime, "hh mm").format('LT')} `)

      }
      updatedInputs[index][name] = value;
    }
    else if (name === "breakDuration") {

      if (value) {
        updatedInputs[index][`hasBreakError`] = ""
      }
      else {
        updatedInputs[index][`hasBreakError`] = "Enter Break time and break duration"
      }
      const day_closingTime = moment().format('YYYY-MM-DD') + ' ' + weekDays[index].closingTime;
      const break_Start_Time = moment().format('YYYY-MM-DD') + ' ' + weekDays[index].breakStartTime;
      const timeInMilliSec = moment(day_closingTime).diff(moment(break_Start_Time))
      const timInMinutes = moment.duration(timeInMilliSec).asMinutes()
      if (value > timInMinutes) {
        updatedInputs[index][`${name}Error`] = "Incorrect Break Durantion"
        toast.error(`You can take break maximum of ${timInMinutes} minutes `)
      }
      else {
        updatedInputs[index][`${name}Error`] = ""
      }
      updatedInputs[index][name] = value;
    }
    else if (name === "closingTime") {
      updatedInputs[index]['breakDuration'] = '';
      updatedInputs[index]['breakStartTime'] = '';
      updatedInputs[index][name] = value;
      if (weekDays[index].hasBreak) {
        updatedInputs[index][`hasBreakError`] = "Enter Break time and break duration"
      }

    }
    else {
      updatedInputs[index][name] = value;
    }

    setWeekDays(updatedInputs)
  }

  const handleSave = () => {
    const updatedSlots = getFinalDays(weekDays)
    if (tab === 'LOCATION') {
      onSubmit({ businessHours: updatedSlots }, 4, {
        success: HOURS_OF_OPERATION_MESSAGES.Success,
        error: HOURS_OF_OPERATION_MESSAGES.Error,
      });
    } else {
      onSubmit({ businessHours: updatedSlots }, 4, serviceId, {
        success: HOURS_OF_OPERATION_MESSAGES.Success,
        error: HOURS_OF_OPERATION_MESSAGES.Error,
      });
    }
  }


  useEffect(() => {
    const updatedData = [...data]

    let parsedHolidays = ParsedDays(updatedData)
    const updatedDays = ModifiedDays(parsedHolidays)
    setWeekDays(updatedDays)
  }, [data])

  useEffect(() => {
    const status = weekDays.some((day) => day.breakStartTimeError || day.hasBreakError || day.breakDurationError)
    setHasError(status)
  }, [weekDays])


  return (
    <div>
      <div className='row g-0 mb-4  '>
        <div className='col-lg-12 is-hidden-mobile'>
          <TableView weekDays={weekDays} handleInputChange={handleInputChange} />
        </div>
        <div className='col-lg-12 is-hidden-tablet'>
          <TileView weekDays={weekDays} handleInputChange={handleInputChange} />
        </div>
      </div>
      <Button color='info' disabled={hasError} onClick={handleSave}   >  Save  </Button>
    </div>
  );
};


const getFinalDays = (slots) => {
  const finalSlots = [];
  const format = 'YYYY-MM-DD HH:mm';

  const blankTime = moment('0001-01-01 00:00').format(format);

  slots.map(
    ({ dayNumber, slot1Id, slot2Id, isOpen, hasBreak, openingTime, closingTime, breakDuration, breakStartTime, }) => {
      let firstSlot = {};
      let secondSlot = {};

      openingTime = moment(`0001-01-01 ${openingTime}`).format(format);

      closingTime = moment(`0001-01-01 ${closingTime}`).format(format);

      breakStartTime = moment(`0001-01-01 ${breakStartTime}`).format(format);

      if (!isOpen) {
        openingTime = closingTime = breakStartTime = blankTime;
        breakDuration = 0;
      }

      firstSlot = {
        id: slot1Id || 0,
        dayOfWeek: dayNumber,
        openingTime,
        closingTime: breakStartTime,
      };

      secondSlot = {
        id: slot2Id || 0,
        dayOfWeek: dayNumber,
        openingTime: moment(breakStartTime, format).add(breakDuration, 'minutes').format(format),
        closingTime,
      };

      if (!hasBreak) {
        breakDuration = 0;
        breakStartTime = blankTime;
        firstSlot = { ...firstSlot, closingTime };
        secondSlot = { ...secondSlot, openingTime: closingTime, closingTime };
      }

      finalSlots.push({ ...firstSlot }, { ...secondSlot });
    }
  );

  return finalSlots;
};


