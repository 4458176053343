import React, { Fragment } from 'react';
import styled from 'styled-components';
import {
  Field,
  Control,
  Icon,
  TextInput,
  Button,
} from '@safelyq/bulma-ui-library';
import { Loader } from '@safelyq/base-feature';
import { useData } from './hooks';
import { useMessageState, useMessageDispatch } from '../../index';

const ScrollList = styled.ul.attrs(() => ({}))`
  height: calc(89vh);
  overflow: scroll;
  background: white;
`;

const CategoryBar = styled.p.attrs(() => ({
  className: 'px-3 py-1',
}))`
  background: rgba(0, 0, 0, 0.5);
  color: white;
`;

const ListItem = styled.li.attrs(() => ({
  className: 'p-3',
}))`
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  background: ${({ bgGrey }) => (bgGrey ? 'rgba(0,0,0,0.04)' : 'white')};
  &:hover {
    cursor: pointer;
    background: rgba(0, 0, 0, 0.04);
  }
`;

const StyledSearchBar = styled(TextInput).attrs(() => ({}))`
  border: 0;
  border-radius: 20px;
`;

export const BusinessesList = ({ onCloseListView }) => {
  const { isLoading } = useData();

  const { businessesList } = useMessageState();

  const dispatch = useMessageDispatch();

  const handleBusinessSelect = (id) =>
    dispatch({ type: 'SET_SELECTED_BUSINESS', payload: { id } });

  if (isLoading) return <Loader isFullScreen />;
  return (
    <Fragment>
      <ul>
        <ListItem className='p-0 is-flex is-align-items-center' bgGrey>
          <Button
            size='small'
            color='info'
            rounded
            className='mr-2'
            onClick={onCloseListView}
          >
            <Icon name='arrow-left' />
          </Button>
          <div style={{ width: '100%' }}>
            <Field>
              <Control>
                <Icon name='search' position='left' />
                <StyledSearchBar placeholder={'Search business'} />
              </Control>
            </Field>
          </div>
        </ListItem>
      </ul>
      <ScrollList>
        {businessesList.map(({ category: { description }, businesses }) => (
          <Fragment>
            <CategoryBar>{description}</CategoryBar>
            {businesses.map(({ id, name, picture }) => (
              <ListItem onClick={() => handleBusinessSelect(id)}>
                <article className='media is-flex is-align-items-center'>
                  <figure className='media-left'>
                    {picture && picture.path ? (
                      <p className='image is-48x48'>
                        <img
                          className='image is-48x48 is-rounded'
                          src={picture?.path}
                          alt="path"
                        />
                      </p>
                    ) : (
                      <p className='image is-48x48 has-background-grey-light rounded is-flex is-justify-content-center is-align-items-center'>
                        {String(name).charAt(0)}
                      </p>
                    )}
                  </figure>
                  <div className='media-content'>
                    <div className='content'>
                      <p>
                        <strong>{name}</strong>
                      </p>
                    </div>
                  </div>
                </article>
              </ListItem>
            ))}
          </Fragment>
        ))}
      </ScrollList>
    </Fragment>
  );
};
