import React, { useEffect, useState } from 'react';
import { isUndefined } from 'lodash';

/* eslint jsx-a11y/anchor-is-valid: 0 */

const CodeValidator = /\d\d\d\d\d\d/;

const CodeEntry = ({ ready, valid, value, children, onChange }) => {
  const dirty = !isUndefined(value);

  return (
    <div className='field'>
      <div
        className={
          ready
            ? 'control has-icons-left has-icons-right'
            : 'control has-icons-left'
        }
      >
        <input
          className={dirty && !valid ? 'input is-danger' : 'input'}
          type='text'
          name='code'
          defaultValue={value}
          maxLength={6}
          onChange={onChange}
        />
        <span className='icon is-small is-left'>
          <i className='fa fa-hashtag' />
        </span>
        {ready && (
          <span className='icon is-small is-right'>
            <i className={valid ? 'fa fa-check' : 'fa fa-times'} />
          </span>
        )}
        {children}
      </div>
    </div>
  );
};

const VerifyMarketingEmail = ({ code, setCode, verify, isEmailRegistered, password1, password2, setPassword1, setPassword2 }) => {
  const isCodeEntered = (code || '').length > 0;
  const isCodeValid = CodeValidator.test(code);
  const [canCheck, setCanCheck] = useState();
  const isPassword1Valid =
    validatePassword(password1) && password1 === password2;
  const isPassword2Valid =
    validatePassword(password2) && password1 === password2;

  useEffect(() => {
    if (isEmailRegistered) {
      setCanCheck(isCodeValid);
    }
    else {
      setCanCheck(isCodeValid && isPassword1Valid && isPassword2Valid);
    }
  }, [isEmailRegistered, isCodeValid, isPassword1Valid, isPassword2Valid]);

  return (
    <>
      <div className="field has-text-left">
        <h1 className='is-size-4 title'>Verify Email</h1>
        <p className='is-size-6 subtitle'>Verify your email for marketing sign up</p>
      </div>
      <div className='content'>
        <CodeEntry
          ready={isCodeEntered}
          valid={isCodeValid}
          value={code}
          onChange={(e) => setCode(e.target.value)}
        >
          <p className={isCodeValid ? 'help is-info' : 'help is-danger'}>
            Enter the verification code you have received in
            your inbox
          </p>
        </CodeEntry>
        {!isEmailRegistered ? (
          <>
            <PasswordTip />
            <PasswordEntry
              valid={isPassword1Valid}
              value={password1}
              placeholder="Password"
              onChange={(e) => setPassword1(e.target.value)}
            />
            <PasswordEntry
              valid={isPassword2Valid}
              value={password2}
              placeholder="Password (again)"
              onChange={(e) => setPassword2(e.target.value)}
            />
          </>
        ) : null}

        <div className='field'>
          <div className='control'>
            <button
              className='button is-block is-primary is-outlined is-medium is-fullwidth my-5'
              disabled={!canCheck}
              onClick={verify}
            >
              Verify
            </button>
          </div>
        </div>
        <div className='field'>
          <p className='is-size-6'>
            We will confirm the verification code.
          </p>
        </div>
      </div>
    </>
  );
};

const validatePassword = (password) => {
  return (password || '').length > 5;
};

const PasswordEntry = ({ valid, name, value, placeholder, onChange }) => {
  const styles = 'input is-medium';
  const dirty = !isUndefined(value);
  const [eye, setEye] = useState(false);

  return (
    <div className="field">
      <div className='control has-icons-left has-icons-right'>
        <input
          className={dirty && !valid ? `${styles} is-danger` : styles}
          type={eye ? "text" : "password"}
          name={name}
          defaultValue={value}
          placeholder={placeholder}
          autoComplete="false"
          onChange={onChange}
        />
        <span className="icon is-medium is-left">
          <i className="fa fa-asterisk" />
        </span>
        <span className="icon is-medium is-right is-clickable" onClick={() => setEye(!eye)}>
          <i className={eye ? "fa fa-eye" : "fa fa-eye-slash"} />
        </span>
        {valid && (
          <span className="icon is-medium is-right mr-5">
            <i className="fa fa-check" />
          </span>
        )}
      </div>
    </div>
  );
};

const PasswordTip = () => {
  return (
    <div className="field has-text-left">
      <p className="is-size-6">Choose a password for your account.</p>
    </div>
  );
};

export default VerifyMarketingEmail;
