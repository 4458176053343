import React from 'react';

const BusinessPhone = ({ businessPhone }) => {
  return (
    <>
      {businessPhone && (
        <a href={`tel:${businessPhone}`} className="columns is-mobile">
          <div className="column is-size-5">
            <span style={{ whiteSpace: 'nowrap' }}>
              <span className="icon has-text-info">
                <i className="fa fa-phone" />
              </span>
              <span className="ml-3 has-text-dark">Call {businessPhone}</span>
            </span>
          </div>
          <div className="column is-size-5 has-text-right">
            <span className="icon">
              <i className="fa fa-chevron-right" color="#037ffc" />
            </span>
          </div>
        </a>
      )}
    </>
  );
};

export default BusinessPhone;
