import { useState, useEffect } from 'react';
import * as _ from 'lodash';
import axios from 'axios';
import { toast } from 'react-toastify';
import { APIPaths } from 'services/api-provider';
import { getAccessToken } from 'methods/addAuthorizationHeader';

export const useData = (values) => {
    const [error, setError] = useState({});

    const [loading, setLoading] = useState(false);
    const [accessToken, setAccessToken] = useState('');

    useEffect(() => {
        const getToken = async () => {
            var tokenResult = await getAccessToken();
            setAccessToken(tokenResult);
        };

        getToken();

    }, []);

    const handleChangePasswordRequest = async () => {
        if (!isFormValid()) return;

        setLoading(true);

        axios
            .post(`${APIPaths.Backend}/account/change-password`, {
                ...values,
            },
                {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`
                    }
                })
            .then((response) => {
                setLoading(false);
                const result = response.data;

                if (result.isSuccess) {
                    toast.success('Your password has been changed successfully.');
                }
                else {
                    toast.error(result.errors.join('. '));
                }
                
            })
            .catch(() => {
                setLoading(false);
                toast.error('Somthing went wrong, please try again later');
            });
    };

    const isFormValid = () => {
        const errors = {};

        const { newPassword, confirmPassword } = values;

        if (_.isEmpty(newPassword)) errors['newPassword'] = 'This field is required';
        if (_.size(newPassword) < 6)
            errors['newPassword'] = 'The Password must be at least 6 characters long';
        else _.unset(errors, 'newPassword');

        if (_.isEmpty(confirmPassword))
            errors['confirmPassword'] = 'This field is required';
        else _.unset(errors, 'confirmPassword');

        if (!_.isEqual(confirmPassword, newPassword))
            errors['confirmPassword'] = 'Please make sure your passwords match';

        setError(errors);

        if (_.isEmpty(errors)) return true;
        else return false;
    };

    return { isLoading: loading, error, onPasswordChange: handleChangePasswordRequest };
};
