import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Section, Container, Title } from '@safelyq/bulma-ui-library';
import { Loader } from '@safelyq/base-feature';
import { NotificationCard, EmptyCard } from '../../components';
import { useData } from './hooks';

//for firestore
import firestore from '@firebase';
import { COLLECTION } from 'constants/index';
import { useData as useLoginState } from 'components/Common/LoginMenu/hooks';
import { useDispatch } from 'react-redux';
import { updateNotification } from 'Redux/AuthSlice/AuthSlice';

export const ManageNotifications = () => {
  const { isLoading, error, refetch, data: notifications, onMarkAsViewed } = useData();

  const dispatch = useDispatch()

  const { authUser: { id } } = useLoginState();
  useEffect(() => {
    const unsubscribe = firestore
      .collection(COLLECTION.CHAT)
      .where('customerId', '==', `${id}`)
      .onSnapshot(async querySnapshot => {
        onTriggerWebSocket();
      });
    return () => unsubscribe();
  }, [id]);

  const onTriggerWebSocket = async () => {
    await refetch()
    await onMarkAsViewed();
    dispatch(updateNotification())
  };

  const totalNotifications = notifications.length;

  const isEmptyNotifications = totalNotifications === 0;

  if (isLoading) return <Loader isFullScreen />;

  if (error) return <Loader isFullScreen />;

  if (isEmptyNotifications) return <EmptyCard />;

  const refetchEvent = new CustomEvent("refetchConsumer");

  document.dispatchEvent(refetchEvent);

  return (
    <Section>
      <MetaTags />
      <Container>
        <Title size='5'>Notifications ({totalNotifications})</Title>
        {notifications.map(({ ...notification }, index) => (
          <NotificationCard key={`notification-${index}`}   {...notification} refetch={refetch} />
        ))}
      </Container>
    </Section>
  );
};

const MetaTags = () => {
  return (
    <Helmet>
      <title>SafelyQ - Notifications</title>
      <meta
        name='description'
        content='SafelyQ, a US based company, offers a comprehensive solution for organizations and agencies to safely queue people into any kind of facilities that are brought up, from mega sites with multiple entry points to drive throughs.'
      />
      <meta
        name='keywords'
        content='Appointments, Online Appointments, SafelyQ Appointment, Queueing System, Le Bernardin Appointment, Dallas City Hall'
      />
    </Helmet>
  );
};
