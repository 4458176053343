import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { toast as showToast } from 'react-toastify';
import { INITIAL_DATA } from './constants';
import { GET_BUSINESS_CONVERSATION } from './requests';
import { useMessageDispatch, useMessageState } from '../../index';

export const useData = ({ genericNumberMessagingAllowed }) => {
    const dispatch = useMessageDispatch();

    const { isReceivedSignal } = useMessageState();

    const { businessId } = useParams();

    const {
        loading: isLoading,
        error,
        data = INITIAL_DATA,
        refetch
    } = useQuery(GET_BUSINESS_CONVERSATION, {
        variables: { businessId },
        fetchPolicy: 'no-cache',
        skip: !genericNumberMessagingAllowed, // skip the api if the generic number messaging is not allowed ,
        notifyOnNetworkStatusChange: true,
        onCompleted: ({ getBusinessById: { conversations } }) => {
            dispatch({ type: 'SET_RECENT_CONVERSATION', payload: { conversations } });
        },
    });

    useEffect(() => {
        if (isReceivedSignal) refetch()
    }, [isReceivedSignal]);

    if (error) showToast.error('There was a problem. Data could not be loaded.');

    const {
        getBusinessById: { conversations },
    } = data;

    return { isLoading, error, data: conversations };
};
