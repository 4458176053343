import React, { useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import Queries from "../Common/Queries";
import { ToastContainer, toast } from "react-toastify";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

/* eslint jsx-a11y/anchor-is-valid: 0 */

const ProfileView = {
  PROFILE_UPDATE: 1,
  CONFIRMATION_CODE: 2,
};

const UserProfile = () => {
  const [userProfile, setUserProfile] = useState({});
  const [currentView, setCurrentView] = useState(ProfileView.PROFILE_UPDATE);
  const [verificationCode, setVerificationCode] = useState(null);

  const updateProfileData = (name, value) => {
    setUserProfile({
      ...userProfile,
      [name]: value,
    });
  };

  const [saveUserProfile] = useMutation(Queries.SAVE_USER_PROFILE);
  const [sendVerificationCode] = useMutation(Queries.SEND_VERIFICATION_CODE);
  const [confirmVerificationCode] = useMutation(
    Queries.CONFIRM_VERIFICATION_CODE
  );

  useQuery(Queries.GET_USER_PROFILE, {
    variables: {},
    onCompleted: (data) => {
      if (data) {
        setUserProfile(
          data.getUserProfile
            ? {
                ...data.getUserProfile.user,
                ...data.getUserProfile.user.userPreferences,
              } || {}
            : {}
        );
      }
    },
  });

  const handleSaveUserProfile = () => {
    saveUserProfile({
      variables: {
        userProfileInput: {
          firstName: userProfile.firstName,
          lastName: userProfile.lastName,
          phoneNumber: userProfile.phoneNumber,
          allowEmail: userProfile.allowEmail,
          allowSms: userProfile.allowSms,
          allowWhatsApp: userProfile.allowWhatsApp,
        },
      },
    }).then(function (result) {
      var data = result.data.saveUserProfile;
      setUserProfile(
        data && data.user ? { ...data.user, ...data.user.userPreferences } : {}
      );

      if (data.error) {
        toast.error(data.error);
        return;
      }

      if (!data.error && data.isUpdated) {
        toast.success(`Your profile data has been updated`);
      }

      if (!data.error && data.isVerficationCodeSent) {
        setCurrentView(ProfileView.CONFIRMATION_CODE);
      }
    });
  };

  const handleResendCode = () => {
    sendVerificationCode({ variables: {} }).then(function (result) {
      var data = result.data.sendVerificationCode;

      if (data.error) {
        toast.error(data.error);
        return;
      }

      if (data.isSuccess) {
        toast.success(
          `Verification code is sent. Please check your phone number`
        );
      }
    });
  };

  const handleConfirmVerificationCode = () => {
    confirmVerificationCode({ variables: { code: verificationCode } }).then(
      function (result) {
        var data = result.data.confirmVerificationCode;

        if (data.error) {
          toast.error(data.error);
          return;
        }

        if (data.isSuccess) {
          toast.success(`Your Phone Number is updated.`);
        }
      }
    );
  };

  return (
    userProfile && (
      <section className="section">
        <div className="container">
          {currentView === ProfileView.PROFILE_UPDATE && (
            <React.Fragment>
              <div className="column">
                <h1 className="title">User Profile</h1>
                <p className="subtitle">Update your profile data</p>
              </div>
              <div className="column is-one-third">
                <div className="field">
                  <label className="is-size-5">
                    Email :{" "}
                    <span className="has-text-weight-bold">
                      {userProfile.email}
                    </span>
                  </label>
                </div>
              </div>
              {!userProfile.emailConfirmed && (
                <div className="column py-4">
                  <div className="notification is-danger is-light has-text-grey">
                    <span className="icon">
                      <i
                        className="fa fa-exclamation-circle"
                        aria-hidden="true"
                      />
                    </span>
                    Your email is not verified yet.
                  </div>
                </div>
              )}
              <div className="column is-one-third">
                <div className="field">
                  <label className="label">First Name</label>
                  <div className="control">
                    <input
                      className="input"
                      type="text"
                      placeholder="First Name"
                      value={userProfile.firstName}
                      onChange={(e) =>
                        updateProfileData("firstName", e.target.value)
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="column is-one-third">
                <div className="field">
                  <label className="label">Last Name</label>
                  <div className="control">
                    <input
                      className="input"
                      type="text"
                      placeholder="Last Name"
                      value={userProfile.lastName}
                      onChange={(e) =>
                        updateProfileData("lastName", e.target.value)
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="column is-one-third">
                <div className="field">
                  <label className="label">Phone Number</label>
                  <div className="control">
                    <PhoneInput
                      inputClass="input"
                      countryCodeEditable={false}
                      country={"us"}
                      value={userProfile.phoneNumber}
                      onChange={(phone) =>
                        updateProfileData("phoneNumber", phone)
                      }
                    />
                  </div>
                </div>
              </div>

              <div className="column is-one-third">
                <div className="field">
                  <label className="checkbox">
                    <input
                      type="checkbox"
                      className="mr-2"
                      checked={userProfile.allowEmail}
                      onChange={(e) =>
                        updateProfileData("allowEmail", e.target.checked)
                      }
                    />
                    Enable email notification
                  </label>
                </div>
              </div>

              <div className="column is-one-third">
                <div className="field">
                  <label className="checkbox">
                    <input
                      type="checkbox"
                      className="mr-2"
                      checked={userProfile.allowWhatsApp}
                      onChange={(e) =>
                        updateProfileData("allowWhatsApp", e.target.checked)
                      }
                    />
                    Enable WhatsApp notification
                  </label>
                </div>
              </div>

              <div className="column is-one-third">
                <div className="field">
                  <label className="checkbox">
                    <input
                      type="checkbox"
                      className="mr-2"
                      checked={userProfile.allowSms}
                      onChange={(e) =>
                        updateProfileData("allowSms", e.target.checked)
                      }
                    />
                    Enable SMS notification
                  </label>
                </div>
              </div>

              {!userProfile.phoneNumberConfirmed && (
                <div className="column py-4">
                  <div className="notification is-danger is-light has-text-grey">
                    <span className="icon">
                      <i
                        className="fa fa-exclamation-circle"
                        aria-hidden="true"
                      />
                    </span>
                    Your phone number is not verified yet.
                  </div>
                </div>
              )}
              <div className="column is-one-third">
                <div className="field is-grouped">
                  <div className="control">
                    <button
                      className="button is-link"
                      onClick={(e) => {
                        e.preventDefault();
                        handleSaveUserProfile();
                      }}
                    >
                      Save
                    </button>
                  </div>
                  <div className="control">
                    <button className="button is-link is-light">Cancel</button>
                  </div>
                </div>
              </div>
            </React.Fragment>
          )}

          {currentView === ProfileView.CONFIRMATION_CODE && (
            <React.Fragment>
              <div className="column">
                <h1 className="title">Verify Phone Number</h1>
                <p className="subtitle">
                  Add your phone number verification code
                </p>
              </div>
              <div className="column is-one-third">
                <div className="field">
                  <label className="is-size-5">
                    Phone Number :{" "}
                    <span className="has-text-weight-bold">
                      {userProfile.claimedPhoneNumber}
                    </span>
                  </label>
                </div>
              </div>
              <div className="column is-one-third">
                <div className="field">
                  <label className="label">Code</label>
                  <div className="control">
                    <input
                      className="input"
                      type="text"
                      placeholder="Code"
                      value={verificationCode}
                      onChange={(e) => setVerificationCode(e.target.value)}
                    />
                  </div>
                </div>
              </div>

              <div className="column is-one-third">
                <div className="field is-grouped">
                  <div className="control">
                    <button
                      className="button is-link"
                      onClick={(e) => {
                        e.preventDefault();
                        handleConfirmVerificationCode();
                      }}
                    >
                      Confirm
                    </button>
                  </div>
                  <div className="control">
                    <button
                      className="button is-link"
                      onClick={(e) => {
                        e.preventDefault();
                        handleResendCode();
                      }}
                    >
                      Resend Code
                    </button>
                  </div>
                  <div className="control">
                    <button
                      className="button is-link is-light"
                      onClick={(e) => {
                        e.preventDefault();
                        setCurrentView(ProfileView.PROFILE_UPDATE);
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </React.Fragment>
          )}
        </div>
        <ToastContainer />
      </section>
    )
  );
};

export default UserProfile;
