import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Menu, List, Icon } from '@safelyq/bulma-ui-library';
import { useWeekDays } from './hooks';

const statusColorEnum = {
  OPEN: 'success',
  CLOSED: 'danger',
};

export const ScheduleList = ({ slots, timeZone }) => {

  const [isOpen, setOpen] = useState(false);

  const { days, status, isCurrentDayExist, openAt, closeAt } = useWeekDays({
    slots,
    timeZone,
  });

  const handleMenu = () => setOpen(!isOpen);

  return (
    <Menu>
      <Menu.Label
        color={statusColorEnum[status]}
        weight='bold'
        style={{ fontSize: 13 }}
      >
        {status}
        <Link to='#' className='has-text-black' onClick={handleMenu}>
          {' '}
          {!isCurrentDayExist
            ? 'Opens At N/A'
            : status === 'OPEN'
              ? `Closes At ${closeAt}`
              : `Opens At ${openAt}`}
          <Icon name={isOpen ? 'angle-up' : 'angle-down'} collection='fa' />
        </Link>
      </Menu.Label>
      {isOpen && (
        <Menu.List>
          <List.Item>
            <List type='unordered'>
              {days.map(({ openingTime, closingTime, dayName, id }) => (
                <List.Item key={`${id}`}>
                  <Link to='#' className='is-flex'>
                    <div style={{ width: '50px' }}>
                      <strong>{dayName}:</strong>
                    </div>{' '}
                    {openingTime == closingTime ? (
                      <span className='has-text-danger'>CLOSED</span>
                    ) : (
                      <span>
                        {openingTime} - {closingTime}
                      </span>
                    )}
                  </Link>
                </List.Item>
              ))}
            </List>
          </List.Item>
        </Menu.List>
      )}
    </Menu>
  );
};
