export const INITIAL_AVAILABILITY_DATA = {
  getAvailability: {},
};

export const rolesEnum = {
  NOT_FOUND: 'not-found',
  SELECTION: 'selection',
};

export const viewEnum = {
  GROUP_BY_SUBLEVEL: 'GroupBySubLevelView',
  GROUP_BY_TIME: 'GroupByTimeView',
  FLAT: 'Flat',
};
