import React, { useState, useEffect, Fragment } from 'react';
import { Link } from 'react-router-dom';
import * as _ from 'lodash';
import {
  Block,
  Text,
  Subtitle,
  Icon,
  Buttons,
  Button,
  Content,
  Paragraph,
} from '@safelyq/bulma-ui-library';
import { useBookingDispatch, useBookingState } from '../../../../context';

export const GroupBySubLevelView = ({
  serviceSubLevels = [],
  availableSlots = [],
}) => {
  const dispatch = useBookingDispatch();

  const { selectedSlot } = useBookingState();

  const [slots, setSlots] = useState([]);

  const [pageSize, setPageSize] = useState(10);

  useEffect(() => {
    var result = _.chain(availableSlots)
      .groupBy('subBusiness.id')
      .map((slots, businessId) => {
        return {
          subBusiness: serviceSubLevels.find(
            (s) => parseInt(s.id) === parseInt(businessId)
          ),
          appointmentSlots: slots,
        };
      })
      .value();
    setSlots(result);
  }, [availableSlots, pageSize]);

  const handleLoadMore = () => setPageSize(pageSize + 10);

  const handleReset = () => setPageSize(10);

  const handleSlotChange = (slot) =>
    dispatch({ type: 'SET_SLOT', payload: slot });

  if (_.isEmpty(slots)) return <NotFoundSection />;

  return (
    <Fragment>
      {slots.map(({ appointmentSlots, subBusiness: { name } = {} }, index) => (
        <Block key={`subLevel-${index}`}>
          {typeof name !== 'undefined' && 
          <Subtitle size='5'>
            <Icon name='dot-circle-o' collection='fa' size='small' />
            {' ' + name}
          </Subtitle>
          }
          <Buttons size='small'>
            {appointmentSlots.slice(0, pageSize).map((slot, index) => (
              <Button
                light={!_.isEqual(slot, selectedSlot)}
                color={'info'}
                key={`slot-${index}`}
                className='slot-btn'
                onClick={() => handleSlotChange(slot)}
              >
                <Text>
                  {String(slot.startTimeOnly).split(':')[0].length === 1
                    ? '0' + slot.startTimeOnly
                    : slot.startTimeOnly}
                </Text>
                {_.isEqual(slot, selectedSlot) && (
                  <Icon name='check' collection='fa'></Icon>
                )}
              </Button>
            ))}
          </Buttons>
          {appointmentSlots.length > pageSize &&
            pageSize < appointmentSlots.length && (
              <div className='icon-text is-justify-content-center'>
                <Link to='#' onClick={handleLoadMore}>
                  Load More <Icon name='angle-down' collection='fa' />
                </Link>
              </div>
            )}
          {appointmentSlots.length > pageSize &&
            pageSize >= appointmentSlots.length && (
              <div className='icon-text is-justify-content-center'>
                <Link to='#' onClick={handleReset}>
                  Show Less <Icon name='angle-up' collection='fa' />
                </Link>
              </div>
            )}
          <hr />
        </Block>
      ))}
    </Fragment>
  );
};

const NotFoundSection = ({ role }) => {
  return (
    <Content className='py-5'>
      <Paragraph alignment='centered' weight='bold' color='grey-light'>
        No slots available
      </Paragraph>
    </Content>
  );
};
