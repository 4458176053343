import { useEffect, useState } from 'react';
import { chain, isEmpty } from 'lodash';
import moment from 'moment';
const dateFormat = 'YYYY-MM-DD HH:mm';

const blankTime = moment('0001-01-01 00:00').format(dateFormat);

const uuid = () => '_' + Math.random().toString(36).substr(2, 9);


export const useBusinessHolidays = ({ regionalHolidays = [], businessHolidays = [], }) => {
  const [slots, setSlots] = useState({ regionalHolidays, businessHolidays });

  const [isValid, setValid] = useState(true);

  useEffect(() => {
    const dumpBusinessHolidays = [];

    const dumpRegionalHolidays = [];

    businessHolidays.map(({ id: holidayId, name, date, specialHours }) => {
      if (isEmpty(specialHours)) {
        dumpBusinessHolidays.push({
          id: holidayId,
          name,
          date,
          openingTime: new Date().setHours(0, 0, 0, 0),
          closingTime: new Date().setHours(0, 0, 0, 0),
          breakStartTime: new Date().setHours(0, 0, 0, 0),
          breakDuration: 0,
          hasBreak: false,
          isBreakTimeNotBetween: false,
        });
      } else {
        const slots = chain(specialHours)
          .groupBy('dayOfYear')
          .map((slots) => {
            const breakDifference = moment
              .duration(
                moment(slots[1]?.openingTime).diff(
                  moment(slots[0]?.closingTime)
                )
              )
              .asMinutes();

            const openingTime = moment(slots[0]?.openingTime);

            const closingTime = moment(slots[1]?.closingTime);

            const timeDifference = moment
              .duration(moment(closingTime).diff(moment(openingTime)))
              .asMinutes();

            const isOpen = timeDifference > 0;

            const hasBreak = isOpen && breakDifference > 0;

            const breakDuration = breakDifference;

            const breakStartTime = slots[0]?.closingTime;

            const { id } = slots[0] || {};

            const { id: slot2Id } = slots[1] || {};

            return {
              id: holidayId,
              name,
              date,
              specialHolidayId1: id,
              specialHolidayId2: slot2Id,
              openingTime: moment(openingTime).format('YYYY-MM-DD HH:mm'),
              closingTime: moment(closingTime).format('YYYY-MM-DD HH:mm'),
              isOpen,
              hasBreak,
              breakStartTime,
              breakDuration,
              isBreakTimeNotBetween: false,
            };
          })
          .value();

        dumpBusinessHolidays.push({ ...slots[0] });
      }
    });

    regionalHolidays.map(({ ...rest }) => {
      const isSelected = !isEmpty(
        businessHolidays.find(({ id }) => id === rest.id)
      );

      dumpRegionalHolidays.push({ ...rest, isSelected });
    });

    setSlots({
      regionalHolidays: [...dumpRegionalHolidays],
      businessHolidays: [...dumpBusinessHolidays],
    });
  }, [businessHolidays]);

  const getFinalData = () => {
    let finalSlots = [];

    let specialHours = [];

    slots.businessHolidays.map(
      ({
        id,
        name,
        date,
        specialHolidayId1,
        specialHolidayId2,
        hasBreak,
        openingTime,
        closingTime,
        breakDuration,
        breakStartTime,
      }) => {
        let firstSlot = {};

        let secondSlot = {};

        if (date) {
          openingTime =
            moment(date).format('YYYY-MM-DD') +
            ' ' +
            moment(openingTime).format('HH:mm');

          closingTime =
            moment(date).format('YYYY-MM-DD') +
            ' ' +
            moment(closingTime).format('HH:mm');
        }

        openingTime = moment(openingTime).format(dateFormat);

        closingTime = moment(closingTime).format(dateFormat);

        breakStartTime = moment(breakStartTime).format(dateFormat);

        firstSlot = {
          id:
            typeof specialHolidayId1 == 'string' || !specialHolidayId1
              ? 0
              : specialHolidayId1,
          openingTime,
          closingTime: breakStartTime,
          date: moment(date).format('YYYY-MM-DD'),
        };

        secondSlot = {
          id:
            typeof specialHolidayId2 == 'string' || !specialHolidayId2
              ? 0
              : specialHolidayId2,
          openingTime: moment(breakStartTime, dateFormat)
            .add(breakDuration, 'minutes')
            .format(dateFormat),
          closingTime,
          date: moment(date).format('YYYY-MM-DD'),
        };

        if (!hasBreak) {
          breakDuration = 0;

          breakStartTime = blankTime;

          firstSlot = { ...firstSlot, closingTime };

          secondSlot = { ...secondSlot, openingTime: closingTime, closingTime };
        }

        if (
          moment(firstSlot?.openingTime).format('HH:mm') == '00:00' &&
          moment(firstSlot?.closingTime).format('HH:mm') == '00:00'
        )
          specialHours = [];
        else specialHours = [{ ...firstSlot }, { ...secondSlot }];

        if (date)
          finalSlots.push({
            id: typeof id == 'string' || !id ? 0 : id,
            name: name ?? ' ',
            date: moment(date).format('YYYY-MM-DD'),
            specialHours,
          });
      }
    );

    return finalSlots;
  };

  const handleBusinessHolidayCreate = (data) => {
    const businessHolidays = [...slots.businessHolidays];

    businessHolidays.push({
      id: uuid(),
      name: null,
      date: '',
      openingTime: new Date().setHours(0, 0, 0, 0),
      closingTime: new Date().setHours(0, 0, 0, 0),
      breakStartTime: new Date().setHours(0, 0, 0, 0),
      breakDuration: 0,
      hasBreak: false,
      isBreakTimeNotBetween: false,
    });

    setSlots({ ...slots, businessHolidays });
  };

  const handleBusinessHolidayRemove = (Ids) => {

    const businessHolidays = slots.businessHolidays.filter((item) => {
      return !Ids.some((el) => {
        return el.id === item.id;
      });
    });

    const index = slots.regionalHolidays.findIndex((slot) => Ids[0].id === slot.id);

    let dumpSlots = [];

    if (index !== -1) {
      dumpSlots = [...slots.regionalHolidays];

      const modifySlot = { ...dumpSlots[index], isSelected: false };

      dumpSlots[index] = modifySlot;
    }

    setSlots({ regionalHolidays: dumpSlots, businessHolidays });
  };



  const handleRegionalHolidayToggle = ({ id, checked }) => {
    const index = slots.regionalHolidays.findIndex((slot) => id === slot.id);

    if (index == -1) return;

    let businessHolidays = [...slots.businessHolidays];

    const date = slots.regionalHolidays[index].date;

    if (checked)
      businessHolidays.push({
        ...slots.regionalHolidays[index],
        openingTime: new Date(date).setHours(0, 0, 0, 0),
        closingTime: new Date(date).setHours(0, 0, 0, 0),
        breakStartTime: new Date(date).setHours(0, 0, 0, 0),
        breakDuration: 0,
        hasBreak: false,
        isBreakTimeNotBetween: false,
        date: date,
      });
    else {
      if (!window.confirm('Are you sure you want to remove slot?')) return;

      businessHolidays = businessHolidays.filter((slot) => slot.id !== id);
    }

    const dumpSlots = [...slots.regionalHolidays];

    const modifySlot = { ...dumpSlots[index], isSelected: checked };

    dumpSlots[index] = modifySlot;

    setSlots({ regionalHolidays: dumpSlots, businessHolidays });
  };

  const handleDayChange = ({ id = -1, value = '' }) => {
    const index = slots.businessHolidays.findIndex((slot) => slot.id === id);

    if (index === -1) return;

    slots.businessHolidays[index].date = value;

    setSlots({ ...slots });
  };

  const handleTimeChange = ({ id = -1, timeStamp = '', fieldName = '' }) => {
    const index = slots.businessHolidays.findIndex((slot) => slot.id === id);

    if (index === -1) return;

    const dumpslots = [...slots.businessHolidays];

    let isWrongDuration = false;

    timeStamp = moment(timeStamp, 'HH:mm').toDate();

    let opening = '';
    let closing = '';
    let breakTime = '';

    if (fieldName === 'breakStartTime') {
      opening = moment(dumpslots[index].openingTime, 'HH:mm').format('HH:mm');

      closing = moment(dumpslots[index].closingTime, 'HH:mm').format('HH:mm');

      breakTime = moment(timeStamp, 'HH:mm').format('HH:mm');
    }

    if (fieldName === 'openingTime') {
      opening = moment(timeStamp, 'HH:mm').format('HH:mm');

      closing = moment(dumpslots[index].closingTime, 'HH:mm').format('HH:mm');

      breakTime = moment(dumpslots[index].breakStartTime, 'HH:mm').format(
        'HH:mm'
      );
    }

    if (fieldName === 'closingTime') {
      opening = moment(dumpslots[index].openingTime, 'HH:mm').format('HH:mm');

      closing = moment(timeStamp, 'HH:mm').format('HH:mm');

      breakTime = moment(dumpslots[index].breakStartTime, 'HH:mm').format(
        'HH:mm'
      );
    }

    const differMinutes = moment
      .duration(
        moment(dumpslots[index].closingTime).diff(
          dumpslots[index].breakStartTime
        )
      )
      .asMinutes();

    if (parseInt(dumpslots[index].breakDuration) > differMinutes)
      isWrongDuration = true;

    const isBreakTimeNotBetween =
      !(breakTime > opening && breakTime < closing) &&
      dumpslots[index].hasBreak;

    dumpslots[index] = {
      ...dumpslots[index],
      [fieldName]: timeStamp,
      isBreakTimeNotBetween,
      isWrongDuration,
    };

    if (isBreakTimeNotBetween || isWrongDuration) setValid(false);
    else setValid(true);

    setSlots({ ...slots, businessHolidays: dumpslots });
  };

  const handleBreakDurationChange = ({ id = -1, value = '' }) => {
    const index = slots.businessHolidays.findIndex((slot) => slot.id === id);

    if (index === -1) return;

    const dumpslots = [...slots.businessHolidays];

    let isWrongDuration = false;

    if (!value) value = 0;

    const differMinutes = moment
      .duration(
        moment(dumpslots[index].closingTime).diff(
          dumpslots[index].breakStartTime
        )
      )
      .asMinutes();

    if (parseInt(value) > differMinutes && dumpslots[index].hasBreak)
      isWrongDuration = true;

    dumpslots[index] = {
      ...dumpslots[index],
      breakDuration: parseInt(value),
      isWrongDuration,
    };

    if (isWrongDuration) setValid(false);
    else setValid(true);

    setSlots({ ...slots, businessHolidays: dumpslots });
  };

  const handleBreakSwitch = ({ id = -1 }) => {
    const index = slots.businessHolidays.findIndex((slot) => slot.id === id);

    if (index === -1) return;

    const dumpslots = [...slots.businessHolidays];

    if (dumpslots[index].hasBreak) {
      dumpslots[index] = {
        ...dumpslots[index],
        hasBreak: !dumpslots[index].hasBreak,
        isBreakTimeNotBetween: false,
        isWrongDuration: false,
        breakDuration: 0,
        breakStartTime: moment('00:00', 'HH:mm'),
      };
    } else {
      dumpslots[index] = {
        ...dumpslots[index],
        hasBreak: !dumpslots[index].hasBreak,
      };
    }

    setSlots({ ...slots, businessHolidays: dumpslots });
  };

  const handleDaySwitch = ({ id = -1 }) => {
    const index = slots.businessHolidays.findIndex((slot) => slot.id === id);

    if (index === -1) return;

    const dumpslots = [...slots.businessHolidays];

    if (dumpslots[index].isOpen) {
      dumpslots[index] = {
        ...dumpslots[index],
        isOpen: !dumpslots[index].isOpen,
        isBreakTimeNotBetween: false,
        isWrongDuration: false,
        breakDuration: 0,
        openingTime: moment('00:00', 'HH:mm'),
        closingTime: moment('00:00', 'HH:mm'),
        breakStartTime: moment('00:00', 'HH:mm'),
      };
    } else {
      dumpslots[index] = {
        ...dumpslots[index],
        isOpen: !dumpslots[index].isOpen,
      };
    }

    setSlots({ ...slots, businessHolidays: dumpslots });
  };

  return {
    slots,
    getFinalData,
    isValidForSubmit: isValid,
    handleRegionalHolidayToggle,
    handleBusinessHolidayCreate,
    handleBusinessHolidayRemove,
    handleDayChange,
    handleTimeChange,
    handleDaySwitch,
    handleBreakSwitch,
    handleBreakDurationChange,
  };
};
