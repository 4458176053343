import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useMutation, useLazyQuery, useQuery } from '@apollo/client';
import { toast as showToast } from 'react-toastify';
import { SEND_MESSAGE_TO_USER, GET_USER_CONVERSATION, GET_BUSINESS_BY_ID } from './requests';
import { useMessageDispatch } from '../../index';

//for firestore
import firestore from '@firebase';
import { COLLECTION } from 'constants/index';

const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

const extensions = ['jpeg', 'png', 'gif', 'tiff', 'psd', 'jpg'];

const k = 1024;

export const useData = () => {
  let { businessId } = useParams();
  businessId = parseInt(businessId);
  const [connection, setConnection] = useState(null);
  const dispatch = useMessageDispatch();
  const [error, setError] = useState(false);


  useEffect(() => {
    const unsubscribe = firestore
      .collection(COLLECTION.CHAT)
      .where('businessId', '==', `${businessId}`)
      .onSnapshot(async querySnapshot => {
        handleBusinessMessageReceived();
      });
    return () => unsubscribe();
  }, [businessId]);


  const [onSendMessageToUser, { loading: onSendMessageLoading }] = useMutation(
    SEND_MESSAGE_TO_USER,
    {
      onCompleted: ({
        sendMessageToUser: {
          messageId,
          userMessage: {
            business,
            user,
            message: { user: commentUser, commentText, createdTime },
            attachment,
          },
        },
      }) => {
        const payload = {
          user,
          attachment,
          recentMessages: [{ message: { commentText, user: commentUser } }],
          recentMessageTime: createdTime,
        };
        dispatch({ type: 'SET_LATEST_MESSAGE', payload });
      },
      onError: () => showToast.error('Message sending failed'),
    }
  );

  const [onGetUserConversation, { loading: isLoading }] = useLazyQuery(
    GET_USER_CONVERSATION,
    {
      fetchPolicy: 'no-cache',
      onCompleted: ({
        getUserConversation: { isSuccess, errorMessage, messages },
        getBusinessById: business,
      }) => {
        try {
          dispatch({
            type: 'SET_USER_CONVERSATION',
            payload: {
              isSuccess,
              errorMessage,
              conversation: messages,
              business,
              user: {
                name:
                  messages[0]?.user?.firstName && messages[0]?.user?.lastName
                    ? messages[0]?.user?.firstName +
                    ' ' +
                    messages[0]?.user?.lastName
                    : null,
                email: messages[0]?.user?.email,
                phoneNumber: messages[0]?.user?.phoneNumber ?? '',
              },
            },
          });
        } catch (e) { }
      },
      onError: () => {
        showToast.error('Failed to get conversation');
      },
    }
  );
  const refetch = new CustomEvent("refetch");
  const handleBusinessMessageReceived = (message) => {
    dispatch({ type: 'SIGNAL_RECEIVED', payload: true });
    document.dispatchEvent(refetch);
  };

  return {
    onSendMessageLoading,
    isLoading,
    onGetUserConversation,
    onSendMessageToUser,
  };
};

export const useFile = () => {
  const [previewSource, setPreviewSource] = useState('');
  const [fileType, setFileType] = useState('');
  const [fileSize, setFileSize] = useState('');
  const [fileName, setFileName] = useState('');

  const handleUpload = (files) => {
    let file = files[0];

    const reader = new FileReader();

    reader.fileName = file?.name;

    reader.onload = () => {
      setPreviewSource(window.btoa(reader.result));

      let { type, name, size } = file || {};

      setFileType(type);

      setFileSize(size);

      setFileName(name);
    };
    reader.readAsBinaryString(file);
  };

  const handleRemove = () => {
    setFileType('');
    setFileSize('');
    setPreviewSource('');
  };

  return {
    onUpload: handleUpload,
    onRemove: handleRemove,
    path: previewSource,
    type: fileType,
    size: fileSize,
    name: fileName,
  };
};


export const useGetBusinessById = (id) => {
  const {
    loading: isDataLoading,
    error: isError,
    data,
  } = useQuery(GET_BUSINESS_BY_ID, {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    variables: { id },
  });
  return { isError, isDataLoading, data };
};