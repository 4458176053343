import { toast } from 'react-toastify';
import { useMutation } from '@apollo/client';
import { SAVE_BAHMNI_PATIENT } from './requests';

export const useData = () => {
  const [onSavePatient, { loading: isLoading, error }] = useMutation(
    SAVE_BAHMNI_PATIENT,
    {
      onCompleted: ({ saveBahmniPatient: { isSaved, errorMessage } }) => {
        if (!isSaved)
          toast.error(errorMessage || 'An error occured during request');
        else toast.success('Patient saved successfully');
      },
      onError: () => toast.error('An error occured during request'),
    }
  );

  return {
    isLoading,
    error,
    onSavePatient,
  };
};
