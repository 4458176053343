import React from 'react';

const BusinessDescription = ({ description }) => {
  return (
    <>
      {description && (
        <section className="section px-0 py-2">
          <div className="container">
            <div className="card">
              <div className="card-content pt-3">
                <div className="content pl-0">
                  <div className="is-size-6 has-text-justified">
                    {description}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default BusinessDescription;
