import React from 'react'
import { useState, useEffect, useRef } from 'react';
import { Section } from '@safelyq/bulma-ui-library';
import SidebarAI from './components/SidebarAI';
import MainSectionAI from './components/MainSectionAI';
// import "./style.css";
import Businesses from './components/Businesses';
import { useSearchBusiness, useCHatSLBText, useAddRemoveFavBusinesses, useFetchAllFavs } from './hooks';
import { useLocation } from 'hooks/useLocation';
import { useSelector } from 'react-redux';
import FavouriteBusinesses from './components/FavouriteBusinesses';
import { useHistory } from 'react-router-dom';

const SearchBusinessAI = () => {

    // Get the coordinates (longitude and latitude) from the browser
    const history = useHistory();
    const { coords } = useLocation();
    const [businesses, setBusinesses] = useState([]);
    const [favBusinesses, setFavBusinesses] = useState([]);

    const allowChatSLB = useSelector(state => state?.consumer?.allowChatSLB);

    // Chat SLB is not allowed
    if (!allowChatSLB) {
        history.push('/')
    }

    const businessesTargetRef = useRef(null);

    const [initialMessage, setInitialMessages] = useState([
        { id: 1, text: 'Hello! How can I assist you?', sender: 'ai', businesses: [] },
    ]);

    const [messages, setMessages] = useState([
        { id: 1, text: 'Hello! How can I assist you?', sender: 'ai', businesses: [] },
    ]);

    const [userStatement, setUserStatement] = useState(null);
    const [input, setInput] = useState('');
    const inputRef = useRef('');

    // Get ChatSLB Text To Display
    const {
        getChatSLBText,
        isLoadingText,
        isErrorText,
        textData,
    } = useCHatSLBText();

    const chatSLBTextData = textData?.getChatSLBPageInfo?.chatSLBInfo;

    // Mutation for add remove fav
    const {
        handleAddRemoveFavorite,
        favouriteLoading
    } = useAddRemoveFavBusinesses();

    // Get all the favourite businesses
    const {
        getFavBusinesses,
        isLoadingFavs,
        isErrorFavs,
        favData,
    } = useFetchAllFavs();


    useEffect(() => {
        favData?.getCurrentUserFavorites && favData?.getCurrentUserFavorites?.length !== 0 && setFavBusinesses(favData?.getCurrentUserFavorites)
    }, [favData])

    // Send Messages and get response
    const {
        getBusinessesByGemini,
        isLoadingResponse,
        isErrorResponse,
        data,
    } = useSearchBusiness();


    useEffect(() => {
        getChatSLBText(); // Get ChatSLB texts
        getFavBusinesses({ variables: { businessId: 0 } }) // Get Favorite Businesses of the current user
    }, [])

    useEffect(() => {
        if (input) {
            inputRef.current = input
        }
    }, [input])

    const [showInput, setShowInput] = useState(false);

    // Function to scroll to the target div
    const scrollToTarget = () => {
        if (businessesTargetRef.current) {
            businessesTargetRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    useEffect(() => {
        if (data?.searchBusinessesByGemini) {

            let dummyMessages = [...messages];
            // If businesses found than populate else remove
            // if (data?.searchBusinessesByGemini?.businesses) {
            //     setBusinesses(data?.searchBusinessesByGemini?.businesses)
            // }
            // else {
            //     setBusinesses([])
            // }

            // If detailed response exist than set in the messages
            let response;
            response = {
                id: messages.length + 1,
                text: data?.searchBusinessesByGemini?.detailedDescription || "",
                sender: 'ai',
                businesses: data?.searchBusinessesByGemini?.businesses || []
            };
            dummyMessages.push(response)

            setMessages(dummyMessages)

        }
    }, [data])

    useEffect(() => {
        if (businesses.length !== 0 && !isLoadingResponse) {
            scrollToTarget();
        }
    }, [businesses])

    const handleSend = () => {
        const inputValue = inputRef.current.trim();

        if (inputValue) {
            setShowInput(true); // show the messages area
            setMessages(prevMessages => [
                ...prevMessages,
                { id: prevMessages.length + 1, text: inputValue, sender: "user", type: "text" }
            ]);
            setUserStatement(inputValue);
            setInput(''); // Clear the input after sending
            inputRef.current = '';
        }
    };

    useEffect(() => {
        // if userStatement is null than return 
        if (!userStatement) {
            return;
        }

        // Call By Graphql
        userStatement && getBusinessesByGemini({
            variables: {
                searchBusinessByGeminiInput: {
                    userStatement: userStatement,
                    latitude: coords?.latitude,
                    longitude: coords?.longitude,
                    isNewChat: messages?.length <= 2 ? true : false
                }
            }
        })
    }, [userStatement])

    return (
        <Section className='bscontainer has-text-centered-mobile'>
            <div className='columns responsive-class'>
                <div className='column is-9' style={{ height: "100%" }}>
                    <div className="columns main-section-ai-custom">
                        <div className='column is-2'>
                            <div className=''>
                                <SidebarAI initialMessage={initialMessage} setMessages={setMessages} setShowInput={setShowInput} showInput={showInput}
                                />
                            </div>
                        </div>
                        <div className='column is-10'>

                            <MainSectionAI
                                setUserStatement={setUserStatement}
                                setShowInput={setShowInput}
                                setMessages={setMessages}
                                showInput={showInput}
                                input={input}
                                messages={messages}
                                handleSend={handleSend}
                                setInput={setInput}
                                loading={isLoadingResponse}
                                chatSLBTextData={chatSLBTextData}
                                isLoadingText={isLoadingText}
                                favBusinesses={favBusinesses}
                                setFavBusinesses={setFavBusinesses}
                                handleAddRemoveFavorite={handleAddRemoveFavorite}
                            />

                        </div>
                    </div>
                </div>
                <div className='column is-3 p-0 px-3' style={{ height: "100%" }} >
                    {/* <div className='column is-3 p-0 px-3' style={{ height: "100%" }} ref={businessesTargetRef}> */}
                    {/* {businesses && <Businesses businesses={businesses} messages={messages} loading={isLoadingResponse} />} */}
                    <FavouriteBusinesses isLoadingFavs={isLoadingFavs} messages={messages} loading={isLoadingResponse} favBusinesses={favBusinesses} setFavBusinesses={setFavBusinesses} handleAddRemoveFavorite={handleAddRemoveFavorite} />
                </div>
            </div>
        </Section>
    )
}

export default SearchBusinessAI
