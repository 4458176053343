import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Modal } from 'antd';
import { useQuery, useMutation } from '@apollo/client';
import moment from 'moment';
import { toast } from 'react-toastify';
import usePageTitle from 'hooks/usePageTitle';
import Queries from '../Common/Queries';
import Calendar from '../Common/Calendar';
import BusinessPromotions from '../Common/BusinessPromotions';
import BusinessDescription from '../Common/BusinessDescription';
import SafetyTags from '../Common/SafetyTags';
import BusinessPhone from '../Common/BusinessPhone';
import WebAddress from '../Common/WebAddress';
import ExploreMenu from '../Common/ExploreMenu';
import AppointmentInfo from '../Common/AppointmentInfo';
import BusinessInfo from '../Common/BusinessInfo';

/* eslint jsx-a11y/anchor-is-valid: 0 */

const BusinessDetails = () => {
  usePageTitle('Book and Check-In');
  const history = useHistory();
  const { id: businessId } = useParams();
  const [showCalender, setShowCalender] = useState(false);
  const [selectedAppointmentSlot, setSelectedAppointmentSlot] = useState(null);
  const [addAppointment] = useMutation(Queries.ADD_USER_APPOINTMENT);
  const { loading, error, data } = useQuery(Queries.GET_BUSINESS, {
    variables: { id: businessId },
  });
  const [businessData, setBusinessData] = useState(null);

  const [newAppointmentData, setNewAppointmentData] = useState({
    adults: 1,
    children: 0,
  });

  const onDataChange = (name, value) => {
    setNewAppointmentData({
      ...newAppointmentData,
      [name]: value,
    });
  };

  const handleDisabled = () => {
    return (
      !selectedAppointmentSlot ||
      (businessData.maxAllowedGuests > 0 && newAppointmentData.adults === 0)
    );
  };

  const addNewAppointment = () => {
    const appointmentInput = {
      adults: newAppointmentData.adults,
      children: newAppointmentData.children,
      startTime: moment(selectedAppointmentSlot.startTime)
        .utc()
        .format('YYYY-MM-DD hh:mm A'),
      endTime: moment(selectedAppointmentSlot.endTime)
        .utc()
        .format('YYYY-MM-DD hh:mm A'),
      timeZone: selectedAppointmentSlot.timeZone,
      serviceId: newAppointmentData.serviceId,
      businessId: selectedAppointmentSlot.subBusiness.id,
      subBusinessId: selectedAppointmentSlot.subBusiness.id,
      userResponses: [],
    };

    addAppointment({ variables: { appointmentInput: appointmentInput } }).then(
      function (result) {
        history.push(
          `/${result.data.createUserAppointment.appointment.id}/confirm-checkin`
        );
      }
    );
  };

  const handleAppointmentSlotChange = (slot) => {
    setSelectedAppointmentSlot(slot);
  };

  useEffect(() => {
    if (loading) {
      //toast.warning('Fetching Appointment Details...');
    }

    if (error) {
      toast.error(error.message || 'Error while fetching info...');
      return null;
    }

    if (data) {
      setBusinessData(data.getBusinessById || {});
    }
  }, [loading, error, data]);

  return (
    <>
      {showCalender && (
        <Modal
          visible={true}
          title='Select Date'
          onCancel={() => {
            setShowCalender(false);
          }}
          onOk={() => {
            setShowCalender(false);
          }}
        >
          <Calendar
            onDayChange={(value) => {
              onDataChange('appointmentDate', moment(value.date).local());
            }}
          />
        </Modal>
      )}

      {businessData && (
        <>
          <section className='section px-2 py-2'>
            <div className='tile is-ancestor'>
              <div className='container'>
                <div className='tile is-vertical is-12'>
                  <div className='tile'>
                    <div className='tile is-parent is-vertical pl-0 pr-1 py-0'>
                      <section className='section px-2 py-2'>
                        <div className='container'>
                          <div className='card-image'>
                            <figure className='image is-16by9'>
                              <img
                                src={businessData.picture?.path}
                                alt='details header'
                              />
                              {/*`${process.env.PUBLIC_URL}/images/details-header.jpeg`*/}
                            </figure>
                          </div>
                        </div>
                      </section>
                      <BusinessPromotions
                        promotions={businessData.promotions}
                      />

                      <section className='section pl-0 pr-1 py-2 is-hidden-tablet'>
                        <BusinessInfo businessData={businessData} />
                      </section>
                      <section className='section pl-0 pr-1 py-2'>
                        <AppointmentInfo
                          businessId={businessData.id}
                          businessServices={businessData.services}
                          appointmentData={newAppointmentData}
                          isChildrenAllowed={businessData.isChildrenAllowed}
                          onAppointmentDataChange={onDataChange}
                          selectedSlot={selectedAppointmentSlot}
                          setSelectedAppointmentSlot={
                            setSelectedAppointmentSlot
                          }
                          onAppointmentSlotChange={handleAppointmentSlotChange}
                          showHeader={true}
                          slots={businessData.slots}
                          headerText={`New Appointment`}
                          maxAllowedGuests={businessData.maxAllowedGuests}
                          hasSubLevels={
                            !(
                              businessData.children.length === 1 &&
                              businessData.children[0].id === +businessId
                            )
                          }
                        />
                      </section>

                      <section className='section pt-0 pl-2 pr-3'>
                        <div className='container'>
                          <div className='buttons is-centered'>
                            <button
                              className='button button-appointment button-appointment-update is-large is-info'
                              onClick={addNewAppointment}
                              disabled={handleDisabled()}
                            >
                              Confirm Appointment
                            </button>
                          </div>
                        </div>
                      </section>
                    </div>

                    <div className='tile is-parent is-vertical pl-0 pr-1 py-0'>
                      <section className='section pl-0 pr-1 py-2 is-hidden-mobile'>
                        <BusinessInfo businessData={businessData} />
                      </section>

                      <section className='pl-0 pr-1'>
                        <SafetyTags safetyTags={businessData.safetyTags} />
                      </section>

                      {/*<section className="section py-4 pl-2 pr-3">
                                                <div className="container">                                               
                                                    <div className="buttons is-centered">
                                                        <button className="button button-appointment button-appointment-update is-medium is-info is-outlined"
                                                          >
                                                            Update Appointment
                                                        </button>
                                                    </div>
                                                </div>
                                            </section>*/}

                      <section className='pl-0 pr-1'>
                        <BusinessDescription
                          description={businessData.description}
                        />
                      </section>

                      <section className='section pl-0 pr-1 py-2'>
                        <div className='container'>
                          <div className='card'>
                            <div className='card-content pt-3'>
                              <div className='content'>
                                <BusinessPhone
                                  businessPhone={businessData.phone}
                                />
                                <WebAddress
                                  webAddress={businessData.webAddress}
                                />
                                <ExploreMenu businessId={businessData.id} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>

                      {/* <section className="section pt-0 pl-2 pr-2">
                                                <div className="container">
                                                    <div className="tile is-ancestor px-2">
                                                        <div className="tile is-vertical is-12">
                                                            <div className="tile">
                                                                <div className="tile is-parent is-vertical is-12">
                                                                    <div className="tile is-child">
                                                                        <h1 className="title mb-1">
                                                                            <span className="icon">
                                                                                <i className="fa fa-star yellow-color" />
                                                                            </span>
                                                                            <span className="icon">
                                                                                <i className="fa fa-star yellow-color" />
                                                                            </span>
                                                                            <span className="icon">
                                                                                <i className="fa fa-star yellow-color" />
                                                                            </span>
                                                                            <span className="icon">
                                                                                <i className="fa fa-star yellow-color" />
                                                                            </span>
                                                                            <span className="icon">
                                                                                <i className="fa fa-star-half-empty yellow-color" />
                                                                            </span>
                                                                            <span className="ml-3">
                                                                                <span>4.3 Out of 5</span>
                                                                            </span>
                                                                        </h1>
                                                                    </div>
                                                                    <div className="tile is-child">
                                                                        <h1 className="subtitle mb-1">261 Customer Ratings</h1>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>

                                            <section className="section pt-0 pl-2 pr-2">
                                                <div className="container">
                                                    <div className="tile is-ancestor px-2">
                                                        <div className="tile is-vertical is-12">
                                                            <div className="columns is-mobile">
                                                                <div className="column is-2 has-text-right">5 Star</div>
                                                                <div className="column is-7 pl-1">
                                                                    <progress
                                                                        className="progress is-warning"
                                                                        value="75"
                                                                        max="100"
                                                                    >
                                                                        75%
                  </progress>
                                                                </div>
                                                                <div className="column is-3 pl-1">75%</div>
                                                            </div>
                                                            <div className="columns is-mobile">
                                                                <div className="column is-2 has-text-right">4 Star</div>
                                                                <div className="column is-7 pl-1">
                                                                    <progress
                                                                        className="progress is-warning"
                                                                        value="6"
                                                                        max="100"
                                                                    >
                                                                        6%
                  </progress>
                                                                </div>
                                                                <div className="column is-3 pl-1">6%</div>
                                                            </div>
                                                            <div className="columns is-mobile">
                                                                <div className="column is-2 has-text-right">3 Star</div>
                                                                <div className="column is-7 pl-1">
                                                                    <progress
                                                                        className="progress is-warning"
                                                                        value="3"
                                                                        max="100"
                                                                    >
                                                                        3%
                  </progress>
                                                                </div>
                                                                <div className="column is-3 pl-1">3%</div>
                                                            </div>
                                                            <div className="columns is-mobile">
                                                                <div className="column is-2 has-text-right">2 Star</div>
                                                                <div className="column is-7 pl-1">
                                                                    <progress
                                                                        className="progress is-warning"
                                                                        value="5"
                                                                        max="100"
                                                                    >
                                                                        5%
                  </progress>
                                                                </div>
                                                                <div className="column is-3 pl-1">5%</div>
                                                            </div>
                                                            <div className="columns is-mobile">
                                                                <div className="column is-2 has-text-right">1 Star</div>
                                                                <div className="column is-7 pl-1">
                                                                    <progress
                                                                        className="progress is-warning"
                                                                        value="11"
                                                                        max="100">
                                                                        11%
                                                                </progress>
                                                                </div>
                                                                <div className="column is-3 pl-1">11%</div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </section>

                                            <section className="section pt-0 pl-2 pr-2">
                                                <div className="container">
                                                    <article className="media">
                                                        <figure className="media-left">
                                                            <p className="image is-64x64">
                                                                <img alt="" src="https://bulma.io/images/placeholders/128x128.png" />
                                                            </p>
                                                        </figure>
                                                        <div className="media-content">
                                                            <div className="content pr-2">
                                                                <p>
                                                                    <strong>Mike Oltmans</strong>
                                                                    <br />
                                                                    <span className="comment-time">
                                                                        <small>3 years ago</small>
                                                                    </span>
                                                                    <br />
                                                                    <span className="icon">
                                                                        <i className="fa fa-star yellow-color" />
                                                                    </span>
                                                                    <span className="icon">
                                                                        <i className="fa fa-star yellow-color" />
                                                                    </span>
                                                                    <span className="icon">
                                                                        <i className="fa fa-star yellow-color" />
                                                                    </span>
                                                                    <span className="icon">
                                                                        <i className="fa fa-star yellow-color" />
                                                                    </span>
                                                                    <span className="icon">
                                                                        <i className="fa fa-star yellow-color" />
                                                                    </span>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Proin ornare magna eros, eu pellentesque tortor vestibulum
                  ut. Maecenas non massa sem. Etiam finibus odio quis
                  feugiat facilisis.
                </p>
                                                            </div>
                                                            <nav className="level is-mobile">
                                                                <div className="level-left">
                                                                    <a className="level-item" href="/#">
                                                                        <span className="icon is-small">
                                                                            <i className="fa fa-reply" />
                                                                        </span>
                                                                    </a>
                                                                    <a className="level-item" href="/#">
                                                                        <span className="icon is-small">
                                                                            <i className="fa fa-retweet" />
                                                                        </span>
                                                                    </a>
                                                                    <a className="level-item" href="/#">
                                                                        <span className="icon is-small">
                                                                            <i className="fa fa-heart" />
                                                                        </span>
                                                                    </a>
                                                                </div>
                                                            </nav>
                                                        </div>
                                                    </article>
                                                    <article className="media">
                                                        <figure className="media-left">
                                                            <p className="image is-64x64">
                                                                <img alt="" src="https://bulma.io/images/placeholders/128x128.png" />
                                                            </p>
                                                        </figure>
                                                        <div className="media-content">
                                                            <div className="content pr-2">
                                                                <p>
                                                                    <strong>Alessandaro Baghat</strong>
                                                                    <br />
                                                                    <span className="comment-time">
                                                                        <small>a year ago</small>
                                                                    </span>
                                                                    <br />
                                                                    <span className="icon">
                                                                        <i className="fa fa-star yellow-color" />
                                                                    </span>
                                                                    <span className="icon">
                                                                        <i className="fa fa-star yellow-color" />
                                                                    </span>
                                                                    <span className="icon">
                                                                        <i className="fa fa-star-o fade-color" />
                                                                    </span>
                                                                    <span className="icon">
                                                                        <i className="fa fa-star-o fade-color" />
                                                                    </span>
                                                                    <span className="icon">
                                                                        <i className="fa fa-star-o fade-color" />
                                                                    </span>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Proin ornare magna eros, eu pellentesque tortor vestibulum
                  ut. Maecenas non massa sem. Etiam finibus odio quis
                  feugiat facilisis.
                </p>
                                                            </div>
                                                            <nav className="level is-mobile">
                                                                <div className="level-left">
                                                                    <a className="level-item" href="/#">
                                                                        <span className="icon is-small">
                                                                            <i className="fa fa-reply" />
                                                                        </span>
                                                                    </a>
                                                                    <a className="level-item" href="/#">
                                                                        <span className="icon is-small">
                                                                            <i className="fa fa-retweet" />
                                                                        </span>
                                                                    </a>
                                                                    <a className="level-item" href="/#">
                                                                        <span className="icon is-small">
                                                                            <i className="fa fa-heart" />
                                                                        </span>
                                                                    </a>
                                                                </div>
                                                            </nav>
                                                        </div>
                                                    </article>
                                                    <article className="media">
                                                        <figure className="media-left">
                                                            <p className="image is-64x64">
                                                                <img alt="" src="https://bulma.io/images/placeholders/128x128.png" />
                                                            </p>
                                                        </figure>
                                                        <div className="media-content">
                                                            <div className="content pr-2">
                                                                <p>
                                                                    <strong>Matt Suppelsa</strong>
                                                                    <br />
                                                                    <span className="comment-time">
                                                                        <small>5 days ago</small>
                                                                    </span>
                                                                    <br />
                                                                    <span className="icon">
                                                                        <i className="fa fa-star yellow-color" />
                                                                    </span>
                                                                    <span className="icon">
                                                                        <i className="fa fa-star yellow-color" />
                                                                    </span>
                                                                    <span className="icon">
                                                                        <i className="fa fa-star yellow-color" />
                                                                    </span>
                                                                    <span className="icon">
                                                                        <i className="fa fa-star yellow-color" />
                                                                    </span>
                                                                    <span className="icon">
                                                                        <i className="fa fa-star-o fade-color" />
                                                                    </span>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Proin ornare magna eros, eu pellentesque tortor vestibulum
                  ut. Maecenas non massa sem. Etiam finibus odio quis
                  feugiat facilisis.
                </p>
                                                            </div>
                                                            <nav className="level is-mobile">
                                                                <div className="level-left">
                                                                    <a className="level-item" href="/#">
                                                                        <span className="icon is-small">
                                                                            <i className="fa fa-reply" />
                                                                        </span>
                                                                    </a>
                                                                    <a className="level-item" href="/#">
                                                                        <span className="icon is-small">
                                                                            <i className="fa fa-retweet" />
                                                                        </span>
                                                                    </a>
                                                                    <a className="level-item" href="/#">
                                                                        <span className="icon is-small">
                                                                            <i className="fa fa-heart" />
                                                                        </span>
                                                                    </a>
                                                                </div>
                                                            </nav>
                                                        </div>
                                                    </article>
                                                </div>
                                            </section>

                                            <section className="section pt-0 pl-2 pr-2">
                                                <div className="container">
                                                    <p className="has-text-centered">
                                                        <a className="link is-info" href="/#">
                                                            <span className="icon">
                                                                More <i className="fa fa-chevron-right ml-1" />
                                                            </span>
                                                        </a>
                                                    </p>
                                                </div>
                                            </section>*/}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/*<section className="section pt-0 pl-2 pr-2">
                        <div className="container">
                            <hr />
                        </div>
                    </section>
                    */}
        </>
      )}
    </>
  );
};

export default BusinessDetails;
