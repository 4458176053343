import { gql } from '@apollo/client';

export const GET_BUSINESS_CATEGORIES = gql`
  query GetBusinessCategories {
    getBusinessCategories {
      name
      description
    }
  }
`;
