import moment from 'moment'
import React from 'react'
import { Link } from 'react-router-dom';
const EmptyAppointmentSection = ({ selectedDate }) => {
    return (
        <Link to={{ pathname: 'business/search', state: { appointmentSelectedDate: moment(selectedDate).format('ddd, DD MMM yyyy'), }, }}  >
            <div className='box has-text-centered'>
                <h1 className='title has-text-centered is-size-3-desktop is-size-5-mobile has-text-grey-light'>
                    <strong>No Appointments</strong>
                </h1>
                <button className='button is-info is-outlined ml-2 mb-3 is-small'>
                    <span className='icon'>
                        <i className='fa fa-plus'></i>
                    </span>
                    <span>Add New Appointment</span>
                </button>
            </div>
        </Link>
    )
}

export default EmptyAppointmentSection