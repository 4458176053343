import React from 'react'
import { useState, useEffect, useRef } from 'react';
import { RiListSettingsLine } from "react-icons/ri";
import { IoSend } from "react-icons/io5";
import Businesses from './Businesses';
import PriorSection from './PriorSection';
import { Button } from '@safelyq/bulma-ui-library';
import { useHistory, Link } from 'react-router-dom';
import StarRatings from 'react-star-ratings';
import { GiEmptyHourglass } from "react-icons/gi";

// HTMl conversion
const parseMarkdown = (text) => {
    // Replace multiple new lines with a single one
    text = text.replace(/\n{2,}/g, '\n\n');
    // Bold text
    let html = text.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
    // Italic text
    html = html.replace(/\*([^*]+)\*/g, '<em>$1</em>');
    // Strikethrough text
    html = html.replace(/~~(.*?)~~/g, '<del>$1</del>');
    // Underlined text
    html = html.replace(/__([^_]+)__/g, '<u>$1</u>');
    // Lists (both unordered and ordered)
    html = html.replace(/(?:^|\n)\* (.*?)(?=\n|$)/g, '<ul><li>$1</li></ul>'); // Unordered lists
    html = html.replace(/(?:^|\n)\d+\. (.*?)(?=\n|$)/g, '<ol><li>$1</li></ol>'); // Ordered lists
    // Remove any extra closing tags for lists
    html = html.replace(/<\/ul>\s*<ul>/g, '');
    html = html.replace(/<\/ol>\s*<ol>/g, '');
    // Code blocks (multiline code)
    html = html.replace(/```([\s\S]*?)```/g, '<pre><code>$1</code></pre>');
    // Inline code
    html = html.replace(/`([^`]+)`/g, '<code>$1</code>');
    // Links
    html = html.replace(/\[([^\]]+)\]\(([^)]+)\)/g, '<a href="$2">$1</a>');
    // Images
    html = html.replace(/\!\[([^\]]*)\]\(([^)]+)\)/g, '<img src="$2" alt="$1" />');
    // New lines (convert to <br> tags)
    html = html.replace(/\n/g, '<br/>');
    // Remove remaining Markdown characters
    html = html.replace(/(\*\*|__|\*|~~|`|\[\[|\]\]|\[|\]|!|`|\\)/g, '');
    // Ensure proper list closing tags
    html = html.replace(/<\/ul><ul>/g, '');
    html = html.replace(/<\/ol><ol>/g, '');
    // Return the cleaned and formatted HTML
    return html;
};

const MainSectionAI = ({ chatSLBTextData, isLoadingText, chat, input, setInput, messages, handleSend, showInput, setShowInput, setUserStatement, setMessages, loading,
    favBusinesses, setFavBusinesses, handleAddRemoveFavorite
}) => {
    const messagesEndRef = useRef(null); // responsible for sending the view to the last message

    // This is a closure proprety and holds the initially stated value
    // when the value changes, it does not update
    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Enter') {
                event.preventDefault(); // Optional: Prevent default behavior
                handleSend();
            }
        };

        // Add event listener on mount
        document.addEventListener('keydown', handleKeyDown); // trigger send input on enter press

        // Cleanup event listener on unmount
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []); // Empty dependency array ensures this effect runs once on mount

    // Scroll to the bottom whenever messages change
    useEffect(() => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }, [messages]);


    const customMessageDivRef = useRef(null);

    const [isAtBottom, setIsAtBottom] = useState(true);
    const [isOverflowing, setIsOverflowing] = useState(false);

    // change the indicator pointing based on the view of the page
    useEffect(() => {
        const element = customMessageDivRef.current;
        const handleScroll = () => {

            if (element) {
                setIsAtBottom(element.scrollTop + element.clientHeight >= element.scrollHeight);
                setIsOverflowing(element.scrollHeight > element.clientHeight);
            }
        };

        if (customMessageDivRef.current) {
            customMessageDivRef.current.addEventListener('scroll', handleScroll);
            // Initial check
            handleScroll();
        }

        return () => {
            if (customMessageDivRef.current) {
                customMessageDivRef.current.removeEventListener('scroll', handleScroll);
            }
        };
    }, [messages]);

    const handleSendMessage = (value) => {
        // setShowInput(true);
        setInput(value);
    }

    return (
        <div className="main-section-custom">
            <div className="header-custom is-flex is-justify-content-space-between">
                {/* {chat.name} */}
                <small>ChatSLB - Search Your Business</small>
                <div className='setting-icon-custom'>
                    <RiListSettingsLine />
                </div>
            </div>

            {
                // either conversation is being started and "New chat" is clicked or already started
                showInput || messages.length > 1 ?
                    <div className="chat-container-custom" style={{}}>
                        <div className="messages-custom" ref={customMessageDivRef}>
                            {messages?.map((message, index) => {
                                let htmlContent, businesses;
                                if (message?.text) {
                                    htmlContent = parseMarkdown(message.text)
                                }
                                if (message?.businesses?.length !== 0) {
                                    businesses = message.businesses;
                                }


                                return (
                                    <div
                                        key={message.id}
                                        ref={index === messages.length - 1 ? messagesEndRef : null} // Attach ref to the latest message
                                        style={{
                                            border: message?.sender === "ai" ? "1px solid gray" : "1px solid #d7d7d7",
                                            marginLeft: message.sender === "ai" ? "0px" : "auto",
                                            marginRight: message.sender === "user" ? "0px" : "auto"
                                        }}
                                        className={`message-custom ${message?.sender === "user" ? "has-background-white" : "has-background-white-ter"}`}
                                    >
                                        {
                                            // show the response if exist
                                            htmlContent && (
                                                <div
                                                    dangerouslySetInnerHTML={{ __html: htmlContent }}
                                                />
                                            )
                                        }

                                        {
                                            // Show business if exist
                                            businesses?.length === 0 && messages?.length > 1 && !loading ? (
                                                <div className='is-flex is-justify-content-center my-1' style={{ width: "100%" }}>
                                                    <GiEmptyHourglass color='gray' fontSize={26} />
                                                    <p className='ml-2 is-size-5 has-text-weight-semibold has-text-grey-dark'>No Suggestions Found</p>
                                                </div>
                                            ) : businesses && (
                                                <Businesses handleAddRemoveFavorite={handleAddRemoveFavorite} businesses={businesses} messages={messages} loading={loading} setFavBusinesses={setFavBusinesses} favBusinesses={favBusinesses} />
                                            )
                                        }

                                    </div>
                                )
                            })}

                        </div>

                        {isOverflowing && (
                            <div style={{ position: "absolute", bottom: 50, right: 20 }}>
                                <div className='has-text-right' style={{ cursor: 'pointer' }}>
                                    {isAtBottom
                                        ?
                                        <div onClick={() => customMessageDivRef.current.scrollTo({ top: 0, behavior: 'smooth' })}>⏫</div>
                                        :
                                        <div onClick={() => customMessageDivRef.current.scrollTo({ top: customMessageDivRef.current.scrollHeight, behavior: 'smooth' })}>⏬</div>}
                                </div>
                            </div>
                        )}

                    </div>
                    :
                    <div className="m-3 messages-custom is-half">
                        <PriorSection chatSLBTextData={chatSLBTextData} isLoadingText={isLoadingText} input={input} loading={loading} handleSend={handleSend} setInput={setInput} setUserStatement={setUserStatement} setShowInput={setShowInput} setMessages={setMessages} messages={messages} />
                    </div>
            }
            <div className="input-box-custom mx-3">
                <input
                    type="text"
                    value={input}
                    onChange={e => handleSendMessage(e.target.value)}
                    placeholder="Type a message..."
                />
                <Button style={{ height: "52px", width: "50px" }} loading={loading} onClick={handleSend}> {!loading && <IoSend color='white' size={24} className='' />} </Button>
            </div>
        </div>

    )
}

export default MainSectionAI