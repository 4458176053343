import React, { Fragment, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { isEmpty } from 'lodash';
import { Section, Columns, Column, Container } from '@safelyq/bulma-ui-library';
import {
  BusinessCard,
  BookingSection,
  InstructionSection,
  RatingSection,
  ExistingAppointment,
  PromotionCard,
  Skeleton,
} from '../../components';
import { useData } from './hooks';
import NewAppointment from '../../components/NewAppointment/NewAppointment';

export const ManageBooking = ({ ...props }) => {
  const { isLoggedIn } = props;

  const ref = useRef(null);

  const { isLoading, error, data: business, refetch } = useData();

  const { push } = useHistory();

  const isEmptyCoupons = isEmpty(business?.businessCoupons);

  if (isLoading) {
    return <Skeleton isLoggedIn={isLoggedIn} />;
  }

  if (error) {
    return <Skeleton isLoggedIn={isLoggedIn} />;
  }

  if (isEmpty(business)) {
    push('/404');
    return null;
  }

  const { canUserCreateAppointment } = business || {};

  return (
    <>
      <Section>
        <MetaTags
          title={business?.name}
          description={business?.description}
          tags={business?.categoryTags}
        />
        <Container>
          <Columns multiline centered>
            {!isEmptyCoupons && (
              <Fragment>
                <Column size='12' className='is-hidden-tablet py-1'>
                  <PromotionCard />
                </Column>

              </Fragment>
            )}
            <Column size='4'>
              <Columns multiline>
                <Column size='12'>
                  <BusinessCard
                    business={business}
                    refetch={refetch}
                    {...props}
                  />
                </Column>
                <Column size='12' className='is-hidden-tablet'>
                  <BookingSection
                    isAllowToCreate={canUserCreateAppointment}
                    business={business}
                    {...props}
                  />
                </Column>
                <Column size='12'>
                  <InstructionSection business={business} />
                </Column>
                <Column size='12'>
                  <RatingSection
                    isLoggedIn={isLoggedIn}
                    business={business}
                    refetch={refetch}
                    ref={ref}
                    {...props}
                  />
                </Column>
              </Columns>
            </Column>
            <Column size='6'>
              <Columns multiline>
                {!isEmptyCoupons && (
                  <Fragment>
                    <Column size='12' className='is-hidden-mobile py-1'>
                      <PromotionCard business={business} refetch={refetch} />
                    </Column>

                  </Fragment>
                )}
                {isLoggedIn && (
                  <Column size='12'>
                    <ExistingAppointment business={business} />
                  </Column>
                )}
                {/* <Column size='12' className='is-hidden-mobile'>
                  <BookingSection
                    isAllowToCreate={canUserCreateAppointment}
                    business={business}
                    {...props}
                  />
                </Column> */}
                <Column size='12' className='is-hidden-mobile'>
                  <NewAppointment
                    isAllowToCreate={canUserCreateAppointment}
                    {...props} 
                  />
                </Column>
              </Columns>
            </Column>
          </Columns>
        </Container>
      </Section>
      {/* <Switch>
        <Route path='/business/:businessId/coupon/:couponId'>
          <PromotionDetailModal
            isOpen={true}
            businessCoupons={business.businessCoupons}
            onCloseModal={() => { push("/business/" + business.id) }}
            refetch={refetch}
          />
        </Route>
      </Switch> */}
    </>
  );
};

const MetaTags = ({ title, description, tags }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name='description' content={description} />
      <meta
        name='keywords'
        content={`Appointment,Online Appointment,SafelyQ Appointment,Queueing System,${title},${title} Appointment${tags.toString()}`}
      />
    </Helmet>
  );
};
