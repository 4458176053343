import React from 'react';
import { Link } from 'react-router-dom';
import StarRatings from 'react-star-ratings';
import { Heading, Paragraph, Icon, Card, Content, } from '@safelyq/bulma-ui-library';
import { useGetBusinessInfo } from '../../routes/ManageBooking/hooks';
import { Skeleton } from 'antd'
import { StyledIcon, FavouriteButton, StylesFooterLink } from './BusinessCard.styles';
import { ScheduleList, MenuList } from './components';
import { useState } from 'react';
import { useMutation } from '@apollo/client';
import Queries from 'components/Common/Queries';
import { toast } from 'react-toastify';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';

export const BusinessCard = (props) => {
  const { isLoggedIn, login: tryToLogin, persistStorage } = props;

  const [favorite, setFavorite] = useState(false);
  const { loading, error, business, refetch } = useGetBusinessInfo();
  const [addRemoveFromFavorite, { loading: favouriteLoading }] = useMutation(Queries.ADD_REMOVE_FROM_FAVORITES);
  const { allMessages } = useSelector((state) => state.auth);


  const history = useHistory();
  let { businessId } = useParams();

  businessId = parseInt(businessId);
  const handleFavourite = async (e, bId, isFavourite) => {
    if (!isLoggedIn) {
      const data = {
        businessId: bId,
        isFavourite: isFavourite
      }
      localStorage.setItem('favouriteBusiness', JSON.stringify(data));
      await tryToLogin();
      return;
    }
    try {
      localStorage.removeItem('favouriteBusiness');
      await addRemoveFromFavorite({ variables: { businessId: bId, isFavorite: !isFavourite }, })
      await refetch()
      setFavorite(!favorite)
      if (business.isFavorite) {
        toast.success('Removed From Favorites');
      }
      else {
        toast.success('Add To Favorites');
      }
    } catch (error) {
      console.log(error);
    }
  }

  const handleMessage = (businessId) => {
    const isOldBusiness = allMessages.some((mesg) => mesg.business.id === businessId)
    if (isOldBusiness) {
      history.push({
        pathname: `/messages/${businessId}`,
        state: undefined
      });
    }
    else {
      history.push({
        pathname: `/messages/${businessId}`,
        state: { businessId: businessId }
      });

    }

  }

  useEffect(() => {
    (async () => {
      const favouriteBusiness = JSON.parse(localStorage.getItem('favouriteBusiness'));
      if (favouriteBusiness) {
        if (favouriteBusiness.businessId === businessId) {
          await addRemoveFromFavorite({ variables: { businessId: favouriteBusiness.businessId, isFavorite: !favouriteBusiness.isFavourite }, })
          await refetch()
          setFavorite(!favorite)
          if (favouriteBusiness.isFavourite) {
            toast.success('Removed From Favorites');
          }
          else {
            toast.success('Add To Favorites');
          }
          localStorage.removeItem('favouriteBusiness');

        }

      }
    })();
  }, [])

  if (loading) {
    return (<Skeleton.Input style={{ height: '200px', }} active={true} size={"large"} block={true} />)
  }
  else {
    return (
      <Card bordered>
        <Card.Image src={business.picture} alt='Business Brand' al style={{ border: '1px solid #d4d8df' }} />
        <Card.Content className='pb-3'>
          <Content className='mb-2'>
            <Heading size='4' className='mb-1'>   {business.name}  </Heading>
            {business.city && <Paragraph className='has-text-grey mb-1'>{business.city}</Paragraph>}
            {business.address1 && (
              <a href={`https://maps.google.com/maps?q=${business.address1}`} target='_blank' rel="noreferrer" className='has-text-black is-flex is-justify-content-space-between'  >
                <Paragraph className='mb-1'>{business.address1}</Paragraph>
                <StyledIcon name='location-arrow' />
              </a>
            )}
            {business.phoneFormatted && (
              <a href={`tel:${business.phoneFormatted}`} className='has-text-grey is-flex is-justify-content-space-between'    >
                <Paragraph className='mb-1'>{business.phoneFormatted}</Paragraph>
                <StyledIcon name='phone' />
              </a>
            )}
            {business.webAddress && (
              <a href={business.webAddress} target='_blank' rel="noreferrer" className='has-text-black is-flex is-justify-content-space-between'   >
                <Paragraph className='mb-0'>{business.webAddress}</Paragraph>
                <StyledIcon name='globe' />
              </a>
            )}

          </Content>
          {business.menus.length > 0 && <MenuList menus={business.menus} />}
          < ScheduleList slots={business.slots} timeZone={business.timeZone} />
        </Card.Content>
        <Card.Footer>
          <Card.FooterItem >
            <div className='mt-1'>
              <StarRatings rating={business.serviceRatings?.value} starRatedColor='rgb(26 17 186)' starSpacing='3px' starDimension='15px' />
            </div>
            <small>[{business.serviceRatings?.reviewsCount}]</small>
          </Card.FooterItem>
          <FavouriteButton onClick={(e) => handleFavourite(e, business.id, business.isFavorite)} data-tip={business.isFavorite ? 'Remove Favorite' : 'Favorite'} className='has-text-info'   >
            {favouriteLoading ? (<small>...</small>) : (<Icon name={business.isFavorite ? 'heart' : 'heart-o'} collection='fa' />)}
          </FavouriteButton>
          <StylesFooterLink aria-label='messages' onClick={(e) => e.preventDefault()}  >
            {business.email && (
              <StylesFooterLink aria-label='email' className='p-0'>
                <a href={`mailto:${business.email}?subject=Mail from ${business.name}`}>   <StyledIcon name='envelope' data-tip='Email' />  </a>
              </StylesFooterLink>
            )}
            {business.consumerInitiationAllowed && (
              <StylesFooterLink aria-label='messages' className='p-0'>
                <span onClick={() => handleMessage(business.id)}>
                  <StyledIcon name='comment' data-tip='Message' />
                </span>
                {/* <Link to={{ pathname: `/messages/${business.id}`, state: { businessId: business.id, businessName: business.name, } }} >
                </Link> */}
              </StylesFooterLink>
            )}
          </StylesFooterLink>
        </Card.Footer>
      </Card>
    );
  }

};
