import React, { useEffect, useState } from 'react';
import { Columns } from 'react-bulma-components';
import Constants from 'constants/index';

/* eslint jsx-a11y/anchor-is-valid: 0 */

const FlatView = ({
  editMode,
  availableSlots = [],
  selectedSlot,
  userMessage,
  onChange,
}) => {
  const [pageSize, setPageSize] = useState(
    editMode ? availableSlots.length : Constants.DEFAULT_TIME_SLOTS_PAGE_SIZE
  );
  const loadMoreRecords = () => {
    setPageSize(pageSize + Constants.DEFAULT_TIME_SLOTS_PAGE_SIZE);
  };

  useEffect(() => {
    setPageSize(
      editMode ? availableSlots.length : Constants.DEFAULT_TIME_SLOTS_PAGE_SIZE
    );
  }, [availableSlots, editMode]);

  const resetPageSize = () => {
    setPageSize(Constants.DEFAULT_TIME_SLOTS_PAGE_SIZE);
  };

  return (
    <>
      <Columns className='is-mobile'>
        {/*Json Structure 
             {
                "startDateOnly": "Wednesday October 28, 2020",
                "startTimeOnly": "12:08 PM",
                "endDateOnly": "Wednesday October 28, 2020",
                "endTimeOnly": "12:38 PM",
                "timeZone": "Eastern Standard Time"
            }
         */}
        {availableSlots.slice(0, pageSize).map((availableSlot, index) => {
          return (
            <Columns.Column
              key={`appointmentSlot_${index}`}
              size={3}
              className='px-1 is-size-5 py-0 my-2'
            >
              <div
                className={`timeTab-slot false ${
                  selectedSlot &&
                  availableSlot.startTimeOnly.toLowerCase() ===
                    selectedSlot.startTimeOnly.toLowerCase()
                    ? 'selected'
                    : ''
                }`}
                onClick={() => {
                  onChange(availableSlot);
                }}
              >
                {availableSlot.startTimeOnly}
              </div>
            </Columns.Column>
          );
        })}
        {availableSlots.length === 0 && (
          <Columns.Column size={12} className='px-1 is-size-5 py-0 my-2'>
            <div
              className={`timeTab-slot has-text-weight-bold is-size-6 has-text-grey-lighter`}
            >
              {userMessage}
            </div>
          </Columns.Column>
        )}
      </Columns>

      {availableSlots.length > Constants.DEFAULT_TIME_SLOTS_PAGE_SIZE && (
        <>
          <Columns.Column
            key={`showMore_FlatView`}
            size={12}
            className={`px-1 is-size-5 py-0 my-2 ${
              pageSize >= availableSlots.length && 'is-hidden'
            }`}
          >
            <div className={`buttons mt-4 is-centered`}>
              <a
                className='link is-size-6 is-info'
                href='/#'
                onClick={(e) => {
                  e.preventDefault();
                  loadMoreRecords();
                }}
              >
                More
                <span className='icon is-size-6'>
                  <i className='fa fa-angle-down'></i>
                </span>
              </a>
            </div>
          </Columns.Column>

          <Columns.Column
            key={`showLess_FlatView`}
            size={12}
            className={`px-1 is-size-5 py-0 my-2 ${
              pageSize < availableSlots.length && 'is-hidden'
            }`}
          >
            <div className={`buttons mt-4 is-centered`}>
              <a
                className='link is-size-6 is-info'
                href='/#'
                onClick={(e) => {
                  e.preventDefault();
                  resetPageSize();
                }}
              >
                Less
                <span className='icon is-size-6'>
                  <i className='fa fa-angle-up'></i>
                </span>
              </a>
            </div>
          </Columns.Column>
        </>
      )}
    </>
  );
};

export default FlatView;
