import { gql } from "@apollo/client";

export const ADD_REMOVE_FROM_FAVORITES = gql`
    mutation addRemoveFromFavorites($businessId: Int!, $isFavorite: Boolean!) {
      addRemoveFromFavorites(businessId: $businessId, isFavorite: $isFavorite) {
        id
        business {
          id
        }
        user {
          id
        }
      }
    }
  `;

export const GET_USER_FAVORITE_BUSINESS_COUPONS = gql`
  query GetFavoriteBusinessCoupons {
    getUserFavoritesBusinessCoupon {
      businessCoupon {
        id
        code
        title
        summary
        discount
        discountType
        endDate
        expireStatus
        couponUrl
        imageUrl
        isFavorite
        business {
          id
          name
          businessCategory {
            name
            description
          }
        }
      }
    }
  }
`;