import React from 'react';

const WebAddress = ({ webAddress }) => {
  return (
    <>
      {webAddress && (
        <a
          href={webAddress}
          rel="noopener noreferrer"
          target="_blank"
          className="columns is-mobile"
        >
          <div className="column is-size-5">
            <span style={{ whiteSpace: 'nowrap' }}>
              <span className="icon has-text-info">
                <i className="fa fa-external-link" />
              </span>
              <span className="ml-3 has-text-dark">Visit Website</span>
            </span>
          </div>
          <div className="column is-size-5 has-text-right">
            <span className="icon">
              <i className="fa fa-chevron-right" color="#037ffc" />
            </span>
          </div>
        </a>
      )}
    </>
  );
};

export default WebAddress;
