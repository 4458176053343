import { useEffect, useState } from 'react';
import Cookies from 'js-cookie';

export const useLocation = () => {
  const [isLocationEnable, setLocationStatus] = useState(true);
  const [coords, setCoords] = useState({ latitude: 0, longitude: 0 });

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      ({ coords: { latitude, longitude } }) => {
        setLocationStatus(true);
        setCoords({ latitude, longitude });

        // Set coordinates to cookies
        Cookies.set('userLatitude', latitude);
        Cookies.set('userLongitude', longitude);
      },
      () => {
        setLocationStatus(false);

        // Check cookies for coordinates
        const storedLatitude = Cookies.get('userLatitude');
        const storedLongitude = Cookies.get('userLongitude');

        if (storedLatitude && storedLongitude) {
          setCoords({
            latitude: parseFloat(storedLatitude),
            longitude: parseFloat(storedLongitude),
          });
        } else {
          setCoords({ latitude: 0, longitude: 0 });
          new Notification(
            'Please enable your current location for a better SafelyQ search experience'
          );
        }
      }
    );
  }, []);

  return { isLocationEnable, coords };
};
