import { gql } from '@apollo/client';

export const GET_CONVERSATION = gql`
  query GetConversation {
    getUser {
      user {
        conversations {
          business {
            id
            name
            picture {
              path
            }
          }
          unreadCount
          recentMessageTime
          recentMessages {
            message {
              commentText
            }
          }
        }
      }
    }
  }
`;

export const ADD_MESSAGE_SUBSCRIPTION = gql`
  subscription UserMessageReceived {
  userMessageReceived {
    isSaved
    errorMessage
    user {
      id
      name
    }
  }
}
`;
