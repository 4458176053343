import React from "react";
import { Modal, Title, Text, Buttons, Button } from "@safelyq/bulma-ui-library";
import { STATUS } from "../../../../constants";

export const ConfirmationModal = ({ ...props }) => {
  const { isOpen, onCancel, action, onClose } = props;

  return (
    <Modal active={isOpen} clipped>
      <Modal.Background />
      <Modal.Card>
        <Modal.Card.Head>
          <Title size="5" className="m-0">
            {action == STATUS.CANCELLED && "Cancel appointment ?"}
            {action == STATUS.CHECKED_IN_BY_BUSINESS &&
              "Check-In appointment ?"}
            {action == STATUS.SERVING_NOW && "Serve Now appointment ?"}
          </Title>
        </Modal.Card.Head>
        <Modal.Card.Body>
          <Text>
            Are your sure you want to &nbsp;
            <b>
              {action == STATUS.CANCELLED && "cancel"}
              {action == STATUS.CHECKED_IN_BY_BUSINESS && "check-in"}
              {action == STATUS.SERVING_NOW && "serve"}
            </b>{" "}
            &nbsp;this appointment ?
          </Text>
          <div className="mt-5">
            <Buttons size="small" className="is-right">
              <Button color="danger" onClick={onCancel}>
                Ok
              </Button>
              <Button onClick={onClose}>Cancel</Button>
            </Buttons>
          </div>
        </Modal.Card.Body>
      </Modal.Card>
    </Modal>
  );
};
