import { gql } from '@apollo/client';

export const SEND_MESSAGE_TO_BUSINESS = gql`
  mutation SendMessageToBusiness(
    $businessId: Int!
    $messageText: String!
    $attachment: FileInput
  ) {
    sendMessageToBusiness(
      businessMessageInput: {
        businessId: $businessId
        messageText: $messageText
        attachment: $attachment
      }
    ) {
      messageId
      userMessage {
        user {
          id
        }
        direction
        business {
          id
          name
          picture {
            path
          }
        }
        message {
          user {
            id
          }
          commentText
        }
        attachment {
          contentType
          publicUrl
          jsonData
          fileName
          id
        }
      }
    }
  }
`;
export const GET_BUSINESS_CONVERSATION = gql`
  query GetBusinessConversation($businessId: Int!) {
    getBusinessConversation(businessId: $businessId) {
      messages {
        user {
          id
          firstName
          email
        }
        direction
        business {
          id
          name
          picture {
            path
          }
          messaging {
            messagingAllowed
            consumerResponseAllowed
            consumerAttachmentsAllowed
          }
        }
        messageType
        message {
          user {
            id
          }
          commentText
          createdTime
        }
        attachment {
          contentType
          publicUrl
          jsonData
          fileName
          id
        }
      }
    }
  }
`;

export const GET_BUSINESS = gql`
  query GetBusiness($businessId: Int) {
    getBusinessById(id: $businessId, showAll: true) {
      name
      messaging {
        messagingAllowed
        consumerResponseAllowed
        consumerAttachmentsAllowed
      }
      picture {
        path
      }
    }
  }
`;
