import React from 'react'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { MdNavigateBefore, MdNavigateNext } from "react-icons/md";
import BusinessListItem from './BusinessListItem';

const SampleNextArrow = (props) => (<span className="NextArrow" onClick={props.onClick} > {" "} < MdNavigateNext /> {" "} </span>);
const SamplePrevArrow = (props) => (<span className="PreviousArrow" onClick={props.onClick}  > {" "} < MdNavigateBefore /> {" "} </span>);


const BusinessListSlider = ({ allbusinesses }) => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: allbusinesses.length === 1 ? 1 : allbusinesses.length === 2 ? 2 : 3,
        slidesToScroll: 1,
        autoplay: allbusinesses.length > 3 ? true : false,
        autoplaySpeed: 2000,
        arrows: allbusinesses.length > 3 ? true : false,
        nextArrow: < SampleNextArrow />,
        prevArrow: < SamplePrevArrow />,
        responsive: [
            { breakpoint: 1280, settings: { slidesToShow: 1, arrows: false, } },
            { breakpoint: 1024, settings: { slidesToShow: 1, arrows: false, } },
            { breakpoint: 600, settings: { slidesToShow: 1, arrows: false, } },
        ],
    };
    return (
        <div className='row g-0'>
            <div className={`${allbusinesses.length === 1 ? 'col-lg-4' : allbusinesses.length === 2 ? 'col-lg-8' : 'col-lg-12'}`}>
                <Slider {...settings} className={"BusinessListSlider"}>
                    {allbusinesses.map((business, i) => {
                        return (
                            <div key={business.id} >
                                <div className='Wrapper'>
                                    <BusinessListItem business={business} />

                                </div>
                            </div>
                        )
                    })}
                </Slider>
            </div>
        </div>
    )
}

export default BusinessListSlider