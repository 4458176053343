import React, { useEffect, useState } from 'react'
import { EllipsisText, ListItem, ScrollList, StyledSearchBar, Tag } from './ManageUserMessages.styled'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux';
import { toggleActiveChat } from 'Redux/AuthSlice/AuthSlice';
import { useParams, useHistory } from 'react-router-dom';
import { Field, Control, Icon } from '@safelyq/bulma-ui-library';

const AllChats = () => {
    const [allList, setallList] = useState([])

    const { allMessages } = useSelector((state) => state.auth);
    const dispatch = useDispatch()
    let { businessId } = useParams();
    const { push } = useHistory();
    const handleActiveChat = (chat) => {
        push(`/messages/${chat.business.id}`)
        dispatch(toggleActiveChat({ businessId: chat.business.id }))
    }
    const handleFilter = (e) => {
        const updatedChats = allMessages.filter((mesg) => String(mesg.business.name).toLocaleLowerCase().includes(String(e.target.value).toLowerCase()))
        setallList(updatedChats)
    }
    useEffect(() => {

        setallList(allMessages)

    }, [allMessages])
    useEffect(() => {
        dispatch(toggleActiveChat({ businessId: +businessId }))


    }, [businessId, dispatch, push])
    return (
        <>
            <ul>
                <ListItem className='p-0' bgGrey>
                    <Field>
                        <Control>
                            <Icon name='search' position='left' />
                            <StyledSearchBar placeholder={'Search chat'} onChange={handleFilter} />
                        </Control>
                    </Field>
                </ListItem>
            </ul>
            <ScrollList>
                {allList.map((chat) => {
                    return (
                        <ListItem active={chat.active} onClick={() => handleActiveChat(chat)}>
                            <div className='col-2'>
                                <figure className='media-left'>
                                    {chat.business.picture && chat.business?.picture?.path ? (
                                        <p className='image is-48x48'>  <img className='image is-48x48 is-rounded' src={chat.business.picture?.path} alt="pic" />   </p>
                                    ) : (
                                        <p className='image is-48x48 has-background-grey-light rounded is-flex is-justify-content-center is-align-items-center'>  {String(chat.business.name).charAt(0)}</p>
                                    )}
                                </figure>
                            </div>
                            <div className='col-10 '>
                                <div className='row g-0'>
                                    <div className='col-7 is-flex is-flex-direction-column is-justify-content-space-evenly'>
                                        <p className='mb-0'>
                                            <strong>
                                                {chat.business.name.length > 22 ? String(chat.business.name).substring(0, 22) + '...' : chat.business.name}
                                            </strong>
                                        </p>
                                        <EllipsisText>
                                            {chat.recentMessages[0].message.commentText}
                                        </EllipsisText>
                                    </div>
                                    <div className='col-5 has-text-right'>
                                        <span className='  is-size-7 has-text-grey-light'>
                                            {moment.utc(chat.business.recentMessageTime).local().calendar()}{' '}
                                        </span>
                                        {chat.unreadCount > 0 && (
                                            <Tag>{chat.unreadCount > 9 ? '9+' : chat.unreadCount}</Tag>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </ListItem>
                    )
                })}
            </ScrollList>
        </>

    )
}

export default AllChats