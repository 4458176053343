import React from 'react';
import { BookingCardSkeleton } from './BookingCardSkeleton';
import { InstructionSkeleton } from './InstructionSkeleton';
import { ContactSkeleton } from './ContactSkeleton';
import { ExistingAppointmentSkeleton } from './ExistingAppointmentSkeleton';
import { BookingFormSkeleton } from './BookingFormSkeleton';
import { Section, Columns, Column, Container } from '@safelyq/bulma-ui-library';

export const Skeleton = ({ ...props }) => {
  const { isLoggedIn } = props;

  return (
    <Section>
      <Container>
        <Columns multiline centered>
          <Column size='4'>
            <Columns multiline>
              <Column size='12'>
                <BookingCardSkeleton />
              </Column>
              <Column size='12'>
                <InstructionSkeleton />
              </Column>
              <Column size='12'>
                <ContactSkeleton />
              </Column>
            </Columns>
          </Column>
          <Column size='6'>
            <Columns multiline>
              {isLoggedIn && (
                <Column size='12'>
                  <ExistingAppointmentSkeleton />
                </Column>
              )}
              <Column size='12'>
                <BookingFormSkeleton />
              </Column>
            </Columns>
          </Column>
        </Columns>
      </Container>
    </Section>
  );
};
