import { useQuery } from '@apollo/client';
import { GET_BUSINESS_INSTRUCTIONS } from "./requests";
import { useParams } from 'react-router-dom';
export const useGetBusinessInstructions = () => {
    const { businessId } = useParams();
    const id = parseInt(businessId);
    const { loading, error, data } = useQuery(GET_BUSINESS_INSTRUCTIONS, {
        variables: { id },
        // fetchPolicy: 'network-only',
        // notifyOnNetworkStatusChange: true,
    }
        ,);

    return { loading, error, data };
};