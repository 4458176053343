
import React, { Fragment, useState, useEffect } from 'react';
import { Link, useHistory, useParams, useLocation, useQuery as useRouterQuery } from 'react-router-dom';
import styled from 'styled-components';
import moment from 'moment';
import mtimezone from 'moment-timezone';
import * as _ from 'lodash';
import { Box, Menu, Text, List, Title, Icon, Paragraph, Button, } from '@safelyq/bulma-ui-library';
// import { LoadingSection, ErrorSection, EditAppointmentModal, } from './components';
import { statusEnum, statusColorsEnum } from './constants';
import { useData } from './hooks';
import { getStatusName, getStatusColor } from 'methods/getStatuses';
import { useLazyQuery, useQuery } from '@apollo/client';
import { GET_USER_APPOINTMENTS } from './requests';
import { useSelector } from 'react-redux';
import { ListLink, ClickableText } from "./ExistingAppointment.styles"
import { GET_BUSINESS_BY_ID } from '../../routes/ManageBooking/requests';
import EditAppointmentPopup from './components/EditAppointmentPopup/EditAppointmentPopup';
import AppointmentDropdown from './components/AppointmentDropdown/AppointmentDropdown';
import { Skeleton } from 'antd';
import useSearchParamsQuery from 'hooks/useSearchParamsQuery';




export const ExistingAppointment = () => {
  const [businessAppointments, setBusinessAppointments] = useState([])
  const [status, setStatus] = useState(statusEnum.ALL)
  const [apptPopup, setApptPopup] = useState(false)
  const [apptData, setApptData] = useState(null)


  const { allAppointments } = useSelector((state) => state.auth);
  let { businessId } = useParams();
  let searchParams = useSearchParamsQuery()
  const business_Id = parseInt(businessId);
  let { location: { search }, push, } = useHistory();

  const { loading, error, data, refetch, } = useQuery(GET_BUSINESS_BY_ID, { variables: { id: business_Id } })

  const handleTabChange = (e, appt_status) => {
    setStatus(appt_status)
  }

  const handleOpenPopup = (appt_data) => {
    setApptPopup(true)
    setApptData(appt_data)
  }
  const handleClosePopup = () => {
    const appID = searchParams.get("edit-appointment")
    if (appID) {
      push(`/business/${business_Id}`)
    }
    setApptPopup(false)
    setApptData(null)
  }

  useEffect(() => {
    const only_business_appointments = allAppointments.filter((appt) => appt.business.id === business_Id)
    let filtered_appointments = only_business_appointments

    if (status === statusEnum.ALL) {
      filtered_appointments = only_business_appointments
    }
    else {
      filtered_appointments = only_business_appointments.filter((appt) => appt.status === status)
    }

    if (searchParams.get("edit-appointment")) {
      const appID = searchParams.get("edit-appointment")
      const singleAppt = filtered_appointments.find((appt) => appt.id === parseInt(appID))
      if (singleAppt) {
        setApptPopup(true)
        setApptData(singleAppt)
      }
    } else {
      setApptPopup(false)
      setApptData(null)
    }
    setBusinessAppointments(filtered_appointments)



  }, [allAppointments, business_Id, status, searchParams])

  if (loading) {
    return (<Skeleton.Input style={{ height: '200px', }} active={true} size={"large"} block={true} />)
  }
  else {
    const business_info = data.getBusinessById
    return (

      <Box bordered>
        {apptPopup && <EditAppointmentPopup onClose={handleClosePopup} isOpen={apptPopup} appt={apptData} business={business_info} />}
        <Menu>
          <Title size='6'>Existing Appointments</Title>
          <div className='tabs is-boxed is-small'>
            <List type='unordered'>
              <List.Item className={`${status === statusEnum.ALL ? "is-active" : ""}`}>
                <Link to={'#'} onClick={(e) => handleTabChange(e, statusEnum.ALL)}>{statusEnum.ALL}</Link>
              </List.Item>
              <List.Item className={`${status === statusEnum.BOOKED ? "is-active" : ""}`}>
                <Link to={'#'} onClick={(e) => handleTabChange(e, statusEnum.BOOKED)}>{statusEnum.BOOKED}</Link>
              </List.Item>
              <List.Item className={`${status === statusEnum.CONFIRMED ? "is-active" : ""}`}>
                <Link to={'#'} onClick={(e) => handleTabChange(e, statusEnum.CONFIRMED)}>{statusEnum.CONFIRMED}</Link>
              </List.Item>
              <List.Item className={`${status === statusEnum.CHECKEDIN ? "is-active" : ""}`}>
                <Link to={'#'} onClick={(e) => handleTabChange(e, statusEnum.CHECKEDIN)}>{statusEnum.CHECKEDIN}</Link>
              </List.Item>
              <List.Item className={`${status === statusEnum.CANCELED ? "is-active" : ""}`}>
                <Link to={'#'} onClick={(e) => handleTabChange(e, statusEnum.CANCELED)}>{statusEnum.CANCELED}</Link>
              </List.Item>
              <List.Item className={`${status === statusEnum.PASSED ? "is-active" : ""}`}>
                <Link to={'#'} onClick={(e) => handleTabChange(e, statusEnum.PASSED)}>{statusEnum.PASSED}</Link>
              </List.Item>
            </List>
          </div>
          <Menu.List>
            {businessAppointments.length === 0 && <Paragraph alignment='centered' weight='bold'>No appointment found</Paragraph>}
            {businessAppointments.map((appt, index) => {
              return (
                <List.Item key={appt.id}>
                  <ListLink className='is-flex is-justify-content-space-between is-align-items-center'>
                    <ClickableText onClick={() => push(`/${appt.id}/confirm-checkin`)}   >
                      <b>
                        <small>  {moment.tz(appt.startTime, appt.timeZone).format('ddd MMM Do, yyyy')}   </small>
                        <small className='ml-3'>  {moment.tz(appt.startTime, appt.timeZone).format('hh:mm A')}     </small>
                      </b>
                      <br />
                      <small> {appt.service.name} - {appt.business.name}</small>
                    </ClickableText>
                    <span className={`is-right`} style={{ color: `rgb(${getStatusColor(appt.status)})`, }}   >
                      <strong>
                        <small>{getStatusName(appt.status)}</small>
                      </strong>
                    </span>
                    <AppointmentDropdown
                      appt={appt}
                      apptId={appt.id}
                      handleOpenPopup={handleOpenPopup}
                      isAllowToCancel={business_info.canUserCancelAppointment}
                      isAllowToEdit={business_info.canUserUpdateAppointment}
                      isConfirmationRequired={business_info.isAppointmentConfirmationRequired}
                      confirmationOffset={business_info.appointmentConfirmationOffset}
                      appointmentTime={appt.startTime}
                      questions={business_info.questions}
                      preCheckinOffset={business_info.details.offset}
                      apptStatus={appt.status}
                    />
                  </ListLink>
                </List.Item>
              )
            })}
          </Menu.List>
        </Menu>
      </Box>
    )
  }

}




