import { gql } from '@apollo/client';

export const MARK_NOTIFICATION_READ = gql`
  mutation MarkNotificationAsRead($correlationKey: String) {
    markNotificationAsRead(correlationKey: $correlationKey) {
      errorMessage
      isSuccess
    }
  }
`;
