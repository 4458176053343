import React from 'react'
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import StarRatings from 'react-star-ratings';
import { useSearchDispatch } from '@safelyq/search-feature';
import { Box, Icon } from '@safelyq/bulma-ui-library';

const emptyThumbnail = '/images/image-not-found.png';
const SingleBusiness = ({ business, ...rest }) => {
    const dispatch = useSearchDispatch();

    let { id, name, description, picture, city, isFavorite, serviceRatings } =
        business || {};

    const { isLoggedIn, key } = rest;

    const isNoAnyPictureFound = !picture || !picture.path;

    const isTextMakeSubstring = String(description).length > 15;

    if (isNoAnyPictureFound) picture = { path: emptyThumbnail };

    const isNameLong = String(name).length > 18;

    const nameSubStringSize = isLoggedIn ? 18 : 20;

    const descriptionSubStringSize = isLoggedIn ? 16 : 20;

    const handleFavoriteRequest = (e) => {
        e.preventDefault();
        let payload = {
            businessId: id,
            isFavorite: !isFavorite,
            sendRequest: true,
        };
        dispatch({ type: 'SET_FAVORITE_REQUEST', payload });
    };
    return (
        <div>
            <div className='row g-0 business_main'>
                <div className='col-lg-5'>
                    <Link to={{ pathname: `/business/${business.id}`, }} >
                        <img className='businessImage' src={business?.picture?.path || emptyThumbnail} alt={business.name} />
                    </Link>
                </div>
                <div className='col-lg-7 p-2 businessdetailContainer'>
                    <div className='row g-0'>
                        <div className='col-10'>
                            <span className='is-6 has-text-weight-bold' style={{ textAlign: "left", display: "block" }} title={business.name}    >
                                {business.name?.length <= 15 ? business.name : `${business.name.substring(0, 15)}...`}
                            </span>
                        </div>
                        <div className='col-2 has-text-right'>
                            <i onClick={handleFavoriteRequest} className={`${isFavorite ? 'fa fa-heart' : 'fa fa-heart-o'} favorite_icon`} />
                        </div>
                    </div>
                    <div className='row g-0'>
                        <div className='col-lg-12'>
                            <p className="business_description"> {business.description ? (`${business.description.substring(0, 20)}...`) : ("No Description")}</p>
                        </div>
                    </div>
                    {business.address1 && (
                        <div className='row g-0'>
                            <div className='col-lg-12'>
                                <i className='fa fa-map-marker' style={{ color: '#037ffc' }}  ></i>
                                <span className='subtitle is-7'>  {business.city}  </span>
                            </div>
                        </div>
                    )}

                    <div className='row g-0'>
                        <div className='col-lg-12'>
                            <div style={{ color: 'rgb(26 17 186)' }}>
                                <StarRatings rating={business?.serviceRatings?.value} starRatedColor='rgb(26 17 186)' starSpacing='3px' starDimension='15px' />
                                &nbsp;[  {business?.serviceRatings?.reviewsCount}]
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default SingleBusiness