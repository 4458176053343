import React from 'react'
import { Helmet } from 'react-helmet'

const MessageBoxMetaTags = ({ title }) => {
    return (
        <Helmet>
            <title>Messages - {title}</title>
            <meta
                name='description'
                content='SafelyQ, a US based company, offers a comprehensive solution for organizations and agencies to safely queue people into any kind of facilities that are brought up, from mega sites with multiple entry points to drive throughs.'
            />
            <meta
                name='keywords'
                content='Appointments, Online Appointments, SafelyQ Appointment, Queueing System, Le Bernardin Appointment, Dallas City Hall'
            />
        </Helmet>
    )
}

export default MessageBoxMetaTags