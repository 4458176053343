import { useMutation } from 'react-query';
import { APIPaths } from 'services/api-provider';

export function useVerifyClaim(claim, handleSuccess, handleError) {
  const { isLoading, mutate: callVerify } = useMutation((body) =>
    fetch(`${APIPaths.Gateway}/Account/VerifyClaim`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    })
  );

  const handleVerifySuccess = (response) => {
    if (response.ok) {
      if (handleSuccess) {
        handleSuccess(claim);
      }
    } else {
      if (handleError) {
        handleError({
          message: 'There was an error verifying claim',
        });
      }
    }
  };

  const handleVerifyError = (error) => {
    handleError(error);
  };

  const verify = () => {
    callVerify(claim, {
      onSuccess: handleVerifySuccess,
      onError: handleVerifyError,
    });
  };

  return {
    isLoading,
    verify,
  };
}
