import { useEffect, useState } from 'react';
import { chain, isEmpty } from 'lodash';
import moment from 'moment';

const format = 'YYYY-MM-DD HH:mm';

const blankTime = moment('0001-01-01 00:00').format(format);

const getWeekDayName = (dayNumber) => {
  switch (dayNumber) {
    case 1:
      return 'Monday';
    case 2:
      return 'Tuesday';
    case 3:
      return 'Wednesday';
    case 4:
      return 'Thursday';
    case 5:
      return 'Friday';
    case 6:
      return 'Saturday';
    case 0:
      return 'Sunday';
  }
};

export const useHolidaysOperation = (originalSlots, dummySlots) => {
  const [slots, setSlots] = useState(dummySlots || []);

  const [dateModal, setDateModal] = useState({
    index: -1,
    fieldName: '',
    isVisible: false,
  });

  useEffect(() => {
    if (!isEmpty(originalSlots)) {
      const businessSlots = chain(originalSlots)
        .groupBy('dayOfWeek')
        .map((slots, index) => {
          const breakDifference = moment
            .duration(
              moment(slots[1]?.openingTime).diff(moment(slots[0]?.closingTime))
            )
            .asMinutes();

          const dayOfWeek = parseInt(slots[0]?.dayOfWeek);

          const openingTime = moment(slots[0]?.openingTime);

          const closingTime = moment(slots[1]?.closingTime);

          const timeDifference = moment
            .duration(moment(closingTime).diff(moment(openingTime)))
            .asMinutes();

          const isOpen = timeDifference > 0;

          const hasBreak = isOpen && breakDifference > 0;

          const breakDuration = breakDifference;

          const breakStartTime = slots[0]?.closingTime;

          const { id: slot1Id } = slots[0] || {};

          const { id: slot2Id } = slots[1] || {};

          return {
            dayOfWeek: getWeekDayName(dayOfWeek),
            dayNumber: dayOfWeek,
            isOpen,
            hasBreak,
            openingTime,
            closingTime,
            breakStartTime,
            breakDuration,
            isBreakTimeNotBetween: false,
            isWrongDuration: false,
            slot1Id,
            slot2Id,
          };
        })
        .value();
      setSlots(businessSlots);
    }
  }, [originalSlots]);

  const getFinalData = () => {
    const finalSlots = [];

    slots.map(
      ({ id, dayNumber, slot1Id, slot2Id, regionClosureId, isOpen, hasBreak, openingTime, closingTime, breakDuration, breakStartTime, }) => {
        let firstSlot = {};
        let secondSlot = {};

        openingTime = moment(openingTime).format(format);
        closingTime = moment(closingTime).format(format);
        breakStartTime = moment(breakStartTime).format(format);

        if (!isOpen) {
          openingTime = closingTime = breakStartTime = blankTime;
          breakDuration = 0;
        }

        firstSlot = {
          id: slot1Id || 0,
          dayOfWeek: dayNumber,
          openingTime,
          closingTime: breakStartTime,
        };

        secondSlot = {
          id: slot2Id || 0,
          dayOfWeek: dayNumber,
          openingTime: moment(breakStartTime, format).add(breakDuration, 'minutes').format(format),
          closingTime,
        };

        if (!hasBreak) {
          breakDuration = 0;
          breakStartTime = blankTime;
          firstSlot = { ...firstSlot, closingTime };
          secondSlot = { ...secondSlot, openingTime: closingTime, closingTime };
        }

        finalSlots.push({ ...firstSlot }, { ...secondSlot });
      }
    );

    return finalSlots;
  };

  const handleTimeChange = (timeStamp, index, fieldName) => {
    const dumpslots = [...slots];

    let isWrongDuration = false;

    timeStamp = moment(timeStamp, 'HH:mm').toDate();

    let opening = '';
    let closing = '';
    let breakTime = '';

    if (fieldName === 'breakStartTime') {
      opening = moment(dumpslots[index].openingTime, 'HH:mm').format('HH:mm');

      closing = moment(dumpslots[index].closingTime, 'HH:mm').format('HH:mm');

      breakTime = moment(timeStamp, 'HH:mm').format('HH:mm');
    }

    if (fieldName === 'openingTime') {
      opening = moment(timeStamp, 'HH:mm').format('HH:mm');

      closing = moment(dumpslots[index].closingTime, 'HH:mm').format('HH:mm');

      breakTime = moment(dumpslots[index].breakStartTime, 'HH:mm').format(
        'HH:mm'
      );
    }

    if (fieldName === 'closingTime') {
      opening = moment(dumpslots[index].openingTime, 'HH:mm').format('HH:mm');

      closing = moment(timeStamp, 'HH:mm').format('HH:mm');

      breakTime = moment(dumpslots[index].breakStartTime, 'HH:mm').format(
        'HH:mm'
      );
    }

    const differMinutes = moment
      .duration(
        moment(dumpslots[index].closingTime).diff(
          dumpslots[index].breakStartTime
        )
      )
      .asMinutes();

    if (parseInt(dumpslots[index].breakDuration) > differMinutes)
      isWrongDuration = true;

    const isBreakTimeNotBetween =
      !(breakTime > opening && breakTime < closing) &&
      dumpslots[index].hasBreak;

    dumpslots[index] = {
      ...dumpslots[index],
      [fieldName]: timeStamp,
      isBreakTimeNotBetween,
      isWrongDuration,
    };

    setSlots(dumpslots);

    setDateModal({ ...dateModal, isVisible: false });
  };

  const handleModal = ({ index, fieldName, isVisible }) =>
    setDateModal({ index, fieldName, isVisible });

  const handleBreakMinuteChange = ({ value, index }) => {
    const dumpslots = [...slots];

    const regex = /^[0-9]+$/;

    let isWrongDuration = false;

    if (!value) value = 0;

    if (!regex.test(value)) return;

    const differMinutes = moment
      .duration(
        moment(dumpslots[index].closingTime).diff(
          dumpslots[index].breakStartTime
        )
      )
      .asMinutes();

    if (parseInt(value) > differMinutes && dumpslots[index].hasBreak)
      isWrongDuration = true;

    dumpslots[index] = {
      ...dumpslots[index],
      breakDuration: parseInt(value),
      isWrongDuration,
    };

    setSlots(dumpslots);
  };

  const handleDaySwitch = (index) => {
    const dumpslots = [...slots];

    if (dumpslots[index].isOpen) {
      dumpslots[index] = {
        ...dumpslots[index],
        openingTime: moment('00:00', 'HH:mm'),
        closingTime: moment('00:00', 'HH:mm'),
        breakStartTime: moment('00:00', 'HH:mm'),
        breakDuration: 0,
      };
    }

    dumpslots[index] = {
      ...dumpslots[index],
      isOpen: !dumpslots[index].isOpen,
    };

    setSlots(dumpslots);
  };

  const handleBreakSwitch = (index) => {
    const dumpslots = [...slots];

    if (dumpslots[index].hasBreak) {
      dumpslots[index] = {
        ...dumpslots[index],
        hasBreak: !dumpslots[index].hasBreak,
        isBreakTimeNotBetween: false,
        isWrongDuration: false,
        breakDuration: 0,
        breakStartTime: moment('00:00', 'HH:mm'),
      };
    } else {
      dumpslots[index] = {
        ...dumpslots[index],
        hasBreak: !dumpslots[index].hasBreak,
      };
    }

    setSlots(dumpslots);
  };

  return {
    slots,
    isVisiblePicker: dateModal.isVisible,
    getFinalData,
    handleTimeChange,
    handleBreakMinuteChange,
    handleModal,
    handleDaySwitch,
    handleBreakSwitch,
  };
};
