import React, { useState, useEffect } from 'react';
import { Link, useParams, useLocation } from 'react-router-dom';
import { isEqual } from 'lodash';
import {
  Level,
  Columns,
  Column,
  Button,
  Title,
  Subtitle,
} from '@safelyq/bulma-ui-library';
import {
  useBusinessDispatch,
  useBusinessState,
} from '@safelyq/manage-business';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import { CreateProfile } from '../CreateProfile';
import { BusinessImagesSetup } from '../BusinessImagesSection';
import { HoursOperation } from '../HoursOperation';
import { HolidaySchedule } from '../HolidaySchedule';
import { ServicesSetup } from '../ServicesSetup';
import { TrafficSetup } from '../TrafficSetup';
import { CheckInSetup } from '../CheckInSetup';
import { PromotionSetup } from '../PromotionSetup';
import { AppointmentConfirmationSetup } from '../AppointmentConfirmationSetup';
import { useData } from './hooks';
import 'react-accessible-accordion/dist/fancy-example.css';

export const SetupStepper = () => {
  const { id: businessId } = useParams() || {};

  const dispatch = useBusinessDispatch();

  let { pathname } = useLocation();

  const {
    business: { name: businessName, organization },
    accordionActiveSteps,
  } = useBusinessState();

  const [organizaion, setOrganization] = useState(null);

  const { isLoading, data: organizaions, onLoadBusiness } = useData();

  const preExpandedTabs = isEqual(
    pathname,
    `/admin/manage-business/promotions/${businessId}`
  )
    ? [9]
    : [0];

  const steps = [
    {
      step_title: 'Profile',
      element: <CreateProfile organizationId={organizaion} />,
    },
    {
      step_title: 'Business Images',
      element: <BusinessImagesSetup organizationId={organizaion} />,
    },
    {
      step_title: 'Hours of Operation',
      element: <HoursOperation organizationId={organizaion} />,
    },
    {
      step_title: 'Holiday Schedule',
      element: <HolidaySchedule organizationId={organizaion} />,
    },
    {
      step_title: 'Services Setup',
      element: <ServicesSetup organizationId={organizaion} />,
    },
    {
      step_title: 'Customer Traffic Setup',
      element: <TrafficSetup organizationId={organizaion} />,
    },
    {
      step_title: 'Appointment Confirmation Setup',
      element: <AppointmentConfirmationSetup organizationId={organizaion} />,
    },
    {
      step_title: 'Check-in Setup',
      element: <CheckInSetup organizationId={organizaion} />,
    },
    {
      step_title: 'Promotions Setup',
      element: <PromotionSetup organizationId={organizaion} />,
    },
  ];

  useEffect(() => {
    if (parseInt(businessId)) {
      dispatch({ type: 'SET_BUSINESS_ID', payload: { businessId } });

      onLoadBusiness({ variables: { businessId } });
    }
  }, [businessId]);

  const handleOrganizationSwitch = ({ target: { value: id } }) => {
    dispatch({ type: 'SET_ACCORDION_ACTIVE_STEPS', payload: [1] });

    setOrganization(id);
  };

  const isActive = (index) =>
    accordionActiveSteps.find((step) => index === step);

  return (
    <Columns multiline>
      {!organization?.id ? (
        <Column size='12'>
          <Level>
            <Level.Left>
              <div className='control'>
                <label className='label'>Organization</label>
                <div
                  className={`select is-fullwidth ${
                    isLoading ? 'is-loading' : ''
                  }`}
                >
                  <select
                    value={organization?.id}
                    disabled={organization?.id}
                    onChange={handleOrganizationSwitch}
                  >
                    <option value={''} selected disabled>
                      Select Organization
                    </option>
                    {organizaions.map(({ id, name }, index) => (
                      <option key={`organization-${index}`} value={id}>
                        {name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </Level.Left>
            {!organization && (
              <Level.Right>
                <Link to='/admin/create-organization'>
                  <Button color='info' disabled={isLoading}>
                    Create Organization
                  </Button>
                </Link>
              </Level.Right>
            )}
          </Level>
        </Column>
      ) : (
        <Column size='12' className='is-hidden-tablet'>
          <Title size='5'>{businessName}</Title>
          <Subtitle size='6'>{organization?.name}</Subtitle>
        </Column>
      )}
      {!organization && (
        <Column size='12'>
          <Title size='5'>Create new Business</Title>
        </Column>
      )}
      <Column size='12'>
        <Accordion preExpanded={preExpandedTabs}>
          {steps.map(
            ({ step_title, element }, index) =>
              (businessId || index !== 3) && (
                <AccordionItem uuid={index + 1}>
                  <AccordionItemHeading
                    style={{
                      opacity: isActive(index + 1) ? 1 : 0.5,
                      pointerEvents: isActive(index + 1) ? 'visible' : 'none',
                    }}
                  >
                    <AccordionItemButton>{step_title}</AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>{element}</AccordionItemPanel>
                </AccordionItem>
              )
          )}
        </Accordion>
      </Column>
    </Columns>
  );
};
