import React, { useState } from 'react'
import { useQuery } from '@apollo/client';
import Queries from 'components/Common/Queries';
import { Skeleton } from 'antd';
import CategoryTags from 'components/Common/CategoryTags';
import FavoritesList from '../FavoritesList';

const BusinessFavorites = () => {
    const [businessCategories, setbusinessCategories] = useState([])
    const [allFavorites, setallFavorites] = useState([])
    const [filteredFavorites, setFilteredFavorites] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('all');

    let { loading, data, refetch } = useQuery(Queries.GET_CURRENT_USER_FAVORITES, {
        fetchPolicy: 'no-cache',
        variables: { businessId: 0 },
        onCompleted: (data) => {
            const ddd = data.getCurrentUserFavorites.map((fav) => fav.business.businessCategory)
            const uniqueObjects = ddd.reduce((accumulator, currentObject) => {
                const keyValue = currentObject["name"];
                // Check if the key value is already in the accumulator
                if (!accumulator.has(keyValue)) {
                    accumulator.set(keyValue, currentObject);
                }
                return accumulator;
            }, new Map());

            const uniqueArray = Array.from(uniqueObjects.values());
            setbusinessCategories((prev) => ([{ name: "all", description: "All" }, ...uniqueArray]))
            setFilteredFavorites(data.getCurrentUserFavorites)
            setallFavorites(data.getCurrentUserFavorites)
        }
    });
    const handleSelectedCategory = (name) => {
        const fff = allFavorites.filter((fav) => fav.business.businessCategory.name === name)
        if (name === "all") { setFilteredFavorites(allFavorites) } else { setFilteredFavorites(fff) }

        setSelectedCategory(name);
    };

    if (loading) {
        return (<Skeleton.Input style={{ height: '200px', }} active={true} size={"large"} block={true} />)
    }
    else {

        return (
            <div >
                {businessCategories.length > 0 && <CategoryTags tags={businessCategories} selectedTag={[selectedCategory]} onChange={(category) => handleSelectedCategory(category.name)} />}

                <FavoritesList favoriteLists={filteredFavorites} refetch={refetch} />
            </div>
        )
    }

}

export default BusinessFavorites