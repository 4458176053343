import { useState } from 'react';
import { useHistory } from 'react-router';
import { useMutation } from 'react-query';
import { APIPaths } from 'services/api-provider';

export function useCheckClaim(claim, handleSuccess, handleError) {
  const history = useHistory();

  const [result, setResult] = useState();

  const { isLoading, mutate: callCheck } = useMutation((body) =>
    fetch(`${APIPaths.Gateway}/Account/CheckClaim`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    })
  );

  const translateError = (message) => {
    switch (message) {
      case 'CANNOT_BE_CLAIMED':
        return {
          message: 'Account cannot be claimed',
        };
      case 'EMAIL_REGISTERED':
        return {
          message: 'Email already taken. \n Please login to claim this number.',
        };

      case 'IS_UNSUPPORTED':
      default:
        return {
          message: 'You should not be seeing this. Contact support.',
        };
    }
  };

  const handleCheckSuccess = (response) => {
    if (response.ok) {
      response
        .json()
        .then((result) => {
          if (result.state === 'DISPLAY_ERROR') {
            const error = translateError(result.message);

            handleError(error);
          } else {
            setResult(result);

            handleSuccess(claim);

            history.push('/account/verify');
          }
        })
        .catch((error) => {
          setResult();

          if (handleError) {
            handleError(error);
          }
        });
    } else {
      setResult();

      if (handleError) {
        handleError({
          message: 'There was an error checking claim',
        });
      }
    }
  };

  const handleCheckError = (error) => {
    handleError(error);
  };

  const check = () => {
    callCheck(claim, {
      onSuccess: handleCheckSuccess,
      onError: handleCheckError,
    });
  };

  return {
    isLoading,
    result,
    check,
  };
}
