import React from 'react';
import "./home.css"
import AppointmentSection from './MainHomePageComponents/AppointmentSection/AppointmentSection';
import SuggestedBusinessSection from './MainHomePageComponents/SuggestedBusinessSection/SuggestedBusinessSection';


const HomePageDesktop = () => {
    return (
        <>
            <div className='bscontainer'>
                <AppointmentSection />
                <SuggestedBusinessSection />
            </div>

        </>
    );
};

export default HomePageDesktop;
