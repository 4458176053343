import { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import moment from 'moment';
import { useLazyQuery, useQuery } from '@apollo/client';
import { GET_APPOINTMENT_SUMMARY, GET_BUSINESS_CAMPAIGNS, GET_BUSINESS_APPOINTMENTS } from './requests';
import { INITIAL_DATA } from './constants';
import { toast as showToast } from 'react-toastify';

// Campaings API
export const useCampaignsData = () => {

  const [
    getUserCampaigns,
    {
      loading: isLoadingCampaign,
      error: isErrorCampaign,
      data = INITIAL_DATA,
      refetch: isRefetchCampaign,
    }
  ] = useLazyQuery(GET_BUSINESS_CAMPAIGNS, {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    onCompleted: () => { },
    onError: () => {
      showToast.error('There was a problem. Data could not be loaded.');
    }
  });

  return {
    getUserCampaigns,
    isLoadingCampaign,
    isErrorCampaign,
    campaigns: data?.getCampaigns?.campaigns,
    // totalCount: data?.getCampaigns?.totalCount,
    isRefetchCampaign,
  };
};

export const useAppointmentsData = () => {


  // Appointments API
  const [
    getUserAppointments,
    {
      loading: isLoadingAppointments,
      error: isErrorAppointments,
      data = INITIAL_DATA,
      refetch: isRefetchAppointments,
    }
  ] = useLazyQuery(GET_BUSINESS_APPOINTMENTS, {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    onCompleted: () => { },
    onError: () => {
      showToast.error('There was a problem. Data could not be loaded.');
    }
  });

  return {
    getUserAppointments,
    isLoadingAppointments,
    isErrorAppointments,
    appointments: data?.getBusinessAppointments,
    // totalCount: data?.getCampaigns?.totalCount,
    isRefetchAppointments,
  };
};
