import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { ApolloRoute } from 'routes/ApolloRoutes';
import Home from 'components/Home';
import SearchBusinessAI from 'components/SearchBusinessAI';
import Favorites from 'components/Favorites';
import BusinessDetails from 'components/BusinessDetails';
import PrivacyPolicy from 'components/PrivacyPolicy';
import AppointmentDetails from 'components/AppointmentDetails';
import RegisterAccount from 'components/RegisterAccount';
import LoginAccount from 'components/LoginAccount';
import ClaimAccount from 'components/ClaimAccount';
import MarketingSignUp from 'components/MarketingSignUp';
import VerifyAccount from 'components/VerifyAccount';
import UserProfile from 'components/UserProfile';
import ConfirmEmail from 'components/ConfirmEmail';
import VerifyEmail from 'components/VerifyEmail';
// import ForgotPassword from 'components/ForgotPassword';
// import ResetPassword from 'components/ResetPassword';
import ChangePassword from 'components/ChangePassword';
import FileUploadTest from 'components/FileUploadTest';
import AboutUs from 'components/AboutUs';
import ContactUs from 'components/ContactUs';
import ConfirmCheckIn from 'components/ConfirmCheckIn';
import BusinessSubscriptionTest from 'components/BusinessSubscriptionTest';
import UserSubscriptionTest from 'components/UserSubscriptionTest';
import ChatHome from 'components/ChatTest';
import BraintreeTest from 'components/BraintreeTest';
import TestPage from 'components/TestPage';
import Pricing from 'components/Pricing';
import { NotFound } from 'components/NotFound';
import { ManageBooking } from '@safelyq/booking-feature';
import { ManageSearch } from '@safelyq/search-feature';
import { ManageForgotPassword, ManageSettings } from '@safelyq/account-feature';
import { ManageNotifications } from '@safelyq/notification-feature';
import { ManageUserMessages } from '@safelyq/message-feature';
import { CreatePatient } from '@safelyq/bahmni';
import useGaTracker from 'hooks/useGaTracker';
import TestUI from 'components/TestUI';
import TermsAndConditionsPage from 'Pages/TermsAndConditionsPage/TermsAndConditionsPage';
import PrivacyPolicyPage from 'Pages/PrivacyPolicyPage/PrivacyPolicyPage';

export const UserRoutes = ({ profile }) => {
  useGaTracker();

  return (
    <Switch>
      <ApolloRoute exact path='/' component={Home} profile={profile} />
      <ApolloRoute exact secure path='/search-business-ai' component={SearchBusinessAI} profile={profile} />
      <ApolloRoute exact path='/privacy' component={PrivacyPolicy} profile={profile} />
      <ApolloRoute exact path='/about-us' component={AboutUs} profile={profile} />
      <ApolloRoute exact path='/contact-us' component={ContactUs} profile={profile} />
      <ApolloRoute exact path='/terms-and-conditions' component={TermsAndConditionsPage} profile={profile} />
      <ApolloRoute exact path='/privacy-policy' component={PrivacyPolicyPage} profile={profile} />
      <ApolloRoute exact path='/business/search' component={ManageSearch} profile={profile} />
      <ApolloRoute exact path='/test-page' component={TestPage} profile={profile} />
      <ApolloRoute exact path='/for-business' component={Pricing} profile={profile} />
      <ApolloRoute
        exact
        isNavbarHide={true}
        path='/account/claim'
        component={ClaimAccount}
        profile={profile}
      />
      <ApolloRoute
        exact
        isNavbarHide={true}
        path='/account/claim/:params'
        component={ClaimAccount}
        profile={profile}
      />
      <ApolloRoute
        exact
        isNavbarHide={true}
        path='/account/marketing/:params'
        component={MarketingSignUp}
        profile={profile}
      />
      <ApolloRoute
        exact
        isNavbarHide={true}
        path='/account/verify'
        component={VerifyAccount}
        profile={profile}
      />
      <ApolloRoute
        exact
        path='/appointment/:businessId/:id/details'
        component={AppointmentDetails}
        profile={profile}
      />
      <ApolloRoute exact path='/account/register' component={RegisterAccount} />
      <ApolloRoute exact path='/account/login' component={LoginAccount} />
      <ApolloRoute
        secure
        exact
        path='/account/profile'
        component={UserProfile}
        profile={profile}
      />
      <ApolloRoute
        secure
        exact
        path='/account/confirmemail'
        component={ConfirmEmail}
        profile={profile}
      />
      <ApolloRoute
        exact
        path='/account/verify-email'
        isNavbarHide={true}
        component={VerifyEmail}
        profile={profile}
      />

      <ApolloRoute
        secure
        exact
        path='/account/changepassword'
        component={ChangePassword}
        profile={profile}
      />
      <ApolloRoute
        secure
        exact
        path='/business/upload-images'
        component={FileUploadTest}
        profile={profile}
      />
      <ApolloRoute
        secure
        exact
        path='/:id/confirm-checkin'
        component={ConfirmCheckIn}
        profile={profile}
      />
      <ApolloRoute
        secure
        exact
        path='/business/:id/details'
        component={BusinessDetails}
        profile={profile}
      />
      <ApolloRoute
        secure
        exact
        path='/favorites'
        component={Favorites}
        profile={profile}
      />
      <ApolloRoute
        secure
        exact
        path='/testpageUI'
        component={TestUI}
        profile={profile}
      />
      <ApolloRoute
        path='/business/:businessId'
        component={ManageBooking}
        profile={profile}
      />
      <ApolloRoute exact path='/search' component={ManageSearch} profile={profile} />
      <ApolloRoute
        exact
        isNavbarHide={true}
        path='/account/forgot-password'
        component={ManageForgotPassword}
        profile={profile}
      />
      <ApolloRoute secure path='/account' component={ManageSettings} profile={profile} />
      <ApolloRoute
        secure
        path='/notifications'
        component={ManageNotifications}
        profile={profile}
      />
      <ApolloRoute
        secure
        exact
        path='/messages'
        component={ManageUserMessages}
        profile={profile}
      />
      <ApolloRoute
        secure
        exact
        path='/messages/:businessId'
        component={ManageUserMessages}
        profile={profile}
      />
      <ApolloRoute
        secure
        exact
        path='/business-subscription-test'
        component={BusinessSubscriptionTest}
        profile={profile}
      />
      <ApolloRoute secure exact path='/chat-test' component={ChatHome} profile={profile} />
      <ApolloRoute secure exact path='/braintree-test' component={BraintreeTest} profile={profile} />
      <ApolloRoute
        secure
        exact
        path='/user-subscription-test'
        component={UserSubscriptionTest}
        profile={profile}
      />
      <ApolloRoute
        exact
        secure
        path='/bahmni/create-patient/:businessId'
        component={CreatePatient}
        profile={profile}
      />


      <Route path='*' render={(props) => <NotFound />} />

    </Switch>
    // <div>
    //   <NavMenu />
    //   <Switch>
    //     <Route exact path={'/'}    >
    //       <Home />
    //     </Route>
    //     <Route exact path={'/messages'}   >
    //       <ManageUserMessages />
    //     </Route>
    //   </Switch>
    // </div>

  );
};
