import React from 'react';
import { Link } from 'react-router-dom';
import Moment from 'react-moment';
import StarRatings from 'react-star-ratings';

/* eslint jsx-a11y/anchor-is-valid: 0 */

const CurrentAppointmentInfo = ({ appointmentData }) => {
  const {
    id: appointmentId,
    business: { id: businessId },
  } = appointmentData;

  return (
    <div className='container'>
      <section className='section py-0 px-0 ml-4 mt-2'>
        <div className='container box p-4'>
          <div className='row'>
            <div className='col-lg-12'>
              <p className='subtitle is-size-6-mobile is-size-5-tablet has-text-weight-bold'> {appointmentData.business.name}    </p>
            </div>
            <div className='col-lg-12 mb-3'>
              <p className='subtitle is-6 is-size-7-mobile is-size-6-tablet has-text-grey'>  {appointmentData.business.city}   </p>
            </div>
            <div className='col-lg-11 mb-3'>
              <p className='subtitle is-6 is-size-7-mobile is-size-6-tablet'>  {appointmentData.business.address1}    </p>
            </div>
            <div className='col-lg-1 mb-3 has-text-right'>
              <a href={`https://maps.google.com/maps?q=${appointmentData.business.address1}`} rel="noreferrer" target='_blank'><i className='fa fa-map-marker' style={{ color: '#037ffc' }}    ></i>  </a>
            </div>
            <div className='col-lg-11 mb-3 '>
              <p className='subtitle is-6 is-size-7-mobile is-size-6-tablet has-text-grey'>   {appointmentData.business.phoneFormatted}  </p>
            </div>
            <div className='col-lg-1  mb-3 has-text-right'>
              <a href={`tel:${appointmentData.business.phone}`}>  <i className='fa fa-phone' style={{ color: '#037ffc' }}  ></i>  </a>
            </div>
            <div className='col-lg-11 mb-3 '>
              <p className='subtitle is-6 is-size-7-mobile is-size-6-tablet'>    {appointmentData.business.webAddress}  </p>
            </div>
            <div className='col-lg-1  mb-3 has-text-right'>
              <a href={appointmentData.business.webAddress} target='_blank' rel="noreferrer" > <i className='fa fa-globe' style={{ color: '#037ffc' }}    ></i> </a>
            </div>
            <div className='col-lg-12'>
              <StarRatings
                rating={appointmentData.business.serviceRatings?.value}
                starRatedColor='rgb(26 17 186)'
                starSpacing='3px'
                starDimension='15px'
              />  &nbsp;[   {appointmentData.business.serviceRatings?.reviewsCount}  ]
            </div>

          </div>
        </div>
      </section>
    </div>
  );
};

export default CurrentAppointmentInfo;
