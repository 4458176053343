import { gql } from '@apollo/client';

export const CREATE_USER_APPOINTMENT = gql`
  mutation CreateUserAppointment($appointmentInput: AppointmentInput!) {
    createUserAppointment(appointmentInput: $appointmentInput) {
      isCreated
      errorMessage
      user {
        id
        name
      }
      appointment {
        id
        startTime
        endTime
        status
        business{
          id
        }
        service {
          id
        }
        adults
        subBusiness {
          name
        }
      }
    }
  }
`;
