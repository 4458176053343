export const statusEnum = {
  CHECKEDIN: 'CheckedIn',
  BOOKED: 'Booked',
  PASSED: 'Passed',
  CONFIRMED: 'Confirmed',
  CANCELLED: 'Cancelled',
  COMPLETED: 'Completed',
};

export const statusColorsEnum = {
  Booked: 'warning',
  Passed: 'danger',
  Cancelled: 'danger',
  Confirmed: 'success',
  Completed: 'success',
  CheckedIn: 'info',
};

export const colorsEnum = {
  warning: '#ffdd57',
  success: '#38761d',
  danger: '#f14668',
  info: '#3298dc',
};

export const notificationTypeEnum = {
  BUSINESS_MESSAGE: 'BusinessMessage',
  APPOINTMENT: 'Appointment',
  APPOINTMENT_COMMENT: 'AppointmentComment',
};
