import React, { useState } from 'react';
import { Modal } from '@safelyq/bulma-ui-library';
import CouponDetails from 'components/Common/CouponDetails';
import ReactTooltip from 'react-tooltip';
import { toast } from 'react-toastify';

export const BusinessCouponModal = ({ isOpen, data, onCloseModal, onFavoritesBusinessCoupon }) => {
  const [loader, setLoader] = useState(false)
  const handleFavorites = async (businessCouponId, isFavorite) => {

    try {
      setLoader(true)
      await onFavoritesBusinessCoupon({
        variables: {
          businessCouponId,
          isFavorite: false,
        },
      });
      setLoader(false)
      toast.success('Removed From Favorites');
    } catch (error) {
      console.log(error);
    }

  };

  return (
    <Modal active={isOpen}>
      <Modal.Background />
      <Modal.Card style={{ width: '350px', borderRadius: '8px' }}>
        <Modal.Card.Body>
          <CouponDetails data={data} onCloseModal={onCloseModal} handleFavorites={handleFavorites} loader={loader} />
        </Modal.Card.Body>
      </Modal.Card>
      <ReactTooltip />
    </Modal>
  );
};
