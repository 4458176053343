import React from 'react';
import styled from 'styled-components';
import Spinner from 'react-spinners/ClipLoader';
import { Block, Subtitle } from '@safelyq/bulma-ui-library';

const LoadingWrapper = styled(Block).attrs(() => ({
  className: 'py-6',
}))`
  text-align: center;
`;

export const LoadingSection = () => {
  return (
    <LoadingWrapper>
      <Spinner size='20' />
      <Subtitle size='6'>Loading...</Subtitle>
    </LoadingWrapper>
  );
};
