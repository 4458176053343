import React from 'react';
import { Link } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import { Box, Menu, Text, List, Title } from '@safelyq/bulma-ui-library';

export const ExistingAppointmentSkeleton = () => {
  return (
    <Box bordered>
      <Menu>
        <Title size='6'>Existing Appointments</Title>
        <div className='tabs is-boxed is-small'>
          <List type='unordered'>
            <List.Item>
              <Link to='#'>
                <Skeleton width={40} height={20} />
              </Link>
            </List.Item>
            <List.Item>
              <Link to='#'>
                <Skeleton width={50} height={20} />
              </Link>
            </List.Item>
            <List.Item>
              <Link to='#'>
                <Skeleton width={60} height={20} />
              </Link>
            </List.Item>
            <List.Item>
              <Link to='#'>
                <Skeleton width={50} height={20} />
              </Link>
            </List.Item>
            <List.Item>
              <Link to='#'>
                <Skeleton width={40} height={20} />
              </Link>
            </List.Item>
          </List>
        </div>
        <Menu.List>
          {[0, 1].map((_, index) => (
            <List.Item key={`status-${index}`}>
              <Link
                to='#'
                className='is-flex is-justify-content-space-between is-align-items-center'
              >
                <Text>
                  <Skeleton width={200} />
                  <br />
                  <Skeleton width={160} />
                </Text>
                <span>
                  <Skeleton width={40} />
                </span>
              </Link>
            </List.Item>
          ))}
        </Menu.List>
      </Menu>
    </Box>
  );
};
