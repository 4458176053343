import React from 'react';
import { Link } from 'react-router-dom';

const ExploreMenu = ({ businessId }) => {
  return (
    <Link to={`/business/${businessId}`} className="columns is-mobile">
      <div className="column is-size-5">
        <span style={{ whiteSpace: 'nowrap' }}>
          <span className="icon has-text-info">
            <i className="fa fa-feed" />
          </span>
          <span className="ml-3 has-text-dark">Explore Menu</span>
        </span>
      </div>
      <div className="column is-size-5 has-text-right">
        <span className="icon">
          <i className="fa fa-chevron-right" color="#037ffc" />
        </span>
      </div>
    </Link>
  );
};

export default ExploreMenu;
