import { useMutation } from '@apollo/client';
import Queries from 'components/Common/Queries';
import moment from 'moment';
import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import StarRatings from 'react-star-ratings';

const BusinessListItem = ({ business }) => {
    const emptyThumbnail = 'https://s3.amazonaws.com/speedsport-news/speedsport-news/wp-content/uploads/2018/07/01082232/image-not-found.png';
    const appointmentSelectedDate = moment(moment(new Date()).local()).format('ddd, DD MMM yyyy')

    const [favorite, setFavorite] = useState(business.isFavorite);

    const [addRemoveFromFavorite] = useMutation(Queries.ADD_REMOVE_FROM_FAVORITES);


    const handleFavourite = async (bId, isFavourite) => {
        try {
            await addRemoveFromFavorite({ variables: { businessId: bId, isFavorite: !isFavourite }, })
            setFavorite(!favorite)
            if (favorite) {
                toast.success('Removed From Favorites');
            }
            else {
                toast.success('Add To Favorites');
            }
        } catch (error) {
            console.log(error);
        }
    }
    return (

        <div className='row g-0 business_main'>
            <div className='col-lg-5'>
                <Link to={{ pathname: `/business/${business.id}`, state: { appointmentSelectedDate }, }} >
                    <img className='businessImage' src={business?.picture?.path || emptyThumbnail} alt={business.name} />
                </Link>
            </div>
            <div className='col-lg-7 p-2 businessdetailContainer'>
                <div className='row g-0'>
                    <div className='col-lg-10'>
                        <Link to={{ pathname: `/business/${business.id}`, state: { appointmentSelectedDate }, }} className='is-6 has-text-weight-bold' title={business.name}    >
                            {business.name?.length <= 15 ? business.name : `${business.name.substring(0, 15)}...`}
                        </Link>
                    </div>
                    <div className='col-lg-2 has-text-right'>
                        <i onClick={() => handleFavourite(business.id, business.isFavorite)} className={`${favorite ? 'fa fa-heart' : 'fa fa-heart-o'} favorite_icon`} />
                    </div>
                </div>
                <div className='row g-0'>
                    <div className='col-lg-12'>
                        <p className="business_description"> {business.description ? (`${business.description.substring(0, 20)}...`) : ("No Description")}</p>
                    </div>
                </div>
                {business.address1 && (
                    <div className='row g-0'>
                        <div className='col-lg-12'>
                            <i className='fa fa-map-marker' style={{ color: '#037ffc' }}  ></i>
                            <span className='subtitle is-7'>  {business.city}  </span>
                        </div>
                    </div>
                )}

                <div className='row g-0'>
                    <div className='col-lg-12'>
                        <div style={{ color: 'rgb(26 17 186)' }}>
                            <StarRatings rating={business?.serviceRatings?.value} starRatedColor='rgb(26 17 186)' starSpacing='3px' starDimension='15px' />
                            &nbsp;[  {business?.serviceRatings?.reviewsCount}]
                        </div>
                    </div>
                </div>

            </div>
        </div>

    )
}

export default BusinessListItem