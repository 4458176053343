
import React, { useContext, useReducer, createContext } from 'react';

const MessageStateContext = createContext();
const MessageDispatchContext = createContext();


const messageReducer = (state, action) => {
  switch (action.type) {
    case 'SET_RECENT_CONVERSATION':
      return {
        ...state,
        recentConversation: action.payload.conversations,
        recentConversationCopy: action.payload.conversations,
        isReceivedSignal: false,
      };

    case 'SET_SELECTED_BUSINESS': {
      let recentConversation = [...state.recentConversation];

      const index = recentConversation.findIndex(
        ({ business: { id } }) => id === action.payload.id
      );

      if (index !== -1) {
        let conversation = { ...recentConversation[index] };

        conversation.unreadCount = 0;


        recentConversation.splice(index, 1, conversation);
      }

      return {
        ...state,
        selectedBusinessId: action.payload.id,
        recentConversation,
      };
    }

    case 'SET_BUSINESSES':
      return {
        ...state,
        businessesList: action.payload.businesses,
      };

    case 'SET_BUSINESS_CONVERSATION':
      return {
        ...state,
        businessConversation: action.payload.conversation,
        business: action.payload.business,
      };

    case 'SET_LATEST_MESSAGE': {
      const {
        message: { user, commentText },
        ...rest
      } = action.payload;

      const {
        business: { id: businessId },
      } = rest;

      let recentConversation = [...state.recentConversation];

      const index = recentConversation.findIndex(
        ({ business: { id } }) => id === businessId
      );

      if (index !== -1) {
        let latestConversation = { ...recentConversation[index] };

        latestConversation.recentMessages = [{ message: { commentText } }];

        latestConversation.recentMessageTime = new Date();

        latestConversation.business = rest.business;

        recentConversation.splice(index, 1);

        recentConversation = [latestConversation, ...recentConversation];
      } else {
        const newMessage = {
          business: rest.business,
          unreadCount: 0,
          recentMessages: [{ message: { commentText } }],
          recentMessageTime: new Date(),
        };
        recentConversation = [newMessage, ...recentConversation];
      }

      return {
        ...state,
        recentConversation,
        recentConversationCopy: recentConversation,
        businessConversation: [
          { ...rest, message: { user, commentText } },
          ...state.businessConversation,
        ],
      };
    }

    case 'FILTER_RECENT_MESSAGES': {
      const recentConversation = action.payload
        ? state.recentConversation.filter(
          ({ business: { name }, recentMessages }) =>
            String(name)
              .toLocaleLowerCase()
              .includes(String(action.payload).toLowerCase()) ||
            String(recentMessages[0]?.message.commentText)
              .toLocaleLowerCase()
              .includes(String(action.payload).toLowerCase())
        )
        : state.recentConversationCopy;
      return {
        ...state,
        recentConversation,
      };
    }

    case 'SIGNAL_RECEIVED':
      return {
        ...state,
        isReceivedSignal: action.payload,
      };

    default:
      throw new Error(`Unknow action type: ${action.type}`);
  }
};

export const MessageProvider = ({ children }) => {
  const [state, dispatch] = useReducer(messageReducer, {
    isReceivedSignal: false,
    recentConversation: [],
    recentConversationCopy: [],
    selectedBusinessId: null,
    businessConversation: [],
    business: { name: '', pictureUrl: '' },
    businessesList: [],
  });

  return (
    <MessageDispatchContext.Provider value={dispatch}>
      <MessageStateContext.Provider value={state}>
        {children}
      </MessageStateContext.Provider>
    </MessageDispatchContext.Provider>
  );
};

export const useMessageState = () => useContext(MessageStateContext);
export const useMessageDispatch = () => useContext(MessageDispatchContext);
