import { useParams } from 'react-router-dom';
import { toast as showToast } from 'react-toastify';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { GET_BUSINESS_CATEGORIES, UPDATE_BUSINESS_CATEGORY, DELETE_BUSINESS_CATEGORY } from './requests';
import { useState } from 'react';

export const useCategoriesData = () => {
    const [categories, setCategories] = useState();

    const [getBusinessCategories, { loading: loadingCategories, error: errorCategories, data: dataCategories }] = useLazyQuery(GET_BUSINESS_CATEGORIES, {
        // fetchPolicy: 'network-only',
        fetchPolicy: 'no-cache',
        // notifyOnNetworkStatusChange: true,
        onCompleted: ({ getBusinessCategories }) => {
            setCategories(getBusinessCategories)
        }
    });

    const handleSearchBusinessCategory = (value) => {
        let { getBusinessCategories } = dataCategories;
        let newCategories = getBusinessCategories?.filter(({ name, description }) =>
            String(name).toLowerCase().includes(value.toLowerCase()) || String(description).toLowerCase().includes(value.toLowerCase())
        );
        setCategories(newCategories);
    }

    return {
        loadingCategories,
        errorCategories,
        categories,
        getBusinessCategories,
        onSearchBusinessCategory: handleSearchBusinessCategory
    };
};

export const useCategoryUpdate = () => {
    const [onUpdateBusinessCategory, { loading: loadingUpdate, error: errorUpdate, data: dataUpdate }] = useMutation(
        UPDATE_BUSINESS_CATEGORY,
        {
            onCompleted: async ({ saveBusinessCategory }) => {
                const { isSaved, errorMessage } = saveBusinessCategory;
                if (isSaved) {
                    showToast.success('Business category saved successfully')
                } else showToast.error(errorMessage || 'Failed to save business category');
            },
            onError: (error) => {
                showToast.error(error?.message || 'Something went wrong, Could save business category');
            },
        }
    );
    return { loadingUpdate, errorUpdate, onUpdateBusinessCategory };
}

export const useCategoryDelete = () => {
    const [onDeleteBusinessCategory, { loading: loadingDelete, error: errorDelete }] = useMutation(
        DELETE_BUSINESS_CATEGORY,
        {
            onCompleted: async ({ deleteBusinessCategory }) => {
                const { isSaved, errorMessage } = deleteBusinessCategory;
                if (isSaved) {
                    showToast.success('Business category deleted successfully')
                } else showToast.error(errorMessage || 'Failed to delete business category');
            },
            onError: (error) => {
                showToast.error(error?.message || 'Something went wrong, Could not delete business category');
            },
        }
    );
    return { loadingDelete, errorDelete, onDeleteBusinessCategory };
}