import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Section, Container } from '@safelyq/bulma-ui-library';
import { HeroSection, SearchBar, SearchList, } from '../../components';
import Footer from 'components/Common/Footer';
import { SearchProvider } from './context';
import './style.css';

const BodySection = styled.div`
  background: white;
  position: absolute;
  width: 100%;
  min-height: 100vh;
  top: 0;
  padding-bottom: 3.5rem;
`;

const SearchSection = styled.div.attrs(() => ({
  className: 'search-section',
}))`
  background: hsl(197deg 78% 95%);
  padding-top: 70px;
  border-bottom-left-radius: 35px;
  border-bottom-right-radius: 35px;
`;

export const ManageSearch = ({ ...props }) => {
  const { push } = useHistory();

  useEffect(() => {
    const redirectURl = localStorage.getItem('post_logout_redirect_url');

    if (redirectURl && typeof redirectURl === 'string') {
      localStorage.removeItem('post_logout_redirect_url');

      push(redirectURl);
    }
  });

  return (
    <SearchProvider>
      <BodySection>
        <SearchSection>
          <Section>
            <Container>
              <HeroSection />
              <SearchBar />
            </Container>
          </Section>
        </SearchSection>
        <Section>
          <Container>
            <SearchList {...props} />
          </Container>
        </Section>
        <Footer />
      </BodySection>
    </SearchProvider>
  );
};
