export default class Constants {
  static APPOINTMENT_ITEM_NAME = 'AppointmentItem';
  static BOOK_BUSINESS_URL = 'BookBusinessUrlItem';
  static BOOKED_STATUS_NAME = 'BOOKED';
  static CHECKED_IN_STATUS_NAME = 'CHECKEDIN';
  static CONFIRMED_STATUS_NAME = 'CONFIRMED';
  static CHECKED_IN_INPUT_NAME = 'CHECKED_IN';
  static CHECKED_IN_BY_BUSINESS_INPUT_NAME = 'CHECKED_IN_BY_BUSINESS';
  static COMPLETED_STATUS_NAME = 'COMPLETED';
  static PASSED_STATUS_NAME = 'PASSED';
  static CANCELLED_STATUS_NAME = 'CANCELLED';
  static DEFAULT_TIME_SLOTS_PAGE_SIZE = 8;
  static DEFAULT_SUBLEVEL_SLOTS_PAGE_SIZE = 5;
  static AppointmentView = {
    FLAT: 1,
    GROUP_BY_TIME: 2,
    GROUP_BY_SUB_LEVEL: 3,
  };
  static NO_APPOINTMENT_MESSAGE = 'No Appointment available today';
  static SELECT_SERVICE_MESSAGE =
    'Select a Service to see available time slots';
  static BUSINESS_MESSAGE_EVENT_NAME = 'BusinessMessageReceived';
  static USER_MESSAGE_EVENT_NAME = 'UserMessageReceived';
}

export const SIGNALR_EVENTS = {
  General: 0,
  UserMessageSent: 1,
  BusinessMessageSent: 2,
  AppointmentCreated: 3,
  AppointmentUpdated: 4,
  AppointmentConfirmed: 5,
  AppointmentCheckedIn: 6,
  AppointmentCancelled: 7,
  AppointmentCommentAdded: 8,
  AppointmentCreatedByAdmin: 9,
  AppointmentUpdatedByAdmin: 10,
  AppointmentConfirmedByAdmin: 11,
  AppointmentCheckedInByAdmin: 12,
  AppointmentCancelledByAdmin: 13,
  AppointmentCommentAddedByAdmin: 14,
  AppointmentEventTriggered: 15,
  AppointmentEventTriggeredByAdmin: 16,
};

export const SIGNALR_USER_TYPE = {
  Any: 0,
  ConsumerUser: 1,
  AdminUser: 2,
};

export const STATUS = {
  ACTIVE: 'ACTIVE',
  BOOKED: 'BOOKED',
  CONFIRMED: 'CONFIRMED',
  CHECKED_IN: 'CHECKEDIN',
  COMPLETED: 'COMPLETED',
  INCOMPLETE: 'INCOMPLETE',
  MISSED: 'MISSED',
  CANCELLED: 'CANCELLED',
  PASSED: 'PASSED',
  SERVING_NOW: 'SERVING_NOW',
  CHECKED_IN_BY_BUSINESS: 'CHECKED_IN_BY_BUSINESS',
};

export const STATUS_CAMPAIGN = {
  ACTIVE: 'ACTIVE',
  INCOMPLETE: 'INCOMPLETE',
  COMPLETED: 'COMPLETED',
  CANCELLED: 'CANCELLED',
};

export const SORT_PRIORITY = {
  Active: 1,
  InComplete: 2,
  Completed: 3,
  Cancelled: 4
}

export const COLLECTION = {
  APPOINTMENTS: 'appointments',
  COMMENTS: 'comments',
  CHAT: 'chat',
  RATINGS: 'ratings',
  BUSINESSES: 'businesses',
  NOTIFICATIONS: 'notifications',
  CAMPAIGNS: 'campaigns'
};