import React, { Fragment } from 'react'
import { Title } from '@safelyq/bulma-ui-library';

export const ConsoleDetails = ({ consoleDetails, setConsoleDetails }) => {

    const handleConsoleDetailsChange = (name, value) => {
        setConsoleDetails({
            ...consoleDetails,
            [name]: value,
        })
    }
    return (
        <Fragment>

        </Fragment>
    )
}