import styled from 'styled-components';
import { Text, } from '@safelyq/bulma-ui-library';
export const ListLink = styled.a.attrs(() => ({
  className: 'has-background-white',
}))`
    &:hover {
      cursor: auto;
    }
  `;

export const ClickableText = styled(Text).attrs()`
  &:hover {
    cursor: pointer;
  }
`;