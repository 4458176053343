import React, { useEffect } from 'react';
import data from './data.json';

const TestPage = () => {

    useEffect(() => {
        const input = 'Taimoor'.toLowerCase();
        const filteredResult = data.map(({ date, summaryDetails }) => {
            const filteredData = summaryDetails
                .map((detail) => {
                    const { appointments, ...rest } = detail;
                    const filteredAppointments = appointments.filter(({ user: { name, firstName, lastName, email } }) => {
                        return name.toLowerCase().includes(input) ||
                            firstName.toLowerCase().includes(input) ||
                            lastName.toLowerCase().includes(input) ||
                            email.toLowerCase().includes(input);
                    });

                    return ({
                        appointments: filteredAppointments,
                        totalAppointments: filteredAppointments.length,
                        ...rest
                    });
                })
                .filter((r) => r.totalAppointments > 0);
            return (
                {
                    date,
                    summaryDetails: filteredData
                });
        })
        .filter((r) => r.summaryDetails.length > 0);
    });

    return (
        <div style={{ margin: '0 30%' }}>
            Test Page
        </div>
    );
};

export default TestPage;

