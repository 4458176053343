import React, { useState } from 'react';
import { isEmpty } from 'lodash';
import styled from 'styled-components';
import { Title, Icon, Text } from '@safelyq/bulma-ui-library';

const DocumentBadge = styled.div.attrs(() => ({
  className: '',
}))`
  width: 100%;
  border-radius: 10px;
  background: #f5f5f5;
  > span {
    > a {
      color: blue !important;
      text-decoration: underline;
    }
  }
  & > a {
    background: rgba(0, 0, 0, 0.05);
    min-width: 300px;
    padding: 8px;
    border-radius: 10px;
    color: black;
  }
`;

export const AttachmentsPanel = (props) => {
  let { businessConversation, isOpen, onClose } = props || {};

  const [currentTab, setCurrentTab] = useState(0);

  const handleCurrentTab = (tab) => setCurrentTab(tab);

  const business =
    (!isEmpty(businessConversation) && businessConversation[0].business) || {};

  businessConversation = businessConversation.filter(
    (conversation) => conversation?.messageType == 'Attachment'
  );

  let media = isEmpty(businessConversation)
    ? []
    : businessConversation.filter(
        (conversation) =>
          String(conversation?.attachment?.contentType).includes('image') ||
          String(conversation?.attachment?.contentType).includes('video') ||
          String(conversation?.attachment?.contentType).includes('audio')
      );

  let documents = isEmpty(businessConversation)
    ? []
    : businessConversation.filter(
        (conversation) =>
          String(conversation?.attachment?.contentType).includes('pdf') ||
          String(conversation?.attachment?.contentType).includes('docx') ||
          String(conversation?.attachment?.contentType).includes('document') ||
          String(conversation?.attachment?.contentType).includes('excel') ||
          String(conversation?.attachment?.contentType).includes(
            'powerpoint'
          ) ||
          String(conversation?.attachment?.contentType).includes('text') ||
          String(conversation?.attachment?.contentType).includes('application')
      );

  return (
    <div id='attachments-modal' className={`modal ${isOpen && 'is-active'}`}>
      <div className='modal-background'></div>
      <div className='modal-card'>
        <header className='modal-card-head is-flex is-align-items-center'>
          <Icon name='times' className='is-hoverable mr-4' onClick={onClose} />
          <Title size='6'>Contact info</Title>
        </header>
        <section className='modal-card-body'>
          <div className='is-flex is-flex-direction-column is-align-items-center is-justify-content-center has-background-white px-3 py-5'>
            <figure className='media-left'>
              <p className='image is-128x128 has-background-grey-light rounded is-flex is-justify-content-center is-align-items-center'>
                {business?.picture?.path ? (
                  <img
                    src={business?.picture?.path}
                    className='image is-128x128 is-rounded'
                  />
                ) : (
                  String(business?.name).toUpperCase().charAt(0)
                )}
              </p>
            </figure>

            <Title size='5' className='mb-0 mt-3'>
              {business?.name}
            </Title>
          </div>
          <div className='has-background-white p-5 mt-3'>
            <Title size='6' className='has-text-grey-light'>
              Media, links and docs
            </Title>
            <div className='tabs is-left '>
              <ul>
                <li
                  className={`attachment-tab ${currentTab == 0 && 'is-active'}`}
                  onClick={() => handleCurrentTab(0)}
                >
                  <a className='pl-0'>
                    <Icon name='file-image-o' />
                    <span>Media</span>
                  </a>
                </li>
                <li
                  className={`attachment-tab ${currentTab == 1 && 'is-active'}`}
                  onClick={() => handleCurrentTab(1)}
                >
                  <a className='pl-0'>
                    <Icon name='paperclip' />
                    <span>Documents</span>
                  </a>
                </li>
                <li
                  className={`attachment-tab ${currentTab == 2 && 'is-active'}`}
                  onClick={() => handleCurrentTab(2)}
                >
                  <a className='pl-0'>
                    <Icon name='link' />
                    <span>Links</span>
                  </a>
                </li>
              </ul>
            </div>
            {currentTab == 0 && (
              <div className='columns is-multiline'>
                {media.length == 0 && (
                  <div className='column is-12'>
                    <Text color='grey-light has-text-centered'>
                      No media found
                    </Text>
                  </div>
                )}
                {media?.map((media) => (
                  <div className='column is-4'>
                    <a href={media?.attachment?.publicUrl} target='__blank'>
                      <figure className='image is-square'>
                        <img src={media?.attachment?.publicUrl} />
                      </figure>
                    </a>
                  </div>
                ))}
              </div>
            )}
            {currentTab == 1 && (
              <div className='columns is-multiline'>
                {documents.length == 0 && (
                  <div className='column is-12'>
                    <Text color='grey-light has-text-centered'>
                      No document found
                    </Text>
                  </div>
                )}
                {documents.map((document) => (
                  <div className='column is-12 pt-1'>
                    <Document attachment={document?.attachment} />
                  </div>
                ))}
              </div>
            )}
            {currentTab == 2 && (
              <div className='columns is-multiline'>
                <div className='column is-12'>
                  <Text color='grey-light has-text-centered'>
                    No link found
                  </Text>
                </div>
              </div>
            )}
          </div>
        </section>
      </div>
    </div>
  );
};

export default AttachmentsPanel;

const Document = ({ attachment }) => {
  const { contentType, publicUrl, fileName } = attachment || {
    contentType: null,
    publicUrl: null,
    fileName: '',
  };

  if (String(contentType).includes('pdf')) {
    return (
      <DocumentBadge>
        <a
          href={publicUrl}
          target='__blank'
          className='is-flex is-align-items-center is-justify-content-space-between py-4'
        >
          <span>
            <Icon name='file-pdf-o' />
            <span className='ml-2' style={{ wordBreak: 'break-all' }}>
              {fileName}
            </span>
          </span>
        </a>
      </DocumentBadge>
    );
  }
  if (
    String(contentType).includes('docx') ||
    String(contentType).includes('document')
  ) {
    return (
      <DocumentBadge>
        <a
          href={publicUrl}
          target='__blank'
          className='is-flex is-align-items-center is-justify-content-space-between py-4'
        >
          <span>
            <Icon name='file-word-o' />
            <span className='ml-2' style={{ wordBreak: 'break-all' }}>
              {fileName}
            </span>
          </span>
        </a>
      </DocumentBadge>
    );
  }
  if (String(contentType).includes('spreadsheet')) {
    return (
      <DocumentBadge>
        <a
          href={publicUrl}
          target='__blank'
          className='is-flex is-align-items-center is-justify-content-space-between py-4'
        >
          <span>
            <Icon name='file-excel-o' />
            <span className='ml-2' style={{ wordBreak: 'break-all' }}>
              {fileName}
            </span>
          </span>
        </a>
      </DocumentBadge>
    );
  }
  if (String(contentType).includes('presentation')) {
    return (
      <DocumentBadge>
        <a
          href={publicUrl}
          target='__blank'
          className='is-flex is-align-items-center is-justify-content-space-between py-4'
        >
          <span>
            <Icon name='file-powerpoint-o' />
            <span className='ml-2' style={{ wordBreak: 'break-all' }}>
              {fileName}
            </span>
          </span>
        </a>
      </DocumentBadge>
    );
  }
  if (String(contentType).includes('plain')) {
    return (
      <DocumentBadge>
        <a
          href={publicUrl}
          target='__blank'
          className='is-flex is-align-items-center is-justify-content-space-between py-4'
        >
          <span>
            <Icon name='file-text-o' />
            <span className='ml-2' style={{ wordBreak: 'break-all' }}>
              {fileName}
            </span>
          </span>
        </a>
      </DocumentBadge>
    );
  }
  if (String(contentType).includes('application')) {
    return (
      <DocumentBadge>
        <a
          href={publicUrl}
          target='__blank'
          className='is-flex is-align-items-center is-justify-content-space-between py-4'
        >
          <span>
            <Icon name='fa-solid fa-file' />
            <span className='ml-2' style={{ wordBreak: 'break-all' }}>
              {fileName}
            </span>
          </span>
        </a>
      </DocumentBadge>
    );
  }
};
