export const INITIAL_DATA = {
  getUserNotifications: {
    userNotifications: [],
  },
};

export const DUMMY_DATA = [
  {
    id: 1128,
    notificationChannel: 'Push',
    subject: 'Appointment Scheduled',
    content:
      'Your appointment with "199th District Court" for "Criminal Cases" with "3rd Jumu\'ah" has been scheduled on Thursday, 06 May 2021 04:15 PM. Your check-in time is Thursday, 06 May 2021 04:10 PM.',
    createdTime: '2021-05-06T11:40:04.192329Z',
    correlationKey: '469571a8-3d83-41f7-b9f6-04ef623804ad',
    isRead: false,
    isViewed: false,
    sentChannels: ['Email', 'Sms'],
    appointment: {
      id: 652,
      status: 'Booked',
      startTime: '2021-05-06T16:15:00',
      business: {
        id: 76,
        name: '199th District Court',
        timeZone: 'America/Chicago',
        details: {
          offset: 5,
        },
        picture: {
          id: 40,
          path: 'https://res.cloudinary.com/safelyq/image/upload/v1619029551/Collin%20County%20Court/Collin_County_Court_1_vwqlz6.jpg',
        },
      },
    },
  },
  {
    id: 1126,
    notificationChannel: 'Push',
    subject: 'Appointment Scheduled',
    sentChannels: ['Email', 'OneSignal'],
    content:
      'Your appointment with "199th District Court" for "Criminal Cases" with "3rd Jumu\'ah" has been scheduled on Thursday, 06 May 2021 04:00 PM. Your check-in time is Thursday, 06 May 2021 03:55 PM.',
    createdTime: '2021-05-06T11:16:07.308075Z',
    correlationKey: 'f02e3ac4-262e-4dfd-a63f-a1666da1d55b',
    isRead: true,
    isViewed: false,
    appointment: {
      id: 651,
      status: 'Booked',
      startTime: '2021-05-06T16:00:00',
      business: {
        id: 76,
        name: '199th District Court',
        timeZone: 'America/Chicago',
        details: {
          offset: 5,
        },
        picture: {
          id: 40,
          path: 'https://res.cloudinary.com/safelyq/image/upload/v1619029551/Collin%20County%20Court/Collin_County_Court_1_vwqlz6.jpg',
        },
      },
    },
  },
  {
    id: 1121,
    notificationChannel: 'Push',
    subject: 'Appointment Scheduled',
    sentChannels: ['Email'],
    content:
      'Your appointment with "199th District Court" for "Criminal Cases" with "3rd Jumu\'ah" has been scheduled on Thursday, 06 May 2021 03:30 PM. Your check-in time is Thursday, 06 May 2021 03:25 PM.',
    createdTime: '2021-05-06T06:39:50.356862Z',
    correlationKey: 'c1fb6ea3-046d-4ee5-a273-439829d1587a',
    isRead: false,
    isViewed: false,
    appointment: null,
  },
  {
    id: 1119,
    notificationChannel: 'Push',
    subject: 'Appointment Scheduled',
    sentChannels: ['Email', 'OneSignal'],

    content:
      'Your appointment with "199th District Court" for "Criminal Cases" with "3rd Jumu\'ah" has been scheduled on Thursday, 06 May 2021 03:15 PM. Your check-in time is Thursday, 06 May 2021 03:10 PM.',
    createdTime: '2021-05-06T06:34:53.736808Z',
    correlationKey: '0952f714-912d-44ff-aee3-e4050bbcb71a',
    isRead: false,
    isViewed: false,
    appointment: null,
  },
  {
    id: 1092,
    notificationChannel: 'Push',
    subject: 'Appointment Reminder',
    sentChannels: ['Email', 'OneSignal'],
    content:
      '\r\nREMINDER - Your appointment with "Le Bernardi" is scheduled for Tuesday, 04 May 2021 07:05 AM for "Dine In The Restaurant" with "Le Bernardi 02". Your check-in time is Tuesday, 04 May 2021 06:53 AM.\r\n\r\nFor more details go to safelyQ.com',
    createdTime: '2021-05-04T09:03:30.462212Z',
    correlationKey: '05555b6d-033a-46a6-9066-b57893cafe44',
    isRead: true,
    isViewed: false,
    appointment: {
      id: 643,
      status: 'Passed',
      startTime: '2021-05-04T07:05:01',
      business: {
        id: 1,
        name: 'Le Bernardi',
        timeZone: 'America/Chicago',
        details: {
          offset: 12,
        },
        picture: {
          id: 1,
          path: 'https://res.cloudinary.com/safelyq/image/upload/v1611856458/samples/Restaraunt/Restaraunt_1_et0lhy.jpg',
        },
      },
    },
  },
];
