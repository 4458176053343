import { gql } from '@apollo/client';

export const Get_Terms_Condition_Page_Info = gql`
  query GetTermsConditionPageInfo  {
    getTermsConditionPageInfo {
        errorMessage
        isSuccess
           termsCondition {
      boldPg
      copyRight
      dateUpdated
      pg1
      pg2
      title
      list {
        listIntro
        pg3
        pg5
        options
        pg1
        pg4
        title
        pg2
        list {
          title
          options
          listIntro
          pg1
          pg2
          pg3
          pg4
          pg5
          list {
            listIntro
            pg1
            title
            options
          }
        }
      }
      additional {
        boldPg
        copyRight
        dateUpdated
        pg1
        pg2
        title
        list {
          listIntro
          pg1
          pg2
          pg3
          pg4
          pg5
          title
          table {
            tableContent {
              t1
              t2
            }
            tableHeadings {
              h1
              h2
            }
          }
          options
          list {
            listIntro
            options
            pg1
            pg2
            pg3
            pg4
            pg5
            title
          }
        }
      }
    }
    }
  }
`;
