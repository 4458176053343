import React from 'react'
import { SenderBadge } from '../../ManageUserMessages.styled'
import Linkify from 'linkify-react'

const MessageItemText = ({ chat, sender }) => {
    return (
        <SenderBadge sender={sender}>
            <Linkify tagName='span' options={{ attributes: { target: '__blank' } }}   >  {chat.message.commentText}  </Linkify>
        </SenderBadge>
    )
}

export default MessageItemText