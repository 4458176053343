import styled from 'styled-components';
import { Icon, Card } from '@safelyq/bulma-ui-library';
export const StyledIcon = styled(Icon).attrs(() => ({
    className: 'has-text-info',
}))`
    cursor: pointer;
  `;

export const FavouriteButton = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  background: white;
  width: 35px;
  height: 35px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px;
  &:hover {
    cursor: pointer;
  }
`;

export const StylesFooterLink = styled(Card.FooterItem)`
  cursor: auto;
`;