import React from 'react';

const StatusButton = ({ handleConfirmed, id, label, disable }) => {
  return (
    <button
      className='button is-small is-outlined is-info'
      disabled={disable}
      style={{ border: '1px solid #36c1e9', color: '#36c1e9' }}
      onClick={() => handleConfirmed(id)}
    >
      {label}
    </button>
  );
};

export default StatusButton;
