export const INITIAL_BUSINESS_DATA = {
  getBusinessById: {
    services: [],
    safetyTags: [],
    slots: [],
    preCheckinInstructions: [],
    menu: [],
    picture: [],
  },
};
