import React, { useState } from 'react';
import moment from 'moment';
import Moment from 'react-moment';
import ReactTooltip from 'react-tooltip';
import { Link } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import Constants from 'constants/index';
import Queries from '../Queries';

const ExistingAppointments = ({
  currentAppointmentId,
  appointments,
  preCheckinOffset,
}) => {
  const [
    updateAppointmentStatusMutation,
    { loading: isCheckedinLoading },
  ] = useMutation(Queries.UPDATE_APPOINTMENT_STATUS);
  const [appointmentId, setAppointmentId] = useState(null);
  const [modalAcive, setModalActive] = useState(false);
  const [checkinModal, setCheckinModal] = useState({ isOpen: false, id: null });

  const cancelAppointment = (e) => {
    setAppointmentId(+e.target.attributes['appointmentid'].value);
    setModalActive(true);
  };

  const checkInNow = () => {
    updateAppointmentStatus(checkinModal.id, Constants.CHECKED_IN_INPUT_NAME);
    if (!isCheckedinLoading)
      setCheckinModal({ ...checkinModal, isOpen: false });
  };

  const enableCheckButton = (appointmentTime) => {
    const currentTime = moment(new Date()).utc();
    const checkInThreshold = moment(appointmentTime).diff(
      moment(currentTime),
      'minutes'
    );

    return checkInThreshold <= preCheckinOffset;
  };

  const handleCheckinModal = () => {
    setCheckinModal({ ...checkinModal, isOpen: !checkinModal.isOpen });
  };

  const closeModal = (id) => {
    setModalActive(false);
  };

  const updateAppointmentStatus = (appointmentId, appointmentStatus) => {
    updateAppointmentStatusMutation({
      variables: { appointmentId: appointmentId, newStatus: appointmentStatus },
    }).then(function (result) {
      window.location.reload();
    });
  };

  return (
    <>
      <div className={`modal ${checkinModal.isOpen ? 'is-active' : ''}`}>
        <div className='modal-background'></div>
        <div className='modal-card'>
          <header className='modal-card-head py-3'>
            <p className='modal-card-title my-0'>Check-In</p>
            <button
              className='delete'
              aria-label='close'
              onClick={handleCheckinModal}
            ></button>
          </header>
          <section className='modal-card-body'>
            You are about to Check-In. Please confirm that you are at the
            location and want to Check-In.
          </section>
          <footer className='modal-card-foot py-2'>
            <section className='section px-0 py-0' style={{ width: '100%' }}>
              <div className='container'>
                <div className='buttons are-small is-right'>
                  <button
                    className='button is-danger'
                    onClick={checkInNow}
                    disabled={isCheckedinLoading}
                  >
                    {isCheckedinLoading ? 'Loading ...' : 'Yes'}
                  </button>
                  <button className='button' onClick={handleCheckinModal}>
                    No{' '}
                  </button>
                </div>
              </div>
            </section>
          </footer>
        </div>
      </div>
      {appointments && appointments.length > 0 && (
        <>
          <div className={`modal ${modalAcive ? 'is-active' : ''}`}>
            <div className='modal-background'></div>
            <div className='modal-card'>
              <header className='modal-card-head py-3'>
                <p className='modal-card-title my-0'>Cancel Appointment</p>
                <button
                  className='delete'
                  aria-label='close'
                  onClick={closeModal}
                ></button>
              </header>
              <section className='modal-card-body'>
                Are you sure that you want to cancel this appointment ?
              </section>
              <footer className='modal-card-foot py-2'>
                <section
                  className='section px-0 py-0'
                  style={{ width: '100%' }}
                >
                  <div className='container'>
                    <div className='buttons are-small is-right'>
                      <button
                        className='button is-danger'
                        onClick={() => {
                          updateAppointmentStatus(
                            appointmentId,
                            Constants.CANCELLED_STATUS_NAME
                          );
                        }}
                      >
                        Cancel Appointment
                      </button>
                      <button className='button' onClick={closeModal}>
                        Close
                      </button>
                    </div>
                  </div>
                </section>
              </footer>
            </div>
          </div>
          <section className='section container px-0 py-4'>
            <div className='px-4 card'>
              <h2 className='mb-0 px-1 py-2 is-size-6 has-text-weight-bold'>
                Existing Appointments
              </h2>
              <div
                className='card-content pt-1 pb-3 pl-1 pr-2'
                style={{
                  height: '173px',
                  overflowY: 'auto',
                  overflowX: 'auto',
                }}
              >
                {appointments.map((appointment) => {
                  return (
                    <div
                      key={`appointment_${appointment.id}`}
                      className='columns pb-0 mx-0 my-2 is-mobile'
                    >
                      <>
                        <div
                          className={`column is-size-7 is-8 mx-0 my-0 px-1 py-1 is-multiline ${appointment.id === +currentAppointmentId &&
                            ' has-background-light'
                            }`}
                        >
                          <span>
                            Appointment on &nbsp;
                            <span className='has-text-info'>
                              <Moment format='YYYY-MM-DD, hh:mm a'>
                                {moment(appointment.startTime)}
                                {/*moment.utc(appointment.startTime).local()*/}
                              </Moment>
                            </span>
                          </span>
                        </div>
                        <div className='column mx-2 is-4  mx-0 my-0 px-0 py-0 has-text-right are-small'>
                          {appointment.status.toUpperCase() ===
                            Constants.BOOKED_STATUS_NAME && (
                              <>
                                {enableCheckButton(appointment.startTime) && (
                                  <span
                                    className='icon'
                                    data-tip='Check-In'
                                    appointmentid={appointment.id}
                                    onClick={() =>
                                      setCheckinModal({
                                        id: appointment.id,
                                        isOpen: true,
                                      })
                                    }
                                    style={{ cursor: 'pointer' }}
                                  >
                                    <i
                                      className='fa fa-check-circle is-size-5 has-text-info'
                                      appointmentid={appointment.id}
                                    />
                                  </span>
                                )}

                                <Link
                                  className='icon'
                                  data-tip='Edit'
                                  to={`/appointment/${appointment.id}/edit`}
                                >
                                  <i className='fa fa-edit is-size-5 has-text-primary' />
                                </Link>
                                <ReactTooltip />
                                <span
                                  className='icon'
                                  data-tip='Close'
                                  appointmentid={appointment.id}
                                  onClick={cancelAppointment}
                                  style={{ cursor: 'pointer' }}
                                >
                                  <i
                                    className='fa fa-window-close is-size-5 has-text-danger'
                                    appointmentid={appointment.id}
                                  />
                                </span>
                              </>
                            )}
                          {appointment.status.toUpperCase() ===
                            Constants.CANCELLED_STATUS_NAME && (
                              <span className='has-background-danger has-text-white has-text-weight-bold px-1 py-1'>
                                Cancelled
                              </span>
                            )}
                          {appointment.status.toUpperCase() ===
                            Constants.PASSED_STATUS_NAME && (
                              <span className='has-background-warning has-text-white has-text-weight-bold px-1 py-1'>
                                Passed
                              </span>
                            )}
                          {appointment.status.toUpperCase() ===
                            Constants.COMPLETED_STATUS_NAME && (
                              <span className='has-background-success has-text-white has-text-weight-bold px-1 py-1'>
                                Completed
                              </span>
                            )}
                          {appointment.status.toUpperCase() ===
                            Constants.CHECKED_IN_STATUS_NAME && (
                              <span className='has-background-info has-text-white has-text-weight-bold px-1 py-1'>
                                Checked In
                              </span>
                            )}
                        </div>
                      </>
                      {/*appointment.status.toUpperCase() === Constants.CANCELLED_STATUS_NAME && (
                                        <>
                                            <div className="column mx-2 is-size-6">
                                                <span style={{ 'text-decoration': 'line-through' }}>
                                                    Appointment on
                                                    <span className="has-text-info">
                                                        <Moment format="YYYY-MM-DD, hh:mm a">
                                                            {moment.utc(appointment.startTime).local()}
                                                        </Moment>
                                                    </span>
                                                </span>
                                            </div>

                                        </>
                                    )
                                    */}
                    </div>
                  );
                })}
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default ExistingAppointments;
