import React, { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { Modal } from 'antd';
import moment from 'moment';
import Moment from 'react-moment';
import Queries from '../Queries';
import Constants from 'constants/index';
import Calendar from '../Calendar';
import FlatView from './FlatView';
import GroupBySubLevelView from './GroupBySubLevelView';
import GroupByTimeView from './GroupByTimeView';

import './styles.css';

const AppointmentInfo = ({
  businessId,
  selectedServiceId,
  hasSubLevels,
  selectedSubLevelId,
  businessServices,
  maxAllowedGuests,
  isChildrenAllowed,
  appointmentTime,
  appointmentData,
  appointmentSelectedDate,
  onAppointmentDataChange,
  setPreviousSlot,
  selectedSlot,
  setSelectedAppointmentSlot,
  onAppointmentSlotChange,
  showHeader,
  headerText,
  excludedAppointmentIds,
}) => {
  const [showCalender, setShowCalender] = useState(false);
  const [appointmentDate, setAppointmentDate] = useState(
    moment(new Date()).local()
  );
  const [userMessage, setUserMessage] = useState(
    Constants.NO_APPOINTMENT_MESSAGE
  );
  const [serviceSubLevels, setServiceSubLevels] = useState([]);
  const [allAppointmentSlots, setAllAppointmentSlots] = useState([]);
  const [appointmentSlots, setAppointmentSlots] = useState([]);
  const [selectedService, setSelectedService] = useState(null);
  const [selectedSubLevel, setSelectedSubLevel] = useState(-1);
  const [currentView, setCurrentView] = useState(
    Constants.AppointmentView.FLAT
  );
  const [editMode, setEditMode] = useState(false);

  const [
    getAppointmentSlots,
    {
      loading: loadingAppointmentSlots,
      error: appointmentSlotsError,
      data: appointmentSlotsData,
    },
  ] = useLazyQuery(Queries.GET_AVAILABLE_APPOINTMENTS);

  useEffect(() => {
    // Handle the Edit appointment part
    if (selectedServiceId && selectedSubLevelId && appointmentTime) {
      const currentService = businessServices.find((s) => {
        return s.id === selectedServiceId;
      });

      if (currentService) {
        // This will be set only once
        setEditMode(true);
        const slotData = {
          startTime: moment(appointmentTime).toISOString(),
          startTimeOnly: moment(appointmentTime).format('h:mm A'),
        };
        setSelectedAppointmentSlot(slotData);
        setPreviousSlot(slotData);
        setSelectedService(currentService);
        onAppointmentDataChange('serviceId', currentService.id);
        setServiceSubLevels(currentService.businesses);
        setSelectedSubLevel(selectedSubLevelId);
        setAppointmentDate(moment(appointmentTime));
        setCurrentView(Constants.AppointmentView.FLAT);
      }
    }
    //if no service is selected
    if (!selectedService || selectedService === '-1') {
      setUserMessage(Constants.SELECT_SERVICE_MESSAGE);
    }
  }, [
    appointmentData.appointmentTime,
    appointmentData.endTime,
    appointmentData.timeZone,
    appointmentTime,
    selectedServiceId,
    selectedSubLevelId,
    setPreviousSlot,
    setSelectedAppointmentSlot,
    businessServices,
  ]);

  useEffect(() => {
    if (loadingAppointmentSlots) {
      //
    }

    if (appointmentSlotsError) {
      // log and show error
    }

    if (appointmentSlotsData) {
      //setSelectedAppointmentSlot(null);
      let result = [];
      const slotsData = appointmentSlotsData.getAvailability;
      if (slotsData.first) {
        result.push(slotsData.first);
      }
      if (slotsData.second) {
        result.push(slotsData.second);
      }
      if (slotsData.third) {
        result.push(slotsData.third);
      }
      if (slotsData.fourth) {
        result.push(slotsData.fourth);
      }
      if (slotsData.fifth) {
        result.push(slotsData.fifth);
      }

      if (slotsData.others && slotsData.others.length > 0) {
        result = result.concat(slotsData.others);
      }
      result = Array.from(
        new Set(result.map((a) => a.startTimeOnly + ':' + a.subBusiness.id))
      ).map((refkey) => {
        return result.find(
          (a) => a.startTimeOnly + ':' + a.subBusiness.id === refkey
        );
      });
      setAllAppointmentSlots(result);
      if (selectedSubLevel) {
        setAppointmentSlots(
          result.filter((slot) => {
            return slot.subBusiness.id === selectedSubLevel;
          })
        );
      } else {
        setAppointmentSlots(result);
      }

      if (editMode) {
        var currentSlot = result.find(
          (slot) => slot.startTimeOnly === selectedSlot.startTimeOnly
        );
        if (currentSlot) {
          setSelectedAppointmentSlot(currentSlot);
          setPreviousSlot(currentSlot);
        }
      }
    }
  }, [
    loadingAppointmentSlots,
    appointmentSlotsError,
    appointmentSlotsData,
    selectedSubLevel,
  ]);

  useEffect(() => {
    if (appointmentDate && selectedService) {
      const subBusinessIds = serviceSubLevels.map((s) => s.id);
      getAppointmentSlots({
        variables: {
          service: selectedService.id,
          business: businessId,
          subBusinesses: subBusinessIds,
          appointmentDate: appointmentDate.format('YYYY-MM-DD'),
          appointmentWindow: 'DAY',
          excludedAppointmentIds: excludedAppointmentIds ?? [],
        },
      });
    }
  }, [
    appointmentDate,
    selectedService,
    businessId,
    getAppointmentSlots,
    serviceSubLevels,
  ]);
  const preSelectedServiceProvider =
    serviceSubLevels?.length === 1 ? serviceSubLevels[0]?.id : null;

  useEffect(() => {
    if (businessServices.length === 1) {
      const currentService = businessServices[0];
      setSelectedSubLevel(0);
      if (currentService) {
        setSelectedService(currentService);
        onAppointmentDataChange('serviceId', currentService.id);
        if (hasSubLevels) {
          setServiceSubLevels(currentService.businesses);
          setCurrentView(Constants.AppointmentView.GROUP_BY_TIME);
        } else {
          setServiceSubLevels([]);
          setCurrentView(Constants.AppointmentView.FLAT);
        }
      }

      setAppointmentSlots([]);
    }
  }, [hasSubLevels, businessServices]);

  useEffect(() => {
    appointmentSelectedDate &&
      setAppointmentDate(moment(appointmentSelectedDate).local());
  }, []);

  const onDayChange = (value) => {
    setAppointmentDate(moment(value.date).local());
    setAppointmentSlots([]);
  };

  const onServiceChange = (event) => {
    const selectedValue = +event.target.value;
    const currentService = businessServices.find((s) => {
      return s.id === selectedValue;
    });

    setSelectedSubLevel(0);
    setSelectedAppointmentSlot(null);
    setEditMode(false);
    if (currentService) {
      setSelectedService(currentService);
      onAppointmentDataChange('serviceId', currentService.id);
      if (hasSubLevels) {
        setServiceSubLevels(currentService.businesses);
        setCurrentView(Constants.AppointmentView.GROUP_BY_TIME);
      } else {
        setServiceSubLevels([]);
        setCurrentView(Constants.AppointmentView.FLAT);
      }
    } else {
      setSelectedService('-1');
      setServiceSubLevels([]);
      setUserMessage(Constants.SELECT_SERVICE_MESSAGE);
      onAppointmentDataChange('serviceId', 0);
    }

    setAppointmentSlots([]);
  };

  const onSubLevelChange = (event) => {
    setSelectedAppointmentSlot(null);
    setEditMode(false);
    const selectedValue = +event.target.value;
    setSelectedSubLevel(selectedValue);
    // Show all the sub levels slots
    if (selectedValue === 0) {
      if (currentView === Constants.AppointmentView.FLAT) {
        setAppointmentSlots(allAppointmentSlots);
        // Update the Flat View to Default view
        setCurrentView(Constants.AppointmentView.GROUP_BY_TIME);
      }
    } else {
      // Filtering the selected slots by sub level.
      const selectedSlots = allAppointmentSlots.filter((slot) => {
        return slot.subBusiness.id === selectedValue;
      });

      // Update User message if there is appointment found for the sub level
      if (selectedSlots.length === 0) {
        setUserMessage(Constants.NO_APPOINTMENT_MESSAGE);
      }

      setAppointmentSlots(selectedSlots);
      // show only the filtered slots in a flat view
      setCurrentView(Constants.AppointmentView.FLAT);
    }
  };

  const onAppointmentViewChange = (newView) => {
    setSelectedAppointmentSlot(null);
    setCurrentView(newView);
  };

  const handleChildrenAllowed = () => {
    const { children } = appointmentData;
    if (children === 1) onAppointmentDataChange('children', 0);
    else onAppointmentDataChange('children', 1);
  };

  var guests = [];
  for (var i = 1; i <= maxAllowedGuests; i++) {
    guests.push(
      <option key={i} value={i}>
        {i}
      </option>
    );
  }

  return (
    <>
      {showCalender && (
        <Modal
          visible={true}
          title='Select Date'
          onCancel={() => {
            setShowCalender(false);
          }}
          onOk={() => {
            setShowCalender(false);
          }}
          className='px-0 py-0'
        >
          <Calendar selectedDate={appointmentDate} onDayChange={onDayChange} />
        </Modal>
      )}
      <section className='section container px-0 py-4'>
        <div className='px-2 card'>
          {showHeader && (
            <h2 className='mb-0 px-1 py-2 is-size-6 has-text-weight-bold'>
              {headerText}
            </h2>
          )}
          <div className='card-content pt-3'>
            <div className='columns is-mobile'>
              <div className='column pt-4 ml-0 pl-0'>
                <div className='select'>
                  <select
                    onChange={onServiceChange}
                    value={selectedService ? selectedService.id : -1}
                  >
                    <option value={-1}>Select Service</option>
                    {businessServices.map((service) => (
                      <option key={`service_${service.id}`} value={service.id}>
                        {service.name}
                      </option>
                    ))}
                  </select>
                </div>
                {/*<span className="is-size-5 px-2" style={{ whiteSpace: "nowrap" }}> OR </span>*/}
              </div>
              {hasSubLevels && (
                <div className='column pt-4 ml-0 pl-0'>
                  <div className='select'>
                    <select
                      onChange={onSubLevelChange}
                      value={preSelectedServiceProvider || selectedSubLevel}
                      disabled={!selectedService || selectedService === '-1'}
                    >
                      {/*<option value={0}> {serviceSubLevels.length > 0 ? `Service Provider` : `Select Sub-Level`}</option>*/}
                      <option value={0}>Service Provider</option>
                      {serviceSubLevels.map((subLevel) => (
                        <option
                          key={`subLevel_${subLevel.id}`}
                          value={subLevel.id}
                        >
                          {subLevel.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              )}
            </div>

            <div className='columns is-mobile'>
              {maxAllowedGuests > 0 && (
                <div className='column pt-4 ml-0 pl-0'>
                  <div className='select'>
                    <select
                      onChange={(event) => {
                        onAppointmentDataChange('adults', +event.target.value);
                      }}
                      value={appointmentData.adults}
                    >
                      <option value={0}>Additional Guests</option>
                      {guests}
                    </select>
                  </div>
                </div>
              )}
              {isChildrenAllowed && (
                <div className='column pt-5 p-0 mr-0 is-flex'>
                  <span
                    className='subtitle is-6 is-size-7-mobile mr-1'
                    style={{ whiteSpace: 'nowrap' }}
                  >{`Children(<13) in your party`}</span>
                  <input
                    type='checkbox'
                    style={{ marginTop: '5px' }}
                    checked={appointmentData.children === 1}
                    onChange={handleChildrenAllowed}
                  />
                </div>
              )}
              {!isChildrenAllowed && (
                <div className='column ml-0 pl-0 is-flex'>
                  <span className='subtitle is-6 mr-1'>{`Children under the age of 15 are not allowed at this time`}</span>
                </div>
              )}
            </div>

            {/*<hr className="is-hidden-desktop my-0" style={{ height: '1px' }} />*/}

            <div className='columns is-align-items-center is-mobile mt-3'>
              <div className='column is-8 p-0'>
                <span className='title is-size-6 has-text-weight-bold'>
                  Slots available
                </span>
              </div>
              <div className='column is-4 p-0'>
                <div>
                  <span
                    style={{ whiteSpace: 'nowrap' }}
                    onClick={() => {
                      setShowCalender(true);
                    }}
                  >
                    {/*<img alt="Select Date" className="pb-2 ml-3" src={process.env.PUBLIC_URL  '/images/cal.png'}*/}
                    <span className='is-size-6 mr-2'>
                      {
                        <Moment format='ddd MMM DD yyyy'>
                          {appointmentDate}
                        </Moment>
                      }
                    </span>
                    <span className='icon has-text-info is-size-5'>
                      <i className='fa fa-calendar' />
                    </span>
                  </span>
                </div>
              </div>
            </div>

            <div
              className={`columns mb-0 ${
                currentView === Constants.AppointmentView.FLAT && 'is-hidden'
              }`}
            >
              {hasSubLevels && (
                <div className='column is-12 my-2 mx-0 px-0'>
                  <div className='field has-addons'>
                    <p className='control'>
                      <button
                        className={`button  is-small ${
                          currentView ===
                            Constants.AppointmentView.GROUP_BY_TIME && 'is-info'
                        }`}
                        onClick={() => {
                          onAppointmentViewChange(
                            Constants.AppointmentView.GROUP_BY_TIME
                          );
                        }}
                      >
                        <span className='icon is-small'>
                          <i className='fa fa-clock-o'></i>
                        </span>
                        <span>Group By Time</span>
                      </button>
                    </p>
                    <p className='control'>
                      <button
                        className={`button is-small ${
                          currentView ===
                            Constants.AppointmentView.GROUP_BY_SUB_LEVEL &&
                          'is-info'
                        }`}
                        onClick={() => {
                          onAppointmentViewChange(
                            Constants.AppointmentView.GROUP_BY_SUB_LEVEL
                          );
                        }}
                      >
                        <span className='icon is-small'>
                          <i className='fa fa-sitemap'></i>
                        </span>
                        <span>Group By Sub Levels</span>
                      </button>
                    </p>
                  </div>
                </div>
              )}
            </div>

            <div className='pt-3 pb-2'>
              <div className='content'>
                {currentView === Constants.AppointmentView.FLAT && (
                  <FlatView
                    editMode={editMode}
                    availableSlots={appointmentSlots}
                    selectedSlot={selectedSlot}
                    userMessage={userMessage}
                    onChange={onAppointmentSlotChange}
                  />
                )}

                {currentView === Constants.AppointmentView.GROUP_BY_TIME && (
                  <GroupByTimeView
                    serviceSubLevels={serviceSubLevels}
                    availableSlots={appointmentSlots}
                    selectedSlot={selectedSlot}
                    userMessage={userMessage}
                    onChange={onAppointmentSlotChange}
                  />
                )}

                {currentView ===
                  Constants.AppointmentView.GROUP_BY_SUB_LEVEL && (
                  <GroupBySubLevelView
                    serviceSubLevels={serviceSubLevels}
                    availableSlots={appointmentSlots}
                    selectedSlot={selectedSlot}
                    userMessage={userMessage}
                    onChange={onAppointmentSlotChange}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AppointmentInfo;
