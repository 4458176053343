import { gql } from "@apollo/client";

export const GET_User_CONVERSATION = gql`
  query GetUserConversation {
    getUser {
      user {
        conversations {
          business {
            id
            name
            picture {
              path
            }
          }
          unreadCount
          recentMessageTime
          recentMessages {
            message {
              commentText
            }
          }
        }
      }
    }
  }
`;

export const GET_BUSINESS_CONVERSATION = gql`
  query GetBusinessConversation($businessId: Int!) {
    getUser {
      user {
        conversationsSummary {
          unreadCount
        }
        
      }
    }
    getBusinessConversation(businessId: $businessId) {
      messages {
        user {
          id
          firstName
          email
        }
        direction
        business {
          id
          name
          picture {
            path
          }
          messaging {
            messagingAllowed
            consumerResponseAllowed
            consumerAttachmentsAllowed
          }
        }
        messageType
        message {
          user {
            id
          }
          commentText
          createdTime
        }
        attachment {
          contentType
          publicUrl
          jsonData
          fileName
          id
        }
      }
      
    }
  }
`;


export const GET_USER_UNREAD_MESSAGES_AGAIN = gql`
query GETUSERUNREADMESSAGESAGAIN {
  getUser {
    user {
     
      conversationsSummary {
        unreadCount
      }
      
    }
  }
}
`;

export const SEND_MESSAGE_TO_BUSINESS = gql`
  mutation SendMessageToBusiness(
    $businessId: Int!
    $messageText: String!
    $attachment: FileInput
  ) {
    sendMessageToBusiness(
      businessMessageInput: {
        businessId: $businessId
        messageText: $messageText
        attachment: $attachment
      }
    ) {
      messageId
      userMessage {
        user {
          id
          email
          firstName
        }
        direction
        business {
          id
          name
          messaging{
            consumerAttachmentsAllowed
            consumerResponseAllowed
            messagingAllowed
          }
          picture {
            path
          }
        }
        message {
          user {
            id
          }
          commentText
          createdTime
        }
        messageType
        attachment {
          contentType
          publicUrl
          jsonData
          fileName
          id
        }
      }
    }
  }
`;