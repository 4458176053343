import React from 'react';
import { isEmpty } from 'lodash';
import { Message, Heading, Content, List } from '@safelyq/bulma-ui-library';
import { useGetBusinessInstructions } from './hooks';
import { Skeleton } from 'antd'
export const InstructionSection = ({ business, ...props }) => {
  const { loading, data } = useGetBusinessInstructions();

  if (loading) {
    return (<Skeleton.Input style={{ height: '200px', }} active={true} size={"large"} block={true} />)
  }
  else {
    const { getBusinessById: { safetyTags, specialInstructions } } = data
    const isSectionVisible = !isEmpty(specialInstructions) || !isEmpty(safetyTags);

    if (!isSectionVisible) return null;
    return (
      <Message background='white'>
        <Message.Body>
          {specialInstructions && (
            <Content>
              <Heading size='5'>Special Instructions</Heading>
              <List type='unordered'>
                <List.Item key={`instruction-0`}>
                  <small>{specialInstructions}</small>
                </List.Item>
              </List>
            </Content>
          )}
          <Content>
            <Heading size='5'>Personal Safety Standards </Heading>
            <List type='unordered'>
              {safetyTags.map((item, index) => (
                <List.Item key={`safety-tag-${index}`}>
                  <small>{item}</small>
                </List.Item>
              ))}
            </List>
          </Content>
        </Message.Body>
      </Message>
    );
  }

};
