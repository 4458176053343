import React from 'react'
import { Modal, Icon, Title, Button } from '@safelyq/bulma-ui-library';
import MessageInput from './MessageInput/MessageInput';
import NoChat from '../../../../../../assets/no_chat.png';

const NewMessageBoxPopup = ({ isOpen, onClose, businessId }) => {

    const getNewMessage = () => {
        onClose()
    }

    return (
        <Modal active={isOpen} clipped>
            <Modal.Background />
            <Modal.Card style={{ height: "300px" }}>
                <Modal.Card.Head className={'is-flex is-justify-content-space-between'}>
                    <Title size='5' className='m-0'> Create New Message</Title>
                    <Button size='small' className='button-icon' onClick={onClose}>
                        <Icon name='close' className='is-right' />
                    </Button>
                </Modal.Card.Head>
                <Modal.Card.Body className="is-flex is-flex-direction-column is-justify-content-flex-end p-0">
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%", background: "lightgrey" }}>
                        <img src={NoChat} style={{ width: 150 }} alt="nochat" />
                    </div>
                    <MessageInput activeChat={{ business: { id: businessId } }} getNewMessage={getNewMessage} allowedMessage={true} allowedResponse={true} allowedAttatchment={false} />
                </Modal.Card.Body>
                {/* <Modal.Card.Foot className='has-background-white'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <Spin spinning={loader}>
                                <Buttons size='small'>
                                    {!isCancelled && business.canUserUpdateAppointment ? <Button color='info' onClick={handleUpdate}   >   Update  </Button> : null}
                                </Buttons>
                            </Spin>
                        </div>
                    </div>
                </Modal.Card.Foot> */}
            </Modal.Card>
        </Modal>
    )
}

export default NewMessageBoxPopup
