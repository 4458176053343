import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Modal } from '@safelyq/bulma-ui-library';
import CouponDetails from 'components/Common/CouponDetails';
import ReactTooltip from 'react-tooltip';

export const PromotionDetailModal = ({ isOpen, businessCoupons, onCloseModal, handleFavorites, loader }) => {
  const [data, setData] = useState({})
  const { couponId } = useParams()

  useEffect(() => {
    setData(businessCoupons.find(({ id }) => id === parseInt(couponId)))
  }, [businessCoupons, couponId])

  return (
    <Modal active={isOpen}>
      <Modal.Background />
      <Modal.Card style={{ width: '350px', borderRadius: '8px' }}>
        <Modal.Card.Body>
          <CouponDetails data={data} onCloseModal={onCloseModal} handleFavorites={handleFavorites} loader={loader} />
        </Modal.Card.Body>
      </Modal.Card>
      <ReactTooltip />
    </Modal>
  );
};
