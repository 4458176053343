import React, { Fragment, useState, useEffect } from 'react';

import { Section, Container, Title } from '@safelyq/bulma-ui-library';
import { Loader } from '@safelyq/base-feature';
import usePageTitle from 'hooks/usePageTitle';
import CategoryTags from '../Common/CategoryTags';
import BusinessListView from '../Common/BusinessListView';
import { BusinessCoupons } from './components/BusinessCoupons';
import { useData } from './hooks';
import { useQuery } from '@apollo/client';
import Queries from 'components/Common/Queries';
import BusinessFavorites from './components/BusinessFavorites/BusinessFavorites';
import CouponsFavorites from './components/CouponsFavorites/CouponsFavorites';
import './Favorites.css';
const Favorites = () => {
  const [currentTab, setcurrentTab] = useState(0)



  const handleCurrentTab = (tab) => {
    setcurrentTab(tab)
  }
  return (
    <div className='bscontainer'>
      <div className='row'>
        <div className='col-lg-12'>
          <Title size='4'>Favorites</Title>
          <div className='tabs'>
            <ul>
              <li className={currentTab === 0 && 'is-active'} onClick={() => handleCurrentTab(0)}> <a className='pl-0'>Business</a></li>
              <li className={currentTab === 1 && 'is-active'} onClick={() => handleCurrentTab(1)}><a>Coupons</a></li>
            </ul>
          </div>

          {currentTab === 0 ? <BusinessFavorites /> : <BusinessCoupons />}

        </div>
      </div>
    </div>
  )
}

export default Favorites