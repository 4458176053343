
import React, { Fragment } from 'react';
import { Section, Container, Title } from '@safelyq/bulma-ui-library';
import usePageTitle from 'hooks/usePageTitle';
import StripeTest from 'components/StripeTest';

const TestUI = () => {
    usePageTitle('test-page');

    return (
        <Fragment>
            <Section  className='pt-4'>
                <Container>
                    <Title size='1'>
                        Test Page
                    </Title>
                    <StripeTest />
                </Container>
            </Section>
        </Fragment>
    )
}

export default TestUI;