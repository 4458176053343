import React, { useEffect } from 'react';
import PulseLoader from 'react-spinners/PulseLoader';
import {
  Columns,
  Column,
  Buttons,
  Button,
  Title,
  Text,
  Icon,
  Content,
  Paragraph,
} from '@safelyq/bulma-ui-library';
import _ from 'lodash';
import { FlatView, GroupBySubLevelView, GroupByTimeView } from './components';
import { useBookingState, useBookingDispatch } from '../../context';
import { rolesEnum, viewEnum } from './constants';
import { useSlots } from './hooks';

export const AvailableSlots = ({ timeZone }) => {
  const dispatch = useBookingDispatch();

  const {
    service,
    serviceProvider,
    appointmentDate,
    serviceSubLevels,
    subLevelIds,
    view,
  } = useBookingState();

  const {
    isLoading,
    error,
    data: slots,
  } = useSlots({
    service,
    appointmentDate,
    subLevelIds,
  });

  useEffect(
    () =>
      handleViewChange(
        serviceProvider ? viewEnum.FLAT : viewEnum.GROUP_BY_SUBLEVEL
      ),
    [serviceProvider]
  );

  const handleViewChange = (view) =>
    dispatch({ type: 'SET_VIEW', payload: view });

  const isSlotsAvailable = slots?.length > 0;

  const isServiceSelected = service;

  if (!isServiceSelected) return <NotFoundSection role={rolesEnum.SELECTION} />;

  if (isLoading) return <LoadingSection />;

  if (error) return <ErrorSection />;

  if (!isSlotsAvailable) return <NotFoundSection role={rolesEnum.NOT_FOUND} />;

  return (
    <React.Fragment>
      <Columns>
        <Column>
          <Title size='6'>Available Slots ({timeZone})</Title>
        </Column>
      </Columns>
      <Columns>
        <Column>
          {!_.isEqual(view, viewEnum.FLAT) && (
            <Buttons addons size='small'>
              <Button
                color='info'
                outlined={!_.isEqual(view, viewEnum.GROUP_BY_TIME)}
                onClick={() => handleViewChange(viewEnum.GROUP_BY_TIME)}
              >
                <Icon name='clock-o' collection='fa' />
                <Text>Group By Time</Text>
              </Button>
              <Button
                color='info'
                outlined={!_.isEqual(view, viewEnum.GROUP_BY_SUBLEVEL)}
                onClick={() => handleViewChange(viewEnum.GROUP_BY_SUBLEVEL)}
              >
                <Icon name='sitemap' collection='fa' />
                <Text>Group By Sublevel</Text>
              </Button>
            </Buttons>
          )}
          {_.isEqual(view, viewEnum.FLAT) && (
            <FlatView
              serviceSubLevels={serviceSubLevels}
              availableSlots={slots}
            />
          )}
          {_.isEqual(view, viewEnum.GROUP_BY_TIME) && (
            <GroupByTimeView
              serviceSubLevels={serviceSubLevels}
              availableSlots={slots}
            />
          )}
          {_.isEqual(view, viewEnum.GROUP_BY_SUBLEVEL) && (
            <GroupBySubLevelView
              serviceSubLevels={serviceSubLevels}
              availableSlots={slots}
            />
          )}
        </Column>
      </Columns>
    </React.Fragment>
  );
};

const ErrorSection = () => {
  return (
    <Content className='py-5'>
      <Paragraph alignment='centered' weight='bold' color='grey-light'>
        <Icon name='exclamation-triangle' />
        Something went wrong
      </Paragraph>
    </Content>
  );
};

const LoadingSection = () => {
  return (
    <Content className='py-5'>
      <Paragraph alignment='centered'>
        <PulseLoader size={7} />
      </Paragraph>
    </Content>
  );
};

const NotFoundSection = ({ role }) => {
  return (
    <Content className='py-5'>
      <Paragraph alignment='centered' weight='bold' color='grey-light'>
        {role === rolesEnum.SELECTION &&
          'Select a service to see available time slots'}
        {role === rolesEnum.NOT_FOUND && 'No slots available'}
      </Paragraph>
    </Content>
  );
};
