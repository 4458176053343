import React, { useState } from 'react';
import { toast } from 'react-toastify';
import {
  Container,
  Box,
  Columns,
  Column,
  Title,
  TextInput,
  Control,
  Field,
  Label,
  Buttons,
  Button,
} from '@safelyq/bulma-ui-library';
import { useMutation } from '@apollo/client';
import PhoneInput from 'react-phone-input-2';
import Queries from '../Common/Queries';

const ContactUs = () => {
  const [values, setValues] = useState({});

  const [onSendMail, { loading }] = useMutation(Queries.SEND_CONTACT_US_EMAIL, {
    onCompleted: () => {
      toast.success('Thanks for contacting us!');
      handleClearValues();
    },
    onError: () => toast.error('Something went wrong'),
  });

  const handleChangeValue = ({ target: { value, name } }) =>
    setValues({ ...values, [name]: value });

  const handleSubmit = (e) => {
    e.preventDefault();
    onSendMail({
      variables: {
        userContactInput: {
          ...values,
        },
      },
    });
  };

  const handleClearValues = () =>
    setValues({
      name: '',
      companyName: '',
      industry: '',
      email: '',
      phone: '+1',
    });

  return (
    <Container>
      <Box className='p-6'>
        <Column size='full' className='mb-5'>
          <Title size='4'>
            <center>Contact Us</center>
          </Title>
        </Column>
        <form onSubmit={handleSubmit}>
          <Columns multiline>
            <Column size='half'>
              <Field>
                <Label>
                  Name <span className='has-text-danger'>*</span>
                </Label>
                <Control>
                  <TextInput
                    name='name'
                    value={values.name}
                    required
                    onChange={handleChangeValue}
                  />
                </Control>
              </Field>
            </Column>
            <Column size='half'>
              <Field>
                <Label>
                  Company Name <span className='has-text-danger'>*</span>
                </Label>
                <Control>
                  <TextInput
                    name='companyName'
                    value={values.companyName}
                    required
                    onChange={handleChangeValue}
                  />
                </Control>
              </Field>
            </Column>
            <Column size='half'>
              <Field>
                <Label>
                  Industry <span className='has-text-danger'>*</span>
                </Label>
                <Control>
                  <TextInput
                    name='industry'
                    value={values.industry}
                    required
                    onChange={handleChangeValue}
                  />
                </Control>
              </Field>
            </Column>
            <Column size='half'>
              <Field>
                <Label>
                  Email <span className='has-text-danger'>*</span>
                </Label>
                <Control>
                  <input
                    className='input'
                    name='email'
                    type='email'
                    value={values.email}
                    required
                    onChange={handleChangeValue}
                  />
                </Control>
              </Field>
            </Column>
            <Column size='half'>
              <Field>
                <Label>Phone Number</Label>
                <Control>
                  <PhoneInput
                    inputClass='input'
                    countryCodeEditable={false}
                    country={'us'}
                    value={values.phone}
                    onChange={(value) =>
                      handleChangeValue({ target: { value, name: 'phone' } })
                    }
                  />
                </Control>
              </Field>
            </Column>
            <Column size='half' />
            <Column>
              <Buttons>
                <Button
                  size='small'
                  color='info'
                  className='ml-auto'
                  disabled={loading}
                  loading={loading}
                >
                  Submit
                </Button>
                <Button
                  type='button'
                  size='small'
                  outlined
                  onClick={handleClearValues}
                >
                  Cancel
                </Button>
              </Buttons>
            </Column>
          </Columns>
        </form>
      </Box>
    </Container>
  );
};

export default ContactUs;
