import React from 'react';

const Button = ({ clicked, disable, label, btnStyle, minWidth, bgColor }) => {
  return (
    <div className="buttons is-centered mt-2">
      <button
        gradient="true"
        className={`button  ${btnStyle ? btnStyle : 'is-medium is-info'}`}
        style={{
          boxShadow:
            '0 0.5em 1em -0.125em rgba(10,10,10,.1), 0 0 0 1px rgba(10,10,10,.02)',
          minWidth: minWidth ? minWidth : '267px',
          backgroundColor: bgColor ? bgColor : '',
        }}
        disabled={disable}
        onClick={clicked}
      >
        <span className="is-size-6-mobile "> {label} </span>
      </button>
    </div>
  );
};

export default Button;
