import React, { useEffect, useState } from 'react';
import {
  Columns,
  Column,
  Title,
  Label,
  Field,
  DateInput,
  Icon,
} from '@safelyq/bulma-ui-library';
import { useBookingDispatch, useBookingState } from '../../context';

export const BasicInformation = ({ business }) => {
  const [isAnySubLevel, setIsSubLevel] = useState(false);

  const { services, maxAllowedGuests, isChildrenAllowed } = business;

  const isGuestsAllowed = maxAllowedGuests > 0;

  const dispatch = useBookingDispatch();

  const {
    service: selectedService,
    serviceProvider: selectedServiceProvider,
    adults,
    children,
    serviceSubLevels,
    appointmentDate,
  } = useBookingState();

  useEffect(() => {
    const isAnySubLevelExist = services[0]?.businesses?.length > 0;
    setIsSubLevel(isAnySubLevelExist);

    const isOnlyOneService = services.length === 1;

    if (isOnlyOneService) {
      const serviceId = services[0].id;

      dispatch({
        type: 'SET_SERVICE',
        payload: { service: serviceId, business },
      });
    }
  }, []);

  const handleServiceChange = ({ target: { value } }) =>
    dispatch({ type: 'SET_SERVICE', payload: { service: value, business } });

  const handleDateChange = ({ target: { value } }) =>
    dispatch({ type: 'SET_APPT_DATE', payload: value });

  const handleServiceProviderChange = ({ target: { value } }) =>
    dispatch({ type: 'SET_SERVICE_PROVIDER', payload: value });

  const handleAdultsChange = ({ target: { value } }) =>
    dispatch({ type: 'SET_ADULTS', payload: value });

  const handleChildrensChange = ({ target: { value } }) =>
    dispatch({ type: 'SET_CHILDRENS', payload: value });

  return (
    <React.Fragment>
      <Columns multiline>
        <Column size='12'>
          <Field>
            <Label size='small' for='appointment-date'>
              Appointment Date
            </Label>
            <DateInput
              id='appointment-date'
              value={appointmentDate}
              onChange={handleDateChange}
            />
          </Field>
        </Column>
        <Column size='6'>
          <Field>
            <Label size='small'>Service</Label>
            <div className='select is-fullwidth'>
              <select value={selectedService} onChange={handleServiceChange}>
                <option value={''}>Select Service</option>
                {services.map(({ id, name }) => (
                  <option key={id} value={id}>
                    {name}
                  </option>
                ))}
              </select>
            </div>
          </Field>
        </Column>
        {serviceSubLevels?.length > 0 && (
          <Column size='6'>
            <Field>
              <Label size='small'>Service Provider</Label>
              <div className='select is-fullwidth'>
                <select
                  value={selectedServiceProvider}
                  onChange={handleServiceProviderChange}
                  disabled={!selectedService}
                >
                  <option value={''}>Select Service Provider</option>
                  {serviceSubLevels?.map(({ id, name }) => (
                    <option key={id} value={id}>
                      {name}
                    </option>
                  ))}
                </select>
              </div>
            </Field>
          </Column>
        )}
        {isGuestsAllowed && (
          <Column size='6'>
            <Field>
              <Label size='small'>Additional Guests</Label>
              <div className='select is-fullwidth'>
                <select value={adults} onChange={handleAdultsChange}>
                  <option value={''}>No of Guests</option>
                  {[...Array(maxAllowedGuests)].map((_, index) => (
                    <option key={`guest-${index}`} value={index + 1}>
                      {index + 1}
                    </option>
                  ))}
                </select>
              </div>
            </Field>
          </Column>
        )}
        <Column size='6'>
          {isChildrenAllowed ? (
            <Field>
              <Label size='small'>Children in your party?</Label>
              <div className='control'>
                <label className='radio'>
                  <input
                    type='radio'
                    name='children'
                    value={1}
                    onChange={handleChildrensChange}
                    className='mr-2'
                  />
                  Yes
                </label>
                <label className='radio'>
                  <input
                    type='radio'
                    name='children'
                    value={0}
                    onChange={handleChildrensChange}
                    className='mr-2'
                  />
                  No
                </label>
              </div>
            </Field>
          ) : (
            <Field>
              <Label size='small'>&nbsp;</Label>
              <Title size='6'>
                <Icon name='times' color='danger' />
                Adults only
              </Title>
            </Field>
          )}
        </Column>
      </Columns>
    </React.Fragment>
  );
};
