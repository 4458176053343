import React, { Fragment } from 'react'
import { Table, Section, Container, Field, Control, Buttons, Button, Label, Title, Icon, TextInput, Columns, Column, Switch } from '@safelyq/bulma-ui-library';
import styled from 'styled-components';
import moment from 'moment';
import { isEmpty } from 'lodash';

export const CampaignTitle = ({ campaign, setCampaign, audienceButtonDisable }) => {

    const today = moment(new Date()).format('YYYY-MM-DD');
    const startDateTime = `${moment(campaign.startDate).format('YYYY-MM-DD')} ${campaign.startTime}`;
    const endDateTime = `${moment(campaign.endDate).format('YYYY-MM-DD')} ${campaign.endTime}`;

    const handleCampaignChange = ({ target: { name, value } }) => {
        setCampaign({ ...campaign, [name]: value });
    };

    return (
        <Fragment>
            <Column className='p-1 is-full-mobile is-full-tablet is-full-desktop is-four-fifths-widescreen is-four-fifths-fullhd'>
                <div className='box pb-4'>
                    <Columns multiline>
                        <Column size='6'>
                            <Field>
                                <Control>
                                    <Label className='has-text-info'>
                                        Title<span className='ml-1 has-text-danger'>*</span>
                                        {audienceButtonDisable && isEmpty(campaign.title) && <UserHint>Please Fill The Required Field</UserHint>}
                                    </Label>
                                    <TextInput name='title' maxLength="40" value={campaign.title} onChange={handleCampaignChange} />
                                </Control>
                            </Field>
                        </Column>
                    </Columns>

                    <Columns multiline>
                        <Column size='6'>
                            <Field>
                                <Control>
                                    <Label className='has-text-info'>
                                        Start Date
                                        <span className='ml-1 has-text-danger'>*</span>
                                    </Label>
                                    <input
                                        className='input'
                                        type='date'
                                        name='startDate'
                                        min={today}
                                        value={campaign.startDate}
                                        onChange={handleCampaignChange}
                                    />
                                    {campaign.startDate < today ? (
                                        <p className='help is-danger'>Selected date is invalid.</p>
                                    ) : (
                                        ''
                                    )}
                                </Control>
                            </Field>
                        </Column>

                        <Column size='6'>
                            <StyledControl>
                                <Field>
                                    <Control>
                                        <Label className='has-text-info'>
                                            Start Time
                                            <span className='ml-1 has-text-danger'>*</span>
                                        </Label>
                                        <input
                                            className='input'
                                            type='time'
                                            name='startTime'
                                            value={campaign.startTime}
                                            onChange={handleCampaignChange}
                                        />
                                    </Control>
                                </Field>
                            </StyledControl>
                        </Column>
                    </Columns>

                    <Columns multiline>
                        <Column size='6'>
                            <Field>
                                <Control>
                                    <Label className='has-text-info'>
                                        End Date
                                        <span className='ml-1 has-text-danger'>*</span>
                                    </Label>
                                    <input
                                        className='input'
                                        type='date'
                                        name='endDate'
                                        min={today}
                                        value={campaign.endDate}
                                        onChange={handleCampaignChange}
                                    />
                                    {campaign.endDate < today ? (
                                        <p className='help is-danger'>Selected date is invalid.</p>
                                    ) : (
                                        ''
                                    )}
                                </Control>
                            </Field>
                        </Column>
                        <Column size='6'>
                            <StyledControl>
                                <Field>
                                    <Control>
                                        <Label className='has-text-info'>
                                            End Time
                                            <span className='ml-1 has-text-danger'>*</span>
                                        </Label>
                                        <input
                                            className='input'
                                            type='time'
                                            name='endTime'
                                            value={campaign.endTime}
                                            onChange={handleCampaignChange}
                                        />
                                        {new Date(startDateTime) >= new Date(endDateTime) ? (
                                            <p className='help is-danger'>
                                                Select Valid Date & Time Range.
                                            </p>
                                        ) : (
                                            <span className='help is-info icon is-right mt-3'>
                                                <i className='fa fa-check' />
                                            </span>
                                        )}
                                    </Control>
                                </Field>
                            </StyledControl>
                        </Column>
                    </Columns>
                </div>
            </Column>
        </Fragment>
    )
}

const UserHint = styled.span.attrs(() => ({
    className: 'help is-danger',
}))`
  font-size: 10px;
  padding-inline: 2px;
}
`;
const StyledControl = styled.div.attrs(() => ({
    className: 'control',
}))`
  width: 100%;
`;