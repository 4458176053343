import React, { useState, useEffect, useRef } from 'react';
import StripeCheckout from 'react-stripe-checkout';
import {
  Title,
  Container,
  Columns,
  Column,
  Button,
} from '@safelyq/bulma-ui-library';
import { useData } from './hooks';

export default function StripeTest() {
  const PRICE = 100;
  const name = "Testing Stripe Payment";
  const boxRef = useRef();

  const onStripeRequestCompleted = () => {
    setShowStripeDropIn(false);
    setNumberOfProducts(1);
  };

  const {
    publishableKey,
    invokingStripeRequest,
    invokeStripeTransactionRequest,
  } = useData(onStripeRequestCompleted);

  useEffect(() => {
    function handleClickOutside(event) {
      if (boxRef.current && !boxRef.current.contains(event.target)) {
        console.log("You clicked outside of me!");
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [boxRef]);

  const [showStripeDropIn, setShowStripeDropIn] = useState(false);
  const [numberOfProducts, setNumberOfProducts] = useState(1);

  const handleToken = (token) => {
    invokeStripeTransactionRequest({
      variables: {
        amount: numberOfProducts * PRICE,
        tokenId: token.id,
        description: name
      },
    });
  }
  return (
    <Container className='py-5'>
      <Columns multiline>
        <Column size={12}>
          <Columns className={'productHeaderRow'}>
            <Column xs={4}>
              <Title size='6'>Product</Title>
            </Column>
            <Column xs={2} className={'text-center'}>
              <Title size='6'>Price</Title>
            </Column>
            <Column xs={2} className={'text-center'}>
              <Title size='6'>Quantity</Title>
            </Column>
            <Column xs={2} className={'text-center'}>
              <Title size='6'>Total</Title>
            </Column>
            <Column xs={2} />
          </Columns>
          <Columns className={'productInfoRow'}>
            <Column xs={4} className={'productInfoColumn'}>
              {'Foo product'}
            </Column>
            <Column xs={2} className={'productInfoColumnCenter'}>
              {`$ ${PRICE}`}
            </Column>
            <Column xs={2} className={'productInfoColumnCenter'}>
              <input
                placeholder='0'
                min={1}
                max={100}
                type='number'
                step='1'
                value={numberOfProducts}
                onChange={(e) => {
                  setNumberOfProducts(e.target.value);
                }}
              />
            </Column>
            <Column xs={2} className={'productInfoColumnCenter'}>
              {`$ ${numberOfProducts * PRICE}`}
            </Column>
            <Column xs={2} className={'productInfoColumnCenter'}>
              <Button
                color='info'
                size='small'
                onClick={() => {
                  setShowStripeDropIn(true);
                }}
                disabled={showStripeDropIn}
              >
                {'Go to Checkout'}
              </Button>
            </Column>
          </Columns>
        </Column>
        <Column size={12} ref={boxRef}>
          {showStripeDropIn || invokingStripeRequest ? (
          <StripeCheckout 
            token={handleToken}
            stripeKey={publishableKey && publishableKey}
            name={name}
            amount={numberOfProducts * PRICE}
          />
          ): null}
        </Column>
      </Columns>
    </Container>
  );
}
