import { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import Queries from 'components/Common/Queries';
//for firestore

const useData = (data, refetch) => {


  const {
    id: apptId,
    status,
    adults,
    startTime,
    allocatedTime: checkinTime,
    startTime: appointmentTime,
    timeZone,
    venueEntrance,
    service: { name: serviceName },
    subBusiness: { name: sublevelName },
    business: {
      id: businessId,
      isAppointmentConfirmationRequired: isConfirmationRequired,
      appointmentConfirmationOffset: confirmationOffset,
      endTime: endTime,
      questions,
      preCheckinInstructions,
      lateCheckInTimeThreshold,
    },
  } = data || {};

  const [updateAppointmentStatus] = useMutation(
    Queries.UPDATE_APPOINTMENT_STATUS,
    {
      onCompleted: async ({
        updateAppointmentStatus: {
          appointment,
        },
      }) => {
        refetch();
      },
    }
  );

  return {
    apptId,
    status,
    adults,
    checkinTime,
    startTime,
    isConfirmationRequired,
    isCheckinRequired: true,
    confirmationOffset,
    endTime,
    checkinTime,
    appointmentTime,
    timeZone,
    serviceName,
    sublevelName,
    preCheckinInstructions,
    lateCheckInTimeThreshold,
    questions,
    venueName: venueEntrance?.name,
    businessId,
    isQuestionsRequired: questions?.length > 0 ? true : false,
    onUpdateAppointmentStatus: updateAppointmentStatus,
  };
};

export default useData;
