import React from 'react'
import { RecipientBadge } from '../../ManageUserMessages.styled'
import { Title, Subtitle, } from '@safelyq/bulma-ui-library';

const MessageItemBusinessCoupon = ({ chat }) => {
    let contentDate = chat.message.commentText.split('-').pop();
    return (
        <RecipientBadge>
            <a href={chat.attachment?.publicUrl} target='__blank' style={{ background: 'transparent' }}  >
                <Title size='6' className='m-0'>   We have a deal you might like   </Title>
                {/* <Subtitle size='6' className='mt-1 mb-2'>  {title}  </Subtitle> */}
                {chat.attachment?.publicUrl ?
                    (<img src={chat.attachment.publicUrl} width={180} height={190} className='has-text-centered' alt="coupon pic" />) :
                    (<Subtitle size='6' className='mt-1 mb-2'>  {contentDate}  </Subtitle>)}
            </a>
        </RecipientBadge>
    )
}

export default MessageItemBusinessCoupon