import { useState } from 'react';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { useMutation } from 'react-query';
import { APIPaths } from 'services/api-provider';

export function useMarketingSignUp(claim, handleError, setVerifyEmail, setIsEmailRegistered) {
  const history = useHistory();

  const [result, setResult] = useState();

  const { isLoading, mutate: callCheck } = useMutation((body) =>
    fetch(`${APIPaths.Gateway}/Account/MarketingSignUp`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    })
  );

  const translateError = (message) => {
    switch (message) {
      case 'CANNOT_BE_CLAIMED':
        return {
          message: 'Your phone number is invalid. If you think this is an error, please contact us at support@safelyq.com.',
        };
      case 'ALREADY_REGISTERED':
        return {
          message: 'Phone number already in use. Please try signing in or claim account.',
        };
      case 'EMAIL_PARTIALLY_REGISTERED':
        return {
          message: 'Email already taken. \n Please use another Email.',
        };

      case 'EMAIL_FULLY_REGISTERED':
        return {
          message: 'Email already registered. \n Please sign in or claim account.',
        };

      case 'INVALID_CODE':
        return {
          message: 'You have entered invalid code.',
        };

      case 'IS_UNSUPPORTED':
      default:
        return {
          message: 'You should not be seeing this. Contact support.',
        };
    }
  };

  const handleCheckSuccess = (response) => {
    if (response.ok) {
      response
        .json()
        .then((result) => {
          if (result.state === 'DISPLAY_ERROR') {
            const error = translateError(result.message);

            handleError(error);
          } else {
            if(result.state === 'VERIFY'){
                setVerifyEmail(true);
                if(result.message === 'EMAIL_FULLY_REGISTERED'){
                  setIsEmailRegistered(true);
                }
            }
            else if(result.state === 'VERIFIED'){
              setResult(result);
              toast.success("Thank you! You are signed up for specials.")
              history.push('/');
            }
          }
        })
        .catch((error) => {
          setResult();

          if (handleError) {
            handleError(error);
          }
        });
    } else {
      setResult();

      if (handleError) {
        handleError({
          message: 'There was an error checking claim',
        });
      }
    }
  };

  const handleCheckError = (error) => {
    handleError(error);
  };

  const check = () => {
    callCheck(claim, {
      onSuccess: handleCheckSuccess,
      onError: handleCheckError,
    });
  };

  return {
    isLoading,
    result,
    check,
  };
}

export function useGetUserZipCode(claim, handleError) {

  const [result, setResult] = useState();

  const { isLoading, mutate: callCheck } = useMutation((body) =>
    fetch(`${APIPaths.Gateway}/Account/GetUserZipCode`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    })
  );

  const translateError = (message) => {
    switch (message) {
      case 'INVALID':
        return {
          message: 'Your phone number is invalid. If you think this is an error, please contact us at support@safelyq.com.',
        };

      case 'IS_UNSUPPORTED':
      default:
        return {
          message: 'You should not be seeing this. Contact support.',
        };
    }
  };

  const handleCheckSuccess = (response) => {
    if (response.ok) {
      response
        .json()
        .then((result) => {
          if (result.state === 'DISPLAY_ERROR') {
            const error = translateError(result.message);

            setResult(result);
            handleError(error);
          } else {
            setResult(result);
          }
        })
        .catch((error) => {
          setResult();

          if (handleError) {
            handleError(error);
          }
        });
    } else {
      setResult();

      if (handleError) {
        handleError({
          message: 'There was an error checking phone number',
        });
      }
    }
  };

  const handleCheckError = (error) => {
    handleError(error);
  };

  const get = () => {
    callCheck(claim, {
      onSuccess: handleCheckSuccess,
      onError: handleCheckError,
    });
  };

  return {
    isLoading,
    result,
    get,
  };
}
