import React, { useState, useEffect } from 'react';
import * as _ from 'lodash';
import { Switch, useLocation, Route } from 'react-router-dom';
import { ApolloRoute } from 'routes/ApolloRoutes';
import { SuperAdminLayout } from '@safelyq/base-feature';
import { SuperAdminHome } from '@safelyq/business-admin';
import { NotFound } from 'components/NotFound';
import ManageBusiness from 'features/admin-manage-business/ManageBusiness';
import Queries from 'components/Common/Queries';
import { useQuery } from '@apollo/client';
import { Loader } from "@safelyq/base-feature";
import MarketingUser from '../../features/marketing-user-count/src/MarketingUser/index';
import Organization from '../../features/admin-delete-organization/Organization';
import BusinessCategories from 'features/admin-business-categories/BusinessCategories';

export const SuperAdminRoutes = ({ profile, data }) => {
    const { pathname } = useLocation();
    const [isSuperAdmin, setIsSuperAdmin] = useState(null);
    useEffect(() => {
        if (data && data.getUser && data.getUser.user) {
            setIsSuperAdmin(data.getUser.user.isSuperAdmin || false);
        }
    }, [data]);

    useEffect(() => {
        const timeout = setTimeout(() => {
            if (isSuperAdmin === null) {
                setIsSuperAdmin(false);
            }
        }, 2000);

        return () => clearTimeout(timeout);
    }, [isSuperAdmin]);

    if (isSuperAdmin === null) {
        return <Loader isFullScreen />;
    } else if (!isSuperAdmin) {
        return <Route path='*' render={() => <NotFound />} />;
    }

    const pathId = String(pathname).substring(pathname.lastIndexOf('/') + 1);
    const businessId = Number(pathId) ? parseInt(pathId) : 0;
    return (
        <Switch>
            <ApolloRoute secure exact path='/superadmin/home' isNavbarHide={true} profile={profile}
                component={() => <SuperAdminLayout businessId={businessId} {...profile}>
                    <SuperAdminHome />
                </SuperAdminLayout>}
            />
            <ApolloRoute secure exact path='/superadmin/managebusiness/' isNavbarHide={true} profile={profile}
                component={() => <SuperAdminLayout businessId={businessId} {...profile}>
                    <ManageBusiness />
                </SuperAdminLayout>}
            />
            <ApolloRoute secure exact path='/superadmin/marketinguser/' isNavbarHide={true} profile={profile}
                component={() => <SuperAdminLayout businessId={businessId} {...profile}>
                    <MarketingUser />
                </SuperAdminLayout>}
            />
            <ApolloRoute secure exact path='/superadmin/organization/' isNavbarHide={true} profile={profile}
                component={() => <SuperAdminLayout businessId={businessId} {...profile}>
                    <Organization />
                </SuperAdminLayout>}
            />
            <ApolloRoute secure exact path='/superadmin/categories/' isNavbarHide={true} profile={profile}
                component={() => <SuperAdminLayout businessId={businessId} {...profile}>
                    <BusinessCategories />
                </SuperAdminLayout>}
            />
            <Route path='*' render={() => <NotFound />} />
        </Switch>
    )
} 