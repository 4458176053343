import React from 'react';
import { Link } from 'react-router-dom';
import {
  Title,
  Subtitle,
  Container,
  Section,
  Button,
} from '@safelyq/bulma-ui-library';

export const NotFound = () => {
  return (
    <Section>
      <Container>
        <center>
          <img src='images/404.svg' width={280} />
          <Title size='4' className='pb-3'>
            Oops! Page not found!
          </Title>
          <Subtitle size='6'>
            We are sorry for inconvenience. It looks like you're trying to
            <br />
            access a page that was has been deleted or never even existed
          </Subtitle>
          <Link to='/'>
            <Button color='info' size='small'>
              BACK TO HOME
            </Button>
          </Link>
        </center>
      </Container>
    </Section>
  );
};
