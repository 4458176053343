import styled from 'styled-components';

export const Wrapper = styled.div`
background:white;
cursor:pointer;
  height: 170px;
  margin: 0px 18px;
  position: relative;
  border-radius: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  gap: 22px;
  @media (max-width: 768px) {
    flex-direction: column;
    height: 280px;
    padding: 0;
  }
`;

export const ImgWrapper = styled.div`
  width: 40%;
  display: flex;
  align-items: center;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const CouponImage = styled.img`
  width: 100%;
  height: inherit;
  object-fit: contain;
`;


export const TextWrapper = styled.div`
  width: 60%;
  border-radius: 10px;
  text-align: center;
  padding: 10px 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  @media (max-width: 768px) {
    width: 100%;
  }
`;
export const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
    align-items: baseline;
    margin-bottom: 12px;
`;
export const Title = styled.h6` 
font-weight: 600;
font-size: 16px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;
export const CouponSummary = styled.p`
  font-style: italic;
  text-overflow: ellipsis;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
`;
export const BusinessName = styled.div` 
  text-overflow: ellipsis; 
  white-space: nowrap;
  overflow: hidden;
`;

export const CouponDiscount = styled.p` 
  text-overflow: ellipsis;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
`;
export const ExpiredStatusTag = styled.span.attrs(({ }) => ({ className: 'tag tag is-light is-rounded', }))`
background-color:#ff6363 !important ;
color:white  !important
`;

export const ActiveStatusTag = styled.span.attrs(({ }) => ({ className: 'tag is-rounded', }))`
  background-color: rgb(174, 233, 209) !important;
`;

