import React from 'react'
import "../style.css";
import { FiPlus } from "react-icons/fi";

const SidebarAI = ({ onSelectChat, setShowInput, showInput, setMessages, initialMessage }) => {
    return (
        <div className="menu-custom">
            <p className="menu-label-custom has-text-centered">
                ChatSLB
            </p>
            <p className='has-text-white has-text-centered is-fullwidth is-size-7'>verison 1.0.0</p>
            {/* <ul className="menu-list-custom">
                {data.map(chat => (
                    <li key={chat.id} onClick={() => onSelectChat(chat)}>
                        <a>
                            <small>{chat.lastMessage}</small>
                        </a>
                    </li>
                ))}
            </ul> */}

            <p className='get-started-class is-clickable' onClick={() => !showInput ? setShowInput(true) : setMessages(initialMessage)}>
                <FiPlus className='icon-class' />
                <span> New Chat </span>
            </p>
        </div>
    )
}

export default SidebarAI