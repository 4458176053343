import React, { useCallback, useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { isUndefined } from 'lodash';
import { useParams } from 'react-router-dom';
import { useMarketingSignUp, useGetUserZipCode } from 'hooks/useMarketingSignUp';
import PhoneInput from 'react-phone-input-2';
import Logo from '../../assets/triangle-logo.png';
import VerifyMarketingEmail from 'components/VerifyMarketingEmail';

const MarketingSignUp = () => {
  let { params } = useParams();
  const [email, setEmail] = useState();
  const [phoneNumber, setPhoneNumber] = useState();
  const [userInfo, setUserInfo] = useState({
    firstName:'',
    lastName :'',
    city :'',
    state :'',
    address1 :'',
    address2 :'',
    zipCode :'',
    allowWhatsApp: false
  });
  const [password1, setPassword1] = useState();
  const [password2, setPassword2] = useState();
  const [verifyEmail, setVerifyEmail] = useState(false);
  const [isEmailRegistered, setIsEmailRegistered] = useState(false);
  const [states, setStates] = useState([]);
  const [code, setCode] = useState();
  const [areLegalTermsAccepted, setLegalTermsAccepted] = useState(false);

  const showError = useCallback((error) => toast.error(error.message), []);

  const isEmailValid = EmailValidator.test(email);
  const isFirstNameValid = userInfo.firstName !== '' && userInfo.firstName !== null;
  const isLastNameValid = userInfo.lastName !== '' && userInfo.lastName !== null;
  const isZipCodeValid = userInfo.zipCode !== '' && userInfo.zipCode !== null && userInfo.zipCode.length === 5;
  const canCheck = isFirstNameValid && isLastNameValid && isEmailValid && areLegalTermsAccepted && isZipCodeValid && phoneNumber;
  const cannotCheck = !canCheck;

  const { result, get } = useGetUserZipCode(
    {
      phoneNumber: params,
    },
    showError
  );

  const { isLoading, check } = useMarketingSignUp(
    {
      firstName: userInfo.firstName,
      lastName: userInfo.lastName,
      city: userInfo.city,
      state: userInfo.state,
      address1: userInfo.address1,
      address2: userInfo.address2,
      zipCode: userInfo.zipCode,
      allowWhatsApp: userInfo.allowWhatsApp,
      code,
      verifyEmail,
      phoneNumber,
      email,
      password: password1 || password2,
    },
    showError,
    setVerifyEmail,
    setIsEmailRegistered
  );

    useEffect(() => {
      get();
    },[params]);

    useEffect(() => {
      if(result !== null && result !== undefined){
        setStates(result.states);
        if(result.state !== "DISPLAY_ERROR"){
          setUserInfo({
            ...userInfo,
            zipCode: result.message
          })
          setPhoneNumber(params);
        }
        else if(result.state === "DISPLAY_ERROR"){
          setPhoneNumber();
        }
      }
    },[result, params]);
    
  return (
    <>
    {verifyEmail ? (
      <Card>
        <VerifyMarketingEmail
          code={code}
          setCode={setCode}
          verify={check}
          isEmailRegistered={isEmailRegistered}
          password1={password1}
          password2={password2}
          setPassword1={setPassword1}
          setPassword2={setPassword2}
        />
      </Card>
    ): (
      <Card>
        <PhoneNumberEntry
            value={phoneNumber}
          />
        <EmailEntry
          valid={isEmailValid}
          value={email}
          placeholder="Email"
          onChange={(e) => setEmail(e.target.value)}
        />
        <UserInfoEntry 
            values={userInfo}
            states={states}
            setValue={setUserInfo}
            validFirstName={isFirstNameValid}
            validLastName={isLastNameValid}
            validZipCode={isZipCodeValid}
        />
        <LegalTerms
          term={areLegalTermsAccepted}
          setTerm={setLegalTermsAccepted}
        />
        <CheckButton
          isLoading={isLoading}
          disabled={cannotCheck}
          check={check}
        />
      </Card>
    )}
      <ToastContainer />
    </>
  );
};

const EmailValidator = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;

const PhoneNumberEntry = ({ value }) => {
  
  return (
    <div className="field">
      <div className='control has-icons-left has-icons-right'>
        <PhoneInput
          countryCodeEditable={false}
          value={value}
          disabled
        />
        { value && (
        <span className="icon is-medium is-right">
          <i className="fa fa-check" />
        </span>
        )}
      </div>
    </div>
  );
};

const EmailEntry = ({
  valid,
  name,
  value,
  placeholder,
  onChange,
}) => {
  const styles = 'input is-medium';
  const dirty = !isUndefined(value);

  return (
    <div className="field">
      <div
        className={
          valid
            ? 'control has-icons-left has-icons-right'
            : 'control has-icons-left'
        }
      >
        <input
          className={dirty && !valid ? `${styles} is-danger` : styles}
          type="text"
          name={name}
          defaultValue={value}
          placeholder={placeholder}
          autoComplete="false"
          onChange={onChange}
        />
        <span className="icon is-medium is-left">
          <i className="fa fa-at" />
        </span>
        {valid && (
          <span className="icon is-medium is-right">
            <i className="fa fa-check" />
          </span>
        )}
      </div>
    </div>
  );
};

const UserInfoEntry = ({
    values,
    states,
    setValue,
    validFirstName,
    validLastName,
    validZipCode,
  }) => {
    const styles = 'input is-medium';

    const handleChange = (e) => {
        const {name, value} = e.target;

        setValue({
            ...values,
            [name]:value
        })
    }
    return (
        <>
      <div className="field">
          <div
            className={
              validFirstName
                ? 'control has-icons-left has-icons-right'
                : 'control has-icons-left'
            }
          >
          <input
            className={styles}
            value={values.firstName}
            type="text"
            name="firstName"
            placeholder="First Name"
            onChange={handleChange}
          />
          <span className="icon is-medium is-left">
            <i className="fa fa-asterisk" />
          </span>
          {validFirstName && (
            <span className="icon is-medium is-right">
              <i className="fa fa-check" />
            </span>
          )}
        </div>
      </div>
      <div className="field">
          <div
            className={
              validLastName
                ? 'control has-icons-left has-icons-right'
                : 'control has-icons-left'
            }
          >
            <input
            className={styles}
            value={values.lastName}
            type="text"
            name="lastName"
            placeholder="Last Name"
            onChange={handleChange}
            />
            <span className="icon is-medium is-left">
              <i className="fa fa-asterisk" />
            </span>
            {validLastName && (
              <span className="icon is-medium is-right">
                <i className="fa fa-check" />
              </span>
            )}
        </div>
      </div>
      <div className="field">
        <div>
            <input
            className={styles}
            value={values.address1}
            type="text"
            name="address1"
            placeholder="Address 1"
            onChange={handleChange}
            />
        </div>
      </div>
      <div className="field">
        <div>
            <input
            className={styles}
            value={values.address2}
            type="text"
            name="address2"
            placeholder="Address 2"
            onChange={handleChange}
            />
        </div>
      </div>
      <div className="field">
        <div>
            <input
            className={styles}
            value={values.city}
            type="text"
            name="city"
            placeholder="City"
            onChange={handleChange}
            />
        </div>
      </div>
      <div className="field">
        <div className='select is-fullwidth is-medium'>
          <select onChange={({target: {value} }) => {
            setValue({
              ...values,
              state : value
          })
          }}>
          <option value={''} selected disabled>
            Select State
          </option>
          {states?.map((state) => (
              <option
                key={`state-${state?.id}`}
                value={state?.name}
              >
                {state?.name}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="field">
          <div
            className={
              validZipCode
                ? 'control has-icons-left has-icons-right'
                : 'control has-icons-left'
            }
          >
            <input
            className={styles}
            value={values.zipCode}
            type="text"
            name="zipCode"
            placeholder="ZipCode"
            onChange={handleChange}
            />
            <span className="icon is-medium is-left">
              <i className="fa fa-asterisk" />
            </span>
            {validZipCode && (
              <span className="icon is-medium is-right">
                <i className="fa fa-check" />
              </span>
            )}
        </div>
      </div>
      <div className="field has-text-left">
        <input
          className="mr-2"
          id="allowWhatsApp"
          type="checkbox"
          checked={values.allowWhatsApp}
          onChange={(e) => setValue({
            ...values,
            allowWhatsApp: !values.allowWhatsApp
          })}
        />
        <label htmlFor="allowWhatsApp">
          Allow WhatsApp messages to this phone number.
        </label>
      </div>
    </>
    );
  };

const CheckButton = ({ disabled, isLoading, check }) => {
  const styles = 'button is-block is-primary is-outlined is-medium is-fullwidth my-5';

  return (
    <div className="field">
      <div className="control">
        <button
          className={isLoading ? `${styles} is-loading` : styles}
          disabled={isLoading || disabled}
          onClick={check}
        >
          Sign Up
        </button>
      </div>
    </div>
  );
};

const LegalTerms = ({ term, setTerm }) => {
  const legalText = "I agree to receive personalized emails, text/SMS and/or WhatsApp messages.";

  return (
    <>
      <div className="field has-text-left">
        <input
          className="mr-2"
          id="legal-terms"
          type="checkbox"
          checked={term}
          onChange={(e) => setTerm(!term)}
        />
        <label htmlFor="legal-terms">
          {legalText}
        </label>
      </div>
    </>
  );
};

function Card({ children }) {
  return (
    <div className="hero is-fullheight">
      <div className="hero-body is-paddingless">
        <div className="container has-text-centered">
          <div className="column is-4 is-offset-4">
            <div className="box p-6">
              <a href="/">
                <img src={Logo} alt="logo" width={120} height={120}/>
              </a>
              <div className="title has-text-grey is-6 mt-4 mb-6">Sign Up for our coupon, specials and more</div>
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MarketingSignUp;
