import React from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Block, Subtitle, Title, Text, } from '@safelyq/bulma-ui-library';
import { BusinessCard, LoadingSection, ErrorSection } from './components';
import { useData } from './hooks';
import { MdNavigateBefore, MdNavigateNext } from "react-icons/md";
import SingleBusiness from './SingleBusiness';
import styles from "./Searchlist.module.css"

const SampleNextArrow = (props) => (<span className="NextArrow" onClick={props.onClick} > {" "} < MdNavigateNext /> {" "} </span>);
const SamplePrevArrow = (props) => (<span className="PreviousArrow" onClick={props.onClick}  > {" "} < MdNavigateBefore /> {" "} </span>);


export const SearchList = ({ ...props }) => {

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: true,
    nextArrow: < SampleNextArrow />,
    prevArrow: < SamplePrevArrow />,
    responsive: [
      { breakpoint: 1280, settings: { slidesToShow: 1, arrows: false, } },
      { breakpoint: 1024, settings: { slidesToShow: 1, arrows: false, } },
      { breakpoint: 600, settings: { slidesToShow: 1, arrows: false, } },
    ],
  };
  const { isLoading, error, data, totalRecords } = useData();

  if (isLoading) return <LoadingSection />;

  if (error) return <ErrorSection />;

  return (
    <Block className='mt-3 mb-6 has-text-centered-mobile'>


      <div className='row g-0'>
        <div className='col-lg-12 is-hidden-mobile'>
          {data.map(({ category, businesses }, index) => (
            <Block key={`category-${index}`}>
              <Title size='6'>{category}</Title>
              <Slider scrollOffset={320}>
                {businesses.map((business, index) => (
                  <BusinessCard
                    key={`businessCard-${index}`}
                    business={business}
                    {...props}
                  />
                ))}
              </Slider>
            </Block>
          ))}
        </div>
        <div className='col-lg-12 is-hidden-tablet'>
          {
            data.map(({ category, businesses }, index) => {
              return (
                <Block key={`category-${index}`}>
                  <Title size='6'>{category}</Title>
                  <Slider {...settings}   >
                    {businesses.map((business, index) => (
                      <div key={business.id} >
                        <div className={styles.businessWrapper}>
                          <SingleBusiness key={`businessCard-${index}`} business={business}    {...props} />
                        </div>
                      </div>

                    ))}
                  </Slider>
                </Block>
              )
            })
          }
        </div>


      </div>
    </Block>
  );
};
