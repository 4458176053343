
import React, { useEffect, useRef, useState } from 'react'
import AppointmentsCalendar from '../AppointmentsCalendar/AppointmentsCalendar';
import { useQuery } from '@apollo/client';
import moment from 'moment-timezone';
import Queries from '../../../Common/Queries';
import firestore from '@firebase';
import { COLLECTION } from 'constants/index';
import { useSelector } from 'react-redux';
import * as _ from 'lodash';
import { Skeleton } from 'antd';
import UserAppointments from 'components/Home/UserAppointments';
import { Link } from 'react-router-dom';

const AppointmentSection = () => {
    const dateFormatString = 'YYYY-MM-DD';

    const [daysOfweek, setDaysOfWeek] = useState([]);
    const [selected, setSelected] = useState(null);
    const [all_Appointments, setAll_Appointments] = useState([]);
    const [currentMY, setCurrentMY] = useState({
        currentMonthName: moment().format('MMMM'),
        currentYear: moment().format('YYYY'),
    })

    const { userinfo, allAppointments } = useSelector((state) => state.auth);

    const todayDate = useRef(moment(new Date()).format(dateFormatString));
    const childRef = useRef(null);
    const { loading, refetch, error, data = { getCurrentUserAppointments: [] } } = useQuery(Queries.GET_USER_APPOINTMENTS, {
        variables: { businessId: 0, status: 'ALL', startDate: todayDate.current },
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true,
    });

    const onSelect = (key) => {

        setCurrentMY({
            currentMonthName: moment(key).format('MMMM'),
            currentYear: moment(key).format('YYYY'),
        })
        if (key === selected) {
            setSelected(0);
        } else {
            setSelected(key);
        }
    };
    const handleTodayBtn = () => {
        childRef.current.scrollTo(todayDate.current)
        setSelected(todayDate.current)
        setCurrentMY({
            currentMonthName: moment().format('MMMM'),
            currentYear: moment().format('YYYY'),
        })
    };

    useEffect(() => {
        const unsubscribe = firestore
            .collection(COLLECTION.APPOINTMENTS)
            .where('customerId', '==', `${userinfo.id}`)
            .onSnapshot(async () => {
                refetch()
            });
        return () => unsubscribe();
    }, [userinfo.id, refetch]);

    useEffect(() => {
        // const { getCurrentUserAppointments: allAppointments = [] } = data;
        setAll_Appointments(allAppointments)
        const calendarLabels = _.chain(allAppointments.map((appointment) => { return { ...appointment, appointmentDate: moment(appointment.startTime).format('YYYY-MM-DD'), }; }))
            .groupBy('appointmentDate')
            .map((value, key) => {
                return ({
                    appointmentDate: key,
                    d: moment(key),
                    text: value.length.toString(),
                    color: 'red',
                    noOfAppointments: value.length > 0 ? value.length : 0
                })
            })
            .value();

        const weekStart = moment().startOf("week");
        const days = [];
        for (let i = 0; i <= 26; i++) {
            const date = moment(weekStart).add(i, "days");
            const weekdate = date.format("YYYY-MM-DD")
            const Apointmentdays = calendarLabels.find((label) => label.appointmentDate === weekdate)
            let obj = {
                id: i,
                weekday: date,
                weekDate: weekdate,
                extraClass: calendarLabels.some((label) => label.appointmentDate === weekdate) ? "has_appointment" : "No",
                noOfAppointments: Apointmentdays ? Apointmentdays.noOfAppointments : 0
            }
            days.push(obj);
        }

        setDaysOfWeek(days)
        setSelected(todayDate.current)
    }, [allAppointments])

    if (loading) {
        return (<Skeleton.Input style={{ height: '200px', }} active={true} size={"large"} block={true} />)
    }
    else {
        return (

            <div className='row mb-5'>
                <div className='col-lg-12'>
                    <div className='row  mb-5'>
                        <div className='col-lg-5 col-6'>
                            <button onClick={handleTodayBtn} className='hsm_today_button'>Today</button>
                        </div>
                        <div className='col-lg-7 col-6 has-text-right-mobile'>
                            <button className='hsm_today_button'>{currentMY.currentMonthName} {currentMY.currentYear}</button>
                        </div>
                    </div>

                    <AppointmentsCalendar ref={childRef} daysOfweek={daysOfweek} selected={selected} onSelect={onSelect} />
                </div>
                {userinfo.isPhoneNumberConfirmed === false && (
                    <div className='col-lg-12'>
                        <div className='py-4'>
                            <div className='notification is-danger is-light has-text-grey'>
                                <span className='icon'>
                                    <i className='fa fa-exclamation-circle' aria-hidden='true' />
                                </span>
                                You may not be able to see all appointments because your  phone number is either not set or has not been verified.{' '}
                                <Link to='/account/setting' className='has-text-weight-bold'  > Click here to resolve  </Link>
                            </div>
                        </div>
                    </div>
                )}
                <div className='col-lg-12'>
                    <UserAppointments selectedDate={selected} appointments={all_Appointments} />
                </div>
            </div>

        )
    }


}

export default AppointmentSection