import React, { useState, useEffect } from 'react';
import * as _ from 'lodash';
import styled from 'styled-components';
import PhoneInput from 'react-phone-input-2';
import { Field, Label, Control, Button, Help, Text, Icon, } from '@safelyq/bulma-ui-library';
import { useHistory } from "react-router-dom";
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

const VerifyButton = styled(Button)`
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 5px;
  margin-right: 5px;
  border-radius: 5px !important;
`;

const PhoneNumberField = ({ values, onProfileUpdate }) => {
    const [phNumber, setphNumber] = useState(values.phoneNumber === null ? values.claimedPhoneNumber : values.phoneNumber)
    const [loader, setloader] = useState(false)

    const { push } = useHistory();
    const { userinfo } = useSelector((state) => state.auth);

    const handleValuesChange = (value) => {
        setphNumber(value)
    }
    const isPhoneChanged = !_.isEqual(_.replace(values.phoneNumber, '+', ''), _.replace(phNumber, '+', ''));
    const isVerifyEnable = isPhoneChanged || !values.phoneNumberConfirmed;

    const handleSaveChanges = async () => {
        try {
            setloader(true)
            const response = await onProfileUpdate({
                variables: {
                    userProfileInput: {
                        firstName: values.firstName,
                        lastName: values.lastName,
                        phoneNumber: phNumber || values.claimedPhoneNumber,
                        ...values.userPreferences,
                    },
                },
            });
            if (response.data.saveUserProfile.isVerficationCodeSent) {
                push({ pathname: "/account/verify-phone", state: { currentMenu: 0, phoneNumber: phNumber }, });
            }
            else if (response.data.saveUserProfile.error) {
                const errorMessage = { code: 403, message: response.data.saveUserProfile.error };
                throw errorMessage;
            }
            setloader(false)

        } catch (error) {
            toast.error(error.message)
            setloader(false)
            console.log(error);
        }
    }

    useEffect(() => {
        setphNumber(values.phoneNumber === null ? values.claimedPhoneNumber : values.phoneNumber)
    }, [values])
    return (
        <Field>
            <Label>Phone Number</Label>
            <Control>
                <PhoneInput
                    inputClass='input'
                    countryCodeEditable={false}
                    country={'us'}
                    value={phNumber}
                    onChange={handleValuesChange}
                />
                {isVerifyEnable && (
                    <VerifyButton size='small' color='info' onClick={handleSaveChanges} >
                        {loader ? 'wait...' : 'Verify'}
                    </VerifyButton>
                )}
            </Control>
            <Help>
                {userinfo.isPhoneNumberConfirmed ? <i>For SMS notifications</i> : <Text color='danger'>  <Icon name='exclamation-triangle' color='danger' />  <span>Phone number not set or verified</span>   </Text>}

            </Help>
        </Field>
    )
}

export default PhoneNumberField