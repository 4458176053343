import React, { useState } from 'react'
import { Modal, Icon, Title, Field, Control, Label, Buttons, Button, DateInput, Paragraph } from '@safelyq/bulma-ui-library';
import { Helmet } from 'react-helmet';
import { getStatusColor, getStatusName } from 'methods/getStatuses';
import moment from 'moment';
import AppointmentSlots from './AppointmentSlots';
import AppointmentComments from './AppointmentComments/AppointmentComments';
import { useMutation } from '@apollo/client';
import { UPDATE_APPOINTMENT } from './request';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { updateAppt, updateNotificationagain } from 'Redux/AuthSlice/AuthSlice';
import { useRef } from 'react';
import { Spin } from 'antd';




const compareOBJ = (obj1, obj2) => {
    return obj1.date === obj2.date &&
        parseInt(obj1.service) === parseInt(obj2.service) &&
        parseInt(obj1.serviceProvider) === parseInt(obj2.serviceProvider) &&
        parseInt(obj1.children) === parseInt(obj2.children) &&
        parseInt(obj1.adults) === parseInt(obj2.adults)
}


const EditAppointmentPopup = ({ isOpen, onClose, appt, business }) => {

    const [selectedSlot, setSelectedSlot] = useState({
        // startTimeOnly: appt.startTimeOnly,
        // startTime: moment.tz(appt.startTime, appt.timeZone).format('YYYY-MM-DD hh:mm A'),
        // endTime: moment.tz(appt.endTime, appt.timeZone).format('YYYY-MM-DD hh:mm A'),
        startTimeOnly: "",
        startTime: "",
        endTime: "",
    })
    const [allServiceProviders, setAllServiceProviders] = useState(() => {

        const sp = business.services.find((service) => service.id === appt.service.id).businesses
        return sp
    })

    const [apptInfo, setApptInfo] = useState({
        date: moment(appt.startTime).format("YYYY-MM-DD"),
        service: appt.service.id,
        serviceProvider: appt.subBusiness.id,
        children: appt.children,
        adults: appt.adults,
        startTimeOnly: appt.startTimeOnly,
        startTime: appt.startTime,
        timeZone: appt.timeZone,
        endTime: appt.endTime
    })
    const SHADOW_APPTINFO = useRef({
        date: moment(appt.startTime).format("YYYY-MM-DD"),
        service: appt.service.id,
        serviceProvider: appt.subBusiness.id,
        children: appt.children,
        adults: appt.adults,
        startTimeOnly: appt.startTimeOnly,
        startTime: appt.startTime,
        timeZone: appt.timeZone,
        endTime: appt.endTime
    })
    const [loader, setLoader] = useState(false)
    const [editMode, setEditMode] = useState(false)

    const [onUpdate, { loading }] = useMutation(UPDATE_APPOINTMENT);
    const dispatch = useDispatch()

    const isAllowToEditAppointment = !(
        String(appt.status).toUpperCase() === 'PASSED' ||
        String(appt.status).toUpperCase() === 'CANCELLED' ||
        String(appt.status).toUpperCase() === 'COMPLETED' ||
        String(appt.status).toUpperCase() === 'MISSED' ||
        String(appt.status).toUpperCase() === 'CHECKEDIN' ||
        String(appt.status).toUpperCase() === 'CHECKEDINBYBUSINESS' ||
        String(appt.status).toUpperCase() === 'CHECKED_IN_BY_BUSINESS' ||
        String(appt.status).toUpperCase() === 'SERVING_NOW'
    );
    const isAllowComment = !(
        String(appt.status).toUpperCase() === "PASSED" ||
        String(appt.status).toUpperCase() === "CANCELLED" ||
        String(appt.status).toUpperCase() === "COMPLETED"
    );
    const isCancelled = String(appt.status).toUpperCase() === 'CANCELLED';
    const isAllowToEdit = !business.canUserUpdateAppointment
    const isGuestsAllowed = business.maxAllowedGuests > 0;

    const currentSlot = { startTimeOnly: appt.startTimeOnly, startTime: appt.startTime, endTime: appt.endTime, subBusiness: appt.subBusiness };

    const handleEditMode = () => {
        setEditMode((prev) => !prev)
    }

    const handleCancel = () => {
        setEditMode((prev) => !prev)
        setApptInfo({
            date: moment(appt.startTime).format("YYYY-MM-DD"),
            service: appt.service.id,
            serviceProvider: appt.subBusiness.id,
            children: appt.children,
            adults: appt.adults,
            startTimeOnly: appt.startTimeOnly,
            startTime: appt.startTime,
            timeZone: appt.timeZone,
            endTime: appt.endTime
        })
    }


    const handleAppointmentInfoChange = (e) => {
        let { name, value } = e.target
        if (name === "service") {
            const all_SP = business.services.find((service) => service.id === parseInt(value))?.businesses
            if (all_SP) {
                if (all_SP.length > 0) {

                    setAllServiceProviders(all_SP)
                    setApptInfo((prev) => ({
                        ...prev,
                        serviceProvider: all_SP[0].id
                    }))
                }
                else {
                    setAllServiceProviders([])
                    setApptInfo((prev) => ({
                        ...prev,
                        serviceProvider: ""
                    }))
                }
            }


            else {
                setAllServiceProviders([])
                setApptInfo((prev) => ({
                    ...prev,
                    serviceProvider: ""
                }))
            }
            setApptInfo((prev) => ({
                ...prev,
                [name]: value
            }))
        }
        else if (name === "apptchildren") {
            setApptInfo((prev) => ({
                ...prev,
                children: value
            }))
        }
        else {
            setApptInfo((prev) => ({
                ...prev,
                [name]: value
            }))
        }

    }

    const handleSlotChange = (slot) => {
        setSelectedSlot({
            startTimeOnly: slot.startTimeOnly,
            startTime: moment(slot.startTime).utc().format('YYYY-MM-DD hh:mm A'),
            endTime: moment(slot.endTime).utc().format('YYYY-MM-DD hh:mm A'),
        })
    }

    const handleUpdate = async () => {
        const { service, serviceProvider, businessId, timeZone, children, adults, } = apptInfo;
        if (selectedSlot.startTime === "") {
            toast.success("Please select an appointment slot")
            return
        }

        try {
            setLoader(true)
            const response = await onUpdate({
                variables: {
                    appointmentInput: {
                        id: appt.id,
                        businessId: business.id,
                        timeZone,
                        adults: parseInt(adults),
                        children: parseInt(children),
                        serviceId: parseInt(service),
                        subBusinessId: serviceProvider === 0 ? parseInt(businessId) : parseInt(serviceProvider),
                        startTime: selectedSlot.startTime,
                        endTime: selectedSlot.endTime,
                    },
                },
            })

            if (response.data.updateUserAppointment.isSaved === false) {
                toast.error(response.data.updateUserAppointment.errorMessage)
            }
            else {
                dispatch(updateAppt({ apptId: response.data.updateUserAppointment.appointment.id, appt: response.data.updateUserAppointment.appointment }))
                dispatch(updateNotificationagain())
                toast.success("Appointment updated successfully")
                onClose()

            }
            setLoader(false)
        } catch (error) {
            setLoader(false)
            console.log("Errrrror", error);
        }


    }

    const isDisabled = compareOBJ(apptInfo, SHADOW_APPTINFO.current)

        ;
    return (
        <Modal active={isOpen} clipped>
            <MetaTags title={business?.name} description={business?.description} />
            <Modal.Background />
            <Modal.Card>
                <Modal.Card.Head className={'is-flex is-justify-content-space-between'}>
                    <Title size='5' className='m-0'>
                        Edit Appointment
                        <span className='tag is-black is-small ml-3'>  <u>  <i>APPT- {appt.id}</i>  </u>   </span>
                        <span className={`tag is-light ml-3`} style={{ background: `rgba(${getStatusColor(appt?.status)}, 0.1)`, color: `rgb(${getStatusColor(appt?.status)})`, }}   >{getStatusName(appt?.status)}  </span>
                    </Title>
                    <Button size='small' className='button-icon' onClick={onClose}>
                        <Icon name='close' className='is-right' />
                    </Button>
                </Modal.Card.Head>
                <Modal.Card.Body>
                    <div className='row'>
                        {!isAllowToEditAppointment && (<div className='col-lg-12'><small className='has-text-danger'>You can't edit this appointment.</small> </div>)}
                        <div className='col-lg-12 mb-4'>
                            <Field>
                                <Control>
                                    <Label>Date</Label>
                                    <DateInput name='date' readOnly={isCancelled || isAllowToEdit} disabled={!editMode} value={apptInfo.date} onChange={handleAppointmentInfoChange} min={moment().format("YYYY-MM-DD")} />
                                </Control>
                            </Field>
                        </div>
                        <div className='col-lg-6  mb-4'>
                            <Field>
                                <Control>
                                    <Label>Service</Label>
                                    <div className='select is-fullwidth'>
                                        <select name='service' readOnly={isCancelled || isAllowToEdit} disabled={!editMode} value={apptInfo.service} onChange={handleAppointmentInfoChange}  >
                                            <option value={''}>Select Service</option>
                                            {business.services.map(({ id, name }) => (<option key={id} value={id}> {name}   </option>))}
                                        </select>
                                    </div>
                                </Control>
                            </Field>
                        </div>
                        {allServiceProviders.length > 0 && (
                            <div className='col-lg-6  mb-4'>
                                <Field>
                                    <Control>
                                        <Label>Service Provider</Label>
                                        <div className='select is-fullwidth'>
                                            <select name='serviceProvider' readOnly={isCancelled || isAllowToEdit} disabled={!editMode} value={apptInfo.serviceProvider} onChange={handleAppointmentInfoChange}  >
                                                <option value={'0'}>Select Service Provider</option>
                                                {allServiceProviders.map((sp) => <option value={sp.id} key={sp.id}>{sp.name}</option>)}
                                            </select>
                                        </div>
                                    </Control>
                                </Field>
                            </div>
                        )}

                        {isGuestsAllowed && (
                            <div className='col-lg-6  mb-4'>
                                <Field>
                                    <Label size='medium'>Additional Guests</Label>
                                    <div className='select is-fullwidth'>
                                        <select name='adults' readOnly={isCancelled || isAllowToEdit} disabled={!editMode} value={parseInt(apptInfo.adults)} onChange={handleAppointmentInfoChange}   >
                                            <option value={0}>No of Guests</option>  {[...Array(business.maxAllowedGuests)].map((_, index) => (<option key={`guest-${index}`} value={index + 1}>   {index + 1}  </option>))}
                                        </select>
                                    </div>
                                </Field>
                            </div>
                        )}
                        <div className='col-lg-6  mb-4'>
                            {business.isChildrenAllowed ? (
                                <Field>
                                    <Label size='medium'>Children in your party?</Label>
                                    <div className='control'>
                                        <label className='radio'>
                                            <input type='radio' name='apptchildren' value={1} readOnly={isCancelled || isAllowToEdit} disabled={!editMode} checked={parseInt(apptInfo.children)} onChange={handleAppointmentInfoChange} className='mr-2' />
                                            Yes
                                        </label>
                                        <label className='radio'>
                                            <input type='radio' name='apptchildren' value={0} readOnly={isCancelled || isAllowToEdit} disabled={!editMode} checked={!parseInt(apptInfo.children)} onChange={handleAppointmentInfoChange} className='mr-2' />
                                            No
                                        </label>
                                    </div>
                                </Field>
                            ) : (
                                <Field>  <Title size='6'> Adults only  </Title> </Field>
                            )}
                        </div>
                        <div className='col-lg-12  mb-4'>
                            {/* {apptInfo.service === "" && <Paragraph alignment='centered' weight='bold' color='grey-light'>  Select a service to see available time slots  </Paragraph>}
                            {apptInfo.serviceProvider === "0" && <Paragraph alignment='centered' weight='bold' color='grey-light'>  Select a service provider to see available time slots  </Paragraph>} */}
                            {editMode ? (
                                <AppointmentSlots apptInfo={apptInfo} selectedSlot={selectedSlot} currentSlot={currentSlot} onChangeSlot={handleSlotChange} />
                            ) : (
                                <Field>
                                    <Control>
                                        <Label>Appointment Time</Label>
                                        <span className="tag is-info is-small is-disabled">
                                            {moment(apptInfo?.startTime).format("hh:mm A")}
                                        </span>
                                    </Control>
                                </Field>
                            )}

                        </div>
                        <div className='col-lg-12' style={{ padding: "16px 12px", borderBottom: "1px solid #bfbfbf", marginBottom: "10px" }}>
                            {editMode ? (
                                <>
                                    <Buttons size='small'>
                                        {!isCancelled && business.canUserUpdateAppointment ? <Spin spinning={loader}>    <Button color='info' onClick={handleUpdate} disabled={isDisabled && !selectedSlot.startTime} >Update</Button>   </Spin> : null}
                                        <Button onClick={handleCancel}>Cancel</Button>
                                    </Buttons>
                                </>

                            ) : (
                                <Buttons size='small'>
                                    {!isCancelled && business.canUserUpdateAppointment ? <Button color='info' onClick={handleEditMode}><span><Icon name="pencil" /> &nbsp; Edit   </span> </Button> : null}
                                </Buttons>
                            )
                            }
                        </div>
                        <div className='col-lg-12  mb-4'>
                            <AppointmentComments comments={appt.comments} apptId={appt.id} business={business} isAllowComment={isAllowComment} />
                        </div>
                    </div>
                </Modal.Card.Body>
                {/* <Modal.Card.Foot className='has-background-white'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <Spin spinning={loader}>
                                <Buttons size='small'>
                                    {!isCancelled && business.canUserUpdateAppointment ? <Button color='info' onClick={handleUpdate}   >   Update  </Button> : null}
                                </Buttons>
                            </Spin>
                        </div>
                    </div>
                </Modal.Card.Foot> */}
            </Modal.Card>
        </Modal>
    )
}

export default EditAppointmentPopup

const MetaTags = ({ title, description }) => {
    return (
        <Helmet>
            <title>{title} | Appointment</title>
            <meta name='description' content={description} />
            <meta name='keywords' content='Appointments, Online Appointments, SafelyQ Appointment, Queueing System, Le Bernardin Appointment, Dallas City Hall' />
        </Helmet>
    );
};