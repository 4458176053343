import React from 'react';

const Instruction = ({ preCheckinInstructions }) => {
  return (
    <div className="container">
      <section className="section py-2 pl-1 pr-2">
        <div className="container">
          <div className="card px-0 pt-3">
            <div className="card-content pb-0 pt-3">
              <div className="control">
                <textarea
                  className="textarea is-hovered is-small pt-4 is-size-6"
                  readOnly
                  placeholder=""
                  value={preCheckinInstructions[0]}
                ></textarea>
                <span className="instruction is-size-6">Instruction</span>
              </div>
              <div className="columns is-mobile is-centered my-2">
                <div className="column is-8-tablet is-12-mobile ">
                  <div className="control">
                    <div className="columns is-mobile">
                      <div className="column is-6 is-flex is-justify-content-center">
                        <label className="radio">
                          <input type="radio" name="answer" />
                          <span className="is-size-6"> Accept </span>
                        </label>
                      </div>
                      <div className="column is-6 is-flex is-justify-content-center">
                        <label className="radio">
                          <input type="radio" name="answer" />
                          <span className="is-size-6"> Do Not Accept </span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Instruction;
