import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import StarRatings from 'react-star-ratings';
import { useSearchDispatch } from '@safelyq/search-feature';
import { Box, Icon } from '@safelyq/bulma-ui-library';

const emptyThumbnail = '/images/image-not-found.png';

const Card = styled(Box).attrs(() => ({
  className: 'mr-2 pb-3',
}))`
  width: 320px;
  border: 1px solid #80808029;
`;

const Image = styled.img`
  height: 100% !important;
  width: 100% !important;
  border-radius: 8px;
`;

export const BusinessCard = ({ business, ...rest }) => {
  const dispatch = useSearchDispatch();

  let { id, name, description, picture, city, isFavorite, serviceRatings } =
    business || {};

  const { isLoggedIn, key } = rest;

  const isNoAnyPictureFound = !picture || !picture.path;

  const isTextMakeSubstring = String(description).length > 15;

  if (isNoAnyPictureFound) picture = { path: emptyThumbnail };

  const isNameLong = String(name).length > 18;

  const nameSubStringSize = isLoggedIn ? 18 : 20;

  const descriptionSubStringSize = isLoggedIn ? 16 : 20;

  const handleFavoriteRequest = (e) => {
    e.preventDefault();
    let payload = {
      businessId: id,
      isFavorite: !isFavorite,
      sendRequest: true,
    };
    dispatch({ type: 'SET_FAVORITE_REQUEST', payload });
  };

  return (
    <Link to={`/business/${id}`} key={key}>
      <Card>
        <article className='media'>
          <figure className='media-left'>
            <p className='image is-64x64'>
              <Image src={picture?.path} alt={name} />
            </p>
          </figure>
          <div className='media-content'>
            <div className='content'>
              <p>
                <strong>
                  {isNameLong
                    ? name.substring(0, nameSubStringSize) + '...'
                    : name}
                </strong>
                <br />
                {isTextMakeSubstring
                  ? description.substring(0, descriptionSubStringSize) + '...'
                  : description}
                <br />
                <small>
                  <i className='fa fa-map-marker has-text-info mr-2' />
                  {city}
                </small>
              </p>
            </div>
          </div>
          {isLoggedIn && (
            <div className='media-right'>
              <Icon
                name={isFavorite ? 'heart' : 'heart-o'}
                color='info'
                data-tip={isFavorite ? 'Remove Favorite' : 'Favorite'}
                onClick={handleFavoriteRequest}
              />
            </div>
          )}
        </article>
        <div
          className='is-flex is-justify-content-flex-end'
          style={{ color: 'rgb(26 17 186)' }}
        >
          <StarRatings
            rating={serviceRatings?.value}
            starRatedColor='rgb(26 17 186)'
            starSpacing='3px'
            starDimension='15px'
          />
          &nbsp;[{serviceRatings?.reviewsCount}]
        </div>
      </Card>
      <ReactTooltip />
    </Link>
  );
};
