import React, { Fragment, useEffect, useState, useRef } from 'react';
import { isEqual, isEmpty } from 'lodash';
import { Helmet } from 'react-helmet';
import moment from 'moment';
import styled from 'styled-components';
import Dropzone from 'react-dropzone';
import Linkify from 'linkify-react';
import EmojiPicker from 'emoji-picker-react';
import { ClipLoader } from 'react-spinners';
import {
  TextInput,
  Icon,
  Button,
  Title,
  Text,
  Subtitle,
} from '@safelyq/bulma-ui-library';
import { Loader } from '@safelyq/base-feature';
import { AttachmentsPanel } from '../AttachmentPanel';
import { useProfile } from 'hooks/useProfile';
import { useData, useFile } from './hooks';
import { useMessageState, useMessageDispatch } from '../../index';
import { Link } from 'react-router-dom';

const ScrollList = styled.ul.attrs(() => ({}))`
  overflow: scroll;
  height: calc(79vh);
  width: 100%;
  background: #e5ddd5;
  padding-bottom: 73px;
  padding-top: 10px;
  display: flex;
  flex-direction: column-reverse;
`;

const HeroSection = styled.div.attrs(() => ({}))`
  max-height: calc(100vh);
  overflow: scroll;
  position: relative;
`;

const TopBar = styled.div.attrs(() => ({
  className: 'p-4',
}))`
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  background: rgba(0, 0, 0, 0.04);
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ListItem = styled.li.attrs(() => ({
  className: 'p-2 py-3',
}))`
  display: flex;
  flex-direction: ${({ sender }) => (sender ? 'row-reverse' : 'row')};
}
`;

const RecipientBadge = styled.div.attrs(() => ({
  className: 'p-1 px-3',
}))`
  background: #ffffff;
  width: fit-content;
  max-width: 600px;
  border-radius: 10px;
  > span {
    > a {
      color: blue !important;
      text-decoration: underline;
    }
  }
  & > a {
    background: rgba(0, 0, 0, 0.05);
    min-width: 300px;
    padding: 8px;
    border-radius: 10px;
    color: black;
  }
`;

const SenderBadge = styled.div.attrs(() => ({
  className: 'p-1 px-3  has-background-primary-light',
}))`
  width: fit-content;
  max-width: 600px;
  border-radius: 10px;
  > span {
    > a {
      color: blue !important;
      text-decoration: underline;
    }
  }
  & > a {
    background: rgba(0, 0, 0, 0.05);
    min-width: 300px;
    padding: 8px;
    border-radius: 10px;
    color: black;
  }
`;

const CouponBadge = styled.div.attrs(() => ({}))`
  background: #ffffff;
  width: fit-content;
  max-width: 600px;
  border-radius: 10px;
  > span {
    > a {
      color: blue !important;
      text-decoration: underline;
    }
  }
  & > a {
    background: #ffffff;
    min-width: 300px;
    border-radius: 10px;
    color: black;
    & > img {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
  }
`;

const AttachmentButton = styled.div`
  &:hover {
    cursor: pointer;
    color: blue !important;
  }
`;

const SendInputSection = styled.div.attrs(() => ({
  className: 'p-3 is-flex is-align-items-center',
}))`
  position: absolute;
  bottom: 0;
  width: 100%;
  background: #ededed;
`;

const MessageField = styled(TextInput).attrs(() => ({
  className: 'px-3',
}))`
  border: 0;
  border-radius: 20px;
`;

const SendButton = styled(Icon).attrs(() => ({
  className: 'has-text-grey',
}))`
  &:hover {
    cursor: pointer;
    color: blue !important;
  }
`;

const StyledDropzone = styled.div.attrs(() => ({}))`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: white;
  padding: 20px;
`;

const StyledDropBox = styled.div`
  border: 5px dashed #ededed;
  height: 87%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 23px;
  color: lightgrey;
  padding: 10px;
`;

const StyledDropImage = styled.img`
  max-width: 100%;
  max-height: 100%;
`;

const StyledIcon = styled(Icon)`
  font-size: 50px;
`;

export const MessageSection = () => {
  const {
    isLoading,
    onSendMessageLoading,
    onSendMessageToBusiness,
    onGetBusinessConversation,
  } = useData();

  const { onUpload, onRemove, ...fileParams } = useFile();

  const {
    profile: { sub },
  } = useProfile();

  const {
    selectedBusinessId,
    isReceivedSignal,
    businessConversation,
    recentConversation,
    business: {
      name: businessName,
      pictureUrl,
      messaging: {
        messagingAllowed,
        consumerResponseAllowed,
        consumerAttachmentsAllowed,
      } = {},
    } = {},
  } = useMessageState();

  const dispatch = useMessageDispatch();

  const [messageText, setMessageText] = useState('');

  const [isOpenEmojiModal, setOpenEmojiModal] = useState(false);

  const [isOpenDropZone, setOpenDropZone] = useState(false);

  const [isOpenAttachments, setOpenAttachmentsModal] = useState(false);

  const [messageSend, setMessageSend] = useState(false);

  const refetch = new CustomEvent("refetchConsumer");

  document.dispatchEvent(refetch);

  let element = document.getElementById("scroll-list");

  const { scrollHeight } = element || {}

  useEffect(() => {
    if(element) element.scrollTop = scrollHeight
  }, [selectedBusinessId, scrollHeight]);

  useEffect(() => {
    const businessId = selectedBusinessId;

    if (isReceivedSignal && businessId)
      onGetBusinessConversation({ variables: { businessId } });
  }, [isReceivedSignal, selectedBusinessId]);

  useEffect(() => {
    const businessId = selectedBusinessId;

    if (businessId) onGetBusinessConversation({ variables: { businessId } });
  }, [selectedBusinessId, messageSend]);

  const isRecipient = (userId) => !isEqual(sub, userId);

  const handleMessageTextChange = ({ target: { value } }) =>
    setMessageText(value);

  const handleEmojiChange = (_, emojiObject) =>
    setMessageText(messageText + emojiObject.emoji);

  const handleEmojiModalOpen = () => setOpenEmojiModal(!isOpenEmojiModal);

  const handleMessageSend = () => {
    let attachment = fileParams.path
      ? {
          fileName: fileParams.name,
          base64String: fileParams.path,
          contentType: fileParams.type,
        }
      : null;

    let variables = {
      businessId: selectedBusinessId,
      messageText,
      attachment,
    };

    if (
      !attachment &&
      !String(fileParams.type).includes('image') &&
      !String(fileParams.type).includes('word') &&
      !String(fileParams.type).includes('pdf') &&
      !String(fileParams.type).includes('spreadsheet') &&
      !String(fileParams.type).includes('presentation') &&
      !String(fileParams.type).includes('plain')
    )
      delete variables.attachment;

    if (isEmpty(messageText) && !variables.attachment) return;

    if (
      messageText ||
      String(fileParams.type).includes('image') ||
      String(fileParams.type).includes('video') ||
      String(fileParams.type).includes('audio') ||
      String(fileParams.type).includes('pdf') ||
      String(fileParams.type).includes('word') ||
      String(fileParams.type).includes('spreadsheet') ||
      String(fileParams.type).includes('presentation') ||
      String(fileParams.type).includes('plain') ||
      String(fileParams.type).includes('application')
    ) {
      onSendMessageToBusiness({
        variables,
      }).then(() => {
        setMessageText('');
        onRemove();
        setOpenDropZone(false);
        if (attachment) {
          // Call get user conversation
          setMessageSend(!messageSend);
        }
      });
    } else {
      alert('File type not supported');
      setMessageText('');
      onRemove();
      setOpenDropZone(false);
    }
  };

  const handleKeyEnter = (e) => {
    if (onSendMessageLoading) return;
    if (e.key === 'Enter') handleMessageSend();
  };

  const handleBack = () => {
    dispatch({ type: 'SET_SELECTED_BUSINESS', payload: { id: null } });
  };

  const handleOpenDropZone = () => setOpenDropZone(!isOpenDropZone);

  if (!selectedBusinessId) return null;

  if (isLoading) return <Loader isFullScreen />;

  return (
    <HeroSection>
      <MetaTags title={businessName} />
      <TopBar>
        <article className='media is-flex is-justify-content-center is-align-items-center'>
          <Icon
            name='arrow-left'
            className='mr-2 is-hidden-tablet'
            onClick={handleBack}
          />
          <figure className='media-left'>
            {pictureUrl ? (
              <p className='image is-32x32'>
                <img className='image is-32x32 is-rounded' src={pictureUrl} />
              </p>
            ) : (
              <p className='image is-32x32 has-background-grey-light rounded is-flex is-justify-content-center is-align-items-center'>
                {String(businessName).charAt(0)}
              </p>
            )}
          </figure>
          <div className='media-content'>
            <div className='content'>
              <Link style={{color: "#4a4a4a"}} to={`/business/${selectedBusinessId}`}><b> {businessName}</b></Link>
            </div>
          </div>
        </article>
        <div className='dropdown is-hoverable is-right ml-3'>
          <div className='dropdown-trigger'>
            <Button
              className='icon-button action-btn'
              aria-haspopup='true'
              aria-controls='actions-dropdown'
              style={{
                background: 'rgba(0,0,0,0.04)'
              }}
            >
              <Icon name='ellipsis-v' color='info' scale='lg' />
            </Button>
          </div>
          <div className='dropdown-menu' id='actions-dropdown' role='menu'>
            <div className='dropdown-content'>
              <div
                className='dropdown-item'
                onClick={() => setOpenAttachmentsModal(true)}
              >
                <Icon name='paperclip' color='success' />
                Attachments
              </div>
            </div>
          </div>
        </div>
      </TopBar>
      <ScrollList id='scroll-list'>
        {recentConversation.map(
          ({ business: { id: businessId } }) =>
            businessId == selectedBusinessId &&
            isEmpty(businessConversation) && (
              <div>
                <Loader isFullScreen />
              </div>
            )
        )}

        {businessConversation.map(
          ({
            user: { id },
            messageType,
            message: {
              user: { id: userId },
              commentText,
              createdTime,
            },
            attachment,
          }) => (
            <Fragment>
              {!isEqual(userId, id) ? (
                <ListItem>
                  <div className='is-flex is-flex-direction-column is-align-items-flex-start'>
                    <MessageContent
                      role='RECEIVER'
                      messageContent={commentText}
                      messageType={messageType}
                      attachment={attachment}
                    />
                    <small className='is-size-7 has-text-grey mt-1'>
                      {moment.utc(createdTime).local().calendar()}{' '}
                    </small>
                  </div>
                </ListItem>
              ) : (
                <ListItem sender={true}>
                  <div className='is-flex is-flex-direction-column is-align-items-flex-end'>
                    <MessageContent
                      role='SENDER'
                      messageContent={commentText}
                      messageType={messageType}
                      attachment={attachment}
                    />
                    <small className='is-size-7 has-text-grey mt-1'>
                      {moment.utc(createdTime).local().calendar()}
                    </small>
                  </div>
                </ListItem>
              )}
            </Fragment>
          )
        )}
      </ScrollList>
      {isOpenDropZone && (
        <AttachmentDropZoneBox
          isLoading={onSendMessageLoading}
          {...fileParams}
          onUpload={onUpload}
        />
      )}
      <SendInputSection>
        {messagingAllowed && consumerResponseAllowed ? (
          <Fragment>
            <div
              className={`dropdown is-up ${
                isOpenEmojiModal ? 'is-active' : ''
              }`}
            >
              <div className='dropdown-trigger'>
                <Button
                  className='button-icon is-small mr-3'
                  aria-haspopup='true'
                  aria-controls='emoji-dropdown'
                  onClick={handleEmojiModalOpen}
                >
                  <Icon name='smile-o' style={{ fontSize: 18 }}></Icon>
                </Button>
              </div>
              <div
                className='dropdown-menu p-0'
                id='emoji-dropdown'
                role='menu'
              >
                <div className='dropdown-content p-0'>
                  <div className='dropdown-item p-0'>
                    <Button
                      className='button-icon is-small is-right'
                      onClick={handleEmojiModalOpen}
                    >
                      <Icon name='times' style={{ fontSize: 18 }}></Icon>
                    </Button>
                    <EmojiPicker onEmojiClick={handleEmojiChange} />
                  </div>
                </div>
              </div>
            </div>
            <MessageField
              value={messageText}
              onKeyDown={handleKeyEnter}
              onChange={handleMessageTextChange}
              placeholder='Type a message here'
            />
            {consumerAttachmentsAllowed && (
              <AttachmentButton className='ml-3' onClick={handleOpenDropZone}>
                {isOpenDropZone ? (
                  <Icon name='times' onClick={onRemove} />
                ) : (
                  <Icon name='paperclip' />
                )}
              </AttachmentButton>
            )}
            {onSendMessageLoading ? (
              <ClipLoader size='20' />
            ) : (
              <SendButton
                name='paper-plane'
                className='ml-3'
                onClick={handleMessageSend}
              />
            )}
          </Fragment>
        ) : (
          <Text size='6' color='danger'>
            Buisness doesn't allow to send response back
          </Text>
        )}
      </SendInputSection>
      <AttachmentsPanel
        businessConversation={businessConversation}
        isOpen={isOpenAttachments}
        onClose={() => setOpenAttachmentsModal(false)}
      />
    </HeroSection>
  );
}; 

const MessageContent = ({ attachment, messageContent, messageType, role }) => {
  const { contentType, publicUrl, fileName } = attachment || {
    contentType: null,
    publicUrl: null,
    fileName: '',
  };

  let contentDate = messageContent.split('-').pop();
  let title = messageContent.replace('-' + contentDate, ' ');

  if (isEqual(role, 'RECEIVER')) {
    if (messageType == 'BusinessCoupon') {
      return (
        <RecipientBadge>
          <a
            href={publicUrl}
            target='__blank'
            style={{ background: 'transparent' }}
          >
            <Title size='6' className='m-0'>
              We have a deal you might like
            </Title>
            <Subtitle size='6' className='mt-1 mb-2'>
              {title}
            </Subtitle>
            {publicUrl ? (
              <img
                src={publicUrl}
                width={180}
                height={190}
                className='has-text-centered'
              />
            ) : (
              <Subtitle size='6' className='mt-1 mb-2'>
                {contentDate}
              </Subtitle>
            )}
          </a>
        </RecipientBadge>
      );
    } else if (messageType == 'Attachment') {
      if (String(contentType).includes('image')) {
        return (
          <RecipientBadge>
            <a
              href={publicUrl}
              target='__blank'
              style={{ background: 'transparent' }}
            >
              <img src={publicUrl} width={150} height={130} />
            </a>
            {messageContent && <div className='mt-1'>{messageContent}</div>}
          </RecipientBadge>
        );
      }
      if (String(contentType).includes('video')) {
        return (
          <RecipientBadge>
            <a
              href={publicUrl}
              target='__blank'
              className='is-flex is-align-items-center is-justify-content-space-between py-4'
            >
              <span>
                <Icon name='file-video-o' />
                <span className='ml-2'>{fileName}</span>
              </span>
              <Icon name='arrow-circle-o-down' />
            </a>
            {messageContent && <div className='mt-1'>{messageContent}</div>}
          </RecipientBadge>
        );
      }
      if (String(contentType).includes('audio')) {
        return (
          <RecipientBadge>
            <a
              href={publicUrl}
              target='__blank'
              className='is-flex is-align-items-center is-justify-content-space-between py-4'
            >
              <span>
                <Icon name='file-audio-o' />
                <span className='ml-2'>{fileName}</span>
              </span>
              <Icon name='arrow-circle-o-down' />
            </a>
            {messageContent && <div className='mt-1'>{messageContent}</div>}
          </RecipientBadge>
        );
      }
      if (String(contentType).includes('application')) {
        return (
          <RecipientBadge>
            <a
              href={publicUrl}
              target='__blank'
              className='is-flex is-align-items-center is-justify-content-space-between py-4'
            >
              <span>
                <Icon name='fa-solid fa-file-o' />
                <span className='ml-2'>{fileName}</span>
              </span>
              <Icon name='arrow-circle-o-down' />
            </a>
            {messageContent && <div className='mt-1'>{messageContent}</div>}
          </RecipientBadge>
        );
      }

      if (String(contentType).includes('pdf')) {
        return (
          <RecipientBadge>
            <a
              href={publicUrl}
              target='__blank'
              className='is-flex is-align-items-center is-justify-content-space-between py-4'
            >
              <span>
                <Icon name='file-pdf-o' />
                <span className='ml-2'>{fileName}</span>
              </span>
              <Icon name='arrow-circle-o-down' />
            </a>
            {messageContent && <div className='mt-1'>{messageContent}</div>}
          </RecipientBadge>
        );
      }
      if (String(contentType).includes('spreadsheet')) {
        return (
          <RecipientBadge>
            <a
              href={publicUrl}
              target='__blank'
              className='is-flex is-align-items-center is-justify-content-space-between py-4'
            >
              <span>
                <Icon name='file-excel-o' />
                <span className='ml-2'>{fileName}</span>
              </span>
              <Icon name='arrow-circle-o-down' />
            </a>
            {messageContent && <div className='mt-1'>{messageContent}</div>}
          </RecipientBadge>
        );
      }
      if (String(contentType).includes('presentation')) {
        return (
          <RecipientBadge>
            <a
              href={publicUrl}
              target='__blank'
              className='is-flex is-align-items-center is-justify-content-space-between py-4'
            >
              <span>
                <Icon name='file-powerpoint-o' />
                <span className='ml-2'>{fileName}</span>
              </span>
              <Icon name='arrow-circle-o-down' />
            </a>
            {messageContent && <div className='mt-1'>{messageContent}</div>}
          </RecipientBadge>
        );
      }

      if (String(contentType).includes('plain')) {
        return (
          <RecipientBadge>
            <a
              href={publicUrl}
              target='__blank'
              className='is-flex is-align-items-center is-justify-content-space-between py-4'
            >
              <span>
                <Icon name='file-text-o' />
                <span className='ml-2'>{fileName}</span>
              </span>
              <Icon name='arrow-circle-o-down' />
            </a>
            {messageContent && <div className='mt-1'>{messageContent}</div>}
          </RecipientBadge>
        );
      }
      if (
        String(contentType).includes('docx') ||
        String(contentType).includes('documents')
      ) {
        return (
          <RecipientBadge>
            <a
              href={publicUrl}
              target='__blank'
              className='is-flex is-align-items-center is-justify-content-space-between py-4'
            >
              <span>
                <Icon name='file-word-o' />
                <span className='ml-2'>{fileName}</span>
              </span>
              <Icon name='arrow-circle-o-down' />
            </a>
            {messageContent && <div className='mt-1'>{messageContent}</div>}
          </RecipientBadge>
        );
      }
      else {
        return (
          <SenderBadge>
            <Linkify
              tagName='span'
              options={{ attributes: { target: '__blank' } }}
            >
              {messageContent}
            </Linkify>
          </SenderBadge>
        );
      }
    } else {
      return (
        <RecipientBadge>
          <Linkify
            tagName='span'
            options={{ attributes: { target: '__blank' } }}
          >
            {messageContent}
          </Linkify>
        </RecipientBadge>
      );
    }
  } else {
    if (messageType == 'BusinessCoupon') {
      return (
        <SenderBadge>
          <a
            href={publicUrl}
            target='__blank'
            style={{ background: 'transparent' }}
          >
            <Title size='6' className='m-0'>
              We have a deal you might like
            </Title>
            <Subtitle size='6' className='mt-1 mb-2'>
              {title}
            </Subtitle>
            {publicUrl ? (
              <img
                src={publicUrl}
                width={150}
                height={130}
                className='has-text-centered'
              />
            ) : (
              <Subtitle size='6' className='mt-1 mb-2'>
                {contentDate}
              </Subtitle>
            )}
          </a>
        </SenderBadge>
      );
    } else if (messageType == 'Attachment') {
      if (String(contentType).includes('image')) {
        return (
          <SenderBadge>
            <a
              href={publicUrl}
              target='__blank'
              style={{ background: 'transparent' }}
            >
              <img src={publicUrl} width={150} height={130} />
            </a>
            {messageContent && <div className='mt-1'>{messageContent}</div>}
          </SenderBadge>
        );
      }
      if (String(contentType).includes('video')) {
        return (
          <SenderBadge>
            <a
              href={publicUrl}
              target='__blank'
              className='is-flex is-align-items-center is-justify-content-space-between py-4'
            >
              <span>
                <Icon name='file-video-o' />
                <span className='ml-2'>{fileName}</span>
              </span>
              <Icon name='arrow-circle-o-down' />
            </a>
            {messageContent && <div className='mt-1'>{messageContent}</div>}
          </SenderBadge>
        );
      }
      if (String(contentType).includes('audio')) {
        return (
          <SenderBadge>
            <a
              href={publicUrl}
              target='__blank'
              className='is-flex is-align-items-center is-justify-content-space-between py-4'
            >
              <span>
                <Icon name='file-audio-o' />
                <span className='ml-2'>{fileName}</span>
              </span>
              <Icon name='arrow-circle-o-down' />
            </a>
            {messageContent && <div className='mt-1'>{messageContent}</div>}
          </SenderBadge>
        );
      }
      if (String(contentType).includes('application')) {
        return (
          <SenderBadge>
            <a
              href={publicUrl}
              target='__blank'
              className='is-flex is-align-items-center is-justify-content-space-between py-4'
            >
              <span>
                <Icon name='fa-solid fa-file-o' />
                <span className='ml-2'>{fileName}</span>
              </span>
              <Icon name='arrow-circle-o-down' />
            </a>
            {messageContent && <div className='mt-1'>{messageContent}</div>}
          </SenderBadge>
        );
      }
      if (String(contentType).includes('pdf')) {
        return (
          <SenderBadge>
            <a
              href={publicUrl}
              target='__blank'
              className='is-flex is-align-items-center is-justify-content-space-between py-4'
            >
              <span>
                <Icon name='file-pdf-o' />
                <span className='ml-2'>{fileName}</span>
              </span>
              <Icon name='arrow-circle-o-down' />
            </a>
            {messageContent && <div className='mt-1'>{messageContent}</div>}
          </SenderBadge>
        );
      }
      if (String(contentType).includes('presentation')) {
        return (
          <SenderBadge>
            <a
              href={publicUrl}
              target='__blank'
              className='is-flex is-align-items-center is-justify-content-space-between py-4'
            >
              <span>
                <Icon name='file-powerpoint-o' />
                <span className='ml-2'>{fileName}</span>
              </span>
              <Icon name='arrow-circle-o-down' />
            </a>
            {messageContent && <div className='mt-1'>{messageContent}</div>}
          </SenderBadge>
        );
      }
      if (String(contentType).includes('plain')) {
        return (
          <SenderBadge>
            <a
              href={publicUrl}
              target='__blank'
              className='is-flex is-align-items-center is-justify-content-space-between py-4'
            >
              <span>
                <Icon name='file-text-o' />
                <span className='ml-2'>{fileName}</span>
              </span>
              <Icon name='arrow-circle-o-down' />
            </a>
            {messageContent && <div className='mt-1'>{messageContent}</div>}
          </SenderBadge>
        );
      }
      if (String(contentType).includes('spreadsheet')) {
        return (
          <SenderBadge>
            <a
              href={publicUrl}
              target='__blank'
              className='is-flex is-align-items-center is-justify-content-space-between py-4'
            >
              <span>
                <Icon name='file-excel-o' />
                <span className='ml-2'>{fileName}</span>
              </span>
              <Icon name='arrow-circle-o-down' />
            </a>
            {messageContent && <div className='mt-1'>{messageContent}</div>}
          </SenderBadge>
        );
      }
      if (
        String(contentType).includes('.docx') ||
        String(contentType).includes('.document')
      ) {
        return (
          <SenderBadge>
            <a
              href={publicUrl}
              target='__blank'
              className='is-flex is-align-items-center is-justify-content-space-between py-4'
            >
              <span>
                <Icon name='file-word-o' />
                <span className='ml-2'>{fileName}</span>
              </span>
              <Icon name='arrow-circle-o-down' />
            </a>
            {messageContent && <div className='mt-1'>{messageContent}</div>}
          </SenderBadge>
        );
      }
      else {
        return (
          <SenderBadge>
            <Linkify
              tagName='span'
              options={{ attributes: { target: '__blank' } }}
            >
              {messageContent}
            </Linkify>
          </SenderBadge>
        );
      }
    } else {
      return (
        <SenderBadge>
          <Linkify
            tagName='span'
            options={{ attributes: { target: '__blank' } }}
          >
            {messageContent}
          </Linkify>
        </SenderBadge>
      );
    }
  }
};

const AttachmentDropZoneBox = ({ onUpload, path, type, isLoading }) => {
  return (
    <Dropzone multiple={false} onDrop={onUpload}>
      {({ getRootProps, getInputProps }) => (
        <StyledDropzone {...getRootProps({ className: 'dropzone' })}>
          <input {...getInputProps()} />
          <StyledDropBox>
            {!isEmpty(path) ? (
              String(type).includes('image') ? (
                <StyledDropImage src={`data:${type};base64,` + path} />
              ) : String(type).includes('video') ? (
                <StyledIcon name='file-video-o' />
              ) : String(type).includes('audio') ? (
                <StyledIcon name='file-audio-o' />
              ) : String(type).includes('application') ? (
                <StyledIcon name='fa-solid fa-file-o' />
              ) : String(type).includes('word') ? (
                <StyledIcon name='file-word-o' />
              ) : String(type).includes('application') ? (
                <StyledIcon name='fa-solid fa-file' />
              ) : String(type).includes('pdf') ? (
                <StyledIcon name='file-pdf-o' />
              ) : String(type).includes('plain') ? (
                <StyledIcon name='file-text-o' />
              ) : String(type).includes('spreadsheet') ? (
                <StyledIcon name='file-excel-o' />
              ) : String(type).includes('presentation') ? (
                <StyledIcon name='file-powerpoint-o' />
              ) : (
                <p>File not supported</p>
              )
            ) : (
              <p>Drag file here</p>
            )}
          </StyledDropBox>
        </StyledDropzone>
      )}
    </Dropzone>
  );
};

const MetaTags = ({ title }) => {
  return (
    <Helmet>
      <title>Messages - {title}</title>
      <meta
        name='description'
        content='SafelyQ, a US based company, offers a comprehensive solution for organizations and agencies to safely queue people into any kind of facilities that are brought up, from mega sites with multiple entry points to drive throughs.'
      />
      <meta
        name='keywords'
        content='Appointments, Online Appointments, SafelyQ Appointment, Queueing System, Le Bernardin Appointment, Dallas City Hall'
      />
    </Helmet>
  );
};
