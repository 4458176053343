import React, { useState, useEffect, Fragment } from 'react';
import { Columns } from 'react-bulma-components';
import * as _ from 'lodash';
import Constants from 'constants/index';
import styled from 'styled-components';
const AvailableSlots = styled.section`
  height: auto;
  display: flex;
  overflow-y: auto;
`;

const AvailableSlot = styled.section`
  min-width: auto;
  height: auto;
`;

/* eslint jsx-a11y/anchor-is-valid: 0 */

const GroupByTimeView = ({
  serviceSubLevels = [],
  availableSlots = [],
  selectedSlot,
  userMessage,
  onChange,
}) => {
  const [pageSize, setPageSize] = useState(
    Constants.DEFAULT_SUBLEVEL_SLOTS_PAGE_SIZE
  );
  const [totalRecords, setTotalRecords] = useState(0);
  const [groupByTimeSlots, setGroupByTimeSlots] = useState([]);

  const loadMoreRecords = () => {
    setPageSize(pageSize + Constants.DEFAULT_SUBLEVEL_SLOTS_PAGE_SIZE);
  };

  const resetPageSize = () => {
    setPageSize(Constants.DEFAULT_SUBLEVEL_SLOTS_PAGE_SIZE);
  };

  useEffect(() => {}, [
    serviceSubLevels,
    availableSlots,
    selectedSlot,
    userMessage,
  ]);

  useEffect(() => {
    var result = _.chain(availableSlots)
      .groupBy('startTimeOnly')
      .map((slots, startTime) => {
        const subLevels = slots.map((slot, index) => {
          var currentSubLevel = serviceSubLevels.find((ssl) => {
            return ssl.id === slot.subBusiness.id;
          });

          return {
            ...slot,
            name: currentSubLevel?.name,
          };
        });

        return {
          startTime: startTime,
          subLevels: subLevels,
        };
      })
      .value();
    setTotalRecords(result.length);
    setGroupByTimeSlots(result.slice(0, pageSize));
  }, [availableSlots, pageSize, serviceSubLevels]);

  return (
    <Columns className='is-mobile' key={`groupByTimeView`}>
      {groupByTimeSlots.map((slot, slotIndex) => {
        return (
          <Fragment key={`timeSlot_${slotIndex}`}>
            <Columns.Column size={12} className={`px-1 is-size-5 py-0 my-2`}>
              {' '}
              {/*${(slotIndex + 1 > pageSize) && 'is-hidden'}*/}
              {slot.startTime}
            </Columns.Column>
            <AvailableSlots className='pb-1'>
              {slot.subLevels.map((subLevel, subLevelIndex) => (
                <AvailableSlot
                  key={`timeSlot_level_${subLevelIndex}_${subLevel.subBusiness.id}`}
                >
                  <Columns.Column className={`px-1 py-0 my-2  is-multiline `}>
                    <div
                      key={`timeSlot_level_tab_${subLevel.id}`}
                      className={`timeTab-slot false is-size-6-tablet p-1 ${
                        selectedSlot &&
                        subLevel.startTimeOnly === selectedSlot.startTimeOnly &&
                        subLevel.subBusiness.id === selectedSlot.subBusiness.id
                          ? 'selected'
                          : ''
                      }`}
                      onClick={() => {
                        onChange(subLevel);
                      }}
                    >
                      {subLevel.name}
                    </div>
                  </Columns.Column>
                </AvailableSlot>
              ))}
            </AvailableSlots>
          </Fragment>
        );
      })}
      {availableSlots.length === 0 && (
        <Columns.Column size={12} className='px-1 is-size-5 py-0 my-2'>
          <div
            className={`timeTab-slot has-text-weight-bold is-size-6 has-text-grey-lighter`}
          >
            {userMessage}
          </div>
        </Columns.Column>
      )}

      {availableSlots.length > Constants.DEFAULT_SUBLEVEL_SLOTS_PAGE_SIZE && (
        <>
          <Columns.Column
            key={`showMore_GroupByTimeView`}
            size={12}
            className={`px-1 is-size-5 py-0 my-2 ${
              pageSize >= totalRecords && 'is-hidden'
            }`}
          >
            <div className={`buttons mt-4 is-centered`}>
              <a
                className='link is-size-6 is-info'
                href='/#'
                onClick={(e) => {
                  e.preventDefault();
                  loadMoreRecords();
                }}
              >
                More
                <span className='icon is-size-6'>
                  <i className='fa fa-angle-down'></i>
                </span>
              </a>
            </div>
          </Columns.Column>

          <Columns.Column
            key={`showLess_GroupByTimeView`}
            size={12}
            className={`px-1 is-size-5 py-0 my-2 ${
              pageSize < totalRecords && 'is-hidden'
            }`}
          >
            <div className={`buttons mt-4 is-centered`}>
              <a
                className='link is-size-6 is-info'
                href='/#'
                onClick={(e) => {
                  e.preventDefault();
                  resetPageSize();
                }}
              >
                Less
                <span className='icon is-size-6'>
                  <i className='fa fa-angle-up'></i>
                </span>
              </a>
            </div>
          </Columns.Column>
        </>
      )}
    </Columns>
  );
};

export default GroupByTimeView;
