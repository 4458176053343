import React, { useState, useEffect } from 'react';
import {
  Box,
  Icon,
  Title,
  Subtitle,
  Field,
  Label,
  Control,
  TextInput,
  Button,
  Columns,
  Column,
  List,
  Content,
} from '@safelyq/bulma-ui-library';
import { useData } from './hooks';
import { useProfile } from 'hooks/useProfile';

export const DeleteAccount = ({ ...props }) => {
  const [email, setEmail] = useState('');
  const { user } = props;
  const { logout } = useProfile();
  
  useEffect(() => {
    setEmail(user?.email)
  },[user]);
  
  const { isLoading, deleteMyAccount } = useData();
  const handleDeleteAccount = (e) => {
    const isConfirm = window.confirm(
      'Deleting your account will remove all your data from SafelyQ. Are you sure you want to detete this account ?'
    );
    if (isConfirm) {
      deleteMyAccount();
      logout();
    }
    e.preventDefault();
  };

  return (
    <Box>
      <Title size='5'>
        <Icon name='trash' /> Delete Account
      </Title>
      <Subtitle size='6' color='danger'>  Deleting your account will remove all your data from SafelyQ. </Subtitle>
      <hr />
      <Columns>
        <Column size='6'>
          <Field>
            <Label>Email</Label>
            <Control>
              <TextInput name='Email Address' type='text' value={email} disabled />
            </Control>
          </Field>
        </Column>
      </Columns>
      <Content>
        <List type='unordered'>
          <List.Item>
            All access, along with appointments, messages, notes, and reviews (not ratings) will be permanently deleted.   </List.Item>
          <br></br>
          <List.Item>
            All access to businesses associated with your admin account will be  permanently deleted. </List.Item>
          <br></br>
          <List.Item>Would you like to continue?</List.Item>
        </List>
      </Content>
      <br></br>

      <Button color='danger' loading={isLoading} disabled={isLoading} onClick={handleDeleteAccount}  >  Delete Account  </Button>
    </Box>
  );
};
