import React, { useState } from 'react'
import { GiEmptyHourglass } from "react-icons/gi";
import { useHistory, Link } from 'react-router-dom';
import StarRatings from 'react-star-ratings';
import { TiHeartFullOutline } from "react-icons/ti";
import { toast } from 'react-toastify';
import ClipLoader from 'react-spinners/ClipLoader';
import { Button, Icon } from '@safelyq/bulma-ui-library';
import styled from 'styled-components';

const FavouriteBusinesses = ({ messages, loading, favBusinesses, setFavBusinesses, handleAddRemoveFavorite, isLoadingFavs }) => {
    const history = useHistory();
    const emptyThumbnail = 'https://s3.amazonaws.com/speedsport-news/speedsport-news/wp-content/uploads/2018/07/01082232/image-not-found.png';

    const handleFavouriteBusinesses = async (business) => {
        // Check if the business already exists in favBusinesses

        const isAlreadyFavourite = favBusinesses.find(fav => fav.business?.id === business.id);

        if (isAlreadyFavourite) {
            // Remove the business if it's already a favorite
            const result = await handleAddRemoveFavorite({ businessId: business?.id, isFavorite: false });
            if (result) {
                toast.success("Removed the business from the Favourites");
                setFavBusinesses(favBusinesses.filter(fav => fav?.business.id !== business.id));
            } else {
                toast.error('Failed to add/remove from favorites');
            }
        } else {
            // Add the business if it's not already a favorite
            const result = await handleAddRemoveFavorite({ businessId: business?.id, isFavorite: true });
            if (result) {
                toast.success("Added the business to the Favourites");
                setFavBusinesses([...favBusinesses, { business: business }]);
            } else {
                toast.error('Failed to add/remove from favorites');
            }
        }
    };

    const FavouriteButton = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  background: white;
  width: 35px;
  height: 35px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px;
`;

    return (
        isLoadingFavs
            ?
            <div style={{ height: "100%" }} className='is-flex is-justify-content-center is-align-items-center'>
                <ClipLoader size={28} />
            </div>
            :
            <>
                {/* Desktop view */}
                < div className="columns-custom is-hidden-mobile" style={{ height: "94%", display: "flex" }}>
                    <p className='is-size-5 has-text-weight-semibold has-text-centered mx-auto'>Favourite Businesses</p>
                    {
                        favBusinesses?.length === 0
                            ?
                            <div className='is-flex is-justify-content-center' style={{ width: "100%" }}>
                                <GiEmptyHourglass color='gray' fontSize={26} />
                                <p className='ml-2 has-text-weight-semibold has-text-grey-dark'>No Businesses Found</p>
                            </div>
                            :
                            <>
                                {
                                    favBusinesses.map(({ business }) => (
                                        <div
                                            key={business.id} className="column is-full-widescreen is-full-fullhd is-full-desktop is-full-tablet is-half-mobile"
                                        >
                                            {/* <div key={business.id} className="column-custom"> */}
                                            <div className="card-custom-businesses is-relative">
                                                <FavouriteButton className='has-text-info is-clickable'>
                                                    <Icon onClick={() => handleFavouriteBusinesses(business)} name='heart' collection='fa' />
                                                    {/* <Icon style={{ cursor: "pointer" }} onClick={() => handleFavouriteBusinesses(business)} name={favorite ? 'heart' : 'heart-o'} collection='fa' /> */}
                                                </FavouriteButton>
                                                {/* <TiHeartFullOutline className='is-clickable' onClick={() => handleFavouriteBusinesses(business)} color='#ff0003' size={28} style={{ position: "absolute", top: 20, right: 20 }} /> */}

                                                <img
                                                    src={business?.picture?.path || emptyThumbnail}
                                                    alt={business.name}
                                                    onError={(e) => e.target.src = emptyThumbnail}
                                                    className="card-image-custom is-clickable"
                                                    onClick={() => history.push(`/business/${business.id}`)}
                                                />
                                                <div className='p-2 businessdetailContainer'>
                                                    <div className='is-flex is-justify-content-space-between my-2'>
                                                        {/* <div className='row g-0'> */}
                                                        <div className=''>
                                                            {/* <div className='col-lg-9'> */}
                                                            <Link to={{ pathname: `/business/${business.id}` }} className='is-size-6 has-text-weight-bold' title={business?.name}    >
                                                                {business?.name?.length <= 15 ? business?.name : `${business?.name.substring(0, 15)}...`}
                                                            </Link>
                                                        </div>
                                                        <div className='is-flex'>
                                                            {/* <div className='col-lg-2 has-text-right'> */}
                                                            <i className="fa fa-quote-left favorite_icon mx-1 is-size-6-mobile is-size-6" />
                                                            {/* <i onClick={() => handleFavourite(business.id, business.isFavorite)} className={`${favorite ? 'fa fa-heart' : 'fa fa-heart-o'} favorite_icon mx-1`} /> */}
                                                            <i className={`fa fa-envelope favorite_icon mx-1 is-size-6`} />
                                                            <i className={`fa fa-comments favorite_icon mx-1 is-size-6`} onClick={() => history.push(`messages/${business?.id}`)} />
                                                        </div>
                                                    </div>
                                                    <div className='row g-0'>
                                                        <div className='col-lg-12'>
                                                            <p className="business_description"> {business.description ? (`${business.description.substring(0, 40)}...`) : ("No Description")}</p>
                                                        </div>
                                                    </div>
                                                    {business.address1 && (
                                                        <div className='row g-0'>
                                                            <div className='col-lg-12'>
                                                                <i className='fa fa-map-marker' style={{ color: '#037ffc' }}  ></i>
                                                                <span className='subtitle is-7'>  {business.city}  </span>
                                                            </div>
                                                        </div>
                                                    )}

                                                    <div className='row g-0'>
                                                        <div className='col-lg-12'>
                                                            <div style={{ color: 'rgb(26 17 186)' }}>
                                                                <StarRatings rating={business?.serviceRatings?.value} starRatedColor='rgb(26 17 186)' starSpacing='3px' starDimension='15px' />
                                                                &nbsp;[  {business?.serviceRatings?.reviewsCount}]
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    ))}
                            </>
                    }
                </div>

                {/* Mobile View */}
                <div className="is-hidden-desktop mt-4" style={{ marginBottom: "75px", overflowX: "auto", whiteSpace: "nowrap", width: "100%", scrollbarWidth: "thin" }}>
                    <p className='is-size-5 has-text-weight-semibold has-text-centered mx-auto mb-3'>Favourite Businesses</p>
                    {
                        favBusinesses?.length === 0
                            ?
                            <div className='is-flex is-justify-content-center' style={{ width: "100%" }}>
                                <GiEmptyHourglass color='gray' fontSize={26} />
                                <p className='ml-2 mb-5 has-text-weight-semibold has-text-grey-dark'>No Businesses Found</p>
                            </div>

                            :
                            favBusinesses?.map(({ business }, i) => {
                                return (
                                    <div
                                        className='card-custom-businesses is-relative'
                                        key={business.id}
                                        style={{ display: "inline-flex", flexDirection: "column", flex: "0 0 33.33%", marginRight: "20px", marginBottom: "10px", width: "140px" }}>
                                        <TiHeartFullOutline
                                            onClick={() => handleFavouriteBusinesses(business)}
                                            className='is-clickable'
                                            color='red'
                                            size={20} style={{ position: "absolute", top: 10, right: 10 }} />
                                        <img className='businessImage' src={business?.logoUrl || emptyThumbnail} alt={business.name} style={{ width: "100%", objectFit: "cover" }} />
                                        {/* <div className='p-2 '> */}
                                        <div className='px-2 my-2 has-text-left'>
                                            <Link to={{ pathname: `/business/${business.id}` }} className='is-size-7 has-text-weight-bold has-text-black' title={business?.name}    >
                                                {business?.name?.length <= 15 ? business?.name : `${business?.name.substring(0, 15)}...`}
                                            </Link>
                                            <div className='is-flex mt-1'>
                                                <i className="fa fa-quote-left favorite_icon mx-1 is-size-7" />
                                                {/* <i onClick={() => handleFavourite(business.id, business.isFavorite)} className={`${favorite ? 'fa fa-heart' : 'fa fa-heart-o'} is-size-7 favorite_icon mx-2`} /> */}
                                                <i className={`fa fa-envelope favorite_icon mx-2 is-size-7`} />
                                                <i className={`fa fa-comments favorite_icon mx-2 is-size-7`} onClick={() => history.push(`messages/${business?.id}`)} />
                                            </div>
                                        </div>
                                        <div className='px-2 row g-0'>
                                            <div className='col-lg-12'>
                                                <p className="business_description is-size-7"> {business.description ? (`${business.description.substring(0, 20)}...`) : ("No Description")}</p>
                                            </div>
                                        </div>
                                        {business.address1 && (
                                            <div className='row px-2 g-0'>
                                                <div className='col-lg-12'>
                                                    <i className='fa fa-map-marker' style={{ color: '#037ffc' }}  ></i>
                                                    <span className='subtitle is-7'>  {business.city}  </span>
                                                </div>
                                            </div>
                                        )}

                                        <div className='row px-2 g-0'>
                                            <div className='col-lg-12'>
                                                <div style={{ color: 'rgb(26 17 186)' }}>
                                                    <StarRatings rating={business?.serviceRatings?.value} starRatedColor='rgb(26 17 186)' starSpacing='3px' starDimension='15px' />
                                                    &nbsp;[  {business?.serviceRatings?.reviewsCount}]
                                                </div>
                                            </div>
                                        </div>

                                        {/* </div> */}
                                    </div>
                                )
                            })
                    }
                </div>
            </>
    )
}

export default FavouriteBusinesses