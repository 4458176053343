import React, { useState } from 'react'
import styled from 'styled-components';

import { Title, Card, Columns, Column, Paragraph, List, Container, Box, Content, Icon, } from '@safelyq/bulma-ui-library';
import { useQuery } from '@apollo/client';
import { Get_Terms_Condition_Page_Info } from './request';
const StyledBox = styled.div`
  width: fit-content;
  padding: 10px 20px;
  background: white;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
`;
const TermsAndConditionsPage = () => {
    const [data, setData] = useState(null)
    useQuery(Get_Terms_Condition_Page_Info,
        {
            fetchPolicy: 'no-cache',
            onCompleted: (res) => {
                setData(res.getTermsConditionPageInfo.termsCondition)
            }
        })
    return (
        <Container>
            <StyledBox>
                <Title size='5' className='has-text-centered'>{data?.title}</Title>
            </StyledBox>
            <Box>
                <Column size='full'>
                    <Paragraph> {data?.dateUpdated} {data?.pg1}</Paragraph>
                </Column>
                <Column size='full'>
                    <Paragraph>
                        <strong>  {data?.boldPg}</strong>
                    </Paragraph>
                </Column>
                <Column size='full'>
                    <Paragraph> {data?.pg2} </Paragraph>
                </Column>
                <Column size='full'>
                    <ol>
                        {
                            data?.list?.map((da) => {
                                return (
                                    <li className='mb-4'>
                                        <strong>{da?.title}</strong>
                                        <p>{da?.pg1}</p>
                                        <ol className='ml-5'>
                                            {
                                                da?.list?.map((sublist) => {
                                                    return (
                                                        <li>
                                                            <b>{sublist?.title}</b> {sublist?.pg1}
                                                            {sublist?.listIntro}
                                                            <ol className='ml-5'>
                                                                {sublist?.options?.map((opt) => {
                                                                    return (<li>{opt}</li>)
                                                                })}
                                                            </ol>
                                                        </li>
                                                    )
                                                })
                                            }
                                        </ol>
                                        <ol className='ml-5'>
                                            {da?.options?.map((opt) => {
                                                return (<li>{opt}</li>)
                                            })}
                                        </ol>
                                    </li>
                                )
                            })
                        }
                    </ol>
                    <p className='mb-5'>{data?.copyRight}</p>
                    <hr className='mb-5' />
                    <p><strong>{data?.additional?.title}</strong></p>
                    <p><strong className='mb-5'>{data?.additional?.dateUpdated}</strong></p>
                    <p className='mb-3'> {data?.additional?.pg1}</p>
                    <p> <b>{data?.additional?.boldPg}</b></p>
                    <p>{data?.additional?.pg2} </p>
                    <ol className='ml-5'>
                        {data?.additional?.list?.map((lt) => {
                            return (
                                <li>
                                    <strong>{lt?.title}</strong>
                                    <ol className='ml-5'>
                                        {lt?.list?.map((subli) => {
                                            return (
                                                <li>
                                                    <p>  {subli?.listIntro}</p>
                                                    <b>{subli?.title}</b>
                                                    <p>  {subli?.pg1}</p>
                                                    <p>  {subli?.pg2}</p>
                                                    <p>  {subli?.pg3}</p>
                                                    <p>  {subli?.pg4}</p>
                                                    <ol className='ml-5'>
                                                        {subli?.options?.map((ssubli) => {
                                                            return (
                                                                <li><p>{ssubli}</p></li>
                                                            )
                                                        })}
                                                    </ol>
                                                </li>
                                            )
                                        })}
                                    </ol>
                                </li>
                            )
                        })}

                    </ol>
                    <p>{data?.additional?.copyRight}</p>
                </Column>

            </Box>
            <br />
        </Container>
    )
}

export default TermsAndConditionsPage
