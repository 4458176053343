import React, { useState } from 'react';
import { useParams } from 'react-router';
import styled from 'styled-components';
import {
  Container,
  Box,
  Columns,
  Column,
  Control,
  Field,
  Label,
  Title,
  TextInput,
  DateInput,
  Buttons,
  Button,
} from '@safelyq/bulma-ui-library';
import { useData } from './hooks';

const Underline = styled.div`
  width: 100%;
  height: 50px;
  font-size: 28px;
  position: relative;
  &:after {
    content: '';
    width: 100%;
    border-bottom: 1px #ccc solid;
    position: absolute;
    left: 0;
    top: 50%;
    z-index: 1;
  }
  h2 {
    width: auto;
    display: inline-block;
    z-index: 3;
    position: relative;
    margin-left: 2rem;
  }
`;

export const CreatePatient = () => {
  const [values, setValues] = useState({});

  const { isLoading, onSavePatient } = useData();

  const { businessId } = useParams();

  const handleChangeValues = ({ target: { name, value } }) =>
    setValues({ ...values, [name]: value });

  const handleSubmit = (e) => {
    e.preventDefault();

    onSavePatient({
      variables: {
        patientInput: {
          businessId: parseInt(businessId),
          ...values,
        },
      },
    }).then(() => handleClear());
  };

  const handleClear = () => {
    setValues({
      address1: '',
      address2: '',
      birthDate: '',
      cityVillage: '',
      country: '',
      familyName: '',
      gender: '',
      givenName: '',
      middleName: '',
      postalCode: '',
      stateProvince: '',
    });
  };

  return (
    <Container>
      <Box>
        <form onSubmit={handleSubmit}>
          <Columns multiline>
            <Column size='full'>
              <Underline>
                <Title size='2'>
                  <span className='tag is-info'>New Patient</span>
                </Title>
              </Underline>
            </Column>
            <Column size='4'>
              <Control>
                <Label>
                  Given Name <span className='has-text-danger'>*</span>
                </Label>
                <Field>
                  <TextInput
                    name={'givenName'}
                    value={values.givenName}
                    required
                    onChange={handleChangeValues}
                  />
                </Field>
              </Control>
            </Column>
            <Column size='4'>
              <Control>
                <Label>
                  Middle Name <span className='has-text-danger'>*</span>
                </Label>
                <Field>
                  <TextInput
                    name={'middleName'}
                    value={values.middleName}
                    required
                    onChange={handleChangeValues}
                  />
                </Field>
              </Control>
            </Column>
            <Column size='4' />
            <Column size='4'>
              <Control>
                <Label>Family Name</Label>
                <Field>
                  <TextInput
                    name={'familyName'}
                    value={values.familyName}
                    onChange={handleChangeValues}
                  />
                </Field>
              </Control>
            </Column>
            <Column size='4'>
              <Control>
                <Label>
                  Gender <span className='has-text-danger'>*</span>
                </Label>
                <div className='select is-fullwidth'>
                  <select
                    name={'gender'}
                    value={values.gender}
                    required
                    onChange={handleChangeValues}
                  >
                    <option value={''} selected disabled>
                      Select Gender
                    </option>
                    <option value='M'>Male</option>
                    <option value='F'>Female</option>
                  </select>
                </div>
              </Control>
            </Column>
            <Column size='4' />
            <Column size='4'>
              <Control>
                <Label>
                  Date of Birth <span className='has-text-danger'>*</span>
                </Label>
                <Field>
                  <DateInput
                    name={'birthDate'}
                    value={values.birthDate}
                    required
                    onChange={handleChangeValues}
                  />
                </Field>
              </Control>
            </Column>
            <Column size='4' />
            <Column size='4' />
            <Column size='full'>
              <Underline>
                <Title size='2'>
                  <span className='tag is-info'>Address Information</span>
                </Title>
              </Underline>
            </Column>
            <Column size='4'>
              <Control>
                <Label>
                  City / Village <span className='has-text-danger'>*</span>
                </Label>
                <Field>
                  <TextInput
                    name={'cityVillage'}
                    value={values.cityVillage}
                    required
                    onChange={handleChangeValues}
                  />
                </Field>
              </Control>
            </Column>
            <Column size='4'>
              <Control>
                <Label>State</Label>
                <Field>
                  <TextInput
                    name={'stateProvince'}
                    value={values.stateProvince}
                    onChange={handleChangeValues}
                  />
                </Field>
              </Control>
            </Column>
            <Column size='4' />
            <Column size='4'>
              <Control>
                <Label>Country</Label>
                <Field>
                  <TextInput
                    name={'country'}
                    value={values.country}
                    onChange={handleChangeValues}
                  />
                </Field>
              </Control>
            </Column>
            <Column size='4'>
              <Control>
                <Label>Postal Code</Label>
                <Field>
                  <input
                    type='number'
                    name={'postalCode'}
                    value={values.postalCode}
                    onChange={handleChangeValues}
                    className='input'
                  />
                </Field>
              </Control>
            </Column>
            <Column size='4' />
            <Column size='4'>
              <Control>
                <Label>Address 1</Label>
                <Field>
                  <textarea
                    name={'address1'}
                    value={values.address1}
                    rows='3'
                    onChange={handleChangeValues}
                    className='textarea'
                  />
                </Field>
              </Control>
            </Column>
            <Column size='4'>
              <Control>
                <Label>Address 2</Label>
                <Field>
                  <textarea
                    name={'address2'}
                    value={values.address2}
                    rows='3'
                    onChange={handleChangeValues}
                    className='textarea'
                  />
                </Field>
              </Control>
            </Column>
            <Column size='4' />
            <Column size='full'>
              <Buttons>
                <Button
                  color='info'
                  loading={isLoading}
                  disabled={isLoading}
                  className='ml-auto'
                >
                  Save
                </Button>
                <Button type='button' outlined onClick={handleClear}>
                  Cancel
                </Button>
              </Buttons>
            </Column>
          </Columns>
        </form>
      </Box>
      <br />
    </Container>
  );
};
