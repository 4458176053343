import React, { useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import {
  GET_USER_FAVORITE_BUSINESS_COUPONS,
  FAVORITES_BUSINESS_COUPON,
} from 'components/Common/Queries/index';

export const useData = () => {
  const {
    loading: isLoading,
    data,
    refetch,
  } = useQuery(GET_USER_FAVORITE_BUSINESS_COUPONS, {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
  });

  const [onFavoritesBusinessCoupon] = useMutation(FAVORITES_BUSINESS_COUPON, {
    onCompleted: () => refetch(),
    onError: () => toast.error('Somethings went wrong', {}),
  });

  const { getUserFavoritesBusinessCoupon } = data || {};

  return {
    isLoading,
    data: getUserFavoritesBusinessCoupon,
    onFavoritesBusinessCoupon,
  };
};
