import { gql } from '@apollo/client';

export const SEARCH_BUSINESSES = gql`
  query SearchBusinesses($searchBusinessInput: SearchBusinessInput) {
    searchBusinesses(searchBusinessInput: $searchBusinessInput) {
      id
      name
      description
      city
      isFavorite
      picture {
        id
        path
      }
      businessCategory {
        description
      }
      serviceRatings {
        ratingType
        value
        reviewsCount
        modifiedTime
      }
    }
  }
`;

export const ADD_REMOVE_FAVORITE = gql`
  mutation AddRemoveFavorite($businessId: Int, $isFavorite: Boolean) {
    addRemoveFromFavorites(businessId: $businessId, isFavorite: $isFavorite) {
      id
    }
  }
`;
