import React, { useEffect } from 'react';
import * as Foo from 'services/prefix-holder';
import Logo from '../../assets/triangle-logo.png';

const LoginAccount = () => {
  useEffect(() => {
    const redirectUrl = `${Foo.prefixHolder.Gateway}/account/login`

    window.location.href = redirectUrl;

    return () => {};
  }, []);

  return (
    <div className="hero is-fullheight">
      <div className="hero-body is-paddingless">
        <div className="container has-text-centered">
          <div className="column is-4 is-offset-4">
            <div className="box p-6">
              <a href="/">
                <img src={Logo} alt="logo" width={120} height={120}/>
              </a>
              <h5>You are being redirected. Please wait.</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginAccount;