import React from 'react';
import { Link } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import { Box, List, Menu, Icon } from '@safelyq/bulma-ui-library';

export const ContactSkeleton = () => {
  return (
    <Box className='p-2' bordered>
      <Menu>
        <Menu.List>
          <List.Item>
            <Link to='#'>
              <Skeleton />
            </Link>
          </List.Item>
          <List.Item>
            <Link to='#' className='icon-text'>
              <Skeleton />
            </Link>
          </List.Item>
          <List.Item>
            <a className='icon-text' target='__blank'>
              <Skeleton />
            </a>
          </List.Item>
        </Menu.List>
      </Menu>
    </Box>
  );
};
