import React, { useCallback, useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { isUndefined } from 'lodash';
import { useParams } from 'react-router-dom';
import { useCheckClaim } from 'hooks/useCheckClaim';
import PhoneInput from 'react-phone-input-2';
import Logo from '../../assets/triangle-logo.png';
import { APIPaths } from 'services/api-provider';

const ClaimAccount = () => {
  let { params } = useParams();

  const [journey, setJourney] = useState();
  const [phoneNumber, setPhoneNumber] = useState();
  const [email, setEmail] = useState();
  const [password1, setPassword1] = useState();
  const [password2, setPassword2] = useState();

  useEffect(() => {
    if (params !== null && params !== undefined) {
      setJourney('email');
      setEmail(params);
    }
    else {
      setJourney('phone-number');
      setEmail();
    }
  }, [params]);

  const saveClaim = useCallback((value) => {
    window.localStorage.setItem('claim', JSON.stringify(value));
  }, []);
  const unsaveClaim = useCallback(() => {
    window.localStorage.removeItem('claim');
  }, []);
  const showError = useCallback((error) => toast.error(error.message), []);

  const isPhoneNumberJourney = journey === 'phone-number';
  const isEmailJourney = journey === 'email';
  const isPhoneNumberValid = PhoneNumberValidator.test(phoneNumber);
  const isEmailValid = EmailValidator.test(email);
  const isPassword1Valid =
    validatePassword(password1) && password1 === password2;
  const isPassword2Valid =
    validatePassword(password2) && password1 === password2;
  const canCheck = isPhoneNumberJourney
    ? isPhoneNumberValid && isEmailValid && isPassword1Valid && isPassword2Valid
    : isEmailJourney
      ? isEmailValid && isPassword1Valid && isPassword2Valid
      : false;
  const cannotCheck = !canCheck;

  useEffect(() => {
    unsaveClaim();

    return () => { };
  }, [unsaveClaim]);

  const { isLoading, check } = useCheckClaim(
    {
      journey,
      phoneNumber,
      email,
      password: password1 || password2,
    },
    saveClaim,
    showError
  );

  return (
    <>
      <Card>
        <JourneySelection
          journey={journey}
          setJourney={setJourney}
        />
        {isPhoneNumberJourney && (
          <PhoneNumberEntry
            valid={isPhoneNumberValid}
            value={phoneNumber}
            onChange={(value) => setPhoneNumber(value)}
          />
        )}
        {isPhoneNumberJourney && (
          <HelpTip
            isPhoneNumberJourney={isPhoneNumberJourney}
            isEmailJourney={isEmailJourney}
          />
        )}
        <EmailEntry
          valid={isEmailValid}
          value={email}
          placeholder="Email"
          onChange={(e) => setEmail(e.target.value)}
          showHelp={isEmailJourney}
        />
        {isEmailJourney && (
          <HelpTip
            isPhoneNumberJourney={isPhoneNumberJourney}
            isEmailJourney={isEmailJourney}
          />
        )}
        <PasswordTip />
        <PasswordEntry
          valid={isPassword1Valid}
          value={password1}
          placeholder="Password"
          onChange={(e) => setPassword1(e.target.value)}
        />
        <PasswordEntry
          valid={isPassword2Valid}
          value={password2}
          placeholder="Password (again)"
          onChange={(e) => setPassword2(e.target.value)}
        />
        <CheckButton
          isLoading={isLoading}
          disabled={cannotCheck}
          check={check}
        />
      </Card>
      <ToastContainer />
    </>
  );
};

const PhoneNumberValidator = /([+(\d]{1})(([\d+() -.]){5,16})([+(\d]{1})/;

const EmailValidator = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;

const validatePassword = (password) => {
  return (password || '').length > 5;
};

const JourneySelection = ({ journey, setJourney }) => {
  const isPhoneNumberJourney = journey === 'phone-number';
  const isEmailJourney = journey === 'email';

  return (
    <>
      <div className="field"  >
        <input
          className="is-checkradio is-medium"
          id="use-phone-number"
          type="radio"
          name="journey"
          value="phone-number"
          checked={isPhoneNumberJourney}
          onChange={(e) => setJourney(e.target.value)}
        />
        <label htmlFor="use-phone-number" style={{ textAlign: "left", display: "block", width: "100%" }}>
          Did you receive a text/Sms on your phone?
        </label>
      </div>
      <div className="field" >
        <input
          className="is-checkradio is-medium"
          id="use-email"
          type="radio"
          name="journey"
          value="email"
          checked={isEmailJourney}
          onChange={(e) => setJourney(e.target.value)}
        />
        <label htmlFor="use-email" style={{ textAlign: "left", display: "block", width: "100%" }}>Did you receive an email?</label>
      </div>
    </>
  );
};

const PhoneNumberEntry = ({ valid, name, value, onChange }) => {
  const dirty = !isUndefined(value);

  return (
    <div className="field">
      <div
        className={
          valid
            ? 'control has-icons-left has-icons-right'
            : 'control has-icons-left'
        }
      >
        <PhoneInput
          inputClass={dirty && !valid ? `is-danger` : ''}
          countryCodeEditable={false}
          name={name}
          value={value}
          country={'us'}
          onChange={onChange}
        />
        {valid && (
          <span className="icon is-medium is-right">
            <i className="fa fa-check" />
          </span>
        )}
        <p className={valid ? 'help is-info' : 'help is-danger'}>
          Enter the phone number on which you received our message
        </p>
      </div>
    </div>
  );
};

const EmailEntry = ({
  valid,
  name,
  value,
  placeholder,
  onChange,
  showHelp = true,
}) => {
  const styles = 'input is-medium';
  const dirty = !isUndefined(value);

  return (
    <div className="field">
      <div
        className={
          valid
            ? 'control has-icons-left has-icons-right'
            : 'control has-icons-left'
        }
      >
        <input
          className={dirty && !valid ? `${styles} is-danger` : styles}
          type="text"
          name={name}
          defaultValue={value}
          placeholder={placeholder}
          autoComplete="false"
          onChange={onChange}
        />
        <span className="icon is-medium is-left">
          <i className="fa fa-at" />
        </span>
        {valid && (
          <span className="icon is-medium is-right">
            <i className="fa fa-check" />
          </span>
        )}
        {showHelp && (
          <p className={valid ? 'help is-info' : 'help is-danger'}>
            Enter the email on which you received our message
          </p>
        )}
      </div>
    </div>
  );
};

const PasswordEntry = ({ valid, name, value, placeholder, onChange }) => {
  const styles = 'input is-medium';
  const dirty = !isUndefined(value);
  const [inputType, setInputType] = useState("password")
  const [eye, setEye] = useState("fa fa-eye-slash")
  const handleInputType = () => {
    if (inputType === "password") {
      setInputType("text");
      setEye("fa fa-eye")
    } else {
      setInputType("password");
      setEye("fa fa-eye-slash")
    }
  }
  return (
    <div className="field is-flex is-align-items-center" >
      <div
        className={
          valid
            ? 'control has-icons-left has-icons-right'
            : 'control has-icons-left'
        }
      >
        <input
          className={dirty && !valid ? `${styles} is-danger` : styles}
          type={inputType}
          name={name}
          defaultValue={value}
          placeholder={placeholder}
          autoComplete="false"
          onChange={onChange}
          style={{ paddingRight: " 2.5em" }}
        />
        <span className="icon is-medium is-left">
          <i className="fa fa-asterisk" />
        </span>
        {valid && (
          <span className="icon is-medium is-right">
            <i className="fa fa-check" />
          </span>
        )}
      </div>

      <span className="icon is-medium" onClick={handleInputType} style={{ fontSize: 24, cursor: "pointer", paddingLeft: 12 }}>
        <i className={eye} />
      </span>

    </div>
  );
};

const CheckButton = ({ disabled, isLoading, check }) => {
  const styles = 'button is-block is-primary is-outlined is-medium is-fullwidth my-5';

  return (
    <div className="field">
      <div className="control">
        <button
          className={isLoading ? `${styles} is-loading` : styles}
          disabled={isLoading || disabled}
          onClick={check}
        >
          Check claim
        </button>
      </div>
    </div>
  );
};

const HelpTip = ({ isEmailJourney, isPhoneNumberJourney }) => {
  return (
    <>
      <div className="field">
        <div className="control" style={{ textAlign: "center" }}>
          {isPhoneNumberJourney && (
            <p className="is-size-6">
              We will send a verification code to confirm you have access to this
              phone number.
            </p>
          )}
          {isEmailJourney && (
            <p className="is-size-6">
              We will send a verification code to confirm you have access to this
              email.
            </p>
          )}
        </div>
      </div>
      <div className="field">
        <p className="is-size-6">
          We also need the following additional information.
        </p>
      </div>
    </>
  );
};

const PasswordTip = () => {
  return (
    <div className="field">
      <p className="is-size-6">Choose a password for your account.</p>
    </div>
  );
};

function Card({ children }) {
  return (
    <div className="hero is-fullheight">
      <div className="hero-body is-paddingless">
        <div className="container has-text-centered">
          <div className="column is-4 is-offset-4">
            <div className="box p-6">
              <a href="/">
                <img src={Logo} alt="logo" width={120} height={120} />
              </a>
              <div className="title has-text-grey is-6 mt-4 mb-6">Claim your pre-created account</div>
              {children}
            </div>
            <p className="has-text-grey">
              <a href={`${APIPaths.Gateway}/Account/Register`}>Sign Up</a>
              &nbsp;·&nbsp;
              <a href={`${APIPaths.Gateway}/account/login`}>Sign in</a>
              &nbsp;·&nbsp;
              <a href={`/account/verify-email`}>Verify Email</a>
              &nbsp;·&nbsp;
              <a href={`/account/forgot-password`}>Forgot password</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ClaimAccount;
