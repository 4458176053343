import React, { useEffect, useState } from 'react';
import { useLocation } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import { APIPaths } from '../../services/api-provider';
import * as axios from 'axios';

/* eslint jsx-a11y/anchor-is-valid: 0 */

const ChangePasswordView = {
    PASSWORD_INPUT: 1,
    CONFIRMATION: 2,
};

const ChangePassword = () => {

    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [currentView, setCurrentView] = useState(ChangePasswordView.PASSWORD_INPUT);

    const handleResetPassword = () => {
        axios.default.post(`${APIPaths.Gateway}/api/identity/changepassword`, {
            oldPassword: oldPassword,
            newPassword: newPassword,
            confirmPassword: confirmPassword
        }).then((response) => {
            const data = response.data;
            if (!data.isSuccess) {
                toast.error(data.errors[0]);
                return;
            }
            setCurrentView(ChangePasswordView.CONFIRMATION);
        }).catch((error) => {
            toast.error('An error occurred in processing the request. Please try again later.');
        })
            .then(() => {

            });
    };

    useEffect(() => {
        return () => { };
    }, []);

    return (
        <section className='section'>
            <div className='container'>
                {currentView === ChangePasswordView.PASSWORD_INPUT && (
                    <React.Fragment>
                        <div className='column'>
                            <h1 className='title'>Change Password</h1>
                            <p className='subtitle'>Please enter your old and new passwrods to change it</p>
                        </div>

                        <div className='column is-one-third'>
                            <div className='field'>
                                <label className='label'>Old Password</label>
                                <div className='control'>
                                    <input
                                        className='input'
                                        type='password'
                                        placeholder='Old Password'
                                        value={oldPassword}
                                        onChange={(e) => setOldPassword(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className='column is-one-third'>
                            <div className='field'>
                                <label className='label'>New Password</label>
                                <div className='control'>
                                    <input
                                        className='input'
                                        type='password'
                                        placeholder='New Password'
                                        value={newPassword}
                                        onChange={(e) => setNewPassword(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className='column is-one-third'>
                            <div className='field'>
                                <label className='label'>Confirm Password</label>
                                <div className='control'>
                                    <input
                                        className='input'
                                        type='password'
                                        placeholder='Confirm Password'
                                        value={confirmPassword}
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>


                        <div className='column is-one-third'>
                            <div className='field is-grouped'>
                                <div className='control'>
                                    <button
                                        className='button is-link'
                                        onClick={(e) => {
                                            e.preventDefault();
                                            handleResetPassword();
                                        }}>
                                        Send Email
                                    </button>
                                </div>
                                <div className='control'>
                                    <button className='button is-link is-light'>Cancel</button>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                )}

                {currentView === ChangePasswordView.CONFIRMATION && (
                    <React.Fragment>
                        <div className='column'>
                            <h1 className='title'>Confirmation Email Sent</h1>
                            <p className='subtitle'>
                                Password email is sent to your email id. Please check your email.
                            </p>
                        </div>
                    </React.Fragment>
                )}
            </div>
            <ToastContainer />
        </section>
    );
};

export default ChangePassword;
