import React, { useState, useEffect } from 'react';
import BraintreeDropIn from './BraintreeDropIn';
import {
  Title,
  Container,
  Columns,
  Column,
  Button,
} from '@safelyq/bulma-ui-library';
import { useData } from './hooks';

export default function BraintreeTest() {
  const PRICE = 50;
  const onBraintreeRequestCompleted = () => {
    setShowBraintreeDropIn(false);
    setNumberOfProducts(1);
  };
  const {
    isSuccess,
    clientToken,
    invokingBraintreeRequest,
    invokeBraintreeTransactionRequest,
  } = useData(onBraintreeRequestCompleted);
  const [showBraintreeDropIn, setShowBraintreeDropIn] = useState(false);
  const [numberOfProducts, setNumberOfProducts] = useState(1);
  const onPaymentCompleted = (nonce) => {
    invokeBraintreeTransactionRequest({
      variables: {
        transactionRequest: {
          nonce: nonce,
          amount: PRICE,
        },
      },
    });
  };

  return (
    <Container className='py-5'>
      <Columns multiline>
        <Column size={12}>
          <Columns className={'productHeaderRow'}>
            <Column xs={4}>
              <Title size='6'>Product</Title>
            </Column>
            <Column xs={2} className={'text-center'}>
              <Title size='6'>Price</Title>
            </Column>
            <Column xs={2} className={'text-center'}>
              <Title size='6'>Quantity</Title>
            </Column>
            <Column xs={2} className={'text-center'}>
              <Title size='6'>Total</Title>
            </Column>
            <Column xs={2} />
          </Columns>
          <Columns className={'productInfoRow'}>
            <Column xs={4} className={'productInfoColumn'}>
              {'Foo product'}
            </Column>
            <Column xs={2} className={'productInfoColumnCenter'}>
              {`$ ${PRICE}`}
            </Column>
            <Column xs={2} className={'productInfoColumnCenter'}>
              <input
                placeholder='0'
                min={1}
                max={100}
                type='number'
                step='1'
                value={numberOfProducts}
                onChange={(e) => {
                  setNumberOfProducts(e.target.value);
                }}
              />
            </Column>
            <Column xs={2} className={'productInfoColumnCenter'}>
              {`$ ${numberOfProducts * PRICE}`}
            </Column>
            <Column xs={2} className={'productInfoColumnCenter'}>
              <Button
                color='info'
                size='small'
                onClick={() => {
                  setShowBraintreeDropIn(true);
                }}
                disabled={showBraintreeDropIn}
              >
                {'Go to Checkout'}
              </Button>
            </Column>
          </Columns>
        </Column>
        <Column size={12}>
          <BraintreeDropIn
            show={showBraintreeDropIn}
            clientToken={clientToken}
            onPaymentCompleted={onPaymentCompleted}
            invokingBraintreeRequest={invokingBraintreeRequest}
          />
        </Column>
      </Columns>
    </Container>
  );
}
