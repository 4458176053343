import { useQuery, useMutation } from '@apollo/client';
import { toast as showToast } from 'react-toastify';
import {
  GET_BRAINTREE_CLIENT_TOKEN,
  BRAINTREE_TRANSACTION_REQUEST,
} from './requests';

export const useData = (onBraintreeRequestCompleted) => {
  const {
    error,
    loading: isLoading,
    data,
  } = useQuery(GET_BRAINTREE_CLIENT_TOKEN, {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    //onCompleted: ({ getBraintreeClientToken: { isSuccess, clientToken } }) => {
    //    return { isSuccess, clientToken };
    //},
  });

  const [
    invokeBraintreeTransactionRequest,
    { loading: invokingBraintreeRequest },
  ] = useMutation(BRAINTREE_TRANSACTION_REQUEST, {
    onCompleted: ({
      invokeBraintreeTransactionRequest: {
        isSuccess,
        errorMessage,
        validationErrors,
      },
    }) => {
      if (isSuccess) {
        showToast.success('Braintree transaction is completed successfully.');
      } else {
        showToast.error('Error Occurred: ' + errorMessage);
      }
      onBraintreeRequestCompleted();
    },
    onError: ({ message }) => {
      showToast.error(message || 'Something went wrong.');
      onBraintreeRequestCompleted();
    },
  });

  const { getBraintreeClientToken } = data || {};
  const { isSuccess, clientToken } = getBraintreeClientToken || {};

  return {
    isLoading,
    error,
    isSuccess,
    clientToken,
    invokingBraintreeRequest,
    invokeBraintreeTransactionRequest,
  };
};
