import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import Queries from '../Common/Queries';
import { useMutation } from '@apollo/client';
import { APIPaths } from '../../services/api-provider';
import * as axios from 'axios';

/* eslint jsx-a11y/anchor-is-valid: 0 */

const FileUploadTest = () => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [UploadBusinessPictures] = useMutation(Queries.UPLOAD_BUSINESS_MENUS);
  const selectFile = (event) => {
    let reader = new FileReader();
    let file = event.target.files[0];
    reader.fileName = file.name;
    reader.onload = (e) => {
      //var uint8Array = new Uint8Array(reader.result); // read the actual file contents
      ////var binaryString = String.fromCharCode.apply(null, array);
      const allFiles = [...selectedFiles];
      ////const fileId = Math.max(0, ...selectedFiles.map(sf => sf.id)) + 1;
      ////id: fileId,
      //const array = [].slice.call(uint8Array);
      var binaryData = reader.result;
      //Converting Binary Data to base 64
      var base64String = window.btoa(binaryData);
      const fileContent = {
        fileName: reader.fileName,
        base64String: base64String,
      };
      allFiles.push(fileContent);
      setSelectedFiles(allFiles);
      //fileName: String
      //fileType: String
      //pictureStream: [Byte]
    };
    reader.readAsBinaryString(file); // readAsDataURL
    //reader.readAsArrayBuffer(file)
    //setSelectedFiles(event.target.files);
  };
  const deleteFile = (file) => {};

  const handleUploadFiles = () => {
    const businessPicturesInput = { businessId: 1, images: [...selectedFiles] };
    UploadBusinessPictures({
      variables: { businessImagesInput: businessPicturesInput },
    }).then(function (result) {
      //history.push('/');
    });
  };

  useEffect(() => {
    return () => {};
  }, []);

  return (
    <section className='section'>
      <div className='container'>
        <div className='column'>
          <h1 className='title'>Upload Files</h1>
          <p className='subtitle'>Upload files Endpoint testing</p>
        </div>

        <div className='column is-one-third'>
          <table class='table'>
            <thead>
              <tr>
                <th>
                  <abbr>File Name</abbr>
                </th>
                <th>
                  <abbr>Type</abbr>
                </th>
                <th>
                  <abbr>Actions</abbr>
                </th>
              </tr>
            </thead>
            <tbody>
              {selectedFiles.map((selectedFile) => (
                <tr>
                  <th>{selectedFile.fileName}</th>
                  <th>{selectedFile.fileType}</th>
                  <th>
                    <button>delete</button>
                  </th>
                </tr>
              ))}
              {selectedFiles.length == 0 && (
                <tr>
                  <th colSpan='3'>No files added yet</th>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <div className='column is-one-third'>
          <div className='field'>
            <label className='label'>Files</label>
            <div className='control'>
              <input
                className='input'
                type='file'
                id='avatar'
                accept='image/png, image/jpeg'
                onChange={selectFile}
              />
            </div>
          </div>
        </div>

        <div className='column is-one-third'>
          <div className='field is-grouped'>
            <div className='control'>
              <button
                className='button is-link'
                onClick={(e) => {
                  e.preventDefault();
                  handleUploadFiles();
                }}
              >
                Upload Files
              </button>
            </div>
            <div className='control'>
              <button className='button is-link is-light'>Cancel</button>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </section>
  );
};

export default FileUploadTest;
