import { gql } from '@apollo/client';

export const GET_BUSINESSES = gql`
  query GetBusinesses {
    getSuggestedBusinesses(suggestedBusinessesInput: { searchText: "" }) {
      category {
        description
      }
      businesses {
        id
        name
        description
        picture {
          path
        }
      }
    }
  }
`;
