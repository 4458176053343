import { gql } from '@apollo/client';

export const GET_USER_APPOINTMENTS = gql`
  query GetUserAppointments(
    $businessId: Int!
    $status: String!
    $startDate: Date!
  ) {
    getCurrentUserAppointments(
      businessId: $businessId
      status: $status
      startDate: $startDate
    ) {
      id
      adults
      children
      startTime
      endTime
      timeZone
      status
      subBusiness {
        name
      }
      service {
        name
      }
      responses {
        id
        answer
        businessQuestion {
          id
          prompt
          questionType
        }
      }
    }
  }
`;

export const UPDATE_APPOINTMENT_STATUS = gql`
  mutation ChangeAppointmentStatus($id: Int, $status: AppointmentStatus) {
    updateAppointmentStatus(appointmentId: $id, newStatus: $status) {
      isSaved
      errorMessage
      appointment {
        id
        startTime
        endTime
        status
        business{
          id
        }
        service {
          id
        }
        adults
        subBusiness {
          name
        }
      }
    }
  }
`;
