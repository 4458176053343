import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import moment from 'moment';
import { useMutation } from '@apollo/client';
import { useProfile } from 'hooks/useProfile';
import Queries from '../../Queries';

const CardView = styled.section`
  min-width: 330px;
  height: auto;
`;

const BusinessCard = ({
  business,
  showFavorite,
  appointmentSelectedDate,
  refetch,
}) => {
  const { profile } = useProfile();
  const history = useHistory();
  const [isFavorite, setIsFavorite] = useState(business.isFavorite);

  const [addRemoveFromFavorite] = useMutation(
    Queries.ADD_REMOVE_FROM_FAVORITES,
    {
      onCompleted: () => {
        refetch();
        setIsFavorite(!isFavorite);
      },
    }
  );

  const handleAddFavourite = (businessId) => {
    if (!profile) {
      history.push({
        pathname: '/authentication/login',
      });
    }

    addRemoveFromFavorite({
      variables: {
        businessId,
        isFavorite: !isFavorite,
      },
    });
  };

  const handleRemoveFavorite = (businessId) => {
    addRemoveFromFavorite({
      variables: {
        businessId,
        isFavorite: false,
      },
    });
  };

  return (
    <CardView className={`my-3 py-3`}>
      <div className='sectin'>
        <div className='conainer'>
          <div className='tile is-ancestor'>
            <div className='tile is-parent pl-0  py-0'>
              <article className='tile is-child box'>
                <Link
                  to={{
                    pathname: `/business/${business.id}`,
                    state: {
                      appointmentSelectedDate: moment(
                        appointmentSelectedDate
                      ).format('ddd, DD MMM yyyy'),
                    },
                  }}
                >
                  <div className='columns is-mobile'>
                    <div className='column is-4 px-0 pt-1 pb-0'>
                      <figure className='image is-square'>
                        {business.picture && (
                          <img
                            src={business.picture?.path}
                            alt=''
                            style={{ borderRadius: 10 }}
                          />
                        )}
                      </figure>
                    </div>
                    <div className='column pb-0'>
                      <nav className='level is-mobile mb-1'>
                        <div className='level-left'>
                          <div className='level-item'>
                            <div
                              className='is-6 has-text-weight-bold'
                              title={business.name}
                            >
                              {business?.name?.length <= 15
                                ? business.name
                                : `${business?.name?.substring(0, 15)}...`}
                            </div>
                          </div>
                        </div>
                        <div className='level-right'>
                          <div className='level-item'>
                            {showFavorite && (
                              <span className='icon is-size-6'>
                                <i
                                  className='fa fa-heart'
                                  style={{
                                    color: '#037ffc',
                                    cursor: 'pointer',
                                  }}
                                  title={'Remove Favorite'}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    handleRemoveFavorite(business.id);
                                  }}
                                />
                              </span>
                            )}
                            {!showFavorite && (
                              <span className='icon is-size-6'>
                                <i
                                  className={
                                    isFavorite ? 'fa fa-heart' : 'fa fa-heart-o'
                                  }
                                  style={{
                                    color: '#037ffc',
                                    cursor: 'pointer',
                                  }}
                                  title={
                                    isFavorite ? `Remove Favorite` : 'Favorite'
                                  }
                                  onClick={(e) => {
                                    e.preventDefault();
                                    handleAddFavourite(business.id);
                                  }}
                                />
                              </span>
                            )}
                            {/*<span className="icon is-size-6">
                                                    <i className="fa fa-share-alt" style={{ color: '#037ffc' }} />
                                                </span>*/}
                          </div>
                        </div>
                      </nav>
                      <nav className='level is-mobile mb-1 pl-0 pr-2'>
                        <div className='level-left ml-0 pl-0'>
                          <div className='level-item'>
                            {business.description &&
                              business.description.length > 0 && (
                                <p
                                  className='subtitle is-size-6'
                                  title={business.description}
                                >
                                  {business.description.length <= 15
                                    ? business.description
                                    : `${business.description.substring(
                                      0,
                                      15
                                    )}...`}
                                </p>
                              )}
                          </div>
                        </div>
                      </nav>
                      <nav className='level is-mobile mb-0'>
                        <div className='level-left'>
                          <div className='level-item'>
                            <p className='subtitle is-6 is-size-7'>
                              {business.address1.length <= 15
                                ? business.address1
                                : `${business.address1.substring(0, 15)}...`}
                            </p>
                          </div>
                        </div>
                        <div className='level-right  mr-0 pr-0'>
                          <div className='level-item'>
                            <span className='icon is-size-6'>
                              {/*<i className="fa fa-star" style={{ color: 'orange' }} />*/}
                            </span>
                            {/* <p className="subtitle is-6">4.9(365)</p>*/}
                          </div>
                        </div>
                      </nav>

                      <nav className='level is-mobile mb-1 pl-0 pr-2'>
                        <div className='level-left ml-0 pl-0'>
                          <div className='level-item'>
                            {business.promotions &&
                              business.promotions.length > 0 && (
                                <p
                                  key={business.promotions[0].id}
                                  className='has-background-info has-text-white px-1 subtitle is-7'
                                >
                                  {business.promotions[0].text}
                                </p>
                              )}
                          </div>
                        </div>
                      </nav>
                    </div>
                  </div>
                </Link>
              </article>
            </div>
          </div>
        </div>
      </div>
    </CardView>
  );
};

export default BusinessCard;
