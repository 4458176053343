import React, { useState, useEffect, useRef } from 'react';
import { prefixHolder } from 'services/prefix-holder';
import { HubConnectionBuilder } from '@microsoft/signalr';
import { getAccessToken } from 'methods/addAuthorizationHeader';

import ChatWindow from './ChatWindow';
import ChatInput from './ChatInput';

const Chat = () => {
  const [connection, setConnection] = useState(null);
  const [chat, setChat] = useState([]);
  const latestChat = useRef(null);

  latestChat.current = chat;

  //useEffect(() => {
  //    const getToken = async () => {
  //        var tokenResult = await getAccessToken();
  //        setAccessToken(tokenResult);
  //    };

  //    getToken();

  //}, []);

  useEffect(() => {
    //const getToken = async () => {
    //    var tokenResult = await getAccessToken();
    //    setAccessToken(tokenResult);
    //};

    //getToken();

    //if (accessToken) {
    const newConnection = new HubConnectionBuilder()
      .withUrl(
        `${prefixHolder.Backend}/hubs/chat`,
        { accessTokenFactory: async () => await getAccessToken() }
        //{
        //    headers: {
        //        'Authorization': `Bearer ${accessToken}`
        //    }
        //}
      )
      .withAutomaticReconnect()
      .build();

    setConnection(newConnection);
    //}
  }, []);

  useEffect(() => {
    if (connection) {
      // && accessToken
      connection.qs = {};
      connection
        .start()
        .then((result) => {
          console.log('Connected!');

          connection.on('ReceiveMessage', (message) => {
            const updatedChat = [...latestChat.current];
            updatedChat.push(message);
            setChat(updatedChat);
          });
        })
        .catch((e) => console.log('Connection failed: ', e));
    }
  }, [connection]);

  const sendMessage = async (user, message) => {
    const chatMessage = {
      user: user,
      sender: user,
      message: message,
    };

    if (connection.connectionStarted) {
      try {
        await connection.send('SendMessage', chatMessage);
      } catch (e) {
        console.log(e);
      }
    } else {
      console.log('No connection to server yet.');
    }
  };

  return (
    <div>
      <ChatInput sendMessage={sendMessage} />
      <hr />
      <ChatWindow chat={chat} />
    </div>
  );
};

export default Chat;
