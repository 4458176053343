import { toast as showToast } from 'react-toastify';
import { useQuery, useMutation } from '@apollo/client';
import { INITIAL_DATA, DUMMY_DATA } from './constants';
import { GET_USER_NOTIFICATIONS } from './requests';
import Queries from 'components/Common/Queries';

export const useData = () => {
  const {
    loading: isLoading,
    error,
    refetch,
    data = INITIAL_DATA,
  } = useQuery(GET_USER_NOTIFICATIONS, {
    fetchPolicy: 'no-cache',
  });

  const {
    getUserNotifications: { userNotifications: notifications },
  } = data;

  const [onMarkAsViewed] = useMutation(Queries.MARK_AS_VIEWED);

  if (error) showToast.error('Something went wrong');

  return { isLoading, error, refetch, data: notifications, onMarkAsViewed };
};
