import React from 'react'
import Dropzone from 'react-dropzone';
import { StyledDropBox, StyledDropImage, StyledDropzone, StyledIcon } from '../../ManageUserMessages.styled';
import { isEmpty } from 'lodash';
const DropDownBox = ({ onUpload, path, type, isLoading }) => {
    return (
        <Dropzone multiple={false} onDrop={onUpload}>
            {({ getRootProps, getInputProps }) => (
                <StyledDropzone {...getRootProps({ className: 'dropzone' })}>
                    <input {...getInputProps()} />
                    <StyledDropBox>
                        {!isEmpty(path) ? (
                            String(type).includes('image') ? (
                                <StyledDropImage src={`data:${type};base64,` + path} />
                            ) : String(type).includes('video') ? (
                                <StyledIcon name='file-video-o' />
                            ) : String(type).includes('audio') ? (
                                <StyledIcon name='file-audio-o' />
                            ) : String(type).includes('application') ? (
                                <StyledIcon name='fa-solid fa-file-o' />
                            ) : String(type).includes('word') ? (
                                <StyledIcon name='file-word-o' />
                            ) : String(type).includes('application') ? (
                                <StyledIcon name='fa-solid fa-file' />
                            ) : String(type).includes('pdf') ? (
                                <StyledIcon name='file-pdf-o' />
                            ) : String(type).includes('plain') ? (
                                <StyledIcon name='file-text-o' />
                            ) : String(type).includes('spreadsheet') ? (
                                <StyledIcon name='file-excel-o' />
                            ) : String(type).includes('presentation') ? (
                                <StyledIcon name='file-powerpoint-o' />
                            ) : (
                                <p>File not supported</p>
                            )
                        ) : (
                            <p>Drag file here</p>
                        )}
                    </StyledDropBox>
                </StyledDropzone>
            )}
        </Dropzone>
    )
}

export default DropDownBox