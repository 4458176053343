import React from 'react'
import { Wrapper, ImgWrapper, TextWrapper, CouponImage, CouponTitle, CouponSummary, CouponExpires, } from "../PromotionCard.styled";
import moment from 'moment';
import { useHistory } from "react-router";
import { Button, Icon } from "@safelyq/bulma-ui-library";
import { Spin, } from 'antd';
const CouponItem = ({ coupon, businessId, handleFavorites, loader, couponId }) => {
    const { push } = useHistory();

    const handleModalOpen = (couponId) => {
        push(`/business/${businessId}/coupon/${couponId}`);
    };

    return (
        <div>
            <Wrapper>
                {coupon.imageUrl && (
                    <ImgWrapper >
                        <CouponImage src={coupon.imageUrl} alt="img" />
                    </ImgWrapper>
                )}
                <TextWrapper >
                    <CouponTitle>{coupon.title}</CouponTitle>
                    <CouponSummary>{coupon.summary ? coupon.summary : "No Summary"} </CouponSummary>
                    <CouponExpires>  Expires {moment(coupon.endDate).format("DD/MM/YYYY")}  </CouponExpires>
                    <div className='is-justify-content-center is-flex'>
                        <Spin spinning={couponId === coupon.id ? loader : false}>
                            <Button size="small" className=" mr-2 button-icon is-active" onClick={() => handleFavorites(coupon.id, coupon.isFavorite)} >
                                <Icon name={coupon.isFavorite ? "heart" : "heart-o"} />
                            </Button>
                        </Spin>
                        <Button size="small" className=" button-icon is-active" onClick={() => handleModalOpen(coupon.id)} >
                            <Icon name="eye" />
                        </Button>
                    </div>
                </TextWrapper>
            </Wrapper>
        </div>
    )
}

export default CouponItem