import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { Heading, Paragraph, Card, Content } from '@safelyq/bulma-ui-library';

export const BookingCardSkeleton = () => {
  return (
    <Card bordered>
      <Skeleton height={200} />
      <Card.Content className='pb-3'>
        <Content className='mb-2'>
          <Heading size='4'>
            <Skeleton width={140} />
          </Heading>
          <Paragraph>
            <Skeleton count={2} />
          </Paragraph>
        </Content>
        <Skeleton width={160} />
      </Card.Content>
      <Card.Footer>
        <Card.FooterItem>
          <Skeleton width={150} />
        </Card.FooterItem>
        <Card.FooterItem>
          <Skeleton width={150} />
        </Card.FooterItem>
      </Card.Footer>
    </Card>
  );
};
