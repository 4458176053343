import { gql } from '@apollo/client';

export const GET_BRAINTREE_CLIENT_TOKEN = gql`
  query all {
  getBraintreeClientToken(isCustomerRequired: false) {
    isSuccess
    clientToken
  }
}
`;

export const BRAINTREE_TRANSACTION_REQUEST = gql`
  mutation ($transactionRequest: TransactionRequestInput!) {
  invokeBraintreeTransactionRequest(transactionRequest: $transactionRequest) {
    isSuccess
    errorMessage
    validationErrors{
      message
      errorCode
      attribute
    }
  }
}
`;

export const INVOKE_BRAINTREE_TRANSACTION_HOLDIN_ESCROW_REQUEST = gql`
  mutation ($transactionRequest: TransactionRequestInput!) {
    invokeBraintreeTransactionHoldInEscrowRequest(transactionRequest: $transactionRequest) {
    isSuccess
    errorMessage
    validationErrors{
      message
      errorCode
      attribute
    }
  }
}
`;

export const RELEASE_TRANSACTION_FROM_ESCROW = gql`
  mutation ($transactionId: String!) {
    invokeBraintreeReleaseTransactionFromEscrow(transactionId: $transactionId) {
    isSuccess
    errorMessage
    validationErrors{
      message
      errorCode
      attribute
    }
  }
}
`;

export const CANCEL_RELEASE_TRANSACTION_FROM_ESCROW = gql`
  mutation ($transactionId: String) {
    invokeBraintreeCancelReleaseTransactionFromEscrow(transactionId: $transactionId) {
    isSuccess
    errorMessage
    validationErrors{
      message
      errorCode
      attribute
    }
  }
}
`;

export const REFUND_TRANSACTION_REQUEST = gql`
  mutation ($transactionId: String) {
    invokeBraintreeRefundTransactionRequest(transactionId: $transactionId) {
    isSuccess
    errorMessage
    validationErrors{
      message
      errorCode
      attribute
    }
  }
}
`;
