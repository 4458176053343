import { prefixHolder } from 'services/prefix-holder';

const { Gateway, WebFrontend } = prefixHolder;

export const oidcConfiguration = {
  authority: `${Gateway}`,
  client_id: 'safelyq.web',
  redirect_uri: `${WebFrontend}/authentication/callback`,
  silent_redirect_uri: `${WebFrontend}/assets/silent-callback.html`,
  post_logout_redirect_uri: `${WebFrontend}/`,
  response_type: 'code',
  scope: 'openid profile email offline_access',
  automaticSilentRenew: true,
  loadUserInfo: true
};