import React, { useState } from 'react';
import { Box, Icon, Title, Subtitle, Switch, Level, Button, Text, Modal } from '@safelyq/bulma-ui-library';
import { toast } from 'react-toastify';
import { Spin } from 'antd';
import { LoadingSection } from '../LoadingSection';
import { useMutation, useQuery } from '@apollo/client';
import { GET_USER_PROFILE, SAVE_MARKETING_DETAILS } from '../../routes/ManageSettings/requests';
import { FaInfoCircle } from "react-icons/fa";
import styled from 'styled-components';

const styles = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  flexGrow: 1,
};
const notificationStyle = {
  width: "max-content",
}

export const MarketingNotificationSetting = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [notifications, setnotifications] = useState({
    allowEmail: false,
    allowSMS: false,
    allowWhatsApp: false,
    allowMarketingNotifications: false
  })

  const modalStyle = {
    position: "absolute",
    top: "10%",
  }

  const ConsentDiv = styled.div`
    height: auto;
    font-weight: bold;

    @media (max-width: 992px) {
        /* Styles for screens smaller than 768px */
        height: 145px;
       margin-top: 20px;
       font-weight: bold;
    }
`;

  const [loader, setloader] = useState(false)
  const [showConsent, setshowConsent] = useState(false)

  const { loading, refetch } = useQuery(GET_USER_PROFILE, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      setnotifications({
        ...data.getUserProfile.user.marketingDetails
      })
    }
  });

  const [onMarketingDetailsUpdate] = useMutation(SAVE_MARKETING_DETAILS,);

  const handleMarketingPreferenceChange = (val) => {
    setnotifications((prev) => ({
      ...prev,
      allowEmail: val,
      allowSMS: val,
      allowWhatsApp: val,
      allowMarketingNotifications: val
    }))
  }
  const handleValueChange = (value, name) => {
    setnotifications((prev) => ({
      ...prev,
      [name]: value
    }))
  }

  // Show instructions on click
  const toggleConcent = () => {
    setshowConsent((prev) => !prev)
  }

  const handleSaveChanges = async () => {
    try {
      setloader(true)
      await onMarketingDetailsUpdate({
        variables: {
          marketingUserInput: {
            ...notifications
          },
        }
      })
      await refetch()
      setloader(false)
      toast.success("Marketing notifications updated successfully")
      setIsOpen(false);
    } catch (error) {
      console.log("Error", error);
    }
  }
  if (loading) {
    return <LoadingSection />
  }
  else {
    return (
      <Box shadow={true}>
        <div className='is-flex is-align-items-center is-justify-content-space-between'>
          <div>
            <Title size='5'>  <Icon name='bell' /> Marketing Messages     </Title>
            <Subtitle size='6' color='grey-light'>   Configure notifications  </Subtitle>
          </div>
          <Switch value={notifications.allowMarketingNotifications} onValueChange={handleMarketingPreferenceChange} />
        </div>
        <hr />
        <div className='row'>
          <div className='col-lg-12 mb-5'>
            <Level>
              <Level style={styles}>
                <Text>Enable Email</Text>
                <Level className='is-flex'>
                  <span onClick={toggleConcent} className='pr-2 is-clickable is-flex is-align-items-center' style={notificationStyle}>I consent...{" "}<FaInfoCircle className='ml-2' /></span>
                  <Switch value={notifications.allowEmail} name={"allowEmail"} onValueChange={(val,) => handleValueChange(val, "allowEmail")} />
                </Level>
              </Level>
            </Level>
          </div>
          <div className='col-lg-12  mb-5'>
            <Level>
              <Level style={styles}>
                <Text>Enable WhatsApp</Text>
                <Level className='is-flex'>
                  <span onClick={toggleConcent} className='pr-2 is-clickable is-flex is-align-items-center' style={notificationStyle}>I consent... {" "}<FaInfoCircle className='ml-2' /></span>
                  <Switch value={notifications.allowWhatsApp} name={"allowWhatsApp"} onValueChange={(val,) => handleValueChange(val, "allowWhatsApp")} />
                </Level>
              </Level>
            </Level>
          </div>
          <div className='col-lg-12  mb-5'>
            <Level>
              <Level style={styles}>
                <Text>Enable SMS</Text>
                <Level className='is-flex'>
                  <span onClick={toggleConcent} className='pr-2 is-clickable is-flex is-align-items-center' style={notificationStyle}>I consent... {" "}<FaInfoCircle className='ml-2' /></span>
                  <Switch value={notifications.allowSMS} name={"allowSMS"} onValueChange={(val,) => handleValueChange(val, "allowSMS")} />
                </Level>
              </Level>
            </Level>
          </div>
          <div className='col-lg-3'>
            <Button color='info' onClick={() => setIsOpen(true)} >   Save Changes  </Button>
          </div>
          <div className='col-lg-9 is-flex is-align-items-center'>
            {showConsent && <ConsentDiv>By enabling this box I consent to receive marketing messages from SafelyQ and partner businesses</ConsentDiv>}
          </div>
        </div>

        <Modal active={isOpen} clipped>
          <Modal.Background />
          <Modal.Card style={modalStyle}>
            <Modal.Card.Head>
              <div className='container is-flex is-justify-content-space-between is-align-items-center'>
                <Title size='6' className='m-0'>
                  User Consent for Marketing Messages
                </Title>
                <Icon name='close' className="is-clickable mx-2" onClick={() => setIsOpen(false)} />
              </div>

            </Modal.Card.Head>
            <Modal.Card.Body>
              {notifications.allowEmail == true
                ?
                <p className='my-3'> I consent to receive Email Messages</p>
                :
                <p className='my-3'> I will not receive Email Messages </p>
              }
              {notifications.allowSMS == true
                ?
                <p className='my-3'> I consent to receive SMS Messages</p>
                :
                <p className='my-3'> I will not receive SMS Messages </p>
              }
              {notifications.allowWhatsApp == true
                ?
                <p className='my-3'> I consent to receive WhatsAppp Messages</p>
                :
                <p className='my-3'> I will not receive WhatsApp Messages </p>
              }
              <div className='row mt-5'>
                <div className='col-lg-3 col-6'>
                  <Spin className='' spinning={loader}>
                    <Button className="is-default is-fullwidth" color='success' onClick={handleSaveChanges}  > Confirm  </Button>
                  </Spin>
                </div>
                <div className='col-lg-3 col-6'>
                  <Button className='is-default is-fullwidth' color='danger' onClick={() => setIsOpen(false)}  > Cancel  </Button>
                </div>
              </div>
            </Modal.Card.Body>
          </Modal.Card>
        </Modal>
      </Box >
    )
  }

}
