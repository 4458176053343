import { gql } from '@apollo/client';

export const GET_BUSINESS_BY_ID = gql`
  query GetBusinessById($id: Int!) {
    getBusinessById(id: $id) {
      id
      name
      description
      address1
      address2
      allowedCapacityAsPercentage
      allowedCapacityInUnits
      canUserCreateAppointment
      canUserUpdateAppointment
      canUserCancelAppointment
      appointmentGap
      city
      email
      endingOffset
      isAppointmentConfirmationRequired
      appointmentConfirmationOffset
      latitude
      longitude
      phone
      phoneFormatted
      specialInstructions
      preCheckinInstructions
      startingOffset
      state
      totalCapacity
      webAddress
      zipCode
      timeZone
      categoryTags
      safetyTags
      maxAllowedGuests
      isChildrenAllowed
      messaging {
        consumerResponseAllowed
      }
      timeZone
      showNotesInput
      details {
        offset
        hasAssignedSeating
        venueEntrances
      }
      businessCoupons {
        id
        title
        code
        couponUrl
        imageUrl
        couponsUsed
        discount
        discountType
        startDate
        endDate
        summary
        terms
        isActive
        isFavorite
      }
      children {
        id
      }
      services {
        id
        name
        duration
        appointmentBlocks
        businesses {
          id
          name
        }
      }
      menus {
        id
        path
      }
      menu {
        id
        path
      }
      pictures {
        id
        path
      }
      picture {
        id
        path
      }
      slots {
        id
        dayOfWeek
        openingTime
        closingTime
      }
      questions {
        id
        prompt
        questionType
      }
      promotions {
        id
        path
        text
      }
      isFavorite
      userRatings {
        id
        value
        ratingType
        modifiedTime
        isVerifiedVisitor
        reviewComment {
          commentText
          user {
            fullName
          }
        }
        reviewCommentResponse {
          id
          commentText
          modifiedTime
          user {
            fullName
          }
        }
      }
      serviceRatings {
        ratingType
        value
        reviewsCount
        modifiedTime
      }
    }
  }
`;

export const GET_BUSINESS_INFO = gql`
  query GetBusinessInfo($id: Int!) {
    getBusinessById(id: $id) {
      id,
      name,
      city,
      email,
      address1,
      phoneFormatted,
      webAddress,
      isFavorite,
      timeZone
      picture {
        id
        path
      },
      serviceRatings {
        ratingType
        value
        reviewsCount
        modifiedTime
      },
      messaging {
        consumerResponseAllowed
        consumerInitiationAllowed
      },
      menus {
        id
        path
      }
      slots {
        id
        dayOfWeek
        openingTime
        closingTime
      }
    }
  }
`;


export const GET_BUSINESS_COUPONS = gql`
  query GetBusinessInfo($id: Int!) {
    getBusinessById(id: $id) {
      id,
      businessCoupons {
        id
        title
        code
        couponUrl
        imageUrl
        couponsUsed
        discount
        discountType
        startDate
        endDate
        summary
        terms
        isActive
        isFavorite
      }
       
    }
  }
`;
