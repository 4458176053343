import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    activeItem: 0,
};

const MiscSlice = createSlice({
    name: 'misc',
    initialState,
    reducers: {
        setActiveItem(state, action) {
            state.activeItem = action?.payload;
        },
    },
});

export const { setActiveItem } = MiscSlice.actions;
export default MiscSlice.reducer;
