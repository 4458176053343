import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Columns,
  Column,
  Button,
  Title,
  Icon,
  Table
} from '@safelyq/bulma-ui-library';
import styled from 'styled-components';
import { TableView, TileView, RegionalHolidaysCalendar } from './components';
import { useBusinessHolidays } from './hooks/useHolidaySchedule';
import { HOLIDAY_SCHEDULE_MESSAGES } from '../../common/constants';
import { useDeleteBusinessHoliday } from './hooks/useDeleteBusinessHoliday';
import { toast } from 'react-toastify';
import { useSaveBusinessHoliday } from './hooks/useSaveBusinessHoliday';
import { ApptConfirmPopup } from './components/ApptConfirmPopup/index';
import { DatePicker } from 'antd';
import moment from 'moment';
import { getFinalData, handleBusinessHolidayRemove, onClosingTimechange, parsedHolidays } from './HolidaySchedule.helpers';

const uuid = () => '_' + Math.random().toString(36).substr(2, 9);

export const HolidaySchedule = ({ data: business, onSubmit, refetch, isLoading, gotToNextStep, organizationId, onChangeStep }) => {
  const { region, businessHolidays } = business;
  const { id } = useParams();

  const businessId = id || localStorage.getItem('businessId');
  const [hasError, setHasError] = useState(false)
  const [dateValue, setDateValue] = useState(null)
  const [dateStatus, setDateStatus] = useState(false)
  const [allBusinessHolidays, setAllBusinessHolidays] = useState([])
  const [hasAppointment, setHasAppointment] = useState(false);

  const { onSaveBusinessHoliday, isSaveLoading } = useSaveBusinessHoliday(refetch)
  const { onBusinessHolidayDelete } = useDeleteBusinessHoliday(refetch)


  const handleInputChange = (e, index, date) => {
    let { name, value } = e.target;
    const updatedInputs = [...allBusinessHolidays];
    if (name === "isOpen" || name === "hasBreak") {
      if (name === "isOpen") {
        if (e.target.checked) {
          updatedInputs[index][`openingTimeError`] = "Enter Opening Time"
          updatedInputs[index][`closingTimeError`] = "Enter Closing Time"
        }
        else {
          updatedInputs[index][`openingTime`] = ""
          updatedInputs[index][`closingTime`] = ""
          updatedInputs[index][`hasBreak`] = false
          updatedInputs[index][`breakStartTime`] = ""
          updatedInputs[index][`breakDuration`] = ""
          updatedInputs[index][`openingTimeError`] = ""
          updatedInputs[index][`closingTimeError`] = ""
        }
      }
      else {
        if (e.target.checked) {
          updatedInputs[index][`breakStartTimeError`] = "Enter Break start Time"
          updatedInputs[index][`breakDurationError`] = "Enter duration"
        }
        else {
          updatedInputs[index][`breakStartTime`] = ""
          updatedInputs[index][`breakDuration`] = ""
          updatedInputs[index][`breakStartTimeError`] = ""
          updatedInputs[index][`breakDurationError`] = ""
        }
      }
      updatedInputs[index][name] = e.target.checked;
    }
    else if (name === "openingTime" || name === "closingTime") {
      if (name === "closingTime") {
        const business_openingTime = date + ' ' + allBusinessHolidays[index].openingTime;
        const least_closingTime = date + ' 23:59';
        const business_closingTime = date + ' ' + value;
        const isBetweenOpeningTimeTime = moment(business_closingTime).isBetween(business_openingTime, least_closingTime);
        if (!isBetweenOpeningTimeTime) {
          toast.error(`Business closing time must be between  form ${moment(allBusinessHolidays[index].openingTime, "hh mm").format('LT')} to  ${moment('23:59', "hh mm").format('LT')}`);
          updatedInputs[index][`closingTimeError`] = "Invalid Closing Time"
        }
        else {
          updatedInputs[index][`closingTimeError`] = ""
        }
      }
      else {
        if (value) { updatedInputs[index][`${name}Error`] = "" }
      }
      updatedInputs[index][`hasBreak`] = false
      updatedInputs[index][`breakStartTime`] = ""
      updatedInputs[index][`breakDuration`] = ""
      updatedInputs[index][`breakStartTimeError`] = ""
      updatedInputs[index][`breakDurationError`] = ""
      updatedInputs[index][name] = value;
    }
    else if (name === 'breakStartTime') {
      const business_openingTime = allBusinessHolidays[index].date + ' ' + allBusinessHolidays[index].openingTime;
      const business_closingTime = allBusinessHolidays[index].date + ' ' + allBusinessHolidays[index].closingTime;
      const break_Start_Time = allBusinessHolidays[index].date + ' ' + value;
      const isBreakBetween = moment(break_Start_Time).isBetween(business_openingTime, business_closingTime);
      if (isBreakBetween) {
        updatedInputs[index][`${name}Error`] = ""
      } else {
        updatedInputs[index][`${name}Error`] = "Incorrect Break Start Time"
        toast.error(`Break start time should be between ${moment(allBusinessHolidays[index].openingTime, "hh mm").format('LT')} and ${moment(allBusinessHolidays[index].closingTime, "hh mm").format('LT')} `)
      }
      updatedInputs[index][`breakDuration`] = ""
      updatedInputs[index][name] = value
    }
    else if (name === "breakDuration") {

      const business_closingTime = allBusinessHolidays[index].date + ' ' + allBusinessHolidays[index].closingTime;
      const break_Start_Time = allBusinessHolidays[index].date + ' ' + allBusinessHolidays[index].breakStartTime;
      const timeInMilliSec = moment(business_closingTime).diff(moment(break_Start_Time))
      const timInMinutes = moment.duration(timeInMilliSec).asMinutes()
      if (value > timInMinutes) {
        updatedInputs[index][`${name}Error`] = "Incorrect Break Durantion"
        toast.error(`You can take break maximum of ${timInMinutes} minutes `)
      }
      else {
        updatedInputs[index][`${name}Error`] = ""
      }
      updatedInputs[index][name] = value;
    }
    else if (name === "date") {
      const isDateExist = allBusinessHolidays.some((holiday) => holiday.date === value)
      if (isDateExist) {
        toast.error(`Same Date cannot be added multiple times`)
        return
      }
      else {
        updatedInputs[index][name] = value;
      }

    }
    setAllBusinessHolidays(updatedInputs)
  }

  const handleSave = async (cancelAppointtment) => {
    const updatedSlots = getFinalData(allBusinessHolidays)
    const id = parseInt(businessId);
    try {
      const response = await onSaveBusinessHoliday({
        variables: {
          businessInput: {
            id: id,
            businessHolidays: updatedSlots,
            organizationId: organizationId,
            isCancelAppointment: cancelAppointtment
          },
        },
      })
      if (response.data.saveBusinessHoliday.isSaved) {
        toast.success("Business Holiday saved successfully");
        setHasAppointment(false)
        refetch()
        gotToNextStep([5])
        onChangeStep([5])
      } else {
        if (response.data.saveBusinessHoliday.isAppointment) {
          setHasAppointment(true)
          throw response.data.saveBusinessHoliday.errorMessage
        } else {
          throw response.data.saveBusinessHoliday.errorMessage
        }
      }

    } catch (error) {
      toast.error(error);
      // if (error.includes("appoinments")) {
      //   setHasAppointment(true)
      // } 
    }
  }
  const onAddNewHoliday = (date, dateString) => {
    setDateValue(null)
    setDateStatus(false)
    const isDateExist = allBusinessHolidays.some((holiday) => holiday.date === date.format('YYYY-MM-DD'))
    if (isDateExist) {
      toast.error(`Same Date cannot be added multiple times`)
      return
    }
    const obj = {
      isOpen: false,
      id: uuid(),
      date: date.format('YYYY-MM-DD'),
      specialHolidayId1: undefined,
      specialHolidayId2: undefined,
      openingTimeError: "",
      closingTimeError: "",
      hasBreakError: "",
      breakStartTimeError: "",
      hasBreak: false,
      breakStartTime: "",
      breakDuration: "",
      openingTime: "",
      businessOpeningTime: "",
      closingTime: "",
      businessClosingTime: ""
    }
    setAllBusinessHolidays((prev) => ([...prev, obj]))
  };
  const handleBlur = () => {
    setDateStatus(false)
    setDateValue(null)
  }

  const onDeleteholiday = async (id, date) => {
    const idexist = businessHolidays?.some((el) => el.id === id)
    if (idexist) {
      if (!window.confirm('Are you sure you want to remove slot?')) return;
      try {
        await onBusinessHolidayDelete({ variables: { businessId: +businessId, date: date, }, });
        const updated_Holidays = handleBusinessHolidayRemove([{ id }], allBusinessHolidays)
        setAllBusinessHolidays(updated_Holidays)
      } catch (error) {
        toast.error(error);
      }
    }
    else {
      const updated_Holidays = handleBusinessHolidayRemove([{ id }], allBusinessHolidays)
      setAllBusinessHolidays(updated_Holidays)
    }
  }
  const onCancelAppointment = () => {
    const removedHolidays = allBusinessHolidays.filter((item) => {
      return !businessHolidays.some((el) => el.id === item.id);
    });
    const updated_Holidays = handleBusinessHolidayRemove(removedHolidays, allBusinessHolidays)
    setAllBusinessHolidays(updated_Holidays)
    setHasAppointment(false);
  }

  useEffect(() => {
    setAllBusinessHolidays(parsedHolidays(businessHolidays))
  }, [businessHolidays])

  useEffect(() => {
    const status = allBusinessHolidays.some((day) => day.openingTimeError || day.closingTimeError || day.breakStartTimeError || day.hasBreakError || day.breakDurationError)
    setHasError(status)
  }, [allBusinessHolidays])

  return (
    <>
      {hasAppointment && <ApptConfirmPopup isOpen={hasAppointment} onOkClick={handleSave} onCancelClick={onCancelAppointment} />}
      <div className='row g-0 mb-4  '>
        <div className='col-lg-12 is-hidden-mobile'>
          <TableView holidaySlots={allBusinessHolidays} handleInputChange={handleInputChange} onDeleteholiday={onDeleteholiday} />
        </div>
        <div className='col-lg-12 is-hidden-tablet'>
          <TileView holidaySlots={allBusinessHolidays} handleInputChange={handleInputChange} onDeleteholiday={onDeleteholiday} />
        </div>
      </div>
      <div className='row'>
        <div className='col-12 has-text-right'  >
          <div style={{ position: "relative" }}>
            <DatePicker open={dateStatus} placement={"topLeft"} bordered={false} value={dateValue ? moment(dateValue, 'YYYY-MM-DD') : null} onChange={onAddNewHoliday} />
            <Button onBlur={handleBlur} style={{ position: "absolute", right: "3px" }} color='info' size='small' className='px-5' onClick={() => setDateStatus(!dateStatus)} >Add New Holiday</Button>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col-12'>
          <Button color='info' loading={isSaveLoading} disabled={hasError} className='px-5' onClick={() => handleSave(false)} >Save</Button>
        </div>
      </div>
    </>
  );
};
