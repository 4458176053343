import React from 'react';
import styled from 'styled-components';
import { Block, Title, Icon, Subtitle } from '@safelyq/bulma-ui-library';

const LoadingWrapper = styled(Block).attrs(() => ({
  className: 'py-6',
}))`
  text-align: center;
`;

export const ErrorSection = () => {
  return (
    <LoadingWrapper>
      <Icon name='exclamation-triangle' className='fa-2x' />
      <Title size='5'>Oops! Something went wrong</Title>
      <Subtitle size='6'>
        We're sorry. We're having some trouble completing your search. Please
        try again later.
      </Subtitle>
    </LoadingWrapper>
  );
};
