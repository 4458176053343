import React from 'react';
import { withRouter } from 'react-router-dom';

const ActionCard = ({
  heading,
  primaryText,
  secondaryText,
  btnLabel,
  isActionDisabled,
  callBack,
  color,
}) => {
  return (
    <div className={`notification is-${color} py-3 has-width-medium`}>
      <div className='content is-small'>
        <p className='is-marginless'>{heading}</p>
        <strong>{primaryText}</strong>
        <p>{secondaryText}</p>
        <button
          className='button is-secondary is-small px-6 has-text-weight-bold is-size-6'
          disabled={isActionDisabled}
          onClick={callBack}
        >
          {btnLabel}
        </button>
      </div>
    </div>
  );
};

export default withRouter(ActionCard);

// #4a86e8
// #38761d
