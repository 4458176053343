export const INITIAL_APPOINTMENTS_DATA = {
  getCurrentUserAppointments: [],
};

export const statusColorsEnum = {
  Booked: 'warning',
  Passed: 'danger',
  Cancelled: 'danger',
  Confirmed: 'success',
  Completed: 'success',
  CheckedIn: 'info',
};
export const statusEnum = {
  ALL: 'All',
  BOOKED: 'Booked',
  PASSED: 'Passed',
  CANCELED: 'Cancelled',
  CONFIRMED: 'Confirmed',
  COMPLETED: 'Completed',
  CHECKEDIN: 'CheckedIn',
  CHECKED_IN: 'Checked_In',
  CHECKED_IN_BY_BUSINESS: 'Checked_In_By_Business',
  SERVING_NOW: 'Serving_Now',
  UPDATE_CHECKEDIN: 'Checked_In',
};
