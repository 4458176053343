// Search Businesses By GEMENI
import { GET_BUSINESSES_BY_GEMENI, GET_CHATSLB_TEXT, ADD_REMOVE_FROM_FAVORITES, GET_CURRENT_USER_FAVORITES } from "./query";
import { useLazyQuery, useMutation } from "@apollo/client";
import { toast } from "react-toastify";

export const useSearchBusiness = () => {
    const [
        getBusinessesByGemini,
        {
            loading: isLoadingResponse,
            error: isErrorResponse,
            data,
            refetch: isRefetchBusinesses,
        }
    ] = useLazyQuery(GET_BUSINESSES_BY_GEMENI, {
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: true,
        onCompleted: ({ searchBusinessesByGemini }) => {
            if (searchBusinessesByGemini?.errorMessage)
                toast.error(searchBusinessesByGemini?.errorMessage || "Could not bring response")
        },
        onError: (error) => {
            toast.error(error?.message || 'There was a problem. Data could not be loaded.');
        }
    });

    return {
        getBusinessesByGemini,
        isLoadingResponse,
        isErrorResponse,
        data,
        isRefetchBusinesses,
    };
};

export const useCHatSLBText = () => {
    const [
        getChatSLBText,
        {
            loading: isLoadingText,
            error: isErrorText,
            data: textData,
        }
    ] = useLazyQuery(GET_CHATSLB_TEXT, {
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: true,
        onCompleted: () => {
        },
        onError: (error) => {
            toast.error(error?.message || 'There was a problem. Data could not be loaded.');
        }
    });

    return {
        getChatSLBText,
        isLoadingText,
        isErrorText,
        textData,
    };
};

export const useAddRemoveFavBusinesses = () => {
    const [addRemoveFromFavorite, { loading: favouriteLoading }] = useMutation(ADD_REMOVE_FROM_FAVORITES);

    const handleAddRemoveFavorite = async (variables) => {

        try {
            const response = await addRemoveFromFavorite({ variables });
            if (response?.data) {
                return true; // Success
            } else {
                return false; // No data, fail
            }
        } catch (error) {
            console.error("Error adding/removing from favorites:", error);
            return false; // Error, fail
        }
    };

    return {
        handleAddRemoveFavorite,
        favouriteLoading,
    };
};

export const useFetchAllFavs = () => {
    const [
        getFavBusinesses,
        {
            loading: isLoadingFavs,
            error: isErrorFavs,
            data: favData,
        }
    ] = useLazyQuery(GET_CURRENT_USER_FAVORITES, {
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: true,
        onCompleted: () => {
        },
        onError: (error) => {
            toast.error(error?.message || 'There was a problem. Data could not be loaded.');
        }
    });


    return {
        getFavBusinesses,
        isLoadingFavs,
        isErrorFavs,
        favData,
    };
};