import React, { Fragment, useEffect } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import moment from 'moment';
import { isEmpty } from 'lodash';
import styled from 'styled-components';
import { Field, Control, Icon, TextInput } from '@safelyq/bulma-ui-library';
import { useData } from './hooks';
import { useMessageState, useMessageDispatch } from '../../index';
import { useDispatch } from 'react-redux';

const ScrollList = styled.ul.attrs(() => ({}))`
  height: calc(79vh);
  overflow: scroll;
  background: white;
`;

const EllipsisText = styled.p.attrs(() => ({}))`
  width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ListItem = styled.li.attrs(() => ({
  className: 'p-3',
}))`
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  background: ${({ bgGrey, active }) =>
    bgGrey || active ? 'rgba(0,0,0,0.04)' : 'white'};
  &:hover {
    cursor: pointer;
    background: rgba(0, 0, 0, 0.04);
  }
`;

const Tag = styled.p.attrs(() => ({
  className: 'tag is-right is-danger has-font-weight-bold',
}))`
  width: 2em;
  height: 2em;
  border-radius: 100% !important;
  background: #06d755 !important;
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 25px;
`;

const StyledSearchBar = styled(TextInput).attrs(() => ({}))`
  border: 0;
  border-radius: 20px;
`;

export const RecentConversation = () => {
  const { isLoading } = useData();

  const { push } = useHistory();

  const { businessId } = useParams();

  const dispatch = useMessageDispatch();
  const dispatchRedux = useDispatch()

  const { recentConversation, selectedBusinessId } = useMessageState();

  useEffect(() => {
    if (businessId) {
      dispatch({ type: 'SET_SELECTED_BUSINESS', payload: { id: businessId } });
    }
  }, [businessId]);

  const handleBusinessSelect = (id) => {
    dispatch({ type: 'SET_SELECTED_BUSINESS', payload: { id } });

  }


  const handleRedirect = () => push('/search');

  const handleFilter = ({ target: { value: searchText } }) =>
    dispatch({ type: 'FILTER_RECENT_MESSAGES', payload: searchText });

  if (isLoading) return <span>Loading</span>;
  else
    return (
      <Fragment>
        <ul>
          <ListItem className='p-0' bgGrey>
            <Field>
              <Control>
                <Icon name='search' position='left' />
                <StyledSearchBar
                  placeholder={'Search chat'}
                  onChange={handleFilter}
                />
              </Control>
            </Field>
          </ListItem>
        </ul>
        <ScrollList>
          {isEmpty(recentConversation) ? (
            <div className='hero is-medium'>
              <div className='hero-body'>
                <center>
                  <span className='has-text-grey-light'>
                    No recent conversation
                  </span>
                  <br />
                  <Link to='#' onClick={handleRedirect}>
                    Start conversation
                  </Link>
                </center>
              </div>
            </div>
          ) : (
            recentConversation.map(
              ({
                business: { id: businessId, name, picture },
                unreadCount,
                recentMessages,
                recentMessageTime,
              }) => (
                <ListItem
                  active={businessId === selectedBusinessId}
                  onClick={() => handleBusinessSelect(businessId)}
                >
                  <article className='media'>
                    <figure className='media-left'>
                      {picture && picture.path ? (
                        <p className='image is-48x48'>
                          <img
                            className='image is-48x48 is-rounded'
                            src={picture?.path}
                          />
                        </p>
                      ) : (
                        <p className='image is-48x48 has-background-grey-light rounded is-flex is-justify-content-center is-align-items-center'>
                          {String(name).charAt(0)}
                        </p>
                      )}
                    </figure>
                    <div className='media-content'>
                      <div className='content is-relative'>
                        <p className='mb-0'>
                          <strong>
                            {name.length > 22
                              ? String(name).substring(0, 22) + '...'
                              : name}
                          </strong>
                          <span className='is-right is-size-7 has-text-grey-light'>
                            {moment.utc(recentMessageTime).local().calendar()}{' '}
                          </span>
                          <br />
                          <EllipsisText>
                            {recentMessages[0].message.commentText}
                          </EllipsisText>
                        </p>
                        {unreadCount > 0 && (
                          <Tag>{unreadCount > 9 ? '9+' : unreadCount}</Tag>
                        )}
                      </div>
                    </div>
                  </article>
                </ListItem>
              )
            )
          )}
        </ScrollList>
      </Fragment>
    );
};
