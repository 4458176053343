import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { toast as showToast } from 'react-toastify';
import { INITIAL_BUSINESS_DATA } from './constants';
import { GET_BUSINESS_BY_ID, GET_BUSINESS_INFO, GET_BUSINESS_COUPONS } from './requests';

export const useData = () => {
  const { businessId } = useParams();

  const id = parseInt(businessId);

  const { loading: isLoading, error, data = INITIAL_BUSINESS_DATA, refetch, } = useQuery(GET_BUSINESS_BY_ID, { variables: { id } });

  if (error) showToast.error('There was a problem. Data could not be loaded.');

  const { getBusinessById: business } = data;

  return { isLoading, error, data: business, refetch };
};


export const useGetBusinessInfo = () => {
  const { businessId } = useParams();

  const id = parseInt(businessId);

  const { loading, error, data, refetch } = useQuery(GET_BUSINESS_INFO, { variables: { id } });
  let business = null
  const emptyThumbnail = 'https://s3.amazonaws.com/speedsport-news/speedsport-news/wp-content/uploads/2018/07/01082232/image-not-found.png';

  if (!loading) {
    business = {
      name: data.getBusinessById.name,
      city: data.getBusinessById.city,
      email: data.getBusinessById.email,
      address1: data.getBusinessById.address1,
      phoneFormatted: data.getBusinessById.phoneFormatted,
      webAddress: data.getBusinessById.webAddress,
      isFavorite: data.getBusinessById.isFavorite,
      picture: data.getBusinessById.picture ? data.getBusinessById.picture?.path : emptyThumbnail,
      serviceRatings: data.getBusinessById.serviceRatings,
      consumerResponseAllowed: data.getBusinessById.messaging.consumerResponseAllowed,
      consumerInitiationAllowed: data.getBusinessById.messaging.consumerInitiationAllowed,
      id: data.getBusinessById.id,
      menus: data.getBusinessById.menus,
      timeZone: data.getBusinessById.timeZone,
      slots: data.getBusinessById.slots,
    }
  }
  return { loading, error, business, refetch };
};

export const useGetCoupons = () => {
  const { businessId } = useParams();
  const id = parseInt(businessId);
  const { loading, error, data, refetch } = useQuery(GET_BUSINESS_COUPONS, {
    variables: { id },
    // fetchPolicy: 'network-only',
    // notifyOnNetworkStatusChange: true,
  }
    ,);
  let business = null
  if (!loading) {
    business = {
      id: data.getBusinessById.id,
      businessCoupons: data.getBusinessById.businessCoupons
    }
  }
  return { loading, error, business, refetch };
};
