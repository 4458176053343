import React from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import ReactPlayer from 'react-player/lazy';
import {
  Title,
  Card,
  Columns,
  Column,
  Paragraph,
  List,
  Container,
  Box,
  Content,
  Icon,
} from '@safelyq/bulma-ui-library';
import { Loader } from '@safelyq/base-feature';
import { useData } from './hooks';

const StyledBox = styled.div`
  width: fit-content;
  padding: 10px 20px;
  background: white;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
`;

const AboutUs = () => {
  const { isLoading, data, error } = useData();

  if (isLoading) return <Loader isFullScreen />;

  if (error) return <ErrorSection />;

  return (
    <Container>
      <MetaTags />
      <StyledBox>
        <Title size='5' className='has-text-centered '>
          SafelyQ Use Cases
        </Title>
      </StyledBox>
      <Box className='p-6'>
        <Columns multiline>
          {data.media.map(({ url, title }) => (
            <Column size='3' className='p-6'>
              <Card style={{ border: '1px solid #f5f5f5' }}>
                <ReactPlayer
                  className='react-video-player'
                  url={url}
                  controls={true}
                  light={true}
                  width='100%'
                />
                <Card.Content className='py-4'>
                  <Title size='6'>{title}</Title>
                </Card.Content>
              </Card>
            </Column>
          ))}
        </Columns>
      </Box>
      <StyledBox>
        <Title size='5' className='has-text-centered'>
          About Us
        </Title>
      </StyledBox>
      <Box>
        <Column size='full'>
          <Title size='4'>{data.title}</Title>
          <Paragraph>{data.goal}</Paragraph>
        </Column>
        <Column size='full'>
          <Paragraph>
            <strong>{data.setupList.title}</strong>
          </Paragraph>
          <List type='unordered'>
            {data.setupList.options.map((option, index) => (
              <List.Item key={`setup-${index}`}>{option}</List.Item>
            ))}
          </List>
        </Column>
        <Column size='full'>
          <Title size='6'>{data.secondTitle}</Title>
          <Paragraph>{data.description.pg1}</Paragraph>
        </Column>
        <Column size='full'>
          <Paragraph>{data.description.pg2}</Paragraph>
        </Column>
        <Column size='full'>
          <Paragraph>{data.description.pg3} </Paragraph>
        </Column>
        <Column size='full'>
          <Paragraph>{data.description.pg4}</Paragraph>
        </Column>
        <Column size='full'>
          <Paragraph>
            {data.footer.text}{' '}
            <strong>
              <a href='mailto:info@safelyq.com'>{data.footer.email}</a>
            </strong>
          </Paragraph>
        </Column>
      </Box>
      <br />
    </Container>
  );
};

export default AboutUs;

const MetaTags = () => {
  return (
    <Helmet>
      <title>SafelyQ - About Us</title>
      <meta
        name='description'
        content='SafelyQ, a US based company, offers a comprehensive solution for organizations and agencies to safely queue people into any kind of facilities that are brought up, from mega sites with multiple entry points to drive throughs.'
      />
      <meta
        name='keywords'
        content='Appointments, Online Appointments, SafelyQ Appointment, Queueing System, Le Bernardin Appointment, Dallas City Hall'
      />
    </Helmet>
  );
};

const ErrorSection = () => {
  return (
    <Container>
      <MetaTags />
      <Box>
        <Column size='12'>
          <Content className='py-5'>
            <Paragraph alignment='centered' weight='bold' color='grey-light'>
              <Icon name='exclamation-triangle' />
              Something went wrong on data loading
            </Paragraph>
          </Content>
        </Column>
      </Box>
    </Container>
  );
};
