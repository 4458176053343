import { useState } from "react";

export const useFile = () => {
    const [previewSource, setPreviewSource] = useState('');
    const [fileType, setFileType] = useState('');
    const [fileSize, setFileSize] = useState('');
    const [fileName, setFileName] = useState('');

    const handleUpload = (files) => {

        let file = files[0];

        const reader = new FileReader();

        reader.fileName = file?.name;

        reader.onload = () => {
            setPreviewSource(window.btoa(reader.result));

            let { type, name, size } = file || {};

            setFileType(type);

            setFileSize(size);

            setFileName(name);
        };
        reader.readAsBinaryString(file);
    };

    const handleRemove = () => {
        setFileType('');
        setFileSize('');
        setPreviewSource('');
    };

    return {
        onUpload: handleUpload,
        onRemove: handleRemove,
        path: previewSource,
        type: fileType,
        size: fileSize,
        name: fileName,
    };
};
