import { gql } from '@apollo/client';

export const FAVORITES_BUSINESS_COUPON = gql`
  mutation FavoritesBusinessCoupon(
    $businessCouponId: Int!
    $isFavorite: Boolean!
  ) {
    addRemoveFromFavoritesBusinessCoupon(
      businessCouponId: $businessCouponId
      isFavorite: $isFavorite
    ) {
      id
      businessCoupon {
        title
      }
    }
  }
`;
