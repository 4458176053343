import { gql } from '@apollo/client';

export const Get_Privacy_Policy_Page_Info = gql`
  query GetPrivacyPolicyPageInfo  {
       getPrivacyPolicyPageInfo {
    errorMessage
    isSuccess
    privacyPolicy {
      copyRight
      dateUpdated
      options
      pg1
      pg2
      pg3
      secondTitle
      title
      list {
        listIntro
        options
        pg1
        pg2
        pg3
        pg4
        pg5
        title
        table {
          tableContent {
            t1
            t2
          }
          tableHeadings {
            h1
            h2
          }
        }
        list {
          options
          listIntro
          pg1
          pg2
          pg3
          pg4
          pg5
          title
        }
      }
    }
  }
  }
`;
