import React, { useCallback, useRef } from 'react'
import QuickPinchZoom, { make3dTransformValue } from "react-quick-pinch-zoom";

export const MobileImage = ({ src }) => {

    const imgRef = useRef();
    const onUpdate = useCallback(({ x, y, scale }) => {
        const { current: img } = imgRef;
        if (img) {
            const value = make3dTransformValue({ x, y, scale });
            img.style.setProperty("transform", value);
        }
    }, []);

    return (
        <div className='is-hidden-desktop'>
            <QuickPinchZoom onUpdate={onUpdate}>
                <img ref={imgRef} className="slider-img" data-target="menu-modal" src={src} alt='Business Brand' />
            </QuickPinchZoom>
        </div>
    )
}
