import { ManageSearch } from 'features/search-feature/src';
import React from 'react'
import { useSelector } from 'react-redux';
import MainHomePage from './MainHomePage';
import { useHistory } from 'react-router-dom';

const HomePageDesktop = () => {
  const history = useHistory();
  const { authinfo } = useSelector((state) => state.auth);
  const redirectTo = localStorage.getItem("redirectTo");

  if (redirectTo) {
    history.push(`/${redirectTo}`)
    localStorage.removeItem('redirectTo')
  }

  if (authinfo) {
    return (
      <MainHomePage />
    )
  }
  else {
    return (
      <ManageSearch />
    )
  }

}

export default HomePageDesktop