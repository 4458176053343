import { gql } from '@apollo/client';
export const GET_BUSINESS_INSTRUCTIONS = gql`
  query GetBusinessInstructions($id: Int!) {
    getBusinessById(id: $id) {
      id, 
      safetyTags
      specialInstructions
    }
  }
`;


