import React, { useEffect, useState } from 'react'
import { GiEmptyHourglass } from "react-icons/gi";
// import "../style.css"
import { useHistory, Link } from 'react-router-dom';
import StarRatings from 'react-star-ratings';
// import { TiHeartFullOutline } from "react-icons/go";
import { TiHeartFullOutline } from "react-icons/ti";
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { Button, Icon } from '@safelyq/bulma-ui-library';

const Businesses = ({ businesses, messages, loading, favBusinesses, setFavBusinesses, handleAddRemoveFavorite }) => {
    const history = useHistory();

    const emptyThumbnail = 'https://s3.amazonaws.com/speedsport-news/speedsport-news/wp-content/uploads/2018/07/01082232/image-not-found.png';
    const [favorite, setFavorite] = useState();

    const handleFavouriteBusinesses = async (business) => {

        const isAlreadyFavourite = favBusinesses.find(fav => fav.business?.id === business.id);

        if (isAlreadyFavourite) {
            // Remove the business if it's already a favorite
            const result = await handleAddRemoveFavorite({ businessId: business?.id, isFavorite: false });
            if (result) {
                toast.success("Removed the business from the Favourites");
                setFavBusinesses(prevFavBusinesses =>
                    prevFavBusinesses.filter(fav => fav.business?.id !== business.id)
                );
            } else {
                toast.error('Failed to remove from favorites');
            }
        } else {
            // Add the business if it's not already a favorite
            const result = await handleAddRemoveFavorite({ businessId: business?.id, isFavorite: true });
            if (result) {
                toast.success("Added the business to the Favourites");
                setFavBusinesses(prevFavBusinesses =>
                    [...prevFavBusinesses, { business }]
                );
            } else {
                toast.error('Failed to add to favorites');
            }
        }
    };

    const getFavoriteIconColor = (businessId) => {

        const isFavorite = favBusinesses?.find(fav => fav?.business?.id === businessId);
        // return isFavorite ? "red" : "white";
        return isFavorite ? "heart" : "heart-o";
    };

    const FavouriteButton = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  background: white;
  width: 35px;
  height: 35px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px;
`;

    return (
        <>
            {/* <div className="columns-custom is-hidden" style={{ height: "94%", display: "flex" }}>
              
                {
                 
                    businesses?.length === 0
                        ?
                        messages?.length > 1 && !loading ?
                            <div className='is-flex is-justify-content-center' style={{ width: "100%" }}>
                                <GiEmptyHourglass color='gray' fontSize={26} />
                                <p className='ml-2 has-text-weight-semibold has-text-grey-dark'>No Suggestions Found</p>
                            </div>
                            :
                            ""
                        :
                        businesses.map(business => (
                            <div
                                key={business.id} className="column is-one-third-widescreen is-one-third-fullhd is-half-desktop is-full-tablet is-half-mobile"
                            >
                            
                                <div className="card-custom-businesses">
                                    <img
                                        src={business?.picture?.path || emptyThumbnail}
                                        alt={business.name}
                                        onError={(e) => e.target.src = emptyThumbnail}
                                        className="card-image-custom is-clickable"
                                        onClick={() => history.push(`/business/${business.id}`)}
                                    />
                                    <div className='p-2 businessdetailContainer'>
                                        <div className='is-flex is-justify-content-space-between my-2'>
                                         
                                            <div className=''>
                                            
                                                <Link to={{ pathname: `/business/${business.id}` }} className='is-size-6 has-text-weight-bold' title={business?.name}    >
                                                    {business?.name?.length <= 15 ? business?.name : `${business?.name.substring(0, 15)}...`}
                                                </Link>
                                            </div>
                                            <div className='is-flex'>
                                             
                                                <i className="fa fa-quote-left favorite_icon mx-1 is-size-6-mobile is-size-6" />
                                                <i onClick={() => handleFavourite(business.id, business.isFavorite)} className={`${favorite ? 'fa fa-heart' : 'fa fa-heart-o'} favorite_icon mx-1`} />
                                                <i className={`fa fa-envelope favorite_icon mx-1 is-size-6`} />
                                                <i className={`fa fa-comments favorite_icon mx-1 is-size-6`} />
                                            </div>
                                        </div>
                                        <div className='row g-0'>
                                            <div className='col-lg-12'>
                                                <p className="business_description"> {business.description ? (`${business.description.substring(0, 40)}...`) : ("No Description")}</p>
                                            </div>
                                        </div>
                                        {business.address1 && (
                                            <div className='row g-0'>
                                                <div className='col-lg-12'>
                                                    <i className='fa fa-map-marker' style={{ color: '#037ffc' }}  ></i>
                                                    <span className='subtitle is-7'>  {business.city}  </span>
                                                </div>
                                            </div>
                                        )}

                                        <div className='row g-0'>
                                            <div className='col-lg-12'>
                                                <div style={{ color: 'rgb(26 17 186)' }}>
                                                    <StarRatings rating={business?.serviceRatings?.value} starRatedColor='rgb(26 17 186)' starSpacing='3px' starDimension='15px' />
                                                    &nbsp;[  {business?.serviceRatings?.reviewsCount}]
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        ))}

            </div> */}

            {/* Sliding businesses view in the main section */}
            <div className='my-2 mt-4' style={{ overflowX: "auto", whiteSpace: "nowrap", width: "100%", scrollbarWidth: "thin" }}>
                {
                    businesses?.length === 0
                        ?

                        <div className='is-flex is-justify-content-center' style={{ width: "100%" }}>
                            <GiEmptyHourglass color='gray' fontSize={26} />
                            <p className='ml-2 mb-5 has-text-weight-semibold has-text-grey-dark'>No Suggestions Found</p>
                        </div>
                        :
                        businesses.map((business, i) => {
                            return (
                                <div
                                    className='card-custom-businesses is-relative'
                                    key={business.id}
                                    style={{ display: "inline-flex", flexDirection: "column", flex: "0 0 33.33%", marginRight: "30px", marginBottom: "10px", width: "150px" }}>
                                    {/* {
                                        <TiHeartFullOutline onClick={() => handleFavouriteBusinesses(business)} className='is-clickable'
                                            color={getFavoriteIconColor(business?.id)} size={20} style={{ position: "absolute", top: 10, right: 10 }} />
                                    } */}
                                    <FavouriteButton className='has-text-info is-clickable'>
                                        <Icon onClick={() => handleFavouriteBusinesses(business)} name={getFavoriteIconColor(business?.id)} collection='fa' />
                                    </FavouriteButton>
                                    <img className='businessImage'
                                        src={business?.picture?.path || emptyThumbnail}
                                        alt={business.name}
                                        onError={(e) => e.target.src = emptyThumbnail}
                                        style={{ width: "100%", objectFit: "cover" }} />
                                    {/* <div className='p-2 '> */}
                                    <div className='px-2 my-2'>
                                        <Link to={{ pathname: `/business/${business.id}` }} className='is-size-7 has-text-weight-bold has-text-black' title={business?.name}    >
                                            {business?.name?.length <= 20 ? business?.name : `${business?.name.substring(0, 20)}...`}
                                        </Link>
                                        <div className='is-flex mt-1'>
                                            <i className="fa fa-quote-left favorite_icon mx-1 is-size-7" />
                                            {/* <i onClick={() => handleFavourite(business.id, business.isFavorite)} className={`${favorite ? 'fa fa-heart' : 'fa fa-heart-o'} is-size-7 favorite_icon mx-2`} /> */}
                                            <i className={`fa fa-envelope favorite_icon mx-2 is-size-7`} />
                                            <i className={`fa fa-comments favorite_icon mx-2 is-size-7`} onClick={() => history.push(`messages/${business?.id}`)} />
                                        </div>
                                    </div>
                                    <div className='px-2 row g-0'>
                                        <div className='col-lg-12'>
                                            <p className="business_description is-size-7"> {business.description ? (`${business.description.substring(0, 20)}...`) : ("No Description")}</p>
                                        </div>
                                    </div>
                                    {business.address1 && (
                                        <div className='row px-2 g-0'>
                                            <div className='col-lg-12'>
                                                <i className='fa fa-map-marker' style={{ color: '#037ffc' }}  ></i>
                                                <span className='subtitle is-7'>  {business.city}  </span>
                                            </div>
                                        </div>
                                    )}

                                    <div className='row px-2 g-0'>
                                        <div className='col-lg-12'>
                                            <div style={{ color: 'rgb(26 17 186)' }}>
                                                <StarRatings rating={business?.serviceRatings?.value} starRatedColor='rgb(26 17 186)' starSpacing='3px' starDimension='15px' />
                                                &nbsp;[  {business?.serviceRatings?.reviewsCount}]
                                            </div>
                                        </div>
                                    </div>

                                    {/* </div> */}
                                </div>
                            )
                        })
                }
            </div>
        </>
    )
}

export default Businesses