import React from 'react';
import { Columns, Column, Title, Subtitle } from '@safelyq/bulma-ui-library';
import companyBrand from 'assets/logo.png';
import { useData } from './hooks';
import { Loader } from '@safelyq/base-feature';

export const HeroSection = () => {
  const { data, isLoading } = useData();

  return (
    <Columns multiline>
      <Column size="12">
        <center>
          <img src={companyBrand} alt={'SafelyQ'} width={120} height={120} />
        </center>
      </Column>
      <Column size='12'>
        <Title size='4'>
          {isLoading ? (
            <>
              <Loader />
            </>
          ) : (
            <>
              <Title size='4'>{data?.getHomePage?.home?.headers?.h1}</Title>
              <Subtitle size='5'>
                {data?.getHomePage?.home?.headers?.h2}
              </Subtitle>
            </>
          )}
        </Title>
      </Column>
    </Columns>
  );
};
