import React from 'react'
import FavoriteItem from './FavoriteItem';

const FavoritesList = ({ favoriteLists, refetch }) => {
    return (
        <div className='row g-3 '>
            {favoriteLists.map((business, i) => {
                return (
                    <div className="col-lg-4 f_business_main" key={business.id} >

                        <FavoriteItem business={business} refetch={refetch} />


                    </div>
                )
            })}

        </div>

    )
}

export default FavoritesList