import React from 'react';
import { gql, useSubscription } from '@apollo/client';

const UserSubscriptionTest = () => {
    const { loading, data, error } = useSubscription(SUBSCRIPTION_TEST_QUERY, {
        variables: { userId: "F2E408FFDC3F75D5F9EFEFF36821CDA7438E4790793704813ABDDEA2AEF2ECDE" }
    });

    if (loading) return <h4>Loading...</h4>;

    if (error)
        return (
            <h4>{error ? JSON.stringify(error, null, 4) : 'Something went wrong'}</h4>
        );

    let { userMessageReceived } = data;

    if (!userMessageReceived) return null;

    let {
        userMessageReceived: {
            business: { name },
            comment: { commentText },
            user: { email },
        },
    } = data;

    return (
        <section className='section'>
            <div className='container'>
                <table className='table'>
                    <thead>
                        <tr>
                            <th>Business</th>
                            <th>Message</th>
                            <th>User</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{name}</td>
                            <td>{commentText}</td>
                            <td>{email}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </section>
    );
};

export default UserSubscriptionTest;

const SUBSCRIPTION_TEST_QUERY = gql`
  subscription ($userId: String) {
  userMessageReceived(userId: $userId) {
    business {
      name
    }
    comment {
      commentText
    }
    errorMessage
    isSaved
    messageId
    user {
      firstName
      lastName
      name
      email
    }
  }
}
`;
