import React from 'react'
import moment from 'moment';
import { } from "./UserAppointments.styles"
import EmptyAppointmentSection from './EmptyAppointmentSection';
import AppointmentCard from './AppointmentCard';
import { MdNavigateBefore, MdNavigateNext } from "react-icons/md";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const SampleNextArrow = (props) => (<span className="NextArrow" onClick={props.onClick} > {" "} < MdNavigateNext /> {" "} </span>);
const SamplePrevArrow = (props) => (<span className="PreviousArrow" onClick={props.onClick}  > {" "} < MdNavigateBefore /> {" "} </span>);

const UserAppointments = ({ selectedDate, appointments }) => {
  const dateFormatString = 'YYYY-MM-DD';
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const selectedAppointments = appointments.filter((appointment) => {
    const dateString = moment(selectedDate).format(dateFormatString);
    return (
      moment(appointment.startTime).format(dateFormatString) === dateString
    );
  });
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: selectedAppointments.length === 1 ? 1 : selectedAppointments.length === 2 ? 2 : 3,
    slidesToScroll: 1,
    autoplay: selectedAppointments.length > 3 ? true : false,
    autoplaySpeed: 2000,
    arrows: selectedAppointments.length > 3 ? true : false,
    nextArrow: < SampleNextArrow />,
    prevArrow: < SamplePrevArrow />,
    responsive: [
      { breakpoint: 1280, settings: { slidesToShow: 1, arrows: false, } },
      { breakpoint: 1024, settings: { slidesToShow: 1, arrows: false, } },
      { breakpoint: 600, settings: { slidesToShow: 1, arrows: false, } },
    ],
  };
  return (
    <div className='row'>
      <div className='col-lg-12'>
        <span className='subtitle  is-6 pb-0 has-text-weight-bold has-text-grey'>
          {moment(selectedDate).tz(timezone).format('dddd MMMM Do, YYYY')}
        </span>
      </div>
      {selectedAppointments.length === 0 ? (
        <div className='col-lg-12'>
          <EmptyAppointmentSection />
        </div>
      ) : (
        <div className='col-lg-12'>
          <div className='row'>
            <div className={`${selectedAppointments.length === 1 ? 'col-lg-4' : selectedAppointments.length === 2 ? 'col-lg-8' : 'col-lg-12'}`}>
              <Slider {...settings} className={"BusinessListSlider"}>
                {selectedAppointments.map((appointment, i) => {
                  return (
                    <div key={i} >
                      <div className='Wrapper'>
                        <AppointmentCard appointment={appointment} />
                      </div>
                    </div>
                  )
                })}
              </Slider>
            </div>
          </div>
        </div>
      )}

    </div>
  )
}

export default UserAppointments