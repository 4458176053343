import React, { useState, Fragment, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Loader } from '@safelyq/base-feature';
import CategoryTags from 'components/Common/CategoryTags';
import { BusinessCouponModal } from '../BusinessCouponModal';
import styled from 'styled-components';
import { useData } from './hooks';
import { Wrapper, ImgWrapper, TextWrapper, CouponImage, TitleWrapper, Title, CouponSummary, CouponDiscount, ExpiredStatusTag, ActiveStatusTag, BusinessName } from "./BusinessCoupons.styled"
import { toast } from 'react-toastify';
import { Spin } from 'antd';
import moment from 'moment';



const emptyThumbnail =
  'https://s3.amazonaws.com/speedsport-news/speedsport-news/wp-content/uploads/2018/07/01082232/image-not-found.png';



export const BusinessCoupons = () => {
  const [catergories, setCategories] = useState([]);

  const [filteredCoupons, setFilteredCoupons] = useState([]);

  const [selectedCategory, setSelectedCategory] = useState('all');

  const [modal, setModal] = useState({ isOpen: false, couponId: null });
  const [loader, setLoader] = useState(false)

  const { isLoading, data: businessCoupons, onFavoritesBusinessCoupon, } = useData();



  useEffect(() => {
    setFilteredCoupons(businessCoupons?.map((businessCoupon) => businessCoupon) || [])
  }, [businessCoupons])

  useEffect(() => {
    let dumpArray = [];

    businessCoupons &&
      businessCoupons.map(({ businessCoupon }) => {
        if (
          !dumpArray.find(
            (category) =>
              category?.name == businessCoupon?.business?.businessCategory?.name
          )
        ) {
          dumpArray.push(businessCoupon?.business?.businessCategory);
        }
      });

    setCategories([{ name: 'all', description: 'All' }, ...dumpArray]);

    setSelectedCategory('all');
  }, [businessCoupons]);

  const handleRemoveFavorite = async (e, businessCouponId) => {
    e.stopPropagation();
    setLoader(true)
    await onFavoritesBusinessCoupon({
      variables: {
        businessCouponId,
        isFavorite: false,
      },
    });
    setLoader(false)
    toast.success('Removed From Favorites');
  }


  const handleSelectedCategory = (category) => {
    handleFilterCoupon(category);
    setSelectedCategory(category);
  };

  const handleFilterCoupon = (categoryName) => {
    let dumpArray = [];

    if (categoryName === 'all')
      businessCoupons.map((businessCoupon) => dumpArray.push(businessCoupon));
    else
      businessCoupons.filter((businessCoupon) => {
        if (
          businessCoupon.businessCoupon.business.businessCategory.name ===
          categoryName
        )
          dumpArray.push(businessCoupon);
      });

    setFilteredCoupons([...dumpArray]);
  };

  const handleModalOpen = (couponId) => setModal({ isOpen: true, couponId });

  const handleModalClose = () => setModal({ isOpen: false, couponId: null });

  if (isLoading) return <Loader isFullScreen />;

  return (
    <Fragment>
      <CategoryTags tags={catergories} selectedTag={[selectedCategory]} onChange={(category) => handleSelectedCategory(category.name)} />
      <section className='section container px-0 py-2'>
        <div className='container'>
          <div className='columns is-multiline'>
            {filteredCoupons.map(({ businessCoupon }, index) => {
              const currentDate = moment();
              const expiryDate = moment(businessCoupon.endDate);
              // const expiryDate = moment("2023-08-14T00:00:00");

              let expirySoon = false
              const daysDifference = expiryDate.diff(currentDate, 'days');

              if (daysDifference <= 7 && daysDifference >= 0) {
                expirySoon = true
              }
              return (
                <div className='column is-4 pb-0' key={`favorite-coupon-${index}`}  >
                  <Wrapper onClick={() => handleModalOpen(businessCoupon.id)}>
                    {businessCoupon.imageUrl && (
                      <ImgWrapper >
                        <CouponImage src={businessCoupon.imageUrl} alt="img" />
                      </ImgWrapper>
                    )
                    }
                    <TextWrapper >
                      <TitleWrapper>
                        <Title>{businessCoupon.title}</Title>
                        <Spin spinning={loader}>
                          <span className='icon is-size-6'>
                            <i onClick={(e) => { handleRemoveFavorite(e, businessCoupon.id); }} className='fa fa-heart' style={{ color: '#037ffc', cursor: 'pointer', }} title={'Remove Favorite'} />
                          </span>
                        </Spin>

                      </TitleWrapper>
                      <BusinessName>
                        <Link to={`/business/${businessCoupon?.business?.id}`}  >  {businessCoupon?.business?.name}   </Link>
                      </BusinessName>

                      <CouponSummary>{businessCoupon.summary ? businessCoupon.summary : "No Summary"} </CouponSummary>
                      <CouponDiscount>  Discount : {businessCoupon.discountType !==
                        'Percentage' && '$'}
                        {businessCoupon.discount}{' '}
                        {businessCoupon.discountType == 'Percentage' && '%'}</CouponDiscount>

                      <p className='subtitle is-size-7'>
                        {businessCoupon.expireStatus ? (
                          <ExpiredStatusTag>Expired</ExpiredStatusTag>
                        ) : (
                          <ActiveStatusTag>Active</ActiveStatusTag>
                        )}
                        {expirySoon && <p style={{ color: "#ffa700", fontSize: 12 }}> expiring soon</p>}
                      </p>


                    </TextWrapper>
                  </Wrapper>
                </div>
              )
            })}
          </div>
        </div>
      </section>
      {modal.isOpen && (
        <BusinessCouponModal
          isOpen={modal.isOpen}
          data={
            businessCoupons.find(
              ({ businessCoupon }) => businessCoupon.id === modal.couponId
            )?.businessCoupon
          }
          onCloseModal={handleModalClose}
          onFavoritesBusinessCoupon={onFavoritesBusinessCoupon}
        />
      )}
    </Fragment>
  );
};
