import React from 'react'
import moment from 'moment';
import { Title, Icon } from '@safelyq/bulma-ui-library';
import { isEmpty } from 'lodash';
import { Spin } from 'antd';

const CouponDetails = ({ data, onCloseModal, handleFavorites, loader }) => {
    return (
        <>
            <div className='is-flex is-right'>
                <Spin spinning={loader}>
                    <Icon onClick={() => handleFavorites(data?.id, data?.isFavorite)} name={data?.isFavorite ? 'heart' : 'heart-o'} className='is-hoverable has-text-link mr-3' />
                </Spin>

                <Icon name='times' className='is-hoverable has-text-link' onClick={onCloseModal} />
            </div>
            {data ?
                <div>
                    {data.imageUrl && (
                        <>
                            <a href={data.imageUrl} target='__blank'>
                                <img src={data.imageUrl} alt="coupons" />
                            </a>
                            <br />
                            <br />
                        </>
                    )}
                    <Title size='3' color='link' className='mb-4'>
                        SAVE {data.discountType === "Value" ? `$${data.discount}` : `${data.discount}%`}
                    </Title>
                    <Title size='6' className='mt-2 mb-0'>
                        Coupon Code
                    </Title>
                    <small>{data.code}</small>
                    <Title size='6' className='mb-0 mt-2'>
                        Expiration Date
                    </Title>
                    <small>{moment(data.endDate).format('MMM DD YYYY')}</small>
                    <Title size='6' className='mb-0 mt-2'>
                        Summary
                    </Title>
                    <small>
                        {String(data.summary).length > 80 ? (
                            String(data.summary).substring(0, 84) + '...'
                        ) : isEmpty(data.summary) ? (
                            <i>No Summary</i>
                        ) : (
                            data.summary
                        )}
                    </small>
                    <Title size='6' className='mb-0 mt-2'>
                        Terms and Conditions
                    </Title>
                    <small>
                        {String(data.terms).length > 80 ? (
                            String(data.terms).substring(0, 84) + '...'
                        ) : isEmpty(data.terms) ? (
                            <i>No Terms & Conditions</i>
                        ) : (
                            data.terms
                        )}
                    </small>
                </div> :
                <>
                    <p className='tag is-warning'>Coupon Does not Exist or is no longer Available. </p>
                    <p className='p-2'>Please Exit this screen to show if any other promotion exist</p>
                </>
            }
        </>
    )
}

export default CouponDetails