import { gql } from '@apollo/client';

export const SAVE_BUSINESS_RATING = gql`
  mutation SaveBusinessRating($ratingInput: RatingInput) {
    saveBusinessRatings(ratingInput: $ratingInput) {
      isSaved
      errorMessage
    }
  }
`;
