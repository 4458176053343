import React, { useContext, useReducer, createContext } from 'react';

const SearchStateContext = createContext();
const SearchDispatchContext = createContext();

const searchReducer = (state, action) => {
  const { payload } = action;

  switch (action.type) {
    case 'SET_NAME':
      return {
        ...state,
        searchRequest: false,
        searchSchema: { ...state.searchSchema, searchText: payload },
      };

    case 'SET_AREA':
      return {
        ...state,
        location: payload,
        searchRequest: false,
        searchSchema: { ...state.searchSchema, areaText: payload },
      };

    case 'SET_RADIUS':
      return {
        ...state,
        searchRequest: false,
        searchSchema: { ...state.searchSchema, radius: parseInt(payload) },
      };

    case 'SET_CATEGORY':
      const { value, data } = payload;

      if (value === 'all') {
        const categories = data.map(({ name }) => name);
        categories.push('all');
        return {
          ...state,
          searchRequest: true,
          searchSchema: { ...state.searchSchema, categories },
        };
      }

      if (value == '') {
        return {
          ...state,
          searchRequest: true,
          searchSchema: { ...state.searchSchema, categories: ['xyz-abc'] },
        };
      }

      let { categories } = state.searchSchema;

      const indexOfAll = categories.findIndex((category) => category === 'all');

      const index = categories.findIndex((category) => category === value);

      if (indexOfAll !== -1) categories.splice(indexOfAll, 1);

      if (index === -1) categories.push(value);
      else categories.splice(index, 1);

      return {
        ...state,
        searchRequest: true,
        searchSchema: { ...state.searchSchema, categories },
      };

    case 'SET_COORDS':
      return {
        ...state,
        // location: 'Current Location',
        searchRequest: false,
        searchSchema: {
          ...state.searchSchema,
          ...payload,
          locationEnabled: true,
        },
      };

    case 'SET_SCROLL_REF':
      return {
        ...state,
        scrollRef: payload,
      };

    case 'SET_SEARCH_REQUEST':
      const { status, coords } = payload;
      return {
        ...state,
        searchRequest: status,
        searchSchema: {
          ...state.searchSchema,
          latitude: coords?.latitude ? coords?.latitude : null,
          longitude: coords?.longitude ? coords?.longitude : null,
        }
      };

    case 'SET_FAVORITE_REQUEST':
      const { businessId, isFavorite, sendRequest } = payload;
      return {
        ...state,
        favoriteRequest: sendRequest,
        favoriteSchema: { businessId, isFavorite },
      };

    case 'SET_LOCATION_STATUS':
      let defaultCoords = { longitude: 0, latitude: 0 };

      const isCurrentLocationSelected = payload === true;

      if (!isCurrentLocationSelected)
        return {
          ...state,
          isCurrentLocationSelected,
          searchRequest: false,
          searchSchema: {
            ...state.searchSchema,
            ...defaultCoords,
            locationEnabled: false,
            areaText: '',
          },
          location: '',
        };
      else
        return {
          ...state,
          isCurrentLocationSelected: payload,
          searchRequest: false,
          location: '',
          searchSchema: {
            ...state.searchSchema,
            areaText: '',
          },
        };

    default:
      throw new Error(`Unknow action type: ${action.type}`);
  }
};

export const SearchProvider = ({ children }) => {
  const [state, dispatch] = useReducer(searchReducer, {
    searchRequest: false,
    location: '',
    isCurrentLocationSelected: false,
    searchSchema: {
      areaText: '',
      categories: [],
      latitude: 0,
      locationEnabled: false,
      longitude: 0,
      radius: 50,
      searchText: '',
    },
  });

  return (
    <SearchDispatchContext.Provider value={dispatch}>
      <SearchStateContext.Provider value={state}>
        {children}
      </SearchStateContext.Provider>
    </SearchDispatchContext.Provider>
  );
};

export const useSearchState = () => useContext(SearchStateContext);
export const useSearchDispatch = () => useContext(SearchDispatchContext);
