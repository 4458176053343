import React from 'react';
import { Radio } from 'antd';

const BusinessQuestion = ({
  id,
  answer,
  question,
  textYes,
  textNo,
  isUserResponseRequired,
  onAnswerChange,
}) => {
  const handleAnswerChange = ({ target: { name, value } }) =>
    onAnswerChange(id, name, value);

  return (
    <div key={`question_${id}`} className='has-text-left'>
      <span className='title is-size-6 my-2 has-text-weight-medium'>
        {/* {`Question: ${question}`} */}
        Question <span className='has-text-danger'>* </span>
        {question}
      </span>
      <div className='columns is-size-6 mb-0'>
        <div className='column is-size-6 pt-5 is-flex'>
          <div className='mr-2 has-text-weight-medium'>Ans:</div>
          {isUserResponseRequired ? (
            <div className='control'>
              <input
                className='input'
                name='responseText'
                onChange={handleAnswerChange}
              />
            </div>
          ) : (
            <Radio.Group
              key={id}
              name={`answer`}
              defaultValue={answer}
              onChange={handleAnswerChange}
              className='title is-size-6'
            >
              <Radio className='subtitle is-6' value={true}>
                {textYes}
              </Radio>
              <Radio className='subtitle is-6' value={false}>
                {textNo}
              </Radio>
            </Radio.Group>
          )}
        </div>
      </div>
    </div>
  );
};

const BusinessQuestions = ({ questions = [], onAnswerChange }) => {
  return (
    questions.length > 0 && (
      <div key={`business_questions`} className='px-4 mb-3'>
        <div className='card'>
          <div className='card-content'>
            {questions.map((q) => {
              return (
                <BusinessQuestion
                  key={`question_${q.id}`}
                  id={q.id}
                  question={q.prompt}
                  textYes={q.textYes}
                  textNo={q.textNo}
                  isUserResponseRequired={q.isUserResponseRequired}
                  onAnswerChange={onAnswerChange}
                />
              );
            })}
          </div>
        </div>
      </div>
    )
  );
};

export default BusinessQuestions;
