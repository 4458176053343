import React, { useState } from "react";
import { useHistory } from "react-router";
import { isEmpty } from "lodash";
import moment from "moment";
import { MdNavigateBefore, MdNavigateNext } from "react-icons/md";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Box, Title, Button, Icon } from "@safelyq/bulma-ui-library";
import { NextArrow, PreviousArrow } from "./PromotionCard.styled";
import { useGetCoupons } from "../../routes/ManageBooking/hooks";
import { Skeleton, Spin } from 'antd'
import CouponItem from "./components/CouponItem";
import { Switch, Route } from 'react-router-dom';
import { PromotionDetailModal } from "../PromotionDetailModal";
import { useMutation } from "@apollo/client";
import { FAVORITES_BUSINESS_COUPON } from "./requests";
import { toast } from "react-toastify";
const SampleNextArrow = (props) => (<NextArrow onClick={props.onClick} > {" "} < MdNavigateNext /> {" "} </NextArrow>);
const SamplePrevArrow = (props) => (<PreviousArrow onClick={props.onClick}  > {" "} < MdNavigateBefore /> {" "} </PreviousArrow>);

export const PromotionCard = () => {
  const [loader, setLoader] = useState(false)
  const [couponId, setCouponId] = useState(0)

  const { loading, error, business, refetch } = useGetCoupons();
  const [onFavoritesBusinessCoupon] = useMutation(FAVORITES_BUSINESS_COUPON);
  const { push } = useHistory();


  const handleCloseModal = () => {
    push("/business/" + business.id)
  }

  const handleFavorites = async (businessCouponId, isFavorite) => {
    try {
      setLoader(true)
      setCouponId(businessCouponId)
      await onFavoritesBusinessCoupon({ variables: { businessCouponId, isFavorite: !isFavorite, }, });
      await refetch()
      setLoader(false)
      setCouponId(0)
      if (isFavorite) {
        toast.success('Removed From Favorites');
      }
      else {
        toast.success('Add To Favorites');
      }
    } catch (error) {
      console.log(error);
      toast.error(error);
    }

  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    nextArrow: < SampleNextArrow />,
    prevArrow: < SamplePrevArrow />,
    responsive: [
      { breakpoint: 1280, settings: { slidesToShow: 1 } },
      { breakpoint: 1024, settings: { slidesToShow: 1 } },
      { breakpoint: 600, settings: { slidesToShow: 1 } },
    ],
  };

  if (loading) {
    return (<Skeleton.Input style={{ height: '200px', }} active={true} size={"large"} block={true} />)
  }
  else {

    return (
      <>
        <Box bordered className="  pb-5" >
          <Slider {...settings}> {
            business.businessCoupons.map((coupon) => <CouponItem loader={loader} key={coupon.id} coupon={coupon} couponId={couponId} businessId={business.id} handleFavorites={handleFavorites} />)}
          </Slider>
          <PromotionsList couponId={couponId} businessCoupons={business.businessCoupons} loader={loader} businessId={business.id} handleFavorites={handleFavorites} />
        </Box>
        <Switch>
          <Route path='/business/:businessId/coupon/:couponId'>
            <PromotionDetailModal
              isOpen={true}
              businessCoupons={business.businessCoupons}
              onCloseModal={handleCloseModal}
              handleFavorites={handleFavorites}
              loader={loader}
            />
          </Route>
        </Switch>
      </>

    );
  }

};

export const PromotionsList = ({ businessCoupons, loader, businessId, handleFavorites, couponId }) => {
  const [isExpand, setExpand] = useState(false);
  const { push } = useHistory();

  const handleToggle = () => setExpand(!isExpand);

  const handleModalOpen = (couponId) =>
    push(`/business/${businessId}/coupon/${couponId}`);


  return (
    <Box bordered >
      <ul>
        {businessCoupons.map(({ id, title, endDate, summary, isFavorite }, index) =>
        (
          <li key={`promotion-${id}`} className={!index == 0 && !isExpand && "is-hidden"} >
            <div className="is-flex is-justify-content-space-between" >
              <Title size="6" style={{ color: "blue" }} className="mt-2 mb-1" >
                {String(title).length > 25 ? String(title).substring(0, 25) + "..." : title} {" "} < br />
                <small >
                  <i > Expires {moment(endDate).format("DD MMM YYYY")} </i>
                </small>
              </Title>
              <div className='is-justify-content-center is-flex'>
                <Spin spinning={couponId === id ? loader : false}>
                  <Button size="small" className="button-icon is-active mr-2" onClick={() => handleFavorites(id, isFavorite)} >
                    <Icon name={isFavorite ? "heart" : "heart-o"} />
                  </Button>
                </Spin>

                <Button size="small" className="button-icon is-active" onClick={() => handleModalOpen(id)} >
                  <Icon name="eye" />
                </Button>
              </div>
            </div>
            <a onClick={() => handleModalOpen(id)} >
              <small style={{ fontWeight: "800" }} >
                {String(summary).length > 40 ? (String(summary).substring(0, 40) + "...") : isEmpty(summary) ? (<i > No summary </i>) : (summary)}
              </small>
            </a>
            <hr />
          </li>
        )
        )
        }
        {businessCoupons?.length > 1 &&
          (
            <div className="has-text-centered" >
              <a onClick={handleToggle} >
                {!isExpand ? (<span > {" "} More <Icon name="angle-down" /> {" "} </span>) : (<span > {" "}  Less < Icon name="angle-up" /> {" "} </span>)}
              </a>
            </div>
          )
        }
      </ul>
    </Box>
  );
};