import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { Message, Heading, Content, List } from '@safelyq/bulma-ui-library';

export const InstructionSkeleton = () => {
  return (
    <Message background='white'>
      <Message.Body>
        <Content>
          <Heading size='6'>Special Instructions</Heading>
          <List type='unordered'>
            {[0].map((_, index) => (
              <List.Item key={`instruction-skeleton-${index}`}>
                <small>{<Skeleton />}</small>
              </List.Item>
            ))}
          </List>
        </Content>
        <Content>
          <Heading size='6'>Personal Safety Standards </Heading>
          <List type='unordered'>
            {[0, 1].map((_, index) => (
              <List.Item key={`safety-tag-${index}`}>
                <small>{<Skeleton />}</small>
              </List.Item>
            ))}
          </List>
        </Content>
      </Message.Body>
    </Message>
  );
};
