import React, { useState, Fragment, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { isEmpty } from 'lodash';
import {
  Button,
  Columns,
  Column,
  Buttons,
  Icon,
} from '@safelyq/bulma-ui-library';
import { useForm, useFormState, useFieldArray } from 'react-hook-form';
import { APPOINTMENT_CONFIRMATION_MESSAGES } from '../../common/constants';
import { FiInfo } from "react-icons/fi";
import { Tooltip } from 'antd';

export const AppointmentConfirmationSetup = ({
  tab,
  data: business,
  onSubmit: onSubmitParent,
  isLoading,
  toolTip
}) => {
  const [isNewQuestion, setIsNewQuestion] = useState(false);

  const [error, setError] = useState('');

  const {
    register,
    control,
    handleSubmit: onSubmit,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = useForm();

  const { dirtyFields } = useFormState({ control });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'questions',
    keyName: 'key',
  });

  useEffect(() => {
    if (!isEmpty(business)) {
      let {
        preCheckinInstructions,
        appointmentConfirmationOffset,
        isAppointmentConfirmationRequired,
        questions,
      } = business;

      questions = questions?.map((question) => ({
        ...question,
        isEditable: false,
        isAllowDelete: false,
      }));

      reset({
        confirmationOffset: String(appointmentConfirmationOffset),
        specialInstructions: preCheckinInstructions[0] || '',
        confirmationIsRequired: isAppointmentConfirmationRequired,
        questions,
      });
    }
  }, [business]);

  const handleQuestionCancel = (index) => remove(index);

  const handleFieldMakeEditable = (index) => {
    const isEditable = fields.find(
      (question, indx) => question.isEditable && index !== indx
    );

    if (isEditable) {
      alert('Please check mark to add changes first');
      return;
    }

    if (!fields[index].isEditable) {
      fields[index].isEditable = true;

      setIsNewQuestion(true);

      reset({ questions: fields });
    } else {
      if (isEmpty(errors)) {
        let questions = watch('questions');

        questions[index].isEditable = false;

        setError('');

        reset({ questions }, { keepDirty: true });
      }
    }
  };

  const handleAddMore = (e) => {
    e.preventDefault();

    const isEditable =
      fields.length > 0
        ? fields.filter((question) => question.isEditable).length > 0
        : false;

    if (isEditable) {
      setError('Please check mark to add this question');
      return;
    }

    setIsNewQuestion(false);

    append({
      prompt: '',
      textYes: 'Yes',
      textNo: 'No',
      isEditable: true,
      isAllowDelete: true,
      isActive: false,
    });
  };

  const handleSubmit = (values) => {
    fields.forEach(function (v) {
      delete v.isEditable;
      delete v.isAllowDelete;
      delete v.key;
    });
    if (tab == 'LOACTION') {
      let businessQuestions = fields?.filter(
        (question) => Object.keys(question).length !== 0
      );

      businessQuestions = businessQuestions.map(
        ({ isUserResponseRequired, ...rest }) => ({
          ...rest,
          isUserResponseRequired: Boolean(isUserResponseRequired),
        })
      );

      onSubmitParent(
        {
          appointmentConfirmationSetup: {
            preCheckinInstructions: values.specialInstructions,
            preCheckinInstructionsAcceptText: 'Accept',
            preCheckinInstructionsRejectText: 'Reject',
            isAppointmentConfirmationRequired: values.confirmationIsRequired,
            appointmentConfirmationOffset: values.confirmationOffset,
            businessQuestions,
          },
        },
        8,
        {
          success: APPOINTMENT_CONFIRMATION_MESSAGES.Success,
          error: APPOINTMENT_CONFIRMATION_MESSAGES.Error,
        }
      );
    } else {
      let businessQuestions = fields?.filter(
        (question) => Object.keys(question).length !== 0
      );

      businessQuestions = businessQuestions.map(
        ({ isUserResponseRequired, ...rest }) => ({
          ...rest,
          isUserResponseRequired: Boolean(isUserResponseRequired),
        })
      );

      onSubmitParent(
        {
          appointmentConfirmationSetup: {
            preCheckinInstructions: values.specialInstructions,
            preCheckinInstructionsAcceptText: 'Accept',
            preCheckinInstructionsRejectText: 'Reject',
            isAppointmentConfirmationRequired: values.confirmationIsRequired,
            appointmentConfirmationOffset: values.confirmationOffset,
            businessQuestions,
          },
        },
        8,
        business.id,
        {
          success: APPOINTMENT_CONFIRMATION_MESSAGES.Success,
          error: APPOINTMENT_CONFIRMATION_MESSAGES.Error,
        }
      );
    }
  };

  return (
    <form onSubmit={onSubmit(handleSubmit)}>
      <Columns multiline>
        <Column size='4'>
          <div className='control'>
            <label className='label is-flex is-justify-content-space-between is-align-content-center is-align-items-center'>
              Customer can confirm X-mins before appointment
              <Tooltip title={toolTip?.customerCanConfirmX_MinsBeforeAppointment}> <FiInfo /> </Tooltip>
            </label>
            <input
              className='input'
              type='number'
              {...register('confirmationOffset')}
            />
          </div>
        </Column>
        <Column size='4'>
          <div className='control'>
            <label className='label is-flex is-justify-content-space-between is-align-content-center is-align-items-center'>Special Instructions
              <Tooltip title={toolTip?.specialInstructions}> <FiInfo /> </Tooltip>
            </label>
            <textarea
              className='textarea'
              rows={3}
              {...register('specialInstructions')}
            />
          </div>
        </Column>
        <Column size='4'>
          <div className='control'>
            <label className='label is-hidden-mobile'>&nbsp;</label>
            <label className='checkbox'>
              <input type='checkbox' {...register('confirmationIsRequired')} />
              <strong className='ml-2'>
                Appointment Confirmation Allowed ?
              </strong>
              <Tooltip title={toolTip?.appointmentConfirmationAllowed}> <FiInfo /> </Tooltip>
            </label>
          </div>
        </Column>
        <Column size='12'>
          <label className='label'>
            Confirmation Questions <small>({fields?.length} of 10)</small>
          </label>
          <small>
            Maximum 10 questions. Question response can be either yes/no or text.
          </small>
        </Column>
        {fields?.map(({ key, isEditable, isUserResponseRequired }, index) => (
          <Fragment>
            <Column size='4'>
              <div className='control'>
                <label className='label'>
                  Question {index + 1}{' '}
                  <span className='has-text-danger'>*</span>
                </label>
                <div className='is-flex'>
                  <label className='checkbox'>
                    <input
                      type='checkbox'
                      disabled={!isEditable}
                      {...register(`questions.${index}.isActive`)}
                    />
                    {/*<strong className='ml-2'>Is Active ?</strong>*/}
                  </label>
                  &nbsp;&nbsp;
                  <input
                    key={key}
                    disabled={!isEditable}
                    className={`input ${!isEmpty(errors) && errors?.questions[index]?.prompt
                      ? 'is-danger'
                      : ''
                      }`}
                    {...register(`questions.${index}.prompt`, {
                      required: true,
                    })}
                  />
                </div>
                {!isEmpty(errors) && errors?.questions[index]?.prompt && (
                  <p className='help is-danger'>Question is required</p>
                )}
              </div>
            </Column>
            <Column size='5'>
              <label className='label'>
                Response
                <span className='has-text-danger'>*</span>
              </label>
              <div>
                <div className='is-flex'>
                  <label className='radio'>
                    <input
                      type='radio'
                      name={`questions.${index}.isUserResponseRequired`}
                      defaultChecked={!isUserResponseRequired}
                      value={false}
                      onChange={() =>
                        setValue(
                          `questions.${index}.isUserResponseRequired`,
                          false,
                          {
                            shouldDirty: true,
                          }
                        )
                      }
                      disabled={!isEditable}
                    />
                  </label>
                  &nbsp;
                  <div className='control'>
                    <input
                      key={key}
                      disabled={!isEditable}
                      className={`input is-small ${!isEmpty(errors) && errors?.questions[index]?.textYes
                        ? 'is-danger'
                        : ''
                        }`}
                      {...register(`questions.${index}.textYes`)}
                    />
                  </div>
                  &nbsp;
                  <div className='control'>
                    <input
                      key={key}
                      disabled={!isEditable}
                      className={`input is-small ${!isEmpty(errors) && errors?.questions[index]?.textNo
                        ? 'is-danger'
                        : ''
                        }`}
                      {...register(`questions.${index}.textNo`)}
                    />
                  </div>
                </div>
                <br />
                <div className='is-flex'>
                  <label className='radio'>
                    <input
                      type='radio'
                      name={`questions.${index}.isUserResponseRequired`}
                      defaultChecked={isUserResponseRequired}
                      value={true}
                      onChange={() =>
                        setValue(
                          `questions.${index}.isUserResponseRequired`,
                          true,
                          {
                            shouldDirty: true,
                          }
                        )
                      }
                      disabled={!isEditable}
                    />
                  </label>
                  &nbsp;
                  <div className='control'>
                    <input
                      className='input is-small'
                      disabled={true}
                      placeholder='Text answer required'
                    />
                  </div>
                </div>
              </div>
            </Column>
            <Column size='1'>
              <label className='label is-hidden-mobile'>&nbsp;</label>
              <Buttons size='small'>
                {isEditable ? (
                  <Fragment>
                    <Button
                      color='info'
                      type='button'
                      onClick={() => handleFieldMakeEditable(index)}
                    >
                      <Icon name='check' />
                    </Button>
                    {!isNewQuestion && (
                      <Button
                        color='info'
                        type='button'
                        onClick={() => handleQuestionCancel(index)}
                      >
                        <Icon name='times' />
                      </Button>
                    )}
                  </Fragment>
                ) : (
                  <Button
                    color='info'
                    type='button'
                    onClick={() => handleFieldMakeEditable(index)}
                  >
                    <Icon name='pencil' />
                  </Button>
                )}
              </Buttons>
            </Column>
            <Column size='2' />
          </Fragment>
        ))}
        {error && (
          <Column size='12'>
            <span className='has-text-danger'>{error}</span>
          </Column>
        )}
        <Column size='12'>
          <Link to='#' className='has-text-info' onClick={handleAddMore}>
            <Icon name='plus' className='mr-2' />
            Add Question
          </Link>
        </Column>
        <Column size='12'>
          <Button
            type='submit'
            color='info'
            disabled={isEmpty(dirtyFields) || isLoading}
            loading={isLoading}
          >
            Save
          </Button>
        </Column>
      </Columns>
    </form>
  );
};
