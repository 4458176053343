import React, { useState, useEffect, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { isEmpty } from 'lodash';
import {
  Columns,
  Column,
  TextInput,
  Field,
  Control,
  Title,
  Icon,
  Button,
  Modal,
} from '@safelyq/bulma-ui-library';
import { useLocation } from 'hooks/useLocation';
import { useSearchDispatch, useSearchState } from '@safelyq/search-feature';
import { radiusArray } from './constants';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

export const SearchBar = () => {
  const dispatch = useSearchDispatch();
  const searchRadiiState = useSelector(state => state?.consumer?.searchRadii);
  const allowChatSLB = useSelector(state => state?.consumer?.allowChatSLB);
  const {
    location,
    searchSchema: { radius, searchText },
    isCurrentLocationSelected,
  } = useSearchState();
  const history = useHistory();

  const [open, setOpen] = useState();

  const [isOpenModal, setModalOpen] = useState(false);

  // Get the coordinates (longitude and latitude) from the browser
  const { isLocationEnable, coords } = useLocation();

  useEffect(() => {
    isLocationEnable && handleCurrentLocationRequest();
  }, [isLocationEnable]);

  useEffect(() => {
    if (isLocationEnable) setModalOpen(false);
    else setModalOpen(true);
  }, [isLocationEnable]);

  const handleDropdown = () => setOpen(!open);

  const handleNameChange = ({ target: { value } }) =>
    dispatch({ type: 'SET_NAME', payload: value });

  const handleRadiusChange = (value) =>
    dispatch({ type: 'SET_RADIUS', payload: value });

  const handleAreaChange = ({ target: { value } }) =>
    dispatch({ type: 'SET_AREA', payload: value });

  const handleCoordsChange = (coords) =>
    dispatch({ type: 'SET_COORDS', payload: coords });

  const handleSearchRequest = () => {
    if (isEmpty(searchText) && isEmpty(coords))
      toast.error('Please enter name / keyword or location');
    else dispatch({ type: 'SET_SEARCH_REQUEST', payload: { status: true, coords: coords } });
  };

  const handleLocationStatus = (status) =>
    dispatch({ type: 'SET_LOCATION_STATUS', payload: status });

  const handleCurrentLocationRequest = () => {
    if (isLocationEnable) {
      handleLocationStatus(true);
      handleCoordsChange(coords);
      setModalOpen(false);
    } else {
      setModalOpen(true);
      // new Notification(
      //   'Please enable you current location for better SafelyQ search experience'
      // );
    }
  };

  const handleKeyDown = ({ key }) => {
    if (key === 'Enter') handleSearchRequest();
  };

  const handleCloseModal = () => setModalOpen(false);

  return (
    <Fragment>
      <Columns multiline>
        <Column size={isCurrentLocationSelected ? '4' : '5'}>
          <Field>
            <Control>
              <TextInput
                autoFocus
                placeholder='Search by name, keyword'
                className='search-bar-input'
                onKeyDown={handleKeyDown}
                onChange={handleNameChange}
              />
              <Icon name='search' color='info' position='left' />
            </Control>
          </Field>
        </Column>
        <Column size={isCurrentLocationSelected ? '3' : '4'}>
          <Field>
            <Control>
              <TextInput
                placeholder='City, State, Zip code'
                className={`search-bar-input ${isCurrentLocationSelected && 'is-disabled-searchbar'
                  }`}
                value={location}
                onChange={handleAreaChange}
                onKeyDown={handleKeyDown}
                onFocus={() => handleLocationStatus(false)}
              />
              <Icon name='map-marker' color='info' position='left' />
            </Control>
          </Field>
        </Column>
        <Column size='2'>
          {
            // If coordinates are not available than show Current Locations button else hide
            coords.latitude === 0
            &&
            coords.longitude === 0
            &&
            <Button
              color='danger'
              fullwidth
              // light
              // disabled
              className={
                !isCurrentLocationSelected ? 'is-danger-disabled' : 'is-light'
              }
              onClick={handleCurrentLocationRequest}
            >
              <Icon name='location-arrow' />
              <span>Current Location</span>
            </Button>
          }

        </Column>
        {isCurrentLocationSelected && (
          <Column size='2'>
            <div
              className={`dropdown radius-dropdown  ${open && 'is-active'}`}
              onClick={handleDropdown}
            >
              <div className='dropdown-trigger'>
                <button
                  className='button has-text-left is-non-focusable'
                  aria-haspopup='true'
                  aria-controls='dropdown-menu'
                >
                  <div style={{ width: '100%' }}>
                    <Icon name='street-view' color='info' />
                    <span className='mx-2'>
                      <strong>Radius</strong>
                    </span>{' '}
                    {`${radius}  miles`}
                    <Icon name='caret-down' />
                  </div>
                </button>
              </div>
              <div className='dropdown-menu' id='dropdown-menu' role='menu'>
                <div className='dropdown-content'>
                  {
                    searchRadiiState?.map((radius, index) => (
                      <Link
                        to='#'
                        onClick={() => handleRadiusChange(radius?.value)}
                        className='dropdown-item'
                        key={`radius-${index}`}
                      >
                        {radius?.value} miles
                      </Link>
                    ))
                  }
                  {/* {(searchRadii || radiusArray).map((radius, index) => (
                    <Link
                      to='#'
                      onClick={() => handleRadiusChange(radius?.value)}
                      className='dropdown-item'
                      key={`radius-${index}`}
                    >
                      {radius?.value} miles
                    </Link>
                  ))} */}
                </div>
              </div>
            </div>
          </Column>
        )}
        <Column size='1' className='has-text-right-desktop has-text-centered'>
          <Button
            color='info'
            aria-label='search'
            fullwidth={true}
            onClick={handleSearchRequest}
          >
            <Icon name='search' />
          </Button>
        </Column>
      </Columns>

      {
        allowChatSLB &&
        <>
          <p className='has-text-centered has-text-weight-semibold is-size-5 my-5'>Or harness the power of our cutting-edge LLM model for expert assistance</p>

          <Columns multiline className='is-flex is-justify-content-center'>
            <Column size={'3'}>
              <Button
                color='info'
                aria-label='ChatSLB'
                fullwidth={true}
                onClick={() => history.push("/search-business-ai")}
              >
                {/* <Icon name='search' /> */}
                <p>ChatSLB</p>
              </Button>
            </Column>
          </Columns>
        </>
      }

      <Modal active={isOpenModal}>
        <Modal.Background />
        <Modal.Card style={{ borderRadius: '8px' }}>
          <Modal.Card.Body>
            <div className='mb-5 is-flex is-justify-content-space-between is-align-items-center'>
              <Title size='5' className='m-0 has-text-danger'>
                We Can't Seem to Find Your Location
              </Title>
              <Icon
                name='times'
                className='is-hoverable has-text-link'
                onClick={handleCloseModal}
              />
            </div>
            <div className='content'>
              <p>
                Try refreshing the page and if the browser asks for your
                location permission, click <strong>Allow.</strong>
              </p>
              <p>
                If these steps do not works, try:
                <ol>
                  <li>
                    At the top of your window, near the web address, click the
                    lock icon.
                  </li>
                  <li>
                    In the window that pop up, make sure{' '}
                    <strong>Location</strong> is set to <strong>Ask</strong> or{' '}
                    <strong>Allow</strong>.
                  </li>
                  <li>Reload the page and try search again.</li>
                </ol>
              </p>
              <p>
                If you're still having trouble, check out the browser support
                page. You can also search near a city, place, or address
                instead.
              </p>
            </div>
          </Modal.Card.Body>
        </Modal.Card>
      </Modal>
    </Fragment>
  );
};
