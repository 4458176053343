import { gql } from "@apollo/client";

export const GET_BUSINESSES_BY_GEMENI = gql`
query SearchBusinessesByGemini($searchBusinessByGeminiInput: LocalBusinessesInput) {
searchBusinessesByGemini(searchBusinessByGeminiInput: $searchBusinessByGeminiInput){
     isSuccess
		 errorMessage
		 businesses{
			logoUrl
      picture {
        id
        path
      }
      name
      businessCategory {
        name
        description
      }
      id
		 }
		 detailedDescription
  }
  }
`;

export const GET_CHATSLB_TEXT = gql`
query getChatSLB{
	getChatSLBPageInfo{
		isSuccess
		errorMessage
		chatSLBInfo{
			title
			subTitle
			description
			suggestions
		}
	}
}
`;

export const ADD_REMOVE_FROM_FAVORITES = gql`
    mutation addRemoveFromFavorites($businessId: Int!, $isFavorite: Boolean!) {
      addRemoveFromFavorites(businessId: $businessId, isFavorite: $isFavorite) {
        id
        business {
          id
        }
        user {
          id
        }
      }
    }
  `;

export const GET_CURRENT_USER_FAVORITES = gql`
    query all($businessId: Int) {
      getCurrentUserFavorites(businessId: $businessId) {
        id
        business {
          id
          name
          description
          address1
          categoryTags
          safetyTags
          phone
          webAddress
		  isFavorite
          menus {
            id
            path
          }
          menu {
            id
            path
          }
          pictures {
            id
            path
          }
          picture {
            id
            path
          }
          promotions {
            id
            path
            text
          }
          businessCategory {
            name
            description
          }
        }
      }
    }
  `;