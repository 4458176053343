import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { isUndefined } from 'lodash';
import { useVerifyClaim } from 'hooks/useVerifyClaim';
import { useCheckClaim } from 'hooks/useCheckClaim';
import { APIPaths } from 'services/api-provider';
import { BsCheckCircle } from "react-icons/bs";
import "./VerifyAccount.css"

/* eslint jsx-a11y/anchor-is-valid: 0 */

const CodeValidator = /\d\d\d\d\d\d/;

const CodeEntry = ({ ready, valid, value, children, onChange }) => {
  const dirty = !isUndefined(value);

  return (
    <div className='field'>
      <div
        className={
          ready
            ? 'control has-icons-left has-icons-right'
            : 'control has-icons-left'
        }
      >
        <input
          className={dirty && !valid ? 'input is-danger' : 'input'}
          type='text'
          name='code'
          defaultValue={value}
          maxLength={6}
          onChange={onChange}
        />
        <span className='icon is-small is-left'>
          <i className='fa fa-hashtag' />
        </span>
        {ready && (
          <span className='icon is-small is-right'>
            <i className={valid ? 'fa fa-check' : 'fa fa-times'} />
          </span>
        )}
        {children}
      </div>
    </div>
  );
};

const VerifyAccount = () => {
  const [claim, setClaim] = useState({});
  const [code, setCode] = useState();
  const [success, setSuccess] = useState(false);

  const { journey, phoneNumber, email, password } = claim || {};
  const channel =
    journey === 'phone-number' ? 'sms' : journey === 'email' ? 'email' : '';
  const to =
    journey === 'phone-number' ? phoneNumber : journey === 'email' ? email : '';

  const loadClaim = useCallback(() => {
    setClaim(JSON.parse(window.localStorage.getItem('claim') || '{}'));
  }, []);
  const unsaveClaim = useCallback(() => {
    window.localStorage.removeItem('claim');
  }, []);
  const showSuccess = useCallback(() => {
    setSuccess(true);

    toast.success(`You have successfully claimed the account linked to ${to}`);
  }, [to]);
  const showError = useCallback((error) => {
    setCode();

    toast.error(error.message);
  }, []);

  const isCodeEntered = (code || '').length > 0;
  const isCodeValid = CodeValidator.test(code);
  const isPhoneNumberJourney = journey === 'phone-number';
  const isEmailJourney = journey === 'email';

  useEffect(() => {
    loadClaim();
    unsaveClaim();

    return () => { };
  }, [loadClaim, unsaveClaim]);

  const { isLoading, verify } = useVerifyClaim(
    {
      channel,
      to,
      code,
      phoneNumber,
      email,
      password,
    },
    showSuccess,
    showError
  );


  const resend = async (e) => {
    e.preventDefault();
    try {
      const payload = {
        journey: claim.journey,
        email: claim.email,
        password: claim.password
      }
      const response = await fetch(`${APIPaths.Gateway}/Account/CheckClaim`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      })

      toast.success("New Verification code is send to your email address")

      const res = await response.json()
    } catch (error) {
      console.log("error", error);
    }



  };

  return (
    <div className='bscontainer-fluid verify_wrapper '>
      <div className='row is-justify-content-center is-align-items-center ' style={{ height: "100vh" }}>
        {success ? (
          <div id="card" className="animated fadeIn">
            <div id="upper-side">
              <BsCheckCircle size={88} />
              <h3 id="status">  Success   </h3>
            </div>
            <div id="lower-side">
              <p id="message">  Congratulations, your account has been successfully created.  </p>
              <Link id="contBtn" to='/'>Continue</Link>
              <p>
                <small>Or click  <a href={`${APIPaths.Gateway}/Account/login`}>here</a> to login</small>
              </p>
              {/* <Link id="loginbtn" to={`${APIPaths.Gateway}/account/login`}>here</Link> */}
            </div>
          </div>


        ) : (
          <div className='col-12 col-md-4 '>
            <h1 className='title'>Claims</h1>
            <p className='subtitle'>Claim  your pre-created account</p>
            <div className='content'>
              <CodeEntry ready={isCodeEntered} valid={isCodeValid} value={code} onChange={(e) => setCode(e.target.value)} >
                {isPhoneNumberJourney && (<p className={isCodeValid ? 'help is-info' : 'help is-danger'}   >  Enter the verification code you have received on  your phone </p>)}
                {isEmailJourney && (<p className={isCodeValid ? 'help is-info' : 'help is-danger'}  >  Enter the verification code you have received in your inbox  </p>)}
              </CodeEntry>
              <div className='field is-grouped'>
                <div className='control'>
                  <button className={isLoading ? 'button is-link is-loading' : 'button is-link'} disabled={!isCodeEntered} onClick={verify}  >  Verify  </button>
                </div>
              </div>
              <div className='field'>
                <p className='is-size-6'>   We will confirm the verification code. Did not receive the code?{' '}
                  <a href='#' onClick={resend}>  Request a new verification code </a>
                </p>
              </div>
            </div>
          </div>
        )}

      </div>
    </div>
  );
};

export default VerifyAccount;
