import { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import moment from 'moment';
import { toast } from 'react-toastify';
import { useMutation } from '@apollo/client';
import { CREATE_USER_APPOINTMENT } from './requests';

//for firestore

export const useData = ({ data, ...rest }) => {
  const { isLoggedIn, login: tryToLogin, persistStorage } = rest;


  let { businessId } = useParams();

  const { push } = useHistory();

  businessId = parseInt(businessId);

  const [onCreateAppointment, { loading: isLoading, error }] = useMutation(
    CREATE_USER_APPOINTMENT,
    {
      fetchPolicy: 'no-cache',
      onCompleted: async ({ createUserAppointment: { appointment, errorMessage, }, }) => {
        errorMessage
          ? toast.error(errorMessage, { hideProgressBar: true })
          : push(`/${appointment.id}/confirm-checkin`);
      },
      onError: ({ createUserAppointment: { errorMessage } }) =>
        toast.error(
          errorMessage ||
          `Something went wrong. We couldn't book that appointment`,
          { hideProgressBar: true }
        ),
    }
  );


  const handleAppointmentCreate = async () => {
    if (!isLoggedIn) {
      persistStorage(data);
      await tryToLogin();
      return;
    }

    localStorage.removeItem('persist-data');

    const {
      service: serviceId,
      adults,
      children,
      notes,
      selectedSlot: {
        startTime,
        endTime,
        timeZone,
        subBusiness: { id: subBusinessId },
      },
    } = data;

    const appointmentInput = {
      adults,
      children,
      notes,
      startTime: moment(startTime).utc().format('YYYY-MM-DD hh:mm A'),
      endTime: moment(endTime).utc().format('YYYY-MM-DD hh:mm A'),
      timeZone,
      serviceId,
      businessId,
      subBusinessId,
      userResponses: [],
    };

    onCreateAppointment({
      variables: {
        appointmentInput,
      },
    });
  };

  return { onCreateAppointment: handleAppointmentCreate, isLoading, error };
};
