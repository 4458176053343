import React, { useState } from 'react';
import { Modal, Button } from '@safelyq/bulma-ui-library';
import Swiper from 'react-id-swiper';
import SwiperCore, { Pagination, Navigation, Keyboard } from 'swiper';
import 'swiper/swiper-bundle.css';
import { MobileImage } from './components'

SwiperCore.use([Pagination, Navigation, Keyboard]);

export const MenuList = ({ menus }) => {

    const [translateImageX, setTranslateImageX] = useState(0)
    const [translateImageY, setTranslateImageY] = useState(0)
    const [isZoomed, setIsZoomed] = useState(false)
    const [isModalOpen, setModalOpen] = useState(false);

    const params = {
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
            type: 'bullets',
            bulletClass: "swiper-pagination-bullet-modal",
            bulletActiveClass: "swiper-pagination-bullet-active-modal",
            renderBullet: (index, className) => {
                return '<img class="' + className + `"src="` + menus[index].path + '">'
            }
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        keyboard: {
            enabled: true,
            onlyInViewport: false
        },
        on: {
            slideChange: function () {
                setIsZoomed(false)
                menus.map((menu, i) => {
                    document.getElementById(`zoom-${i}`).checked = false
                })
            },
        },
    }


    const handleMenu = () => setModalOpen(!isModalOpen);
    return (
        <>
            <Button className='tag mb-1 has-text-black' onClick={handleMenu} weight='bold' style={{ fontSize: 13 }}    >
                <strong className='is-size-7'>Explore Menu</strong>
            </Button>
            <Modal className="is-justify-content-flex-start image-modal" style={{ "--displayControl": `${isZoomed && "none"}` }} active={isModalOpen}>
                <Modal.Content style={{ overflow: "visible" }} className="img-modal-content">
                    {isModalOpen && (
                        <Swiper {...params}>
                            {menus.map((menu, index) => (
                                <div style={{ width: "auto" }}>
                                    <div style={{ height: "100vh", "--x": `${translateImageX}px`, "--y": `${translateImageY}px` }} className="is-flex is-justify-content-center is-align-items-center click-zoom">
                                        <label className='is-hidden-touch'>
                                            <input onChange={() => {
                                                setTranslateImageX(0)
                                                setTranslateImageY(0)
                                                setIsZoomed(!isZoomed)
                                            }} id={`zoom-${index}`} type="checkbox" />
                                            <img
                                                onMouseMove={(e) => {
                                                    if (document.getElementById(`zoom-${index}`).checked) {
                                                        setTranslateImageX(translateImageX - e.movementX)
                                                        setTranslateImageY(translateImageY - e.movementY)
                                                    }
                                                }}
                                                className="slider-img"
                                                data-target="menu-modal"
                                                src={menu.path}
                                                alt='Business Brand'
                                            />
                                        </label>
                                        <MobileImage src={menu.path} />
                                    </div>
                                </div>
                            ))}
                        </Swiper>
                    )}
                </Modal.Content>

                <Modal.Close className="is-large" onClick={(e) => {
                    setModalOpen(!isModalOpen)
                }}>Close</Modal.Close>
            </Modal>
        </>
    );
};
