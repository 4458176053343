import React, { Fragment } from 'react';
import {
  Box,
  Icon,
  Button,
  Content,
  Column,
  Field,
  Label,
} from '@safelyq/bulma-ui-library';
import { Link } from 'react-router-dom';

import { AvailableSlots, BasicInformation } from './components';
import {
  BookingProvider,
  useBookingState,
  useBookingDispatch,
} from './context';
import { useData } from './hooks';

export const BookingSection = ({ business, ...props }) => {
  return (
    <BookingProvider>
      <BookingForm business={business} {...props} />
    </BookingProvider>
  );
};

const BookingForm = ({ business, ...props }) => {
  const { isAllowToCreate } = props;

  const data = useBookingState();

  const dispatch = useBookingDispatch();

  const { onCreateAppointment, isLoading } = useData({
    data,
    ...props,
  });

  const { service, selectedSlot } = data;

  const { timeZone, showNotesInput } = business;

  const isInputsFullfill = service && selectedSlot;

  const handleNotesChange = ({ target: { value } }) =>
    dispatch({ type: 'SET_NOTES', payload: value });

  return (
    <Box bordered>
      <Content>
        <div className='icon-text is-align-items-center'>
          <Icon name='calendar-check-o' collection='fa'></Icon>
          <h5 className='is-marginless'>New Appointment</h5>
        </div>
      </Content>
      <section>
        {isAllowToCreate ? (
          <Fragment>
            <BasicInformation business={business} />
            <AvailableSlots timeZone={timeZone} />
            {showNotesInput && (
              <Field>
                <Label size='small' for='notes'>
                  Notes
                </Label>
                <textarea
                  id='notes'
                  name='notes'
                  className='textarea'
                  onChange={handleNotesChange}
                ></textarea>
              </Field>
            )}
            <Button
              color='info'
              disabled={!isInputsFullfill}
              loading={isLoading}
              onClick={onCreateAppointment}
            >
              Set Appointment
            </Button>
          </Fragment>
        ) : (
          <span>
            <center>
              This business does not allow guest to create appointments. Please
              <Link to={`/messages/${business.id}`}> message</Link> the business
              to schedule an appointment.
            </center>
          </span>
        )}
      </section>
    </Box>
  );
};
