import React, { createContext, useCallback, useContext, useReducer, useState } from 'react';
import { APIPaths } from '../../services/api-provider';
import * as axios from 'axios';
import Logo from '../../assets/triangle-logo.png';

/* eslint jsx-a11y/anchor-is-valid: 0 */
const INITIAL_STATE = {
  state: 'get-email'
};
const GET_EMAIL_STATE = 'get-email';
const SHOW_SUCCESS_STATE = 'show-success';
const SHOW_FAILURE_STATE = 'show-failure';

function reducer(state, action) {
  switch (action.type) {
    case GET_EMAIL_STATE:
    case SHOW_SUCCESS_STATE:
      return {
        state: action.type
      };

    case SHOW_FAILURE_STATE:
      return {
        state: action.type,
        error: action.error
      };

    default:
      throw new Error();
  }
}

const DispatchContext = createContext();

const VerifyEmail = () => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  return (
    <DispatchContext.Provider value={dispatch}>
      {state.state === GET_EMAIL_STATE && <Form/>}
      {state.state === SHOW_SUCCESS_STATE && <Confirmation/>}
      {state.state === SHOW_FAILURE_STATE && <Error error={state.error}/>}
    </DispatchContext.Provider>
  );
};

function Card({ children }) {
  return (
    <div className="hero is-fullheight">
      <div className="hero-body is-paddingless">
        <div className="container has-text-centered">
          <div className="column is-4 is-offset-4">
            <div className="box p-6">
              <a href="/">
                <img src={Logo} alt="logo" width={120} height={120}/>
              </a>
              <div className="title has-text-grey is-6 mt-4 mb-6">Verify your account using your email address</div>
              {children}
            </div>
            <p className="has-text-grey">
              <a href={`${APIPaths.Gateway}/account/register`}>Sign Up</a>
              &nbsp;·&nbsp;
              <a href={`${APIPaths.Gateway}/account/login`}>Sign in</a>
              &nbsp;·&nbsp;
              <a href={`/account/forgot-password`}>Forgot Password</a>
              &nbsp;·&nbsp;
              <a href={`/account/claim`}>Claim Account</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

function Form() {
  const dispatch = useContext(DispatchContext);
  const [email, setEmail] = useState('');

  const callAPI = useCallback(async () => {
    try {
      await axios.default.post(`${APIPaths.Gateway}/account/resend-verification-email`, { email });

      dispatch({ type: SHOW_SUCCESS_STATE });
    } catch (error) {
      dispatch({ type: SHOW_FAILURE_STATE, error });
    }
  }, [axios, dispatch, email]);

  const handleEmailChange = useCallback((e) => setEmail(e.target.value), [setEmail]);

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();

    await callAPI();
  }, [callAPI]);

  return (
    <Card>
      <div className="field">
        <div className="control">
          <input
            className="input is-medium"
            type="email"
            placeholder="Email address"
            value={email}
            onChange={handleEmailChange}
          />
        </div>
      </div>

      <button
        className="button is-block is-primary is-outlined is-medium is-fullwidth my-5"
        disabled={!email}
        onClick={handleSubmit}
      >
        Verify email
      </button>
    </Card>
  );
}

function Confirmation() {
  return (
    <Card>
      <h5>An email has been sent to you. Please check your email to verify your email and login.</h5>
    </Card>
  );
}

function Error({ error }) {
  const dispatch = useContext(DispatchContext);
  
  const resetForm = useCallback(() => dispatch({ type: GET_EMAIL_STATE }), [dispatch]);

  const handleRetry = useCallback((e) => {
    e.preventDefault();

    resetForm();
  }, [resetForm]);

  return (
    <Card>
      <h5>An email could not be sent to you. Please try again later.</h5>
      <h5>{error.message}</h5>

      <button
        className="button is-block is-primary is-outlined is-medium is-fullwidth my-5"
        onClick={handleRetry}
      >
        Try again
      </button>
    </Card>
  );
}

export default VerifyEmail;
