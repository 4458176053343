import React from 'react';
import { gql, useSubscription } from '@apollo/client';

const BusinessSubscriptionTest = () => {
    const { loading, data, error } = useSubscription(SUBSCRIPTION_TEST_QUERY, {
        variables: { businessId: 1, userId: "4d7f2231-a554-4b42-8ae0-d24d65230b39" }
    });

  if (loading) return <h4>Loading...</h4>;

  if (error)
    return (
      <h4>{error ? JSON.stringify(error, null, 4) : 'Something went wrong'}</h4>
    );

    let { businessMessageReceived } = data;

    if (!businessMessageReceived) return null;

    let {
        businessMessageReceived: {
            business: { name },
            comment: { commentText },
            user: { email },
        },
    } = data;

  return (
    <section className='section'>
      <div className='container'>
        <table className='table'>
          <thead>
            <tr>
              <th>Business</th>
              <th>Message</th>
              <th>User</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{name}</td>
              <td>{commentText}</td>
              <td>{email}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>
  );
};

export default BusinessSubscriptionTest;

const SUBSCRIPTION_TEST_QUERY = gql`
  subscription ($businessId: Int!) {
  businessMessageReceived(businessId: $businessId) {
    business {
      name
    }
    comment {
      commentText
    }
    errorMessage
    isSaved
    messageId
    user {
      firstName
      lastName
      name
      email
    }
  }
}
`;
