import { useQuery, useMutation } from '@apollo/client';
import { toast as showToast } from 'react-toastify';
import {
  GET_STRIPE_PUBLISHABLE_KEY,
  STRIPE_TRANSACTION_REQUEST,
} from './requests';

export const useData = (onStripeRequestCompleted) => {
  const {
    error,
    loading: isLoading,
    data,
  } = useQuery(GET_STRIPE_PUBLISHABLE_KEY, {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
  });

  const [
    invokeStripeTransactionRequest,
    { loading: invokingStripeRequest },
  ] = useMutation(STRIPE_TRANSACTION_REQUEST, {
    onCompleted: ({
      invokeStripeTransactionRequest: {
        isSaved,
        errorMessage,
      },
    }) => {
      if (isSaved) {
        showToast.success('Stripe transaction is completed successfully.');
      } else {
        showToast.error('Error Occurred: ' + errorMessage);
      }
      onStripeRequestCompleted();
    },
    onError: ({ message }) => {
      showToast.error(message || 'Something went wrong.');
      onStripeRequestCompleted();
    },
  });

  const { getStripePublishableKey } = data || {};
  const { isSuccess, publishableKey } = getStripePublishableKey || {};

  return {
    isLoading,
    error,
    isSuccess,
    publishableKey,
    invokingStripeRequest,
    invokeStripeTransactionRequest,
  };
};
