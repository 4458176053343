import React from 'react'
import { RecipientBadge, SenderBadge } from '../../ManageUserMessages.styled'
import { Icon } from '@safelyq/bulma-ui-library';
import Linkify from 'linkify-react';

const MessageItemAttatchment = ({ chat }) => {
    const contentType = chat?.attachment?.contentType
    if (String(contentType).includes('image')) {
        return (
            <RecipientBadge>
                <a href={chat.attachment.publicUrl} target='__blank' style={{ background: 'transparent' }}> <img src={chat.attachment.publicUrl} width={150} height={130} alt="attatchment" />  </a>
                {chat.message.commentText && <div className='mt-1'>{chat.message.commentText}</div>}
            </RecipientBadge>
        )
    }
    else if (String(contentType).includes('video')) {
        return (
            <RecipientBadge>
                <a href={chat.attachment.publicUrl} target='__blank' className='is-flex is-align-items-center is-justify-content-space-between py-4' >
                    <span> <Icon name='file-video-o' /> <span className='ml-2'>{chat.attachment.fileName}</span></span>
                    <Icon name='arrow-circle-o-down' />
                </a>
                {chat.message.commentText && <div className='mt-1'>{chat.message.commentText}</div>}
            </RecipientBadge>
        );
    }
    else if (String(contentType).includes('audio')) {
        return (
            <RecipientBadge>
                <a href={chat.attachment.publicUrl} target='__blank' className='is-flex is-align-items-center is-justify-content-space-between py-4' >
                    <span> <Icon name='file-audio-o' />  <span className='ml-2'>{chat.attachment.fileName}</span> </span>
                    <Icon name='arrow-circle-o-down' />
                </a>
                {chat.message.commentText && <div className='mt-1'>{chat.message.commentText}</div>}
            </RecipientBadge>
        );
    }
    else if (String(contentType).includes('application')) {
        return (
            <RecipientBadge>
                <a href={chat.attachment.publicUrl} target='__blank' className='is-flex is-align-items-center is-justify-content-space-between py-4' >
                    <span>  <Icon name='fa-solid fa-file-o' /> <span className='ml-2'>{chat.attachment.fileName}</span> </span>
                    <Icon name='arrow-circle-o-down' />
                </a>
                {chat.message.commentText && <div className='mt-1'>{chat.message.commentText}</div>}
            </RecipientBadge>
        );
    }
    else if (String(contentType).includes('pdf')) {
        return (
            <RecipientBadge>
                <a href={chat.attachment.publicUrl} target='__blank' className='is-flex is-align-items-center is-justify-content-space-between py-4'  >
                    <span> <Icon name='file-pdf-o' /> <span className='ml-2'>{chat.attachment.fileName}</span>  </span>
                    <Icon name='arrow-circle-o-down' />
                </a>
                {chat.message.commentText && <div className='mt-1'>{chat.message.commentText}</div>}
            </RecipientBadge>
        );
    }
    else if (String(contentType).includes('spreadsheet')) {
        return (
            <RecipientBadge>
                <a href={chat.attachment.publicUrl} target='__blank' className='is-flex is-align-items-center is-justify-content-space-between py-4' >
                    <span> <Icon name='file-excel-o' /><span className='ml-2'>{chat.attachment.fileName}</span> </span>
                    <Icon name='arrow-circle-o-down' />
                </a>
                {chat.message.commentText && <div className='mt-1'>{chat.message.commentText}</div>}
            </RecipientBadge>
        );
    }
    else if (String(contentType).includes('presentation')) {
        return (
            <RecipientBadge>
                <a href={chat.attachment.publicUrl} target='__blank' className='is-flex is-align-items-center is-justify-content-space-between py-4' >
                    <span> <Icon name='file-powerpoint-o' /> <span className='ml-2'>{chat.attachment.fileName}</span>  </span>
                    <Icon name='arrow-circle-o-down' />
                </a>
                {chat.message.commentText && <div className='mt-1'>{chat.message.commentText}</div>}
            </RecipientBadge>
        );
    }
    else if (String(contentType).includes('plain')) {
        return (
            <RecipientBadge>
                <a href={chat.attachment.publicUrl} target='__blank' className='is-flex is-align-items-center is-justify-content-space-between py-4'  >
                    <span>  <Icon name='file-text-o' />   <span className='ml-2'>{chat.attachment.fileName}</span>  </span>
                    <Icon name='arrow-circle-o-down' />
                </a>
                {chat.message.commentText && <div className='mt-1'>{chat.message.commentText}</div>}
            </RecipientBadge>
        );
    }
    else if (String(contentType).includes('spreadsheet')) {
        return (
            <SenderBadge>
                <a href={chat.attachment.publicUrl} target='__blank' className='is-flex is-align-items-center is-justify-content-space-between py-4' >
                    <span>  <Icon name='file-text-o' />   <span className='ml-2'>{chat.attachment.fileName}</span>  </span>
                    <Icon name='arrow-circle-o-down' />
                </a>
                {chat.message.commentText && <div className='mt-1'>{chat.message.commentText}</div>}
            </SenderBadge>
        );
    }
    else if (String(contentType).includes('docx') || String(contentType).includes('documents')) {
        return (
            <RecipientBadge>
                <a href={chat.attachment.publicUrl} target='__blank' className='is-flex is-align-items-center is-justify-content-space-between py-4' >
                    <span>  <Icon name='file-word-o' /> <span className='ml-2'>{chat.attachment.fileName}</span>  </span>
                    <Icon name='arrow-circle-o-down' />
                </a>
                {chat.message.commentText && <div className='mt-1'>{chat.message.commentText}</div>}
            </RecipientBadge>
        );
    }
    else {
        return (
            <SenderBadge>
                <Linkify tagName='span' options={{ attributes: { target: '__blank' } }}  >  {chat.message.commentText}  </Linkify>
            </SenderBadge>
        );
    }

}

export default MessageItemAttatchment