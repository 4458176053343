import { gql } from '@apollo/client';

export const GET_AVAILABLE_APPOINTMENTS = gql`
  query GetAvailableSlots(
    $business: Int!
    $service: Int!
    $appointmentDate: Date!
    $subBusinesses: [Int]
    $appointmentWindow: AppointmentWindow!
  ) {
    getAvailability(
      business: $business
      service: $service
      subBusinesses: $subBusinesses
      appointmentDate: $appointmentDate
      appointmentWindow: $appointmentWindow
    ) {
      first {
        business {
          id
          name
        }
        subBusiness {
          id
          name
        }
        startTime
        startDateOnly
        startTimeOnly
        endTime
        endDateOnly
        endTimeOnly
        timeZone
      }
      second {
        business {
          id
          name
        }
        subBusiness {
          id
          name
        }
        startTime
        startDateOnly
        startTimeOnly
        endTime
        endDateOnly
        endTimeOnly
        timeZone
      }
      third {
        business {
          id
          name
        }
        subBusiness {
          id
          name
        }
        startTime
        startDateOnly
        startTimeOnly
        endTime
        endDateOnly
        endTimeOnly
        timeZone
      }
      fourth {
        business {
          id
          name
        }
        subBusiness {
          id
          name
        }
        startTime
        startDateOnly
        startTimeOnly
        endTime
        endDateOnly
        endTimeOnly
        timeZone
      }
      fifth {
        business {
          id
          name
        }
        subBusiness {
          id
          name
        }
        startTime
        startDateOnly
        startTimeOnly
        endTime
        endDateOnly
        endTimeOnly
        timeZone
      }
      others {
        business {
          id
          name
        }
        subBusiness {
          id
          name
        }
        startTime
        startDateOnly
        startTimeOnly
        endTime
        endDateOnly
        endTimeOnly
        timeZone
      }
    }
  }
`;
