import React from 'react'
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import StarRatings from 'react-star-ratings';
import { useMutation } from '@apollo/client';
import { ADD_REMOVE_FROM_FAVORITES } from '../request';
const FavoriteItem = ({ business, refetch }) => {
    const emptyThumbnail = 'https://s3.amazonaws.com/speedsport-news/speedsport-news/wp-content/uploads/2018/07/01082232/image-not-found.png';

    const history = useHistory();
    const [addRemoveFromFavorite] = useMutation(ADD_REMOVE_FROM_FAVORITES);
    const handleFavourite = async (bId, isFavourite) => {
        try {
            await addRemoveFromFavorite({ variables: { businessId: business.business.id, isFavorite: false }, })
            toast.success('Removed From Favorites');
            refetch()

        } catch (error) {
            // console.log(error);
            toast.error(error?.message || "Could not remove from the favourites")
        }
    }

    return (
        <div className='row g-0  '>
            <div className='col-lg-4'>
                <Link to={{ pathname: `/business/${business.business.id}` }} >
                    <img className='businessImage' src={business?.business.picture?.path || emptyThumbnail} alt={business.name} />
                </Link>
            </div>
            <div className='col-lg-8 p-2 businessdetailContainer'>
                <div className='row g-0'>
                    <div className='col-lg-10'>
                        <Link className='is-6 has-text-weight-bold' title={business.business.name}    >
                            {business.business.name?.length <= 15 ? business.name : `${business.business.name.substring(0, 15)}...`}
                        </Link>
                    </div>
                    <div className='col-lg-2 has-text-right'>
                        <i onClick={() => handleFavourite(business.id, business.business.isFavorite)} className={`fa fa-heart favorite_icon`} />
                    </div>
                </div>
                <div className='is-flex is-justify-content-space-between'>
                    <div className=''>
                        <p className="business_description"> {business.business.description ? (`${business.business.description.substring(0, 20)}...`) : ("No Description")}</p>
                    </div>
                    <div className=''>
                        <i className="fa fa-quote-left favorite_icon mx-1 is-size-6" />
                        {/* <i onClick={() => handleFavourite(business.id, business.isFavorite)} className={`${favorite ? 'fa fa-heart' : 'fa fa-heart-o'} is-size-7 favorite_icon mx-2`} /> */}
                        <i className={`fa fa-envelope favorite_icon mx-2 is-size-6`} />
                        <i className={`fa fa-comments favorite_icon mx-2 is-size-6`} onClick={() => history.push(`messages/${business?.id}`)} />
                    </div>

                </div>
                {business.address1 && (
                    <div className='row g-0'>
                        <div className='col-lg-12'>
                            <i className='fa fa-map-marker' style={{ color: '#037ffc' }}  ></i>
                            <span className='subtitle is-7'>  {business.business.city}  </span>
                        </div>
                    </div>
                )}

                <div className='row g-0'>
                    <div className='col-lg-12'>
                        <div style={{ color: 'rgb(26 17 186)' }}>
                            <StarRatings rating={business?.business?.serviceRatings?.value} starRatedColor='rgb(26 17 186)' starSpacing='3px' starDimension='15px' />
                            &nbsp;[  {business?.business?.serviceRatings?.reviewsCount}]
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default FavoriteItem