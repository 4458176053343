import React, { useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import { APIPaths } from '../../services/api-provider';
import * as axios from 'axios';

/* eslint jsx-a11y/anchor-is-valid: 0 */

const ConfirmEmail = () => {
  const query = new URLSearchParams(useLocation().search);
  const [message, setMessage] = useState(null);
  const [messageClass, setMessageClass] = useState('');

  useEffect(() => {
    const userId = query.get('userId');
    const code = encodeURIComponent(query.get('code'));
    axios.default
      .get(
        `${APIPaths.Gateway}/api/identity/confirmemail?userId=${userId}&code=${code}`
      )
      .then((response) => {
        //{isSuccess: true, errors: Array(0)}
        if (response.data) {
          setMessageClass('success');
          setMessage('You email is confirmed successfully.');
        } else {
          setMessageClass('danger');
          setMessage(
            'An error occurred in confirming the email address. Please try again.'
          );
        }
      })
      .catch((error) => {
        setMessageClass('danger');
        setMessage(
          'An error occurred in confirming the email address. Please try again.'
        );
      })
      .then(() => {});
  }, []);

  return (
    <section className='section'>
      <div className='container'>
        <React.Fragment>
          {message && (
            <div className='column'>
              <div className='column'>
                <h1 className='title'>Email Confirmed</h1>
                <div className={`subtitle notification is-${messageClass}`}>
                  {message}
                </div>
              </div>
            </div>
          )}
        </React.Fragment>
      </div>
      <ToastContainer />
    </section>
  );
};

export default ConfirmEmail;
