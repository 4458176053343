import { gql } from '@apollo/client';

export const GET_STRIPE_PUBLISHABLE_KEY = gql`
  query all {
    getStripePublishableKey {
      isSuccess
      errorMessage
      publishableKey
  }
}
`;

export const STRIPE_TRANSACTION_REQUEST = gql`
  mutation ($description: String, $tokenId: String, $amount: Decimal) {
  invokeStripeTransactionRequest(description: $description, tokenId: $tokenId, amount: $amount) {
    isSaved
    errorMessage
  }
}
`;
