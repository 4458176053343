import { gql } from '@apollo/client';

export const SAVE_BAHMNI_PATIENT = gql`
  mutation SaveBahmniPatient($patientInput: PatientInput!) {
    saveBahmniPatient(patientInput: $patientInput) {
      errorMessage
      isSaved
    }
  }
`;
