import React from 'react';
import Chat from './Chat';

const ChatHome = () => {
    return (
        <div style={{ margin: '0 30%' }}>
            <Chat userId={''} />
        </div>
    );
};

export default ChatHome;

