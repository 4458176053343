import React, { Fragment } from 'react';
import styled from 'styled-components';
import Carousel from 'react-multi-carousel';

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
    slidesToSlide: 3, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 2, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2,
    slidesToSlide: 1, // optional, default to 1.
  },
};

const AvailableTag = styled.div.attrs((props) => ({
  className: '',
}))`
  border: 2px solid #2389e5;
  cursor: pointer;
  text-align: center;
  border-radius: 8px;
  padding-top: 2px !important;
  padding-bottom: 2px !important;
  padding-left: 2px;
  padding-right: 2px;
`;

const CategoryTags = ({ tags, selectedTag, onChange }) => {
  return (
    <Fragment>
      {tags && tags.length > 0 && (
        <section className='px-2 py-1'>
          <div className='container'>
            <Carousel
              responsive={responsive}
              autoPlay={true}
              autoPlaySpeed={2000}
            >
              {tags.map((tag, i) => (
                <AvailableTag
                  key={`availabletag_${i}`}
                  className={`is-size-6 my-3 mx-1 px-3 ${
                    selectedTag?.find((name) => name === tag.name)
                      ? 'tag-active'
                      : 'is-white'
                  }`}
                  onClick={() => onChange(tag)}
                >
                  {tag.description}
                </AvailableTag>
              ))}
            </Carousel>
          </div>
        </section>
      )}
    </Fragment>
  );
};

export default CategoryTags;
