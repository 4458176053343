import React, { useEffect, useState } from 'react'
import { useLazyQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { Skeleton } from 'antd';
import { isEmpty, isEqual, orderBy } from 'lodash';
import { Buttons, Button, Text, Title, Paragraph } from '@safelyq/bulma-ui-library';
import { GET_AVAILABLE_SLOTS } from './requests';

const NewAppointmentSlots = ({ apptInfo, service, serviceProvider, date, currentSlot, selectedSlot, onChangeSlot, timeZone }) => {
    const { businessId } = useParams();
    const business = parseInt(businessId);
    const [uniqueSlots, setuniqueSlots] = useState([])
    const [more, setmore] = useState(false)

    // let uniqueSlots = []

    const [onLoadSlots, { loading }] = useLazyQuery(
        GET_AVAILABLE_SLOTS,
        {

            onCompleted: (data) => {
                let dumpArray = [];
                let dddd = []
                const hasOtherSlotsAvailable = !isEmpty(data.getAvailability.others);
                if (!isEmpty(data.getAvailability.first)) dumpArray.push(data.getAvailability.first);
                if (!isEmpty(data.getAvailability.second)) dumpArray.push(data.getAvailability.second);
                if (!isEmpty(data.getAvailability.third)) dumpArray.push(data.getAvailability.third);
                if (!isEmpty(data.getAvailability.fourth)) dumpArray.push(data.getAvailability.fourth);
                if (!isEmpty(data.getAvailability.fifth)) dumpArray.push(data.getAvailability.fifth);
                if (currentSlot && serviceProvider === currentSlot?.subBusiness?.id) {
                    dumpArray.push(currentSlot);
                }

                if (hasOtherSlotsAvailable) {
                    dumpArray = dumpArray.concat(data.getAvailability.others);
                }
                dddd = Array.from(new Set(dumpArray.map((a) => a.startTimeOnly + ':' + a.subBusiness.id))
                ).map((refkey) => { return dumpArray.find((a) => a.startTimeOnly + ':' + a.subBusiness.id === refkey); });
                dddd = orderBy(dddd, 'startTimeOnly', 'asc');
                setuniqueSlots(dddd)
                setmore(false)

            },
            onError: () => {
                setuniqueSlots([])
            }

        }
    );
    const loadMore = () => {
        setmore(true)
    }




    useEffect(() => {
        if (service || serviceProvider) {

            onLoadSlots({
                fetchPolicy: 'no-cache',
                variables: {
                    service: service,
                    appointmentDate: date,
                    adults: apptInfo.adults ? parseInt(apptInfo.adults) : 0,
                    business: business,
                    appointmentWindow: 'DAY',
                    subBusinesses: !serviceProvider || parseInt(serviceProvider) === parseInt(service) ? [] : [serviceProvider],
                },
            })

        }
        else {

            setuniqueSlots([])
        }

    }, [service, serviceProvider, business, date,apptInfo.adults, onLoadSlots])

    if (loading) {
        return (<Skeleton.Input style={{ height: '200px', }} active={true} size={"large"} block={true} />)
    }
    else {
        return (
            <div>
                {service === "" ? <Paragraph alignment='centered' weight='bold' color='grey-light'>  Select a service to see available time slots  </Paragraph> : (
                    <div className='mt-5'>{uniqueSlots.length > 0 && <Title size='6' color='info' className='mb-4'>   <i>Available Slots</i>   <small className='has-text-grey-light'>({timeZone})</small>    </Title>}
                        {uniqueSlots.length === 0 && <Paragraph alignment='centered' weight='bold' color='grey-light'>  No slots available   </Paragraph>}
                        <Buttons size='small' style={{ height: more ? "100%" : 77, overflow: "hidden" }}>
                            {uniqueSlots.map((slot, index) => {
                                return (
                                    <Button onClick={() => onChangeSlot(slot)} light={!isEqual(slot.startTimeOnly, selectedSlot?.startTimeOnly)} color={'info'} key={`slot-${index}`} className='slot-btn' >
                                        <Text>{slot.startTimeOnly} </Text>
                                    </Button>
                                )
                            })}
                        </Buttons>

                        {uniqueSlots.length > 12 && !more && <Buttons size='small' style={{ justifyContent: "center" }}>  <Button color='info' onClick={loadMore}   >  Load More </Button>  </Buttons>}
                    </div>
                )}
            </div>

        )
    }

}

export default NewAppointmentSlots