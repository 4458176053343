import { gql } from "@apollo/client";

export const SAFELYQ_HOME_TEXT = gql`
  query getHomePage {
    getHomePage {
      errorMessage

      home {
        headers {
          h1
          h2
        }
      }
      isSuccess
    }
  }
`;
