import React from 'react';
import Skeleton from 'react-loading-skeleton';
import {
  Box,
  Icon,
  Button,
  Content,
  Columns,
  Column,
  Field,
  Label,
} from '@safelyq/bulma-ui-library';

export const BookingFormSkeleton = () => {
  return (
    <Box bordered>
      <Content>
        <div className='icon-text is-align-items-center'>
          <Icon name='calendar-check-o' collection='fa'></Icon>
          <h5 className='is-marginless'>New Appointment</h5>
        </div>
      </Content>
      <section>
        <Columns multiline centered>
          <Column size='12'>
            <Field>
              <Skeleton height={40} />
            </Field>
          </Column>
          <Column size='6'>
            <Field>
              <Skeleton height={40} />
            </Field>
          </Column>
          <Column size='6'>
            <Field>
              <Skeleton height={40} />
            </Field>
          </Column>
          <Column size='6'>
            <Field>
              <Skeleton height={40} />
            </Field>
          </Column>
          <Column size='6'>
            <Field>
              <Skeleton height={40} />
            </Field>
          </Column>
          <Column size='6'>
            <Field>
              <Skeleton />
            </Field>
          </Column>
        </Columns>
        <Skeleton width={100} height={30} />
      </section>
    </Box>
  );
};
