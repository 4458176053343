import React, { useState, useEffect, useRef } from 'react';
import { useHistory, useParams, Link } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import moment from 'moment';
import usePageTitle from 'hooks/usePageTitle';
import { useProfile } from 'hooks/useProfile';
import ExistingAppointments from '../Common/ExistingAppointments';
import AppointmentInfo from '../Common/AppointmentInfo';
import Queries from '../Common/Queries';
import BusinessPromotions from '../Common/BusinessPromotions';
import BusinessDescription from '../Common/BusinessDescription';
import SafetyTags from '../Common/SafetyTags';
import BusinessPhone from '../Common/BusinessPhone';
import WebAddress from '../Common/WebAddress';
import Constants from 'constants/index';
import BusinessSlots from '../Common/BusinessSlots';

const AppointmentDetails = () => {
  const { profile } = useProfile();

  usePageTitle('Appointment Details');
  const history = useHistory();
  const { id: appointmentId, businessId } = useParams();
  const [isFavorite, setIsFavorite] = useState(false);
  const [userAppointments, setUserAppointments] = useState(null);
  const [businessData, setBusinessData] = useState(null);
  const [selectedAppointmentSlot, setSelectedAppointmentSlot] = useState(null);
  const [addAppointment] = useMutation(Queries.ADD_USER_APPOINTMENT);
  const [addRemoveFromFavorite] = useMutation(
    Queries.ADD_REMOVE_FROM_FAVORITES
  );
  const todayDate = useRef(moment(new Date()).format('YYYY-MM-DD'));
  const { loading, error, data } = useQuery(
    Queries.GET_BUSINESS_AND_APPOINTMENTS,
    {
      variables: {
        appointmentId: appointmentId,
        businessId: businessId,
        status: 'ALL',
        startDate: todayDate.current,
      },
    }
  );

  const [newAppointmentData, setNewAppointmentData] = useState({
    adults: 1,
    children: 0,
  });

  const onDataChange = (name, value) => {
    setNewAppointmentData({
      ...newAppointmentData,
      [name]: value,
    });
  };

  const handleAppointmentSlotChange = (slot) => {
    setSelectedAppointmentSlot(slot);
  };

  const addNewAppointment = () => {
    if (profile) {
      const appointmentInput = {
        adults: newAppointmentData.adults,
        children: newAppointmentData.children,
        startTime: moment(selectedAppointmentSlot.startTime)
          .utc()
          .format('YYYY-MM-DD hh:mm A'),
        endTime: moment(selectedAppointmentSlot.endTime)
          .utc()
          .format('YYYY-MM-DD hh:mm A'),
        timeZone: selectedAppointmentSlot.timeZone,
        businessId: businessId,
        serviceId: newAppointmentData.serviceId,
        subBusinessId: selectedAppointmentSlot.subBusiness.id,
        userResponses: [],
      };

      addAppointment({
        variables: { appointmentInput: appointmentInput },
      }).then(function (result) {
        history.push(
          `/${result.data.createUserAppointment.appointment.id}/confirm-checkin`
        );
      });
    } else {
      localStorage.setItem(
        Constants.APPOINTMENT_ITEM_NAME,
        JSON.stringify({
          adults: newAppointmentData.adults,
          children: newAppointmentData.children,
          startTime: moment(selectedAppointmentSlot.startTime)
            .utc()
            .format('YYYY-MM-DD hh:mm A'),
          endTime: moment(selectedAppointmentSlot.endTime)
            .utc()
            .format('YYYY-MM-DD hh:mm A'),
          timeZone: selectedAppointmentSlot.timeZone,
          businessId: businessId,
          serviceId: newAppointmentData.serviceId,
          subBusinessId: selectedAppointmentSlot.subBusiness.id,
          userResponses: [],
        })
      );
      localStorage.setItem(
        Constants.BOOK_BUSINESS_URL,
        `/appointment/${businessId}/${appointmentId}/details`
      );
      history.push({
        pathname: '/authentication/login',
      });
    }
  };

  const handleDisabled = () => {
    return (
      !selectedAppointmentSlot ||
      (businessData.maxAllowedGuests > 0 && newAppointmentData.adults === 0)
    );
  };

  const toggleFavorite = () => {
    if (!profile) {
      localStorage.setItem(
        Constants.BOOK_BUSINESS_URL,
        `/appointment/${businessId}/${appointmentId}/details`
      );
      history.push({
        pathname: '/authentication/login',
      });
    }
    addRemoveFromFavorite({
      variables: { businessId: businessId, isFavorite: !isFavorite },
    }).then(function (result) {
      setIsFavorite(!isFavorite);
    });
  };

  useEffect(() => {
    if (loading) {
      //message.loading('Fetching Appointment Details...');
    }

    if (error) {
      //message.error(error.message || 'Error while fetching info...');
      return null;
    }

    if (data) {
      let appointmentInput = JSON.parse(
        localStorage.getItem(Constants.APPOINTMENT_ITEM_NAME)
      );

      if (profile && appointmentInput) {
        localStorage.removeItem(Constants.APPOINTMENT_ITEM_NAME);
        addAppointment({
          variables: { appointmentInput: appointmentInput },
        }).then(function (result) {
          history.push(
            `/${result.data.createUserAppointment.appointment.id}/confirm-checkin`
          );
        });
      } else {
        const {
          getCurrentUserAppointments: appointmentsData,
          getBusinessById,
        } = data;
        setUserAppointments(appointmentsData);
        setBusinessData(getBusinessById);
        setIsFavorite(getBusinessById.isFavorite);
      }
    }
  }, [appointmentId, loading, error, data, profile, addAppointment, history]);

  return (
    <>
      <div className='container'>
        <Link to='/'>
          <i className='fa fa-arrow-left m-2' aria-hidden='true' />
          Back
        </Link>
      </div>
      {userAppointments && businessData && (
        <>
          {businessData.picture && (
            <section className='section px-2 py-2'>
              <div className='container'>
                <div className='card-image'>
                  <figure className='image is-16by9'>
                    <img src={businessData.picture?.path} alt='details header' />
                  </figure>
                </div>
              </div>
            </section>
          )}

          <section className='section container has-background-light py-2'>
            <div className='columns is-mobile mb-0'>
              <div className='column'>
                <p
                  className='title is-size-5 pb-0 mb-1'
                  style={{ whiteSpace: 'nowrap' }}
                >
                  <span>{businessData.name}</span>
                </p>
                {businessData.address1 && businessData.address1.length > 0 && (
                  <p className='sub-title is-size-6 pl-4'>
                    <span className='icon pr-0 mr-1 is-inline'>
                      <i
                        className='fa fa-map-marker'
                        style={{ color: '#037ffc' }}
                      />
                    </span>
                    <span>{businessData.address1}</span>
                  </p>
                )}
              </div>
              <div className='column has-text-right'>
                <p className='title is-size-5' style={{ whiteSpace: 'nowrap' }}>
                  <span
                    className='pl-4 pr-3 icon'
                    style={{ cursor: 'pointer' }}
                  >
                    <i
                      className={`${isFavorite ? 'fa fa-heart' : 'fa fa-heart-o'
                        }`}
                      style={{ color: '#037ffc' }}
                      onClick={() => {
                        toggleFavorite();
                      }}
                    />
                  </span>
                  {/*<span className="pl-4 icon">
                                        <i className="fa fa-share-alt" style={{ color: '#037ffc' }} />
                                    </span>*/}
                </p>
              </div>
            </div>

            <div className='column  p-0'>
              <BusinessSlots
                timeZone={businessData.timeZone}
                slots={businessData.slots || []}
              />
            </div>
          </section>

          <BusinessPromotions promotions={businessData.promotions} />

          <ExistingAppointments
            appointments={userAppointments}
            currentAppointmentId={appointmentId}
            preCheckinOffset={businessData.details.offset}
          />

          <AppointmentInfo
            businessId={businessData.id}
            businessServices={businessData.services}
            appointmentData={newAppointmentData}
            onAppointmentDataChange={onDataChange}
            selectedSlot={selectedAppointmentSlot}
            setSelectedAppointmentSlot={setSelectedAppointmentSlot}
            onAppointmentSlotChange={handleAppointmentSlotChange}
            showHeader={true}
            headerText={`New Appointment`}
            isChildrenAllowed={businessData.isChildrenAllowed}
            slots={businessData.slots}
            maxAllowedGuests={businessData.maxAllowedGuests}
            hasSubLevels={
              !(
                businessData.children.length === 1 &&
                businessData.children[0].id === +businessId
              )
            }
          />

          {businessData.preCheckinInstructions &&
            businessData.preCheckinInstructions.length > 0 && (
              <section className='section container px-0 py-4'>
                <div className='px-4 card'>
                  <section className='section container px-0 py-4'>
                    <div className='px-1'>
                      <p className='title is-size-6 has-text-weight-bold'>
                        Special Instructions
                      </p>
                      <div className='card-content pt-1 pl-0'>
                        <div className='content'>
                          <div className='subtitle is-6 has-text-justified'>
                            {businessData.preCheckinInstructions[0]}
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </section>
            )}

          <section className='section container px-0 pt-0 pb-6'>
            <div className='buttons is-centered mt-2'>
              <Link
                to=''
                color={'primary'}
                gradient='true'
                className='button is-info is-large'
                onClick={addNewAppointment}
                disabled={handleDisabled()}
              >
                Confirm Appointment
              </Link>
            </div>
          </section>

          <BusinessDescription description={businessData.description} />

          <SafetyTags safetyTags={businessData.safetyTags} />

          <section className='section container px-0 py-4'>
            <div className='px-0 card'>
              <div className='card-content pt-3'>
                <div className='content'>
                  <BusinessPhone businessPhone={businessData.phone} />
                  <WebAddress webAddress={businessData.webAddress} />
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default AppointmentDetails;
