import React, { useState } from 'react'
import styled from 'styled-components';
import { Title, Container, Box, Column, Paragraph } from '@safelyq/bulma-ui-library';
import { Get_Terms_Condition_Page_Info } from 'Pages/TermsAndConditionsPage/request';
import { useQuery } from '@apollo/client';
import { Get_Privacy_Policy_Page_Info } from './request';
const StyledBox = styled.div`
  width: fit-content;
  padding: 10px 20px;
  background: white;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
`;
const PrivacyPolicyPage = () => {
    const [data, setData] = useState(null)

    useQuery(Get_Privacy_Policy_Page_Info,
        {
            fetchPolicy: 'no-cache',
            onCompleted: (res) => {
                setData(res.getPrivacyPolicyPageInfo.privacyPolicy)
            }
        })
    return (
        <Container>
            <StyledBox>
                <Title size='5' className='has-text-centered'>{data?.title}</Title>
            </StyledBox>
            <Box>
                <Column size='full'>
                    <Paragraph><b>{data?.dateUpdated}</b> {data?.pg1}</Paragraph>
                </Column>
                <Column size='full'>
                    <Paragraph>  {data?.pg2}  </Paragraph>
                    <Paragraph> {data?.pg3}</Paragraph>
                    <p className='mb-3'><strong >{data?.secondTitle}</strong></p>
                    <ol className='ml-5 mb-5'>
                        {data?.options.map((opt) => <li>{opt}</li>)}
                    </ol>

                </Column>
                <Column size='full'>
                    <ol>
                        {data?.list.map((dt) => {
                            return (
                                <li>
                                    <strong>{dt?.title}</strong>
                                    <p>{dt?.pg1}</p>
                                    <p>{dt?.listIntro}</p>
                                    <p>{dt?.pg2}</p>
                                    <p>{dt?.pg3}</p>
                                    <p>{dt?.pg4}</p>
                                    <ol className='ml-5'>
                                        {dt?.list?.map((subli) => {
                                            return (
                                                <li>
                                                    <p> <b>{subli?.title}</b>  {subli?.pg1}  </p>
                                                </li>
                                            )
                                        })}
                                    </ol>
                                    <table className={dt?.table ? "table" : ""}>
                                        <tr>
                                            <th> {dt?.table?.tableHeadings?.h1}</th>
                                            <th> {dt?.table?.tableHeadings?.h2}</th>
                                        </tr>
                                        {dt?.table?.tableContent?.map((tabd) => {
                                            return (
                                                <tr>
                                                    <td>{tabd?.t1}</td>
                                                    <td>{tabd?.t2}</td>
                                                </tr>
                                            )
                                        })}

                                    </table>
                                </li>
                            )
                        })}
                    </ol>
                </Column>
                <Column size='full has-test-center'>
                    <Paragraph>{data?.copyRight}</Paragraph>
                </Column>
            </Box>
        </Container>
    )
}

export default PrivacyPolicyPage
