import React, { useState, useRef } from 'react';
import * as _ from 'lodash';
import { Box, Icon, Title, Subtitle, Field, Help, Label, Control, TextInput, Button } from '@safelyq/bulma-ui-library';
import { useMutation, useQuery } from '@apollo/client';
import { GET_USER_PROFILE, SAVE_USER_PROFILE } from '../../routes/ManageSettings/requests';
import { LoadingSection } from '../LoadingSection';
import PhoneNumberField from './components/PhoneNumberField/PhoneNumberField';
import { toast } from 'react-toastify';
import { Spin } from 'antd';


export const GeneralSetting = () => {
  const [values, setValues] = useState({
    firstName: '',
    lastName: '',
    email: '',
  });
  const [loader, setloader] = useState(false)
  let FirstName = useRef("");
  let LastName = useRef("");


  const { loading, refetch } = useQuery(GET_USER_PROFILE, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      setValues({ ...data.getUserProfile.user })
      FirstName.current = data.getUserProfile.user.firstName
      LastName.current = data.getUserProfile.user.lastName
    }
  });

  const [onProfileUpdate] = useMutation(SAVE_USER_PROFILE,);

  const handleValuesChange = (e) => {
    setValues((prev) => ({
      ...prev,
      [e.target.name]: e.target.value

    }))
  }

  const handleSaveChanges = async () => {
    try {
      setloader(true)
      await onProfileUpdate({
        variables: {
          userProfileInput: {
            firstName: values.firstName,
            lastName: values.lastName,
            phoneNumber: values.phoneNumber,
            ...values.userPreferences,
          },
        }
      })
      await refetch()
      setloader(false)
      toast.success("Profile updated successfully")
    } catch (error) {
      console.log("Error", error);
    }
  }


  if (loading) {
    return <LoadingSection />
  }
  else {
    return (
      <Box shadow={true}>
        <Title size='5'>   <Icon name='user' /> General Setting  </Title>
        <Subtitle size='6' color='grey-light'>  Configure account settings  </Subtitle>
        <hr />
        <div className='row'>
          <div className='col-lg-6 mb-4'>
            <Field>
              <Label>First Name</Label>
              <Control>  <TextInput name='firstName' value={values.firstName} type='text' onChange={handleValuesChange} />   </Control>
              <Help>    <i>Visible to profile</i>  </Help>
            </Field>
          </div>
          <div className='col-lg-6 mb-4'>
            <Field>
              <Label>Last Name</Label>
              <Control> <TextInput name='lastName' value={values.lastName} type='text' onChange={handleValuesChange} /> </Control>
            </Field>
          </div>
          <div className='col-lg-6 mb-4'>
            <Field>
              <Label>Email Address</Label>
              <Control>  <TextInput value={values.email} type='text' disabled />  </Control>
              <Help>   <i>For email notifications and logging in</i>   </Help>
            </Field>
          </div>
          <div className='col-lg-6 mb-4'>
            <PhoneNumberField values={values} onProfileUpdate={onProfileUpdate} />
          </div>
          <div className='col-lg-12'>
            <Spin spinning={loader}>
              <Button color='info' disabled={FirstName.current === values.firstName && LastName.current === values.lastName} onClick={handleSaveChanges}>Save Changes</Button>
            </Spin>
          </div>
        </div>
      </Box>
    )
  }

} 