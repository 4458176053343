import React, { Fragment } from 'react'
import { Modal, Icon, Title } from '@safelyq/bulma-ui-library';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { TemplateTextPreview } from '../TemplateTextPreview';
import { TemplateCouponPreview } from '../TemplateCouponPreview';

export const TemplatePreview = ({ isOpen, setIsOpen, campaign, campaignGroups, selectedMessageType, selectedCoupon, marketingCampaignTemplates, coupon, sMS, setSMS, campaignFiles }) => {

    const state = useSelector(state => state)
    const business = state?.business;

    const modalStyle = {
        position: "absolute",
        top: "5%",
    }

    // Text Templates
    let marketingCampaignText = marketingCampaignTemplates?.find(template => template?.name === "Marketing Campaign Notification")

    // Reorder the campaignGroups array to place MMS and SMS campaigns first
    const reorderedTemplateText = [...marketingCampaignText.templates].sort((a, b) => {
        if (a.notificationChannelDispalyName === "MMS" || a.notificationChannelDispalyName === "SMS") return -1; // Place MMS and SMS first
        // if (b.notificationChannelDispalyName === "MMS" || b.notificationChannelDispalyName === "SMS") return 1; // Place MMS and SMS first
        return 0;
    });

    // Coupon Templates
    let marketingCampaignCoupon = marketingCampaignTemplates?.find(template => template?.name === "Marketing Campaign Notification For Coupon")

    // Reorder the campaignGroups array to place MMS and SMS campaigns first
    const reorderedTemplateCoupon = [...marketingCampaignCoupon.templates].sort((a, b) => {
        if (a.notificationChannelDispalyName === "MMS" || a.notificationChannelDispalyName === "SMS") return -1; // Place MMS and SMS first
        // if (b.notificationChannelDispalyName === "MMS" || b.notificationChannelDispalyName === "SMS") return 1; // Place MMS and SMS first
        return 0;
    });

    return (
        <Fragment>
            <Modal active={isOpen} clipped>
                <Modal.Background />
                <Modal.Card style={modalStyle}>
                    <Modal.Card.Head>
                        <div className='container is-flex is-justify-content-space-between is-align-items-center'>
                            <Title size='6' className='m-0'>
                                {selectedCoupon ? marketingCampaignCoupon?.name : marketingCampaignText?.name}
                            </Title>
                            <Icon name='close' className="is-clickable mx-2" onClick={() => setIsOpen(false)} />
                        </div>
                    </Modal.Card.Head>
                    <Modal.Card.Body>
                        {
                            // If no campaign Type is selected then ask user to select
                            campaignGroups?.filter(camp => camp?.isSelected).length === 0 ? <p className='is-size-6 has-text-weight-semibold has-text-danger'> Select Message Type to preview Template </p>
                                :
                                selectedCoupon ?
                                    <TemplateCouponPreview reorderedTemplateCoupon={reorderedTemplateCoupon} campaignGroups={campaignGroups} coupon={coupon} campaign={campaign} selectedMessageType={selectedMessageType} />
                                    :
                                    <TemplateTextPreview reorderedTemplateText={reorderedTemplateText} campaignGroups={campaignGroups} sMS={sMS} setSMS={setSMS} campaign={campaign} selectedMessageType={selectedMessageType} campaignFiles={campaignFiles} />
                        }
                    </Modal.Card.Body>
                </Modal.Card>
            </Modal >
        </Fragment >
    )
}

const MarketingBox = styled.div`
  border: 2px solid #50677736;
  border-radius: 8px;
  padding: 10px;
  margin-bottom: 16px
`;

