import { gql } from '@apollo/client';

export const SAVE_BUSINESS = gql`
  mutation SaveBusiness($businessInput: BusinessInput!) {
    saveBusiness(businessInput: $businessInput) {
      isSaved
      errorMessage
    }
  }
`;

export const GET_BUSINESS_BY_ID = gql`
  query GetBusinessById($id: Int!) {
  getBusinessById(id: $id) {
    businessSubscription {
      subscriptionPlan {
        features {
          advanceAppointmentAllowed
        }
        name
      }
    }
  }
}
`;
