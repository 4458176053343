import { useQuery } from '@apollo/client';
import { GET_ABOUT_US_INFO } from 'components/Common/Queries';

export const useData = () => {
  const { loading: isLoading, error, data } = useQuery(GET_ABOUT_US_INFO);

  const { getAboutUsPageInfo } = data || {};

  return { isLoading, error, data: getAboutUsPageInfo?.aboutUs };
};
