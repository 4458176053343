import React from 'react';

const SafetyTags = ({ safetyTags }) => {
  return (
    <>
      {safetyTags && safetyTags.length > 0 && (
        <section className="section px-0 py-2">
          <div className="container">
            <div className="card">
              <div className="card-header-title mb-0 ml-1">
                <div className="title is-size-6 has-text-weight-bold">
                  Personal Safety Standards
                </div>
              </div>
              <div className="card-content pt-1">
                <div className="content">
                  <div className="columns is-multiline is-mobile">
                    {safetyTags.map((tag) => {
                      return (
                        <div
                          key={`safetyTag_${tag}`}
                          className="column is-size-6 is-one-quarter-tablet is-half-mobile pl-0"
                        >
                          <span className="icon has-text-info">
                            <i className="fa fa-check" />
                          </span>
                          {tag}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default SafetyTags;
