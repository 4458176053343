import React, { forwardRef } from 'react';
import moment from 'moment-timezone';
import ScrollMenu from "react-horizontal-scroll-menu";
import { IoIosArrowDropleftCircle, IoIosArrowDroprightCircle } from "react-icons/io";

const AppointmentsCalendar = forwardRef(({ daysOfweek, selected, onSelect }, ref) => {
    const currentDay = moment().format("D");

    const MenuItem = ({ title, text, weekDate, selected, key, extraClass, noOfAppointments }) => {
        return (
            <div className={`hsm_menu-item hsm_dayItem ${selected ? "active" : ""} ${currentDay === text ? "today" : null} `} key={key} >
                <h5 className="hsm_day">{title}</h5>
                <span className="hsm_day"> {text}</span>
                {extraClass === "has_appointment" && (<span className='hsm_appt_bubble'>{noOfAppointments}</span>)}
            </div>
        );
    };

    const Menu = (selected) =>
        daysOfweek.map(({ id, weekday, weekDate, extraClass, noOfAppointments }) => {
            return (
                <MenuItem
                    title={weekday.format("ddd")}
                    text={weekday.format("D")}
                    key={weekDate}
                    weekDate={weekDate}
                    selected={selected}
                    extraClass={extraClass}
                    noOfAppointments={noOfAppointments}
                />
            );
        });

    const menu = Menu(selected);
    return (
        <>

            <ScrollMenu
                ref={ref}
                data={menu}
                arrowLeft={<IoIosArrowDropleftCircle className='hsm_button' />}
                arrowRight={<IoIosArrowDroprightCircle className='hsm_button' />}
                selected={selected}
                onSelect={onSelect}
                scrollToSelected={true}
            />
        </>
    )



})

export default AppointmentsCalendar



