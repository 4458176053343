import { gql } from '@apollo/client';
export const GET_BUSINESS_RATINGS = gql`
  query GetBusinessRating($id: Int!) {
    getBusinessById(id: $id) {
      id,
      name,
      userRatings {
        id
        value
        ratingType
        modifiedTime
        isVerifiedVisitor
        reviewComment {
          commentText
          user {
            fullName
          }
        }
        reviewCommentResponse {
          id
          commentText
          modifiedTime
          user {
            fullName
          }
        }
      }
      serviceRatings {
        ratingType
        value
        reviewsCount
        modifiedTime
      }
       
    }
  }
`;

export const SAVE_BUSINESS_RATING = gql`
  mutation SaveBusinessRating($ratingInput: RatingInput) {
    saveBusinessRatings(ratingInput: $ratingInput) {
      isSaved
      errorMessage
    }
  }
`;

