import React, { useContext, useReducer, createContext } from 'react';
import moment from 'moment';

const BookingStateContext = createContext();
const BookingDispatchContext = createContext();

const bookingReducer = (state, action) => {
  switch (action.type) {
    case 'SET_SERVICE':
      const {
        service: selectedService,
        business: { services },
      } = action.payload;

      const { businesses } =
        services.find(({ id }) => parseInt(id) === parseInt(selectedService)) ||
        {};

      const isOnlyOneSubLevel = businesses && businesses.length === 1;

      if (isOnlyOneSubLevel) {
        return {
          ...state,
          service: selectedService,
          serviceSubLevels: businesses,
          serviceProvider: businesses[0].id,
          subLevelIds: businesses?.map(({ id }) => id),
        };
      }

      return {
        ...state,
        service: selectedService,
        serviceSubLevels: businesses,
        serviceProvider: undefined,
        subLevelIds: businesses?.map(({ id }) => id),
      };

    case 'SET_APPT_DATE':
      return {
        ...state,
        appointmentDate: action.payload,
      };

    case 'SET_SERVICE_PROVIDER':
      return {
        ...state,
        serviceProvider: action.payload,
      };

    case 'SET_NOTES':
      return {
        ...state,
        notes: action.payload,
      };

    case 'SET_ADULTS':
      return {
        ...state,
        adults: action.payload,
      };

    case 'SET_CHILDRENS':
      return {
        ...state,
        children: parseInt(action.payload),
      };

    case 'SET_SLOT':
      return {
        ...state,
        selectedSlot: action.payload,
      };

    case 'SET_VIEW':
      return {
        ...state,
        view: action.payload,
      };

    default:
      throw new Error(`Unknow action type: ${action.type}`);
  }
};

export const BookingProvider = ({ children }) => {
  const persistData = localStorage.getItem('persist-data');

  const [state, dispatch] = useReducer(bookingReducer, {
    appointmentDate: moment().format('YYYY-MM-DD'),
    children: 0,
    view: 'GroupByTimeView',
    ...JSON.parse(persistData),
  });

  return (
    <BookingDispatchContext.Provider value={dispatch}>
      <BookingStateContext.Provider value={state}>
        {children}
      </BookingStateContext.Provider>
    </BookingDispatchContext.Provider>
  );
};

export const useBookingState = () => useContext(BookingStateContext);
export const useBookingDispatch = () => useContext(BookingDispatchContext);
