import React from 'react';

const BusinessPromotions = ({ promotions }) => {
  return (
    <>
      {promotions && promotions.length > 0 && (
        <section className="section px-2 py-2">
          <div className="container">
            <div
              key={promotions[0].id}
              className="notification is-info is-large has-text-centered"
            >
              {promotions[0].text}
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default BusinessPromotions;
