import React from 'react'
import ClipLoader from 'react-spinners/ClipLoader';

const PriorSection = ({ chatSLBTextData, isLoadingText, setUserStatement, input, setInput, setShowInput, setMessages, messages }) => {
    const options = [
        {
            message: "Suggest some healthy vegetarian restaurants "
        },
        {
            message: "What's the best pizza place in town?"
        },
        {
            message: "I'm craving something spicy, what should I eat?"
        },
        {
            message: "Find me a good Italian restaurant downtown"
        }
    ];

    const handleOptionsSelected = (message) => {
        // setUserStatement(message);
        setShowInput(true);
        setInput(message);
        // input.current = message;
        // setMessages([...messages, { id: messages.length + 1, text: message, sender: "user" }]);
    }
    return (
        isLoadingText
            ?
            <div style={{ height: "100%" }} className='is-flex is-justify-content-center is-align-items-center'>
                <ClipLoader size={28} />
            </div>
            :
            <div className='is-flex is-align-items-center pl-5 pr-5' style={{ height: "100%" }}>
                <div className='py-4'>
                    <p className='is-fullwidth is-size-3 is-size-4-touch has-text-weight-semibold mb-3'>
                        {chatSLBTextData?.title || "Hi"}
                        <br />
                        {chatSLBTextData?.subTitle}
                    </p>
                    <div className='columns is-fullheight' style={{ height: "100%", display: "flex", flexWrap: "wrap" }}>
                        {
                            chatSLBTextData?.suggestions?.map((option, index) => {
                                return <div className='column is-one-quarter-widescreen is-one-quarter-fullhd is-half-desktop is-half-tablet is-full-mobile is-clickable' onClick={() => handleOptionsSelected(option)}>
                                    <div className='card-custom has-background-white p-4'>
                                        <p>{option}</p>
                                    </div>
                                </div>
                            })
                        }
                    </div>
                    <p className='is-size-6 has-text-weight-medium has-text-grey-dark'>
                        {chatSLBTextData?.description} <br />
                        <span className='is-hidden-desktop has-text-weight-normal is-underline has-text-link' onClick={() => setShowInput(true)}>Get Started</span>
                    </p>
                </div>
            </div >
    )
}

export default PriorSection